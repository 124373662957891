import React, { useState, useEffect } from "react";
import "./App.css";
import "./ThemeContext.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// AMCCustomer
// import AMCCustomerForm from "../RunningAccountsProjects/AMCCustomer/AMCCustomerForm";
import AMCCustomerForm from "./RunningAccountsProject/AmcCustomer/AMCCustomerForm";
import AMCCustomerTable from "./RunningAccountsProject/AmcCustomer/AMCCustomerTable";
import AMCCustomerEditForm from "./RunningAccountsProject/AmcCustomer/AMCCustomerEditForm";

import AMCProjectForm from "./RunningAccountsProject/AmcProject/AMCProjectForm";
import AMCProjectTable from "./RunningAccountsProject/AmcProject/AMCProjectTable";
import AMCProjectEditForm from "./RunningAccountsProject/AmcProject/AMCProjectEditForm";

import BOQForm from "./RunningAccountsProject/BOQ/BOQForm";
import BOQTable from "./RunningAccountsProject/BOQ/BOQTable";
import BOQEditForm from "./RunningAccountsProject/BOQ/BOQEditForm";

import BOQAddQtyForWeek from "./RunningAccountsProject/BOQ/BOQAddQtyForWeek";
import BOQAddQtyForMonths from "./RunningAccountsProject/BOQ/BOQAddQtyForMonths";

// Audit
import AuditForm from "./Audit/AuditForm";
import AuditEditForm from "./Audit/AuditEditForm";
import AuditTable from "./Audit/AuditTable";
import AuditView from "./Audit/AuditView";

// Reports
import ReportPage from "./AllReports/ReportPage";
import ScheduleGenerator from "./AllReports/ScheduleGenerator";

// ReportsView
import AmcReportView from "./AllReportsView/AmcReportView";
import ProcurementReportView from "./AllReportsView/ProcurementReportView";
import PreventiveReportView from "./AllReportsView/PreventiveReportView";

// ScheduleView
import AmcSchedulesView from "./AllScheduleView/AmcSchedulesView";
import BreakdownView from "./AllScheduleView/BreakdownView";
import PreventiveSchedulesView from "./AllScheduleView/PreventiveSchedulesView";

// AllSchedules
import AllPreventiveSchedules from "./AllSchedules/AllPreventiveSchedules";
import BreakDownSchedules from "./AllSchedules/BreakDownSchedules";
import AllAmcSchedules from "./AllSchedules/AllAmcSchedules";
import ElectricalDepartment from "./AllSchedules/ElectricalDepartment";
// ArchivedsTables
import ArchivedUser from "./ArchivedsTables/ArchivedUser";
import ArchivedCustomer from "./ArchivedsTables/ArchivedCustomer";
import ArchivedManPowerRequire from "./ArchivedsTables/ArchivedManPowerRequire";
import ArchivedProject from "./ArchivedsTables/ArchivedProject";
import ArchivedContractorCompany from "./ArchivedsTables/ArchivedContractorCompany";
import ArchivedDepartment from "./ArchivedsTables/ArchivedDepartment";
import ArchivedSubDepartment from "./ArchivedsTables/ArchivedSubDepartment";
import ArchivedEquipment from "./ArchivedsTables/ArchivedEquipment";
import ArchivedEquipmentType from "./ArchivedsTables/ArchivedEquipmentType";
import ArchivedEquipmentSubType from "./ArchivedsTables/ArchivedEquipmentSubType";
import ArchivedEquipmentSubTypevariant from "./ArchivedsTables/ArchivedEquipmentSubTypevariant";
import ArchivedAllSchedules from "./ArchivedsTables/ArchivedAllSchedules";
import ArchivedAllAmcSchedules from "./ArchivedsTables/ArchivedAllAmcSchedules";
import ArchivedAllAmcs from "./ArchivedsTables/ArchivedAllAmcs";
import ArchivedBreakdown from "./ArchivedsTables/ArchivedBreakdown";
import ArchivedVendor from "./ArchivedsTables/ArchivedVendor";
import ArchivedPrRequest from "./ArchivedsTables/ArchivedPrRequest";
import ArchivedPrRequestList from "./ArchivedsTables/ArchivedPrRequestList";
import ArchivedApprovedPR from "./ArchivedsTables/ArchivedApprovedPR";
import ArchivedStatutory from "./ArchivedsTables/ArchivedStatutory";
import ArchivedInventory from "./ArchivedsTables/ArchivedInventory";
import ArchivedPoList from "./ArchivedsTables/ArchivedPoList";
import ArchivedInward from "./ArchivedsTables/ArchivedInward";
import ArchivedTechnicalList from "./ArchivedsTables/ArchivedTechnicalList";
import ArchivedRejectedStock from "./ArchivedsTables/ArchivedRejectedStock";
import ArchivedAmcConsumption from "./ArchivedsTables/ArchivedAmcConsumption";
import ArchivedOutwardlist from "./StoreManagement/ArchivedOutwadrlist";
import ArchivedDocumentCategory from "./ArchivedsTables/ArchivedDocumentCategory";
import ArchivedSafetyInduction from "./ArchivedsTables/ArchivedSafetInduction";
import ArchivedActualmanPower from "./ArchivedsTables/ArchivedActualmanPower";

// import ArchivedHrList from "./ArchivedsTables/ArchivedHrList";
import ArchivedhrList from "./ArchivedsTables/ArchivedhrList";
import ArchivedMatcode from "./ArchivedsTables/ArchivedMatcode";
import ArchivedLevel1 from "./ArchivedsTables/ArchivedLevel1";
import ArchivedLevel2 from "./ArchivedsTables/ArchivedLevel2";
import ArchivedLevel3 from "./ArchivedsTables/ArchivedLevel3";
import ArchivedFrequency from "./ArchivedsTables/ArchivedFrequency";
import ArchivedDesignations from "./ArchivedsTables/ArchivedDesignatons";

// Component
import SignUp from "./Component/SignUp";
import Login from "./Component/Login";
import Sidebar from "./Component/Sidebar";
import Dashboard from "./Component/Dashboard";
import Header from "./Component/Header";

// EditsForms
import EditSafetyInduction from "./EditsForms/EditSafetyInduction";
import EditEmailConfig from "./EditsForms/EditEmailConfig";
import EditLibraryDocument from "./EditsForms/EditLibraryDocument";
import EditLibraryCategory from "./EditsForms/EditLibraryCategory";
import EditSidePettyCash from "./EditsForms/EditSidePettyCash";
import EditCustomer from "./EditsForms/EditCustomer";
import EditPOConfiguration from "./EditsForms/EditPOConfiguration";
import EditOutward from "./EditsForms/EditOutward";
import EditInward from "./EditsForms/EditInward";
import EditPRRequest from "./EditsForms/EditPRRequest";
import EditProject from "./EditsForms/EditProject";
import EditContractor from "./EditsForms/EditContractor";
import EditUserForm from "./EditsForms/EditUserForm";
import EditAmcForm from "./EditsForms/EditAmcForm";
import EditManpower from "./EditsForms/EditManpower";
import EditActualManpower from "./EditsForms/EditActualManpower";
import EditVendor from "./EditsForms/EditVendor";
import EditMatCode from "./EditsForms/EditMatCode";
import EditDesignation from "./EditsForms/EditDesignation";
import EditFrequency from "./EditsForms/EditFrequency";
import EditStatutoryCompliance from "./EditsForms/EditStatutoryCompliance";
import EditBreakdown from "./EditsForms/EditBreakdown";
import EditEquipmentType from "./EditsForms/EditEquipmentType";
import EditNewEquipment from "./EditsForms/EditNewEquipment";
import EditEquipmentSubType from "./EditsForms/EditEquipmentSubType";
import EditEquipmentSubTypeVariant from "./EditsForms/EditEquipmentSubTypeVariant";
import EditDepartment from "./EditsForms/EditDepartment";
import EditSubDepartment from "./EditsForms/EditSubDepartment";
import EditHRModules from "./EditsForms/EditHRModules";
import EditDailyProgress from "./EditsForms/EditDailyProgress";
import EditEmailTemplate from "./EditsForms/EditEmailTemplate";
import EditHrCompilance from "./EditsForms/EditHrCompilance";
import EditReturnable from "./EditsForms/EditReturnable";




// forms
import DailyProgressForm from "./forms/DailyProgressForm";
import EmailConfigForm from "./forms/EmailConfigForm";
import SafetyInductionForm from "./forms/SafetyInductionForm";
import LibraryCategoryForm from "./forms/LibraryCategoryForm";
import LibraryDocumentsForm from "./forms/LibraryDocumentsForm";
import SatutoryForm from "./forms/SatutoryForm";
import UserForm from "./forms/UserForm";
import VendorForm from "./forms/VendorForm";
import SidePettyCashForm from "./forms/SidePettyCashForm";
import EquipmentForm from "./forms/EquipmentForm";
import AmcSchedulesForm from "./forms/AmcSchedulesForm";
import NewEquipmentForm from "./forms/NewEquipmentForm";
import ProjectForm from "./forms/ProjectForm";
import PRRequestForm from "./forms/PRRequestForm";
import CustomerForm from "./forms/CustomerForm";
import BreakDownForm from "./forms/BreakDownForm";
import ManpowerRequirmentForm from "./forms/ManpowerRequirmentForm";
import ActualManpowerForm from "./forms/ActualManpowerForm";
import DesignationForm from "./forms/DesignationForm";
import ContractorCompanyForm from "./forms/ContractorCompanyForm";
import EquipmentSubTypeForm from "./forms/EuipmentSubTypeForm";
import FrequencyConfig from "./forms/FrequencyConfig";
import EquipmentTypeForm from "./forms/EquipmentTypeForm";
import HRForm from "./forms/HRForm";
import EquipmentTypevariantForm from "./forms/EquipmentTypevariantForm";
import UserTypeForm from "./forms/UserTypeForm";
import DepartmentForm from "./forms/DepartmentForm";
import SubDepartmentForm from "./forms/SubDepartmentForm";
import StatutoryComplianceForm from "./forms/StatutoryComplianceForm";
import HrCompilanceForm from "./forms/HrCompilanceForm";
import ReturnableForm from "./forms/ReturnableForm";

// MateCode
import LevelConfig from "./Matcode/LevelConfig";
import Level1Form from "./Matcode/Level1Form";
import UpdateMatCodeLevel1 from "./Matcode/UpdateMatCodeLevel1";
import UpdateMatCodeLevel2 from "./Matcode/UpdateMatCodeLevel2";
import UpdateMatCodeLevel3 from "./Matcode/UpdateMatCodeLevel3";
import MatCodeConfigForm from "./Matcode/MatCodeConfigForm";
import Level2Form from "./Matcode/Level2Form";
import Level3Form from "./Matcode/Level3Form";
import MatCode from "./Matcode/MatCode";
import UpdateMatCode from "./Matcode/UpdateMatCode";

// StoreManagement
import AmcConsumption from "./StoreManagement/AmcConsumption";
import AmcConsumptionHistory from "./StoreManagement/AmcConsumptionHistory";
import POConfigurationForm from "./StoreManagement/POConfigurationForm";
import POConfigurationlist from "./StoreManagement/POConfigurationlist";
import PoLisTtab from "./StoreManagement/PoListTab";
import InwardForm from "./StoreManagement/InwardForm";
import OutwardForm from "./StoreManagement/OutwardForm";
import InwardList from "./StoreManagement/InwardList";
import OutwardList from "./StoreManagement/OutwardList";
import POView from "./StoreManagement/POView";
import InwardView from "./StoreManagement/InwardView";
import OutwardView from "./StoreManagement/OutwardView";
import GeneratePO from "./StoreManagement/GeneratePO";
import Inventory from "./StoreManagement/Inventory";
import RejectedStock from "./StoreManagement/RejectedStock";
import InventoryView from "./StoreManagement/InventoryView";
import RejectedStockView from "./StoreManagement/RejectedStockView";
import InventoryHistory from "./StoreManagement/InventoryHistory";
import RejectedHistory from "./StoreManagement/RejectedHistory";
import TechnicalRequestList from "./StoreManagement/TechnicalRequestList";
import TechnicalRequestListView from "./StoreManagement/TechnicalRequestListView";
import ApprovedTechnical from "./StoreManagement/ApprovedTechnical";
import ApprovedTechnicalView from "./StoreManagement/ApprovedTechnicalView";
// tables
import EmailConfig from "./tables/EmailConfig";
import EmailTemplateForm from "./forms/EmailTemplateForm";
import EmailTemplate from "./tables/EmailTemplate";
import DailyProgress from "./tables/DailyProgress";
import HrTable from "./tables/HrTable";
import SafetyInduction from "./tables/SafetyInduction";
import User from "./tables/User";
import PRRequest from "./tables/PRRequest";
import Vendor from "./tables/Vendor";
import AmcSchedules from "./tables/AmcSchedules";
import NewEquipment from "./tables/NewEquipment";
import SidePettyCash from "./tables/SidePettyCash";
import ContractorCompany from "./tables/ContractorCompany";
import EquipmentSubType from "./tables/EquipmentSubType";
import EquipmentType from "./tables/EquipmentType";
import EquipmentTypevariant from "./tables/EquipmentTypevariant";
import ReminderDetails from "./tables/ReminderDetails";
import Project from "./tables/Project";
import Designation from "./tables/Designation";
import Customer from "./tables/Customer";
import Department from "./tables/Department";
import ManpowerRequirment from "./tables/ManpowerRequirment";
import ActualManpower from "./tables/ActualManpower";
import Frequency from "./tables/Frequency";
import UserType from "./tables/UserType";
import PRRequestList from "./tables/PRRequestList";
import PRRequestListTab from "./tables/PRRequestListTab";
import ApprovedPRList from "./tables/ApprovedPRList";
import SubDepartment from "./tables/SubDepartment";
import ReportManpower from "./tables/ReportManpower";
import StatutoryCompliance from "./tables/StatutoryCompliance";
import DocumentCategory from "./tables/DocumentCategory";
import DocumentLibrary from "./tables/DocumentLibrary";
import UserAccessTable from "./UserAcess/UserAccessTable";
import HrCompilances from "./tables/HrCompilances";
import Returnable from "./tables/Returnable";


// Cold Work Permint
import TemplateForm from "./ColdWorkPermit/TemplateForm";
import TemplategetForm from "./ColdWorkPermit/TemplategetForm";
import PermitRequestForm from "./ColdWorkPermit/PermitRequestForm";
import PermitRequestFormEdit from "./ColdWorkPermit/PermitRequestFormEdit";
import PermitRequestForRequesterTab from "./ColdWorkPermit/PermitRequestForRequesterTab";
import PermitRequestForIssuerTab from "./ColdWorkPermit/PermitRequestForIssuerTab";
import PermitRequestForSafetyTab from "./ColdWorkPermit/PermitRequestForSafetyTab";
import PermitRequestEditTab from "./ColdWorkPermit/PermitRequestEditTab";
import PermitExtensionRequestView from "./ColdWorkPermit/PermitExtensionRequestView";
import PermitPdfFormate from "./ColdWorkPermit/PermitPdfFormate";
import ColdWorkPermitTemplate from "./ColdWorkPermit/ColdWorkPermitTemplate";
import PermitExtensionRequestEdit from "./ColdWorkPermit/PermitExtensionRequestEdit";
import PermitRequestRejectView from "./ColdWorkPermit/PermitRequestRejectView";
// import PermitRequestForOMHead from "./ColdWorkPermit/PermitRequestForOMHead";
import PermitRequestForOMHeadTab from "./ColdWorkPermit/PermitRequestForOMHeadTab";

import Form1 from "./ColdWorkPermit/Form1";
import Form2 from "./ColdWorkPermit/Form2";
import Form3 from "./ColdWorkPermit/Form3";
import Form4 from "./ColdWorkPermit/Form4";
import Form5 from "./ColdWorkPermit/Form5";

import WorkPermitConfig from "./ColdWorkPermit/WorkPermitConfig";
import CloseRequestTabIssuer from "./ColdWorkPermit/CloseRequestTabIssuer";
import CloseRequestTabInspection from "./ColdWorkPermit/CloseRequestTabInspection";
import ColdWorkPermitInspectionView from "./ColdWorkPermit/ColdWorkPermitInspectionView";
import EditExtensionRequestReason from "./ColdWorkPermit/EditExtensionRequestReason";

// Hot Work Permint
import HotWorkPermitTemplate from "./HotWorkPermit/HotWorkPermitTemplate";

// Views
import HRView from "./Views/HRView";
import DailyProgressEmailHistory from "./Views/DailyProgressEmailHistory";
import UserView from "./Views/UserView";
import ContractorView from "./Views/ContractorView";
import CustomerView from "./Views/CustomerView";
import FrequencyConfigView from "./Views/FrequencyConfigView";
import ProjectView from "./Views/ProjectView";
import EquipmentView from "./Views/EquipmentView";
import StatutoryCompliancesView from "./Views/StatutoryCompliancesView";
import ManpowerHistory from "./Views/ManpowerHistory";
import PrVendorDetails from "./Views/PrVendorDetails";
import History from "./Views/History";
import Breakdownreportview from "./Views/Breakdownreportview";
import PRRequestView from "./Views/PRRequestView";
import PRRequestDetail from "./Views/PRRequestDetail";
import VendorView from "./Views/VendorView";
import ApprovedPrView from "./Views/ApprovedPrView";
import AmcView from "./Views/AmcView";

import NotificationComponent from "./Component/NotificationComponent";

import ReportLog from "./MonthlyReportLog/ReportLog";
import AllNotification from "./Popups/AllNotification";
import IsolationTemplate1 from "./ElectricalIsolation/IsolationTemplate1";
import IsolationTemplate from "./ElectricalIsolation/IsolationTemplate";
import RequestIsolation from "./ElectricalIsolation/RequestIsolation";
import RequestEnergisation from "./ElectricalIsolation/RequestEnergisation";
import CetificateIsolation from "./ElectricalIsolation/CetificateIsolation";
import CetificateEnergisation from "./ElectricalIsolation/CetificateEnergisation";


const App = () => {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  const token = localStorage.getItem("authKey");

  // const requestPermission = async () => {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     try {
  //       const firebaseToken = await requestFirebaseToken();
  //       console.log(
  //         "Notification permission granted, Firebase token:",
  //         firebaseToken
  //       );

  //       // Send the token to your Flask backend to save
  //       await fetch(`${API_URL}/api/fcm/register`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: token,
  //         },
  //         body: JSON.stringify({ fcm_token: firebaseToken }),
  //       });
  //     } catch (error) {
  //       console.error("Failed to get Firebase token:", error);
  //     }
  //   } else {
  //     console.warn("Notification permission denied.");
  //   }
  // };

  useEffect(() => {
    const authKey = localStorage.getItem("authKey");
    const name = localStorage.getItem("userName");

    if (authKey && name) {
      setLoggedIn(true);
      setUserName(name);
    } else {
      setLoggedIn(false);
      setUserName("");
    }

    // Simulate a delay to see the loader
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleLogin = async (authKey, name, email, company_id, user_role) => {
    // const handleLogin =  (authKey, name, email, company_id) => {
    if (authKey && name) {
      localStorage.setItem("authKey", authKey);
      localStorage.setItem("userName", name);
      localStorage.setItem("email", email);
      localStorage.setItem("company_id", company_id);
      localStorage.setItem("user_role", user_role);
      setLoggedIn(true);
      setUserName(name);
    }
  };

  const handleSidebarToggle = (expanded) => {
    setIsSidebarExpanded(expanded);
  };

  return (
    <Router>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
          <div>Loading...</div>
        </div>
      ) : (
        <Routes>
          {!loggedIn && <Route path="*" element={<Navigate to="/login" />} />}
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/" element={<Login onLogin={handleLogin} />} />
          <Route path="/signup" element={<SignUp />} />
          {loggedIn && (
            <>
            
            
            <Route
                path="/TemplateForm"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <TemplateForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
                 <Route
                path="/TemplategetForm"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <TemplategetForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/boq-add-details-week/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <BOQAddQtyForWeek isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
                  <Route
                path="/boq-add-details-month/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <BOQAddQtyForMonths isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/boqs"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <BOQTable isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/boqs-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <BOQForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/boqs-edit-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <BOQEditForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/amc-customers-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AMCCustomerForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/amc-customers"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AMCCustomerTable isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/amc-customers-edit-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AMCCustomerEditForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />



{/* <Route
                path="/vendor-returnable"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Returnable isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              /> */}

              <Route
                path="/amc-projects"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AMCProjectTable isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/amc-projects-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AMCProjectForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/amc-projects-edit-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AMCProjectEditForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/reports"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ReportPage isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/schedule_generator"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ScheduleGenerator isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/audit-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AuditForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/statutory-audits"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AuditTable isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/audit-edit-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AuditEditForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/audit-view/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AuditView isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/schedule-report-view/:department_id/:filter_option/:status"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PreventiveReportView
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/amc-report-view/:department_id/:filter_option/:status"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AmcReportView isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/breakdown-report-view/:department_id/:filter_option/:status"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Breakdownreportview
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/procurement-report-view/:department_id/:filter_option/:pr_status"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ProcurementReportView
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/user"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <User isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/user-acess-table/:ID"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <UserAccessTable isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                // path="/cold-work-permit"
                path="/permit-requests"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PermitRequestForRequesterTab
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                // path="/form2"
                path="/checklistitems"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Form2 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              {/* ColdWorkPermitDetail */}
              {/* ColdWorkPermitInspection */}
              <Route
                path="/work-permit-inspection"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PermitRequestForSafetyTab
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/cold-work-permit-inspection-view/:work_permit_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ColdWorkPermitInspectionView
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/view-pdf-formate/:work_permit_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PermitPdfFormate isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/cold-work-permit"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PermitRequestForIssuerTab
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              {/* <Route
                path="/cold-work-permit-request-list/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ColdWorkPermitRequestList
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              /> */}
              <Route
                path="/cold-work-permit-form/:id/:permit_type_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PermitRequestEditTab
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/close-request-tab/:work_permit_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <CloseRequestTabIssuer
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/close-request-tab-inspection/:work_permit_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <CloseRequestTabInspection
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/reject-req/:work_permit_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PermitRequestRejectView
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/edit-email-template/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditEmailTemplate isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-daily-progress/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditDailyProgress isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/daily-progress-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <DailyProgressForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/email-configuration"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EmailConfig isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/email-config-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EmailConfigForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/cold-work-permit-config"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <WorkPermitConfig isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/daily-work-progress"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <DailyProgress isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              {/* <Route
                path="/Permit-request-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Form1 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              /> */}
              <Route
                path="/form1"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Form1 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/permit-request-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PermitRequestForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-permit-request-form/:work_permit_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PermitRequestFormEdit
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/request-issue/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Form2 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/form3"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Form3 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />

<Route
                path="/returnable-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ReturnableForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/returnable-items"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Returnable
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/edit-returnable/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditReturnable
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />

              <Route
                path="/edit-extension/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PermitExtensionRequestEdit
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/edit-extension-reason/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditExtensionRequestReason
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/view-extension/:work_permit_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PermitExtensionRequestView
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/form4/:work_permit_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Form4 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/form5/:work_permit_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Form5 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/safety-induction"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <SafetyInduction isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/archived-safety-induction"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedSafetyInduction
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/safety-induction-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <SafetyInductionForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/edit-safety-induction/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditSafetyInduction
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/cold-work-permit-template"
                element={
                  <div>
                    {/* <Sidebar onSidebarToggle={handleSidebarToggle} /> */}
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ColdWorkPermitTemplate
                    // isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/hot-work-permit-template"
                element={
                  <div>
                    {/* <Sidebar onSidebarToggle={handleSidebarToggle} /> */}
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <HotWorkPermitTemplate
                    // isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/product-library-categories"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <DocumentCategory isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/archived-product-library-categories"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedDocumentCategory
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/category-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <LibraryCategoryForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/email-history/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <DailyProgressEmailHistory
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/edit-hr-modules/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditHRModules isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-category-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditLibraryCategory
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/edit-email-config/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditEmailConfig isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-document-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditLibraryDocument
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/report-logs"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ReportLog isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/hr-modules"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <HrTable isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/archived-hr-modules"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedhrList isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/hr-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <HRForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/library-documents/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <DocumentLibrary isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/library-documents-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <LibraryDocumentsForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/all-schedules"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AllPreventiveSchedules
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/generate-po/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <GeneratePO isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/amc-consumptions"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AmcConsumption isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/archived-amc-consumptions"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedAmcConsumption
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/amc-consumptions-history/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AmcConsumptionHistory
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/product-inventory"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Inventory isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/archived-inventory"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedInventory isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              {/* <Route
                path="/product-inventory-view/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <InventoryView isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              /> */}
              <Route
                path="/product-inventory-view/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <InventoryHistory isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              {/* <Route
                path="/product-inventory-view/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <RejectedHistory isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              /> */}
              <Route
                path="/approved-technical-view/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ApprovedTechnicalView
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/new-po"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <POConfigurationForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/po-view/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <POView isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/hr-view/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <HRView isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/po-list"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PoLisTtab isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/po-list-tab"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <POConfigurationlist
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/archived-po-list"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedPoList isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/po-inbound-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <InwardForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/po-inbound-list"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <InwardList isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/archived-po-inbound-list"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedInward isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/outward-list"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <OutwardList isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/archived-outward-list"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedOutwardlist
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/outward-Form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <OutwardForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-poconfiguration"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditPOConfiguration
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/edit-outward"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditOutward isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-inward"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditInward isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              {/* <Route
                path="/schedules-reports"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PreventiveReports isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              /> */}
              <Route
                path="/mat-code"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <MatCode isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/archived-mat-code"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedMatcode isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              {/* <Route
                path="/archived-level3"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedLevel3 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              /> */}
              <Route
                path="/maintenance-history"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <History isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/inward-view/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <InwardView isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/outward-view/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <OutwardView isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/amc-configuration"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AmcSchedules isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/pr-request"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PRRequest isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/archived-pr-request"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedPrRequest isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/user-view/:userID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <UserView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/amc-view/:amcID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AmcView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/contractor-view"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ContractorView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/customer-view/:customerID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <CustomerView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/frequency-view"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <FrequencyConfigView
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/project-view/:projectID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ProjectView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/joining-history/:id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ManpowerHistory isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/statutory-view-view"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <StatutoryCompliancesView
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/equipment-view"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EquipmentView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Dashboard isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/pr-request-view/:pr_request_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PRRequestView isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/pr-request-detail/:pr_request_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PRRequestDetail isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-mat-code-l1/:l1_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <UpdateMatCodeLevel1
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/archived-level1-matcode"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedLevel1 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-mat-code-l2/:l2_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <UpdateMatCodeLevel2
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              {/* <Route
                path="/archived-level-3"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedLevel3 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              /> */}
              <Route
                path="/archived-level2-matcode"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedLevel2 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/archived-level3-matcode"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedLevel3 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-mat-code-l3/:l3_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <UpdateMatCodeLevel3
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/new-mat-code"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <MatCodeConfigForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/matcode-levels"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <LevelConfig isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/level1-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Level1Form isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/level2-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Level2Form isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/level3-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Level3Form isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/pr-request-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PRRequestForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-pr-request-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditPRRequest isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/amc-schedules"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AllAmcSchedules isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/rejected-po-list"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <RejectedStock isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/archived-rejected-stock"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedRejectedStock
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/rejected-view/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <RejectedStockView isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/pr-requests-list-tab"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PRRequestList isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              PRRequestListTab
              <Route
                path="/pr-requests-list"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PRRequestListTab isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/approved-pr-requests"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ApprovedPRList isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/archived-approved-pr-requests"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedApprovedPR isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/amc-schedules-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AmcSchedulesForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/userform"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <UserForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-statutory-compliance/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditStatutoryCompliance
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/statutory-compliance-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <StatutoryComplianceForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/statutory-compliance"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <StatutoryCompliance
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/archived-statutory-compilance"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedStatutory isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/breakdown-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <BreakDownForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/new-equipment"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <NewEquipment isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/vendor-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />

                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <VendorForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/HrCompilance-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />

                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <HrCompilanceForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/all-notification"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />

                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AllNotification isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/vendors"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Vendor isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/hr-compliances"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <HrCompilances isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/Edit-hr-Compilance/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditHrCompilance isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/archived-vendor"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedVendor isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/pr-vendor-details/:vendor_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PrVendorDetails isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-vendor/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditVendor isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/contractor-Company"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ContractorCompany isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/equipment-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EquipmentForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/inward-technical-inspection-list"
                //  path="/technical-pr-request-list"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <TechnicalRequestList
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/archived-inward-technical-inspection-list"
                //  path="/technical-pr-request-list"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedTechnicalList
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/technical-pr-request-list-view/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <TechnicalRequestListView
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/archived-pr-request-list"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedPrRequestList
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/technical-pr-approved-list"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ApprovedTechnical isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/new-equipment-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <NewEquipmentForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/equipment-type"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EquipmentType isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/designations"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Designation isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/archived-designations"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedDesignations
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/edit-designation/:ID"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditDesignation isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/designation-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <DesignationForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/side-petty-cash"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <SidePettyCash isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/side-petty-cash-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <SidePettyCashForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-side-petty-cash"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditSidePettyCash isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/equipment-type-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EquipmentTypeForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/contractor-company-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ContractorCompanyForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/equipment-sub-type"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EquipmentSubType isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/equipment-sub-type-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EquipmentSubTypeForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
                 <Route
                path="/oandm-head-inspection"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PermitRequestForOMHeadTab
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/equipment-sub-type-variant"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EquipmentTypevariant
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/equipment-sub-type-variant-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EquipmentTypevariantForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/user-type"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <UserType isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/email-template-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EmailTemplateForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/email-templates"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EmailTemplate isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/manpowerrequirment-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ManpowerRequirmentForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                // path="/manpower-requirements/:id"
                path="/manpower-requirements"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ManpowerRequirment isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                // path="/actualmanpower/:id"
                path="/actual-manpowers"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ActualManpower isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/actualmanpower-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ActualManpowerForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-man-powers-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditManpower isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-actual-man-powers/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditActualManpower isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                // path="/report-manpower/:id"
                path="/manpower-report"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ReportManpower isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-contractor/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditContractor isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-amc/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditAmcForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-mat-code"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditMatCode isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/vendor-view/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <VendorView isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/user-type-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <UserTypeForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/department"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Department isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/sub-department"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <SubDepartment isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/department-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <DepartmentForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/sub-department-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <SubDepartmentForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-user/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditUserForm isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-equipment-type/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditEquipmentType isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-frequency/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditFrequency isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-breakdown/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditBreakdown isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-new-equipment/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditNewEquipment isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/reminders/:department_id/:due_type"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ReminderDetails isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/electrical-department/:department_id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ElectricalDepartment
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/frequency"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Frequency isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archived-frequency"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedFrequency isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/project-form"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ProjectForm isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/customer-form"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <CustomerForm isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-customer/:id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditCustomer isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-project/:id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditProject isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/project"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Project isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/breakdown-schedules"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <BreakDownSchedules isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archived-breakdown"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedBreakdown isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/customer"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <Customer isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/frequency-config"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <FrequencyConfig isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-equipment-sub-Type/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditEquipmentSubType
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/edit-equipment-sub-type-variant/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditEquipmentSubTypeVariant
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/edit-department/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditDepartment isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-sub-department/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <EditSubDepartment isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archive-user"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedUser isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archive-All-AMC-Schedules"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedAllAmcSchedules
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/archive-All-AMCs"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedAllAmcs isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archive-department"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedDepartment isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/scheduler-view/:scheduler_id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <PreventiveSchedulesView
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/breakdown-scheduler-view/:breakdown_id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <BreakdownView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/amc-scheduler-view/:schedule_id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <AmcSchedulesView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/approved-pr-view/:pr_id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ApprovedPrView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/update-mat-code/:id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <UpdateMatCode isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archive-sub-department"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedSubDepartment
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              ArchivedContractorCompany
              <Route
                path="/archive-contractor-company"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedContractorCompany
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/archive-equipment"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedEquipment isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archive-All-Schedule"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedAllSchedules
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/archive-customer"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedCustomer isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archive-Actual-man-Power/:id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedActualmanPower
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/archive-man-required/:id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedManPowerRequire
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/archive-project"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedProject isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archive-equipment-type"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedEquipmentType
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/archive-equipment-sub-type"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedEquipmentSubType
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/archive-equipment-sub-type-variant"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <ArchivedEquipmentSubTypevariant
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/isolation-template"
                element={
                  <>
                    <IsolationTemplate isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/request-isolation"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <RequestIsolation
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
               <Route
                path="/request-energisation"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <RequestEnergisation
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/isolation-certificate"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <CetificateIsolation
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
                <Route
                path="/energisation-certificate"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Header isSidebarExpanded={isSidebarExpanded} />
                    <CetificateEnergisation
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
            </>
          )}
        </Routes>
      )}
    </Router>
  );
};

export default App;
