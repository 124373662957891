import React, { useEffect, useState } from 'react';
import { Snackbar, Alert, AlertTitle } from '@mui/material';
import { useNotifications } from './useNotifications';

const NotificationComponent = () => {
  const { notification, notificationPermission } = useNotifications();
  const [open, setOpen] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(null);

  useEffect(() => {
    if (notification) {
      console.log('🔔 New notification triggering Snackbar:', notification);
      setCurrentNotification(notification);
      setOpen(true); 
    }
  }, [notification]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    console.log('🔕 Closing Snackbar');
    setOpen(false);
  };

  if (!notificationPermission) {
    return null;
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ zIndex: 9999 }} // Ensures Snackbar stays on top
    >
      {currentNotification && (
        <Alert
          onClose={handleClose}
          severity="success" // Green background
          sx={{
            backgroundColor: '#4CAF50', // Custom green color
            color: 'white', // Ensures text visibility
            fontWeight: 'bold',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
            borderRadius: '8px',
            zIndex: 9999, // Ensures it's on top
          }}
        >
          <AlertTitle>{currentNotification.title || 'Notification'}</AlertTitle>
          {currentNotification.body || 'You have a new update.'}
         
        </Alert>
      )}
    </Snackbar>
  );
};

export default NotificationComponent;
