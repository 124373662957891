import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import "./PreventiveSchedulesView.css";
import "../forms/AmcSchedules.css";
import { useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../Config";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Select from "react-select"; // Import react-select
import DatePicker from "react-datepicker";
import { AiOutlineDownload } from "react-icons/ai";
import { format } from "date-fns";
import { ThemeContext } from "../ThemeContext";

function PreventiveSchedulesView({ isSidebarExpanded }) {
  const { scheduler_id } = useParams();
  const today = new Date();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [offerDocumentError, setOfferDocumentError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [serviceDueDate, setServiceDueDate] = useState(today);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dateError, setDateError] = useState("");
  const [status, setStatus] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [scheduleData, setScheduleData] = useState(null);
  const [scheduleFileData, setSchedulesFileData] = useState(null);
  const [remark, setRemark] = useState("");

  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const { theme, toggleTheme } = useContext(ThemeContext);

  const handlePreviewClick = (url) => {
    setPreviewUrl(url);
  };

  // Close the preview modal
  const handleCloseModal = () => {
    setPreviewUrl(null);
  };

  const fetchScheduler = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await fetch(
        `${API_URL}/api/get_scheduler/${scheduler_id}`,
        {
          method: "GET",
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const schedule = Array.isArray(data) ? data[0] : data;
        console.log("API Response schedule:", schedule);

        setScheduleData(schedule);
        setSchedulesFileData(Array.isArray(data) ? data : [data]);
        const fetchedServiceDueDate = schedule.last_serviced_date
          ? new Date(schedule.last_serviced_date)
          : new Date();

        setServiceDueDate(fetchedServiceDueDate);
        setRemark(schedule.remark);

        const fetchedStatus = schedule.status || schedule.new_status;
        console.log("Fetched status:", fetchedStatus);
        setStatus(fetchedStatus || "Unknown");
      } else {
        console.error("Error fetching schedule:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching schedule:", error.message);
    }
  };

  // Call fetchScheduler in useEffect
  useEffect(() => {
    fetchScheduler();
  }, [scheduler_id]);

  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string" || string.length === 0) {
      return string; // Return as is if not a valid string or empty
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  // const handleSubmit = async () => {
  //   if (status === "Completed") {
  //     if (attachmentFiles.length === 0) {
  //       setSnackbarMessage("Please select at least one attachment.");
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //       return;
  //     }

  //     if (!serviceDueDate) {
  //       setDateError("Please select a service date.");
  //       return;
  //     }
  //   }
  //   await handleUploadSubmit();
  //   const formattedDate = serviceDueDate
  //     ? format(new Date(serviceDueDate), "yyyy-MM-dd")
  //     : undefined;

  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const response = await axios.put(
  //       `${API_URL}/api/update_scheduler_status/${scheduler_id}`,
  //       {
  //         status: status,
  //         service_date: formattedDate,
  //         remark: remark,
  //       },
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       const { message } = response.data;
  //       setSnackbarMessage(message || "Scheduler status updated successfully");
  //       setSnackbarSeverity("success");
  //       setSnackbarOpen(true);
  //       fetchScheduler();
  //     } else {
  //       const { message } = response.data;
  //       setSnackbarMessage(message || "Failed to update scheduler status");
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //     }
  //   } catch (error) {
  //     const errorMessage =
  //       error.response?.data?.message ||
  //       "An error occurred while updating scheduler status";
  //     console.error(
  //       "An error occurred while updating scheduler status:",
  //       error
  //     );
  //     setSnackbarMessage(errorMessage);
  //     setSnackbarSeverity("error");
  //     setSnackbarOpen(true);
  //   }
  // };

  const handleSubmit = async () => {
    if (status === "Completed") {
      // Validate attachment files
      if (attachmentFiles.length === 0) {
        setSnackbarMessage("Please select at least one attachment.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }
  
      // Validate service due date
      if (!serviceDueDate) {
        setDateError("Please select a service date.");
        return;
      }
    }
  
    // Format the service date if provided
    const formattedDate = serviceDueDate
      ? format(new Date(serviceDueDate), "yyyy-MM-dd")
      : undefined;
  
    // Create FormData and append data
    const formData = new FormData();
    formData.append("schedule_id", scheduler_id);
    formData.append("status", status);
    if (formattedDate) formData.append("service_date", formattedDate);
    if (remark) formData.append("remark", remark);
  
    // Append all attachment files to FormData
    attachmentFiles.forEach((file) => {
      formData.append("attachments", file);
    });

    setAttachmentFiles([]);
  
    try {
      const authKey = localStorage.getItem("authKey");
  
      // Perform the API request
      const response = await axios.put(
        `${API_URL}/api/v2/update_preventive_schedule_status/${scheduler_id}`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      // Handle success or failure based on response status
      if (response.status === 200) {
        const { message } = response.data;
        setSnackbarMessage(message || "Scheduler status updated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        fetchScheduler(); // Refresh scheduler data
      } else {
        const { message } = response.data;
        setSnackbarMessage(message || "Failed to update scheduler status");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while updating scheduler status";
      console.error(
        "An error occurred while updating scheduler status:",
        error
      );
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  

  const handleDefaultStatus = async () => {
    const authKey = localStorage.getItem("authKey");
    try {
      const response = await axios.put(
        `${API_URL}/api/reset_status_to_default/${scheduler_id}`,
        {},
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { message } = response.data;
        setSnackbarMessage(message || "Status reset to default successfully");
        setSnackbarSeverity("success");
        fetchScheduler(); // Refresh the data
      } else {
        const { message } = response.data;
        setSnackbarMessage(message || "Failed to reset status to default");
        setSnackbarSeverity("error");
      }
      setSnackbarOpen(true);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while resetting status";
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const formatDate = (dateString) => {
    // Check if dateString is valid
    if (!dateString || isNaN(Date.parse(dateString))) {
      return "N/A";
    }

    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    return formatter.format(date);
  };

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "overdue", label: "Overdue" },
    { value: "Completed", label: "Completed" },
    { value: "Hold", label: "Hold" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "In Progress", label: "In Progress" },
  ];

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleBackScheduler = () => {
    window.history.back(); 
  };

  const handleCancel = () => {
    window.history.back(); 
  };
  const handleFileInputChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const newFileDetails = Array.from(files).map((file) => ({
        name: file.name,
        size: file.size,
      }));

      setSelectedFiles((prevFiles) => [...prevFiles, ...newFileDetails]);
      setAttachmentFiles((prevFiles) => [...prevFiles, ...files]);
    }
    setOfferDocumentError("");
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption.value);
    if (selectedOption.value !== "Completed") {
      setServiceDueDate(null);
      setDateError("");
    }
  };

  const handleUploadSubmit = async () => {
    if (attachmentFiles.length === 0) return;

    const authKey = localStorage.getItem("authKey");
    const formData = new FormData();
    formData.append("schedule_id", scheduler_id);

    // Append all files to FormData
    attachmentFiles.forEach((file) => {
      formData.append("attachments", file);
    });

    try {
      const response = await axios.post(
        `${API_URL}/api/upload_schedule_datasheet/${scheduler_id}`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSnackbarMessage("File uploaded successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setAttachmentFiles([]);
        setSelectedFiles([]);
        fetchScheduler();
      } else {
        setSnackbarMessage("Failed to upload file");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while uploading file");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <div
        className={`invoice-request-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="invoice-request-content">
          <div className="invoice-request-header">
            <div className="invoice-request-header-content">
              <div className="invoice-request-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackScheduler}
                  />
                </Tooltip>
                Scheduler View
              </div>
            </div>
          </div>
          <div className="schedule-body">
          <div className="action-purchase-header">
            <div className="action-purchase-header-content">
              {scheduleData ? (
                <div className="project-detailss">
                  <div className="project-detail-arround">
                    <div className="project-details-left-side">
                      <div className="project-detail">
                        <div className="detail-header">Contractor Name:</div>
                        <div className="detail-part">
                          {scheduleData.contractor_details?.contractor_name ||
                            "N/A"}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">Equipment tag no:</div>
                        <div className="detail-part">
                          {scheduleData.equipment_tag_no || "N/A"}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">Equipment Location:</div>
                        <div className="detail-part">
                          {scheduleData.equipment_location || "N/A"}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">Status:</div>
                        <div className="detail-part">
                          {capitalizeFirstLetter(scheduleData.status) || "N/A"}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">Service Date:</div>
                        <div className="detail-part">
                          {formatDate(scheduleData.last_serviced_date) || "N/A"}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">Service Due Date:</div>
                        <div className="detail-part">
                          {formatDate(scheduleData.service_due_date) || "N/A"}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">
                          Product Description:
                        </div>
                        <div className="detail-part">
                          {scheduleData.description || "N/A"}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">Frequency:</div>
                        <div className="detail-part">
                          {scheduleData.frequency
                            ? `Every ${scheduleData.frequency} days`
                            : "N/A"}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">OISD Point:</div>
                        <div className="detail-part">
                          {scheduleData.oisd_point || "N/A"}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">
                          Frequency Description:
                        </div>
                        <div className="detail-part">
                          {scheduleData.frequency_description || "N/A"}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">Uploaded Files:</div>
                        <div className="detail-part">
                          {scheduleData.attachments &&
                          scheduleData.attachments.length > 0
                            ? scheduleData.attachments.map(
                                (file, fileIndex) => (
                                  <div key={fileIndex}>
                                    <span>
                                      {file.filename} ({file.file_size} KB)
                                    </span>
                                  </div>
                                )
                              )
                            : "No files uploaded"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
          <div className="invoice-body-container">
            <div className="invoice-body-container-content-schedule">
              <div className="invoice-upload-files-schedules">
                {scheduleData ? (
                  <div className="current-status">
                    <label className="upload-label">
                      Current Status:{" "}
                      <span
                        className={
                          scheduleData.status === "Completed"
                            ? "status-completed"
                            : scheduleData.status === "active"
                            ? "status-active"
                            : scheduleData.status === "overdue"
                            ? "status-overdue"
                            : scheduleData.status === "not in tenure"
                            ? "status-not-in-tenure"
                            : scheduleData.status === "Cancelled"
                            ? "status-cancelled"
                            : scheduleData.status === "In Progress"
                            ? "status-progress"
                            : scheduleData.status === "Hold"
                            ? "status-hold"
                            : scheduleData.status === "schedule"
                            ? "status-schedule"
                            : "status-default" // A default class for unexpected statuses
                        }
                      >
                        {capitalizeFirstLetter(scheduleData.status) || "N/A"}{" "}
                        {/* Displaying the status */}
                      </span>
                    </label>
                  </div>
                ) : (
                  <p>Loading...</p>
                )}

                <div className="status-content">
                  <div className="form-group-select">
                    <label htmlFor="docket-file">Change Status:</label>
                    {/* <Select
                      options={statusOptions}
                      value={statusOptions.find(
                        (option) => option.value === status
                      )} // Ensure 'status' state matches fetched value
                      onChange={(selectedOption) => {
                        handleStatusChange(selectedOption);
                        setStatus(selectedOption.value);
                      }}
                      placeholder="Select status"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: theme === "dark" ? "black" : "white",
                          color: theme === "dark" ? "white" : "black",
                          borderColor: theme === "dark" ? "gray" : "#ccc",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: theme === "dark" ? "white" : "black",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          backgroundColor: theme === "dark" ? "black" : "white",
                          color: theme === "dark" ? "white" : "black",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused
                            ? theme === "dark"
                              ? "gray"
                              : "#f0f0f0"
                            : theme === "dark"
                            ? "black"
                            : "white",
                          color: theme === "dark" ? "white" : "black",
                          cursor: "pointer",
                        }),
                      }}
                    /> */}
                    <Select
                      options={statusOptions}
                      value={statusOptions.find(
                        (option) => option.value === status
                      )} // Ensure 'status' state matches fetched value
                      onChange={(selectedOption) => {
                        handleStatusChange(selectedOption);
                        setStatus(selectedOption.value);
                      }}
                      placeholder="Select status"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: theme === "dark" ? "black" : "white",
                          color: theme === "dark" ? "white" : "black",
                          borderColor: theme === "dark" ? "gray" : "#ccc",
                          minHeight: "45px",
                          borderRadius: "8px",
                          boxShadow: "none",
                          "&:hover": {
                            borderColor: theme === "dark" ? "#888" : "#888",
                          },
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: theme === "dark" ? "white" : "black",
                          fontWeight: "500",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          backgroundColor: theme === "dark" ? "black" : "white",
                          color: theme === "dark" ? "white" : "black",
                          borderRadius: "8px",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                          maxHeight: "150px",
                          overflowY: "scroll", // Enable vertical scroll
                          scrollbarWidth: "thin", // Makes scrollbar thin in Firefox
                          "&::-webkit-scrollbar": {
                            // Custom scrollbar for Webkit browsers
                            width: "6px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            background: theme === "dark" ? "#555" : "#ccc",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb:hover": {
                            background: theme === "dark" ? "#666" : "#aaa",
                          },
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused
                            ? theme === "dark"
                              ? "#444"
                              : "#f0f0f0"
                            : theme === "dark"
                            ? "black"
                            : "white",
                          color: theme === "dark" ? "white" : "black",
                          cursor: "pointer",
                          padding: "10px 12px",
                          "&:active": {
                            backgroundColor:
                              theme === "dark" ? "#666" : "#dcdcdc",
                          },
                        }),
                      }}
                    />
                  </div>
                  {status === "Hold" && (
                    <>
                      <div className="form-group">
                        <label>Remarks:</label>
                        <div className="input-group">
                          <textarea
                            rows="4"
                            placeholder="Enter your remarks here..."
                            className="form-control"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <>
                    {status === "Completed" && (
                      <>
                        <div className="form-group">
                          <label>Service Date:</label>
                          <div className="input-group-datepicker">
                            <DatePicker
                              // selected={completedDate}
                              selected={serviceDueDate}
                              onChange={(date) => setServiceDueDate(date)}
                              placeholderText="Select Service date"
                              dateFormat="dd/MM/yyyy"
                              isClearable
                              showYearDropdown
                              showMonthDropdown
                              dropdownMode="select"
                              maxDate={today}
                            />
                          </div>
                          {dateError && (
                            <div className="error-message">{dateError}</div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="upload-label" htmlFor="docket-file">
                            Upload Maintenance Report:
                          </label>
                          {scheduleFileData.length > 0 &&
                            Array.isArray(scheduleFileData) &&
                            scheduleFileData.map((equipment, index) => (
                              <div key={index}>
                                <div className="chalann-input">
                                  <button
                                    type="button"
                                    className="challan-btns"
                                    onClick={handleUploadButtonClick}
                                  >
                                    <FiUpload /> Upload File
                                  </button>
                                  <input
                                    type="file"
                                    id="fileInput"
                                    ref={fileInputRef}
                                    onChange={handleFileInputChange}
                                    style={{ display: "none" }}
                                  />
                                </div>
                                {selectedFiles.length > 0 && (
                                  <div className="attchment-file-lists">
                                    <ul
                                      style={{
                                        listStyleType: "none",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      {selectedFiles.map((file, index) => (
                                        <li key={index}>{file.name}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                                <div>
                                  {/* Check if attachments exist */}
                                  {equipment.attachments &&
                                  equipment.attachments.length > 0 ? (
                                    equipment.attachments.map((file) => (
                                      <div
                                        key={file.file_id}
                                        className="file-name-lists"
                                      >
                                        <span>File Name: </span>
                                        <span
                                          style={{
                                            color: "blue",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handlePreviewClick(file.preview_url)
                                          }
                                        >
                                          {file.filename.length > 15
                                            ? `${file.filename.slice(0, 15)}...`
                                            : file.filename}{" "}
                                          ({file.file_size} KB)
                                        </span>

                                        <a
                                          href={`${API_URL}/api/download_attachment/${file.file_id}/${file.file_type}`}
                                          download
                                        >
                                          <AiOutlineDownload className="download-icon" />
                                        </a>
                                      </div>
                                    ))
                                  ) : (
                                    <p>No attachments available.</p>
                                  )}
                                </div>
                              </div>
                            ))}

                          <Modal
                            open={!!previewUrl}
                            onClose={handleCloseModal}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                          >
                            <Box sx={modalStyle}>
                              {previewUrl && (
                                <embed
                                  src={previewUrl}
                                  width="100%"
                                  height="100%"
                                  type="application/pdf"
                                />
                              )}
                            </Box>
                          </Modal>
                        </div>
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="schedules-button footer">
            <button className="data-form-save-button" onClick={handleSubmit}>
              Save
            </button>
            <button className="data-form-cancel-button" onClick={handleCancel}>
              Cancel
            </button>
            <button className="reset-button" onClick={handleDefaultStatus}>
              Reset Status to Default
            </button>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PreventiveSchedulesView;
