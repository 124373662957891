import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Pagination from "../Component/Pagination";
import useClickOutside from "../useClickOutside";
import { IoMdArrowBack } from "react-icons/io";

function DocumentLibrary({ isSidebarExpanded }) {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [sortOrders, setSortOrders] = useState({});
  const [isSearching, setIsSearching] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const { id } = useParams();
  const user_role = localStorage.getItem("user_role");
  
  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };
  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedBreakdown = [...documents].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setDocuments(sortedBreakdown);
  };
  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const fetchData = async () => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_product_library/${id}`,
        {
          headers: {
            Authorization: authKey,
          },
          // Uncomment if pagination is needed
          // params: {
          //   page: currentPage,
          //   per_page: itemsPerPage,
          // },
        }
      );

      const responseData = response.data;

      // Assuming responseData is an array
      const formattedData = responseData.ProductLibrary.map((doc) => ({
        id: doc.id,
        doc_name: doc.doc_name,
        library_type_id: doc.library_type_id,
        company_id: doc.company_id,
        created_at: doc.created_at,
        updated_at: doc.updated_at,
        status: doc.status,
        library_name: doc.library_name,
        attachments: doc.attachments || [], // Ensure that attachments are always an array
      }));

      setDocuments(formattedData); // Now sets an array to designations
      setTotalItems(response.data.TotalItems);
      setLoading(false);
      setTotalItems(responseData.TotalItems);
    } catch (error) {
      console.error("Error fetching product library data:", error.message);
      setLoading(false);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/search_product_libraries`,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
          params: {
            query: searchInput,
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      if (response.status === 200) {
        setDocuments(response.data.Designations);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? documents.map((designation) => designation.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (designationID) => {
    const updatedSelectedRows = selectedRows.includes(designationID)
      ? selectedRows.filter((id) => id !== designationID)
      : [...selectedRows, designationID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === documents.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchiveDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_document_library`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDesignations = documents.filter(
          (designation) => !selectedRows.includes(designation.ID)
        );
        setDocuments(updatedDesignations);
        fetchData();
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting designations:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setSelectAll(false);
    setChecked(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveDeparment = () => {
    navigate("/archive-department");
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRefresh = () => {
    if (searchInput) {
      console.log('Search input is set:', searchInput);
    } else {
      fetchData(page, itemsPerPage);
    }
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const handleBackView = () => {
    navigate(`/product-library-categories`);
  };

  const handleNew = () => {
    if (id) {
      navigate(`/library-documents-form/${id}`);
    } else {
      console.error("Project ID is undefined");
    }
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-list-content" ref={containerRef}>
          {isChecked ? (
            <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                  <button
                        className="delete"
                        onClick={handleDeletePopup}
                      >
                        Move to archive
                      </button>
                    {isDeletePopup && (
                      <div className="confirm-delete-popup">
                        <div className="confirm-delete-popup-content">
                          <div className="confirm-model">
                            <div className="confirm-model-content">
                              <div className="confirm-model-content-body">
                                <p>Are you sure want to move archive?</p>
                              </div>
                              <div className="confirm-model-content-footer">
                                <button
                                  className="confirm-delete-button"
                                  onClick={handleArchiveDepartment}
                                >
                                  Yes
                                </button>
                                <button
                                  className="confirm-cancel-button"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="data-list-header">
              <div className="data-list-header-content">
                <div className="data-list-heading-content-left-side">
                  <Tooltip title="Back" arrow>
                    <IoMdArrowBack
                      className="back-company"
                      onClick={handleBackView}
                    />
                  </Tooltip>
                  All Document Library
                </div>
                <div className="data-list-heading-content-right-side">
                  <div className="search-bar-container">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      className="search-bar"
                    />
                    <IoSearchOutline className="search-bar-icon" />
                  </div>

                  {/* <Link to="/library-documents-form" className="data-list-new"> */}
                  {(user_role === "admin" || user_role === "O & M Head"  )&& (
                  <Tooltip title="New document" arrow>
                    <button className="new-button" onClick={handleNew}>
                      <HiOutlinePlus className="new-plus-icon" /> New
                    </button>
                  </Tooltip>
                  )}
                  {/* </Link> */}

                  <Tooltip title="Refresh List" arrow>
                    <button className="refresh-button" onClick={handleRefresh}>
                      <TbRefresh className="refresh-icon" />
                    </button>
                  </Tooltip>
                  <button
                    className="sort-button"
                    onClick={handleSortpopup}
                    ref={buttonRef}
                  >
                    <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                  </button>
                  {isSortPopup && (
                    <div className="sort-popup-container" ref={containerRef}>
                      <div className="sort-popup-content">
                        <div className="sort-by">
                          SORT BY {isAscending ? "(Ascending)" : "(Descending)"}
                        </div>
                        <div className="sort-by-button">
                          <button
                            onClick={() => handleSort("designation_name")}
                          >
                            Document Name{" "}
                            {sortOrders.doc_name ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                          <br />
                        </div>
                        {/* <button
                          className="delete-item"
                          onClick={handleArchiveDeparment}
                        >
                          Archived Document Library
                        </button> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="data-list-body" onClick={closePopups}>
            <div className="data-list-scroll-body">
              <div className="data-list-table">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : documents.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th className="center-alignment">SR. NO.</th>
                        <th>CATEGORY</th>
                        <th>DOCUMENT NAME</th>
                        <th>DOC FILE NAME</th>
                        <th className="center-alignment">ACTION</th>
                        {(user_role === "admin" || user_role === "O & M Head"  )&& (
                        <th></th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {documents.map((document, index) => (
                        <tr key={document.id} className="hoverable-row">
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(document.id)}
                              onChange={() => handleSelectRow(document.id)}
                            />
                          </td>
                          <td className="center-alignment">{index + 1}</td>
                          <td>{document.library_name || "NA"}</td>

                          <td>{document.doc_name || "NA"}</td>
                          <td className="attachment-button">
                            {document.attachments &&
                            document.attachments.length > 0
                              ? document.attachments[0].filename.length > 15
                                ? `${document.attachments[0].filename.substring(
                                    0,
                                    15
                                  )}...`
                                : document.attachments[0].filename
                              : "NA"}
                            {/* <div className="attachment-actions">
                              {document.attachments &&
                                document.attachments.length > 0 && (
                                  <>
                                    <a
                                      href={`${API_URL}/api/download_library_attachment/${document.attachments[0].file_id}/${document.attachments[0].file_type}`}
                                      download
                                      className="attachment-button-a"
                                    >
                                      Download
                                    </a>
                                    <a
                                      href={`${API_URL}/api/preview_library_attachment/${document.attachments[0].file_id}/${document.attachments[0].file_type}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="attachment-button-a"
                                    >
                                      View
                                    </a>
                                  </>
                                )}
                            </div> */}
                          </td>
<td className="center-alignment">
<div className="attachment-actions1">
                              {document.attachments &&
                                document.attachments.length > 0 && (
                                  <>
                                    <a
                                      href={`${API_URL}/api/download_library_attachment/${document.attachments[0].file_id}/${document.attachments[0].file_type}`}
                                      download
                                      className="document-library-button-attchment"
                                    >
                                      Download
                                    </a>
                                    <a
                                      href={`${API_URL}/api/preview_library_attachment/${document.attachments[0].file_id}/${document.attachments[0].file_type}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="document-library-button-attchment"
                                    >
                                      View
                                    </a>
                                  </>
                                )}
                            </div>
</td>
{(user_role === "admin" || user_role === "O & M Head"  )&& (
                          <td className="edit-pop">
                            <button
                              className="edit-data-button"
                              onClick={() => handleButtonClick(index)}
                            >
                              <div className="edit-button">
                                <MdExpandMore />
                              </div>
                            </button>
                          </td>
)}
                          {selectedRowIndex === index && (
                            <div className="edit-popup">
                              <div className="edit-popup-content">
                                <div className="edit-popup-button">
                                  <Link
                                    to={`/edit-document-form/${document.id}`}
                                    className="inquiry-new"
                                  >
                                    <button className="edit-button-inquiry">
                                      <MdOutlineModeEditOutline /> Edit
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">
                      No Document Library Found
                    </div>
                  </div>
                )}
              </div>
              <Pagination
                showPerPage={itemsPerPage}
                total={totalItems}
                onPaginationChange={handlePaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default DocumentLibrary;
