import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import useClickOutside from "../useClickOutside";
import API_URL from "../Config";
import Pagination from "../Component/Pagination";
import { MdAttachFile } from "react-icons/md";

function VendorRetuenable({ isSidebarExpanded, departmentID }) {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);
  const Navigate = useNavigate();
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [page, setPage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);

  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedBreakdown = [...departments].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setDepartments(sortedBreakdown);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  // const fetchData = async () => {
  //   try {
  //     setLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.get(`${API_URL}/api/get_departments`, {
  //       headers: {
  //         Authorization: authKey,
  //       },
  //     });

  //     setDepartments(response.data);
  //     console.log(response.data);

  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching department data:", error.message);
  //     setLoading(false);
  //   }
  // };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_returnable_items`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      setDepartments(response.data.ReturnableItems);
      setTotalItems(response.data.TotalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching scheduler data:", error.message);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const handleSearch = async () => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.get(`${API_URL}/api/search_departments`, {
  //       headers: {
  //         Authorization: authKey,
  //         "Content-Type": "application/json",
  //       },
  //       params: {
  //         query: searchInput,
  //       },
  //     });

  //     if (response.status === 200) {
  //       setDepartments(response.data);
  //     } else {
  //       console.error("Error fetching search results:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching search results:", error.message);
  //   }
  // };
  const handleSearch = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/search_breakdown_schedules`,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
          params: {
            query: searchInput,
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      if (response.status === 200) {
        setDepartments(response.data.BreakdownSchedules);
        setTotalItems(response.data.TotalItems);
        setPage(response.data.CurrentPage);
      } else {
        console.error("Error fetching data:", response.statusText);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error searching data:", error.message);
      setLoading(false);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      if (searchInput.trim() !== "") {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      } else {
        setIsSearching(false);
        fetchData(page, itemsPerPage);
      }
    }, 600); // Adding delay for debouncing

    return () => clearTimeout(delay);
  }, [searchInput, page, itemsPerPage]);

  // useEffect(() => {
  //   const delay = setTimeout(() => {
  //     handleSearch();
  //   }, 600);
  //   return () => clearTimeout(delay);
  // }, [searchInput]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? departments.map((department) => department.ID) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (departmentID) => {
    const updatedSelectedRows = selectedRows.includes(departmentID)
      ? selectedRows.filter((id) => id !== departmentID)
      : [...selectedRows, departmentID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === departments.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchivedDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_department`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDepartments = departments.filter(
          (department) => !selectedRows.includes(department.ID)
        );
        setDepartments(updatedDepartments);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting departments:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRefresh = () => {
    fetchData();
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const handleArchiveDeparment = () => {
    Navigate("/archive-department");
  };

  const handleIconClick = (index) => {
    // Toggle the popup based on the index
    setIsPopupOpen(index === isPopupOpen ? null : index); // Close if the same index is clicked
    setSelectedRowIndex(false); // Reset row selection
    console.log("Popup toggled"); // Debugging
  };

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-list-content" ref={containerRef}>
          {isChecked ? (
            <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                    <button className="delete" onClick={handleDeletePopup}>
                      Move to archive
                    </button>
                    {isDeletePopup && (
                      <div className="confirm-delete-popup">
                        <div className="confirm-delete-popup-content">
                          <div className="confirm-model">
                            <div className="confirm-model-content">
                              <div className="confirm-model-content-body">
                                <p>Are you sure want to move archive?</p>
                              </div>
                              <div className="confirm-model-content-footer">
                                <button
                                  className="confirm-delete-button"
                                  onClick={handleArchivedDepartment}
                                >
                                  Yes
                                </button>
                                <button
                                  className="confirm-cancel-button"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="data-list-header">
              <div className="data-list-header-content">
                <div className="data-list-heading-content-left-side">
                  All Returnable Items
                </div>
                <div className="data-list-heading-content-right-side">
                  {/* <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div> */}

                  <Link to="/returnable-form" className="data-list-new">
                    <Tooltip title="New Department" arrow>
                      <button className="new-button">
                        <HiOutlinePlus className="new-plus-icon" /> New
                      </button>
                    </Tooltip>
                  </Link>

                  <Tooltip title="Refresh List" arrow>
                    <button className="refresh-button" onClick={handleRefresh}>
                      <TbRefresh className="refresh-icon" />
                    </button>
                  </Tooltip>
                  <button
                    className="sort-button"
                    onClick={handleSortpopup}
                    ref={buttonRef}
                  >
                    <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                  </button>
                  {isSortPopup && (
                    <div className="sort-popup-container" ref={containerRef}>
                      <div className="sort-popup-content">
                        <div className="sort-by">
                          SORT BY{" "}
                          {sortOrder[sortColumn] === "asc"
                            ? "(Ascending)"
                            : "(Descending)"}
                        </div>
                        <div className="sort-by-button">
                          <button onClick={() => handleSort("DepartmentName")}>
                            DepartmentName{" "}
                            {sortOrder.DepartmentName === "asc" ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                        </div>
                        <button
                          className="delete-item"
                          onClick={handleArchiveDeparment}
                        >
                          Archived Departments
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="data-list-body" onClick={closePopups}>
            <div className="data-list-scroll-body">
              <div className="data-list-table">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : departments.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th className="center-alignment">
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th className="center-alignment">SR. No.</th>
                        <th>DATE</th>
                        <th>DESCRIPTION</th>
                        <th>CREATED AT</th>
                        <th className="center-alignment">ATTACHMENT</th>
                       
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {departments.map((department, index) => (
                        <tr key={department.ID} className="hoverable-row">
                          <td className="center-alignment">
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(department.ID)}
                              onChange={() => handleSelectRow(department.ID)}
                            />
                          </td>
                          <td className="center-alignment">{index + 1}</td>
                          <td>{formatDate(department.date || "NA")}</td>
                          <td>{department.description || "NA"}</td>
                          <td> {department.created_at
                            ? new Date(department.created_at)
                                .toUTCString()
                                .split("GMT")[0]
                                .trim() + " IST"
                            : "NA"}</td>
                          <td className="center-alignment">
                            <span
                              className="inquiry-file-btns"
                              onClick={() => handleIconClick(index)}
                            >
                              <MdAttachFile className="inquiry-file-icon" />
                            </span>
                            {isPopupOpen === index && (
                              <div
                                className="inquiry-attach-popupss"
                                ref={popupRef}
                              >
                                <div className="attach-popup-contant">
                                  <div className="attachment-header">
                                    <div className="attachment-header-content">
                                      Attachments
                                    </div>
                                    <button
                                      className="fiile-close-btn"
                                      onClick={handleCloseAttachment}
                                    >
                                      <IoCloseOutline className="file-close-icon" />
                                    </button>
                                  </div>
                                  <div className="attach-popup-contant-body">
                                    {department.attachments &&
                                    department.attachments.length > 0 ? (
                                      <div className="attach-popup-content-body">
                                        {department.attachments.map(
                                          (attachment, attachmentIndex) => (
                                            <div
                                              className="inquiry-attach-file-container"
                                              key={attachmentIndex}
                                            >
                                              <div className="inquiry-attacth-file-model">
                                                <div className="attach-file-show">
                                                  <div className="text-attachment">
                                                    {attachment.filename
                                                      .length > 20
                                                      ? `${attachment.filename.substring(
                                                          0,
                                                          15
                                                        )}...`
                                                      : attachment.filename}
                                                  </div>
                                                  <div className="text-attachment-file">
                                                    File Size:{" "}
                                                    {attachment.file_size ||
                                                      "NA"}{" "}
                                                    KB
                                                  </div>
                                                </div>
                                                <div className="file-footer">
                                                  <a
                                                    href={`${API_URL}/api/download_returnable_item_attachment/${attachment.file_id}`}
                                                    download
                                                    className="file-download-attachment file-txt"
                                                  >
                                                    Download
                                                  </a>
                                                  <a
                                                    href={`${API_URL}/api/view_returnable_item_attachment/${attachment.file_id}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="file-download-attachment file-txt"
                                                  >
                                                    View
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      <div className="no-attachments">
                                        No attachments found
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>

                          <td className="edit-pop">
                            <button
                              className="edit-data-button"
                              onClick={() => handleButtonClick(index)}
                            >
                              <div className="edit-button">
                                <MdExpandMore />
                              </div>
                            </button>
                          </td>
                          {selectedRowIndex === index && (
                            <div className="edit-popup">
                              <div className="edit-popup-contant">
                                <div className="edit-popup-button">
                                  <Link
                                    to={`/edit-returnable/${department.id}`}
                                    className="inquiry-new"
                                  >
                                    <button className="edit-button-inquiry">
                                      <MdOutlineModeEditOutline /> Edit
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">No Returnable Items Found</div>
                  </div>
                )}
              </div>
              <Pagination
                showPerPage={itemsPerPage}
                total={totalItems}
                onPaginationChange={handlePaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default VendorRetuenable;
