import React, { useState, useEffect, useRef } from "react";
// import "./POConfigurationForm.css";
import "./TechnicalRequestListView.css";
import { useNavigate, useParams } from "react-router-dom"; // useParams to get po_id
import axios from "axios"; // Ensure axios is imported
import API_URL from "../../src/Config"; // Ensure the correct path to your config file
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";

function TechnicalRequestListView({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams(); // Get po_id from the route parameters
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [technicalLists, setTechnicalLists] = useState([]);
  const [rows, setRows] = useState([]);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [rejectionReason, setRejectionReason] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);
  const [inboundId, setInboundId] = useState(null); // New state to store inbound_id
  const [matCodeId, setMatCodeId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [rejectedQtys, setRejectedQtys] = useState({});
  const [isDeletePopup, setIsDeletePopup] = useState(false);

  const handleDeletePopup = () => {
    if (!rejectionReason.trim()) {
      setErrorMessage("Rejection reason is required.");
      return; // Prevent closing without entering a reason
    }
    setIsPopupVisible(false);
    setIsDeletePopup(!isDeletePopup);
  };

  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const [isPopupOpen, setIsPopupOpen] = useState(null);
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  const handleIconClick = (index, event) => {
    event.preventDefault(); // Prevent default behavior if necessary
    event.stopPropagation(); // Prevent the click from bubbling up

    // Toggle the popup for the specific index
    setIsPopupOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_inbound_purchase_orders/${id}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      const poDataResponse = response.data.inbound_purchase_orders[0];

      setTechnicalLists({
        po_no: poDataResponse.po_no,
        invoice_no: poDataResponse.invoice_no,
        attachments: poDataResponse.attachments,
      }); // Accessing PO number from the response
      setSelectedVendor({
        label: poDataResponse.vendor_name,
        value: poDataResponse.vendor_id,
      });

      setInboundId(poDataResponse.id); // Set the inbound_id
      setMatCodeId(poDataResponse.items.mat_code_id);
      const rowsData = poDataResponse.items.map((item) => ({
        matCodes: item.mat_code, // Mat Code ID
        mat_code_id: item.mat_code_id,
        mat_code_description: item.mat_code_description,
        quantity: item.qty, // Quantity
        price: item.price,
        est_unit_rate: item.est_unit_rate, // Price
        description: item.description, // Description
        inbounded_qty: item.inbounded_qty,
        inbound_list_id: item.id,
        approved_qty: item.approved_qty,
        rejected_reason: item.rejected_reason,
        IsApproved: item.IsApproved,
        unit: item.unit,
        rejected_qty: item.rejected_qty,
        isSaved: true,
        IsNew: item.IsNew,
      }));

      setRows(rowsData);
    } catch (error) {
      console.error("Error fetching PO data:", error.message);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const handleBackProject = () => {
    navigate("/inward-technical-inspection-list");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleRejectedQtyChange = (index, value, inboundedQty) => {
    let numericValue = value === "" ? 0 : Number(value);

    if (numericValue > inboundedQty) {
      numericValue = inboundedQty;
      showSnackbar(
        `The entered value cannot exceed the maximum allowable quantity of ${inboundedQty}. Rejected quantity set to ${inboundedQty}.`
      );
      setSeverity("error");
    }

    // Update the specific row's rejected_qty in the state
    const updatedRows = [...rows];
    updatedRows[index].rejected_qty = numericValue;
    updatedRows[index].accepted_qty = inboundedQty - numericValue;
    setRows(updatedRows);

    setRejectedQtys((prev) => ({
      ...prev,
      [updatedRows[index].id]: numericValue,
    }));
  };

  const handleSaveQty = (itemId) => {
    const itemIndex = rows.findIndex((row) => row.inbound_list_id === itemId);

    if (itemIndex === -1) {
      console.error("Item not found");
      return;
    }

    setCurrentRowIndex(itemIndex);

    console.log("Selected Row:", rows[itemIndex]); // Debug log

    const item = rows[itemIndex];

    if (item.rejected_qty > 0) {
      setIsPopupVisible(true);
    } else {
      setIsDeletePopup(true);
    }
  };

  const handleInputClick = (e) => {
    e.target.select(); //
  };

  const saveQty = async (item) => {
    setIsLoading(true);

    try {
      const authKey = localStorage.getItem("authKey");

      const payload = {
        mat_code_id: item.mat_code_id,
        inbound_id: inboundId,
        inbound_list_id: item.inbound_list_id,
        rejected_qty: item.rejected_qty || 0,
        rejected_reason: item.rejected_reason || "",
      };

      const response = await axios.put(
        `${API_URL}/api/update_inbound_po_status`,
        payload,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        await fetchData();
        setSeverity("success");
        showSnackbar("Rejected qty updated successfully");
      }
    } catch (error) {
      console.error("Error saving rejected qty orders:", error);
      setSeverity("error");
      showSnackbar("Failed to update rejected qty");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirm = () => {
    if (currentRowIndex === null || rows[currentRowIndex] === undefined) {
      console.error("Invalid row index");
      return;
    }

    const updatedRows = [...rows];
    updatedRows[currentRowIndex].rejected_reason = rejectionReason; // Safely set the reason

    setRows(updatedRows);
    saveQty(updatedRows[currentRowIndex]); // Save the updated row data
    setRejectionReason("");
    setIsDeletePopup(false);
    setEditingRowId(null);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />
                </Tooltip>
                Incoming Material Inspection Items
              </div>
              <div className="data-form-heading-content-right-side">
  {technicalLists.attachments && technicalLists.attachments.length > 0 && (
    <div>
      {/* Single trigger button for all attachments */}
      <span
        className="inquiry-file-btns"
        onClick={(event) => handleIconClick(0, event)} // Use a fixed index for a single button
        ref={buttonRef}
      >
        <MdAttachFile className="inquiry-file-icon" />
      </span>
      {isPopupOpen === 0 && ( // Check based on a single index for popup visibility
        <div
          className="polist-popup1"
          ref={containerRef}
        >
          <div className="attach-popup-contant">
            <div className="attachment-header">
              <div className="attachment-header-content">Attachments</div>
              <button
                className="fiile-close-btn"
                onClick={handleCloseAttachment}
              >
                <IoCloseOutline className="file-close-icon" />
              </button>
            </div>
            <div className="attach-popup-contant-body">
              <div className="inquiry-attach-file-containerssss">
                {/* Iterate through all attachments and display them */}
                {technicalLists.attachments.map((attachment, attachmentIndex) => (
                  <div
                    className="inquiry-attach-file-container"
                    key={attachmentIndex}
                  >
                    <div className="inquiry-attacth-file-model">
                      <div className="attach-file-show">
                        <div className="text-attachment">
                          {attachment.filename.length > 20
                            ? `${attachment.filename.substring(0, 15)}...`
                            : attachment.filename}
                        </div>
                        <div className="text-attachment-file">
                          File Size: {attachment.file_size || "NA"} KB
                        </div>
                      </div>

                      <div className="file-footer">
                        <a
                          href={`${API_URL}/api/download_attachment/${attachment.file_id}/${attachment.file_type}`}
                          download
                          className="file-download-attachment file-txt"
                        >
                          Download
                        </a>
                        <a
                          href={`${API_URL}/api/view_attachment/${attachment.file_id}/${attachment.file_type}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="file-download-attachment file-txt"
                        >
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )}
</div>

            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <div className="action-purchase-header">
              <div className="project-detailss">
                <div className="project-detail-arround">
                  <div className="project-details-left-side">
                    <div className="project-detail">
                      <div className="detail-header">PO Number:</div>
                      <div className="detail-part">
                        {technicalLists.po_no || "N/A"}
                      </div>
                    </div>
                    <div className="project-detail">
                      <div className="detail-header">Invoice No:</div>
                      <div className="detail-part">
                        {technicalLists.invoice_no || "N/A"}
                      </div>
                    </div>
                    <div className="project-detail">
                      <div className="detail-header">Vendor:</div>
                      {/* Display Vendor name here */}
                      <div className="detail-part">
                        {selectedVendor ? selectedVendor.label : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="action-purchase-sheet-header">
              <div className="actions-purchase-sheet-header-content">
                <div className="actions-purchase-sheet-heading-content-left-side">
                  Items
                </div>
              </div>
            </div>

            <div className="action-purchase-body">
              <div className="action-purchase-scroll-body">
                <div className="action-purchase-table">
                  <table className="action-table action-multilevel-data ">
                    <thead className="action-purchase-heads">
                      <tr>
                        <th className="th2">MAT CODE</th>
                        <th className="th5">MAT CODE DES</th>
                        <th className="th3">PO QTY</th>
                        <th className="th3">UNIT</th>
                        <th className="th3">EST UNIT RATE</th>
                        <th className="th5">DESCRIPTION</th>
                        <th className="th4">PRICE</th>
                        <th className="th4 qty1">INWARDED QTY</th>
                        <th className="th4 qty2">ACCEPTED QTY</th>
                        <th className="th4 qty3">REJECTED QTY</th>
                        <th className="th4">REJECTED REASON</th>
                        <th className="th4">ACTION</th>
                      </tr>
                    </thead>

                    <tbody className="action-purchase-scroll-bodys">
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td className="th1">{row.matCodes || "N/A"}</td>
                          <td className="th5">
                            {row.mat_code_description || "N/A"}
                          </td>
                          <td className="th22">{row.quantity || "N/A"}</td>
                          <td className="th22">{row.unit || "N/A"}</td>
                          <td className="th3">{row.est_unit_rate || "N/A"}</td>
                          <td className="th5">{row.description || "N/A"}</td>
                          <td className="th4">{row.price || "N/A"}</td>
                          <td className="th4 qty1">
                            {row.inbounded_qty || "N/A"}
                          </td>

                          <td className="qty2">
                            {row.IsNew === 0
                              ? row.approved_qty !== null
                                ? row.approved_qty
                                : "N/A"
                              : row.inbounded_qty !== null
                              ? row.accepted_qty !== undefined
                                ? row.accepted_qty
                                : row.inbounded_qty
                              : "N/A"}
                          </td>

                          <td className="th4 qty3">
                            {row.IsNew === 0 ? (
                              row.rejected_qty || "0"
                            ) : (
                              <input
                                type="number"
                                className="rejected-qty"
                                value={rows[index]?.rejected_qty || 0}
                                onChange={(e) =>
                                  handleRejectedQtyChange(
                                    index,
                                    e.target.value,
                                    row.inbounded_qty
                                  )
                                }
                                min="0"
                                onFocus={handleInputClick}
                              />
                            )}
                          </td>

                          <td className="th4">
                            {row.rejected_reason || "N/A"}
                          </td>
                          <td>
                            <button
                              className="data-form-save-button-save"
                              onClick={() => handleSaveQty(row.inbound_list_id)}
                              disabled={isLoading || row.IsNew === 0}
                              style={{
                                backgroundColor:
                                  isLoading || row.IsNew === 0
                                    ? "#e3e5ed"
                                    : "#4CAF50",
                                cursor:
                                  isLoading || row.IsNew === 0
                                    ? "not-allowed"
                                    : "pointer",
                                border:
                                  isLoading || row.IsNew === 0
                                    ? "1px solid #e3e5ed"
                                    : "1px solid #e3e5ed",
                                color:
                                  isLoading || row.IsNew === 0
                                    ? "black"
                                    : "none",
                              }}
                            >
                              {isLoading || row.IsNew === 0
                                ? "Approved"
                                : "Approve"}
                            </button>
                          </td>
                        </tr>
                      ))}

                      {isPopupVisible && (
                        <div className="custom-popup-overlay">
                          <div className="custom-popup-content">
                            <h3>Enter Rejection Reason</h3>
                            <textarea
                              value={rejectionReason}
                              onChange={(e) => {
                                setRejectionReason(e.target.value);
                                if (errorMessage) setErrorMessage("");
                              }}
                              rows={4}
                              placeholder="Please enter a rejection reason"
                            />
                            {errorMessage && (
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {errorMessage}
                              </p>
                            )}
                            <div className="custom-popup-actions">
                              <button
                                className="data-form-save-button"
                                // onClick={handlePopupClose}
                                onClick={handleDeletePopup}
                              >
                                Save
                              </button>
                              <button
                                className="data-form-cancel-button"
                                onClick={() => {
                                  setIsPopupVisible(false); // Close the popup
                                  setRejectionReason(""); // Clear the rejection reason
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>
                                    Are you sure you want to reject the entered
                                    quantity? This action cannot be undone. The
                                    accepted quantity of{" "}
                                    <b>
                                      {rows[currentRowIndex]?.rejected_qty ===
                                        0 ||
                                      !rows[currentRowIndex]?.rejected_qty
                                        ? rows[currentRowIndex]
                                            ?.inbounded_qty || 0
                                        : rows[currentRowIndex]?.accepted_qty ||
                                          0}
                                    </b>{" "}
                                    will be added to the inventory.
                                  </p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleConfirm}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ top: "75px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default TechnicalRequestListView;
