import React, { useState, useEffect, useContext, createContext } from "react";
import "./Header.css";
import axios from "axios";
import "../Responsive/HeaderResponsive.css";
import Tooltip from "@mui/material/Tooltip";
import NotificationPopup from "../Popups/NotificationPopup";
import { useNavigate } from "react-router-dom";
import { FiMinimize, FiMaximize } from "react-icons/fi";
import API_URL from "../../src/Config";
import { ThemeContext } from "../../src/ThemeContext";
import { LuLogOut } from "react-icons/lu";
import { IoMdNotificationsOutline } from "react-icons/io";

// const Header = (isSidebarExpanded) => {
function Header({ isSidebarExpanded }) {
  const navigate = useNavigate();
  // const { theme } = useTheme();
  const [greeting, setgretting] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const userName = localStorage.getItem("userName");
  const [unreadCount, setUnreadCount] = useState(0);
  const [page, setPage] = useState(1);

  const [notifications, setNotifications] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  function getInitials(userName) {
    const nameParts = userName.trim().split(" ");

    const titles = ["Mr.", "Miss", "Ms.", "Mrs."];

    const initials = nameParts
      .filter((part) => !titles.includes(part))
      .map((part) => part.charAt(0).toUpperCase())
      .slice(0, 2) // Take only the first 2 initials
      .join("");

    return initials;
  }

  const firstLetter = userName ? getInitials(userName) : "";

  useEffect(() => {
    const getGreeting = () => {
      const hour = new Date().getHours();
      if (hour < 12) {
        return "Good Morning";
      } else if (hour < 18) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    };

    setgretting(getGreeting()); // Set the greeting based on the current hour
  }, []);

  const updateTotalUnread = (count) => {
    setUnreadCount(count); // Update unread count
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen(); // Enter fullscreen
      setIsFullscreen(true);
    } else {
      document.exitFullscreen(); // Exit fullscreen
      setIsFullscreen(false);
    }
  };

  const handleLogout = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      // If authKey is present, make the logout request
      if (authKey) {
        await axios.post(
          `${API_URL}/api/logout`,
          {},
          { headers: { Authorization: authKey } }
        );
      }

      // Clear relevant local storage items
      localStorage.removeItem("authKey");
      localStorage.removeItem("userName");
      localStorage.removeItem("currentPage");
      localStorage.removeItem("itemsPerPage");
      localStorage.removeItem("selectedStatus");
      localStorage.removeItem("selectedScheduleStatus");
      localStorage.removeItem("selectedFilter");
      // localStorage.removeItem('selectedFilter');
      localStorage.removeItem("company_id");
      localStorage.removeItem("email");
      localStorage.removeItem("menuItems");
      localStorage.removeItem("user_role");
      localStorage.removeItem("menuData");

      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
      // Optionally, show user feedback here
    }
  };

  const handleNotification = () => {
    setIsPopupOpen((prev) => !prev); // Toggle popup open/close
  };
  const onClose = () => {
    setIsPopupOpen(false);
  };
  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/notifications`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });
      setNotifications(response.data.notifications);
      updateTotalUnread(response.data.unread);
    } catch (error) {
      console.error("Error fetching notification data:", error.message);
    }
  };
  useEffect(() => {
    fetchData(page, itemsPerPage);
  }, [page, itemsPerPage]);

  return (
    <>
      {/* <div className="header"> */}
      <div
        className={`data-list-content-container page-content header ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="headers">
          <div className="header-layout">
            <div className="header-wrapper">
              {/* <div className="heading-start">
              <img src="/new-logo251.png" alt="Logo" className="header-logo" />
            </div> */}
              {/* <div className="heading-centre"></div> */}
              <div className="heading-end">
                <div className="heading-end-first"></div>
                {/* <div className="heading-end-center">
                <div className="notification">
                  <RiNotification3Line className="notification-icon" />
                </div>
                <div className="setting">
                  <IoSettingsOutline className="setting-icon" />
                </div>
              </div> */}
                {/* <p>{`Current Theme: ${theme}`}</p> */}
                <div className="heading-end-last">
                  <div className="username">
                    <span>
                      Hello, {greeting} {userName}
                    </span>
                  </div>
                  <div className="username-letter">
                    <button className="letter-background">
                      <div className="first-letter">{firstLetter}</div>
                    </button>
                    {/* <div className="letter-background">
                      <div className="first-letter">{firstLetter}</div>
                    </div> */}
                  </div>
                  <button className="notification-header">
                    <Tooltip title="Notifications" arrow>
                      <div onClick={handleNotification}>
                        <IoMdNotificationsOutline className="notification-header-icon" />
                        {unreadCount > 0 && (
                          <div className="unread-count">{unreadCount}</div>
                        )}
                      </div>
                    </Tooltip>
                    {isPopupOpen && (
                      <NotificationPopup
                        onClose={onClose}
                        updateTotalUnread={updateTotalUnread}
                        fetchData={fetchData}
                      />
                    )}
                  </button>

                  <button className="fullscreen-toggle" onClick={toggleFullscreen}>
                    {isFullscreen ? (
                      <FiMinimize className="fullscreen-icon" />
                    ) : (
                      <FiMaximize className="fullscreen-icon" />
                    )}
                  </button>

                  <button className="log-out" onClick={handleLogout}>
                    {/* <div onClick={handleLogout} */}
                      <LuLogOut className="log-out-icon" />
                    {/* </div> */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
