import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Select from "react-select";
import API_URL from "../../src/Config";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
function LevelThreeAddForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [levelOneData, setLevelOneData] = useState([]);
  const [levelTwoData, setLevelTwoData] = useState([]);
  const [levelThreeData, setLevelThreeData] = useState([]);

  const [isDescriptionDuplicate, setIsDescriptionDuplicate] = useState(false);
  const [formData, setFormData] = useState({
    levelThree: "",
    Description: "",
    levelOneId: "",
    levelTwoId: "",
  });

  const navigate = useNavigate();

  const {theme} = useContext(ThemeContext)

  const [levelOptions, setLevelOptions] = useState(
    Array.from({ length: 100 }, (_, i) => ({
      value: i.toString().padStart(2, "0"),
      label: i.toString().padStart(2, "0"),
    }))
  );

  const fetchData = async (levelTwoId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_mat_code_level_three_by_level_two/${levelTwoId}`, {
        headers: {
          Authorization: authKey,
        },
      });

      const apiData = response.data;
      setLevelThreeData(apiData);
      
      // Extract used levels from the API response
      const usedLevels = new Set(apiData.map(item => item.level));

      // Filter out used levels from levelOptions
      const filteredLevelOptions = levelOptions.filter(option => !usedLevels.has(option.value));
      setLevelOptions(filteredLevelOptions);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching level1 data:", error.message);
      setIsLoading(false);
    }
  };

 

  const handleLevelThreeChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      levelThree: selectedOption ? selectedOption.value : "", 
    }));
    setErrors((prevErrors) => ({ ...prevErrors, levelThree: "" }));
  };

  const fetchLevelOneData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_all_mat_code_level_ones`, {
        headers: {
          Authorization: authKey,
        },
      });

      const options = response.data.map((level) => ({
        label: `${level.level} (${level.description})`,
        value: level.id, 
        description: level.description
      }));

      setLevelOneData(options);
    } catch (error) {
      console.error("Error fetching level1 data:", error.message);
    }
  };

  useEffect(() => {
    fetchLevelOneData();
  }, []);


  const fetchLevelTwoData = async (levelOneId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_mat_code_level_two_by_level_one/${levelOneId}`, {
        headers: {
          Authorization: authKey,
        },
      });
      const options = response.data.map((item) => ({
        label: `${item.level} (${item.description})`,
        value: item.id,
        description: item.description
      }));
      setLevelTwoData(options);
    } catch (error) {
      console.error("Error fetching Level 2 data:", error.message);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
    // if (name === "Description" && value.trim()) {
    //   checkDescription(value.trim());
    // }
  };

  // const checkDescription = async (description) => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");
  
  //     // Make the API call
  //     await axios.post(
  //       `${API_URL}/api/l3/check_mat_code_description`,
  //       { description },
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );
  
  //     setIsDescriptionDuplicate(false);
  
  //   } catch (error) {
  
  //     if (error.response && error.response.status === 400 
  //       && error.response.data.message === "Description already exists") {
  //       setIsDescriptionDuplicate(true);
  //       setErrors((prevState) => ({
  //         ...prevState,
  //         Description: "Description already exists",
  //       }));
  //     } else {
  //       // Handle other errors
  //       console.error("Error checking description:", error.message);
  //       setSeverity("error");
  //       showSnackbar("An error occurred while checking the description");
  //     }
  //   }
  // };


  const handleLevelOneChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      levelOneId: selectedOption ? selectedOption.value : "",
      levelTwoId: "", // Reset Level 2 when Level 1 changes
      level1Description: selectedOption ? selectedOption.description : "",
    }));
   
    fetchLevelTwoData(selectedOption ? selectedOption.value : "");
    setErrors((prevErrors) => ({ ...prevErrors, levelOne: "" }));
  };

  const handleLevelTwoChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      levelTwoId: selectedOption ? selectedOption.value : "",
      level2Description: selectedOption ? selectedOption.description : "",
    }));
   
    fetchData(selectedOption ? selectedOption.value : "");
    setErrors((prevErrors) => ({ ...prevErrors, levelTwo: "" }));
  };

  const handleCancelClick = () => {
    navigate("/matcode-levels?tab=level-three");
  };

  // const handleSaveClick = async () => {
  //   const errors = {};
  //   let error = false;
  //   Object.keys(formData).forEach((key) => {
  //     if (!formData[key]) {
  //       errors[key] = `${key} is required`;
  //       setSeverity("warning");
  //       error = true;
  //     }
  //   });
  
  //   if (isDescriptionDuplicate) {
  //     if (isDescriptionDuplicate) {
  //       errors.Description = "Description already exists";
  //     }
  //     setErrors(errors);
  //     return;
  //   }

    
  //   if (error) {
  //     showSnackbar("Required fields are empty");
  //     setErrors(errors);
  //     return;
  //   }
  
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");
  
  //     const response = await axios.post(
  //       `${API_URL}/api/add_mat_code_level_three`,
  //       {
  //         level: formData.levelThree,
  //         description: formData.Description,
  //         level_two_id: formData.levelTwoId,
  //         level_one_id: formData.levelOneId, 
  //       },
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );
  
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Level 3 added successfully");
  //       setTimeout(() => {
  //         navigate("/matcode-levels?tab=level-three");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving Level 3:", error.message);
  //     setSeverity("error");
  //     showSnackbar("Failed to save Level 3");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const validateForm = () => {
    let errors = {};
  
    if (!formData.levelOneId) {
      errors.levelOne = "Level 1 is required";
    }
    if (!formData.levelTwoId) {
      errors.levelTwo = "Level 2 is required";
    }
    if (!formData.levelThree) {
      errors.levelThree = "Level 3 is required";
    }
    if (!formData.Description.trim()) {
      errors.Description = "Description is required";
    }
  
    setErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors
  };
  

  const handleSaveClick = async () => {
    if (!validateForm()) {
      showSnackbar("Please fill in all required fields", "error");
      return;
    }
  
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      const response = await axios.post(
        `${API_URL}/api/add_mat_code_level_three`,
        {
          level: formData.levelThree,
          description: formData.Description,
          level_two_id: formData.levelTwoId,
          level_one_id: formData.levelOneId,
        },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
  
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Level 3 added successfully");
        setTimeout(() => {
          navigate("/matcode-levels?tab=level-three");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving Level 3:", error.message);
      setSeverity("error");
      showSnackbar("Failed to save Level 3");
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Level 3
              </div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="LevelOne">
                    Level 1<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={levelOneData}
                    onChange={handleLevelOneChange}
                    filterOption={(option, inputValue) =>
                      option.label.toLowerCase().includes(inputValue.toLowerCase())
                    } 
                    placeholder="Select Level 1"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                        border: `1px solid ${
                          theme === "dark" ? "#444" : "#ccc"
                        }`, // Border for the menu
                        borderRadius: "4px", // Optional: add border radius for rounded edges
                        zIndex: 1000, // Ensures the menu appears above other elements
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.levelOne && <div className="error-message">{errors.levelOne}</div>}
                </div>
                
                <div className="form-group-select">
                  <label htmlFor="LevelTwo">
                    Level 2<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={levelTwoData}
                    onChange={handleLevelTwoChange}
                    filterOption={(option, inputValue) =>
                      option.label.toLowerCase().includes(inputValue.toLowerCase())
                    } 
                    placeholder="Select Level 2"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                        border: `1px solid ${
                          theme === "dark" ? "#444" : "#ccc"
                        }`, // Border for the menu
                        borderRadius: "4px", // Optional: add border radius for rounded edges
                        zIndex: 1000, // Ensures the menu appears above other elements
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.levelTwo && <div className="error-message">{errors.levelTwo}</div>}
                </div>
              
                <div className="form-group-select">
                  <label htmlFor="level">
                    Level 3<span className="text-danger">*</span>
                  </label>

                  <Select
                    name="levelThree"
                    value={levelOptions.find(
                      (option) => option.value === formData.levelThree
                    )}
                    onChange={handleLevelThreeChange}
                    options={levelOptions}
                    placeholder="Select Level 3"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                        border: `1px solid ${
                          theme === "dark" ? "#444" : "#ccc"
                        }`, // Border for the menu
                        borderRadius: "4px", // Optional: add border radius for rounded edges
                        zIndex: 1000, // Ensures the menu appears above other elements
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.levelThree && (
                    <div className="error-message">{errors.levelThree}</div>
                  )}

                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentCode">
                    Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Description"
                      value={formData.Description}
                      onChange={handleInputChange}
                      placeholder="Enter description"
                      className={errors.Description ? "input-error" : ""}
                      />
                      
                    </div>
                    {errors.Description && (
                        <div className="error-message">{errors.Description}</div>
                      )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default LevelThreeAddForm;
