import React, { useState, useEffect, useRef } from "react";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Pagination from "../Component/Pagination";

function ArchivedLibraryCategory({ isSidebarExpanded, departmentID }) {
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrders, setSortOrders] = useState({});
  const [isSearching, setIsSearching] = useState(false);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const containerRef = useRef(null);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };
  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_archived_product_library_types`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      setCategory(response.data.ProductLibraryTypes);
      setTotalItems(response.data.TotalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching customers data:", error.message);
      setLoading(false);
    }
  };
  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }

    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_customers`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setCategory(response.data.Customers);
        setTotalItems(response.data.TotalItems);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? category.map((subDepartment) => subDepartment.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (subDepartmentID) => {
    const updatedSelectedRows = selectedRows.includes(subDepartmentID)
      ? selectedRows.filter((id) => id !== subDepartmentID)
      : [...selectedRows, subDepartmentID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === category.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleRestore = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/unarchive_document_library`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data restored successfully");
        fetchData();
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting departments:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setSelectAll(false);
    setChecked(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleRefresh = () => {
    fetchData();
  };
  const closePopups = () => {
    setIsSortPopup(false);
  };
  const handleBackCompany = () => {
    navigate("/product-library-categories");
  };

  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedVendors = [...category].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setCategory(sortedVendors);
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-list-content" ref={containerRef}>
          {isChecked ? (
            <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                    <button onClick={handleRestore} className="delete">
                      Unarchive
                    </button>
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="data-list-header">
              <div className="data-list-header-content">
                <div className="data-list-heading-content-left-side">
                  <Tooltip title="Back" arrow>
                    {" "}
                    <IoMdArrowBack
                      className="back-company"
                      onClick={handleBackCompany}
                    />{" "}
                  </Tooltip>
                  Archived Document Libraries
                </div>
                <div className="data-list-heading-content-right-side">
                  <Tooltip title="Refresh List" arrow>
                    <button className="refresh-button" onClick={handleRefresh}>
                      <TbRefresh className="refresh-icon" />
                    </button>
                  </Tooltip>
                  <button className="sort-button" onClick={handleSortpopup}>
                    <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                  </button>
                  {isSortPopup && (
                    <div className="sort-popup-container" ref={containerRef}>
                      <div className="sort-popup-content">
                        <div className="sort-by">
                          SORT BY {isAscending ? "(Ascending)" : "(Descending)"}
                        </div>
                        <div className="sort-by-button">
                          <button onClick={() => handleSort("library_name")}>
                            Category{" "}
                            {sortOrders.library_name ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <button onClick={() => handleSort("document_count")}>
                            Total Document{" "}
                            {sortOrders.document_count ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <button onClick={() => handleSort("created_at")}>
                            Created At{" "}
                            {sortOrders.created_at ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="data-list-body" onClick={closePopups}>
            <div className="data-list-scroll-body">
              <div className="data-list-table">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : category.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th>SR. NO.</th>
                        <th>CATEGORY</th>
                        <th>TOTAL DOCUMENTS</th>
                        <th>CREATED AT</th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {category.map((library, index) => (
                        <tr key={library.id} className="hoverable-row">
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(library.id)}
                              onChange={() => handleSelectRow(library.id)}
                            />
                          </td>

                          <td>{index + 1}</td>
                          <td>{library.library_name || "NA"}</td>
                          <td>{library.document_count || "0"}</td>
                          <td>
                            {library.created_at
                              ? new Date(library.created_at)
                                  .toUTCString()
                                  .split("GMT")[0]
                                  .trim() + " IST"
                              : "NA"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">
                      No Archived Document Libraries Found
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Pagination
          showPerPage={itemsPerPage}
          total={totalItems}
          onPaginationChange={handlePaginationChange}
        />
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ArchivedLibraryCategory;
