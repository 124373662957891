import "./isolation-certificate.css";
export default function IsolationTemplate() {
  return (
    <div className="certificate-container">
      <div className="certificate-header">
        <h1>अलगाव का प्रमाण पत्र / Certificate of Isolation:</h1>
        <div className="date-time">
          <p>दिनांक/Date:........................समय/Time:.................</p>
        </div>
      </div>

      <div className="certificate-body">
        <p className="equipment-info">
          उपकरण को प्रमाणित किया गया/सर्किट क्र. / Certified that Equipment /
          Circuit no. ................................. का/of
          .....................................
        </p>

        <p className="isolation-info">
          .............................................................................
          संयंत्र को स्विच/आइसोलेटर्स/लिंक/फ्यूज (लागू के रूप में टिक करें)
          द्वारा विद्युत रूप से अलग किया गया है और अपूर्ति पैनल पर खतरे के टैग
          लगाया गया है । विद्युत पृथक्करण के संबंध में क्रियाएँ विद्युत पारी
          लॉगबुक में दर्ज की गई है।
        </p>

        <p className="isolation-info-english">
          plant has been electrically isolated by switches / isolators / links /
          fuses (tick as applicable) and the danger tag is put on the supply
          panel. Actions in respect of electrical isolation have been recorded
          in the electrical shift logbook.
        </p>

        <p className="tag-number">
          लॉग आउट टैग क्रमांक/Lock Out Tag
          No.....................................
        </p>

        <div className="signature-section">
          <div className="signature-field">

            <p>अधिकृत व्यक्ति का नाम / Name of Authorized Person</p>
          </div>

          <div className="signature-field">

            <p>पद / Designation</p>
          </div>

          <div className="signature-field">

            <p>हस्ताक्षर / Signature</p>
          </div>
        </div>
      </div>

      <div className="section-divider"></div>

      <div className="section-b">
        <h2>अनुभाग-बी/Section-B: ऊर्जा की अनुमति / Energisation Permit</h2>

        <p className="energisation-request">
          ऊर्जा के लिए अनुरोध / Request for Energisation:
          <span className="date-time-right">
            दिनांक/Date:......................समय/Time:.................
          </span>
        </p>

        <p className="department-info">
          विभाग/अनुभाग/परमिट जारी करने वाला क्षेत्र / Department / Section /
          Area issuing the permit
          ...........................................................
        </p>

        {/* <p className="department-info-line">
          .......................................................................................................................................................................
        </p> */}

        <p className="equipment-number">
          सक्रिय होने के लिए उपकरण संख्या / Equipment number to be energized:
          .................................................................................................
        </p>

        <p className="equipment-name">
          उपकरण का नाम/सक्रिय होने के लिए सर्किट / Name of the equipment /
          circuit to be energized:
          .....................................................................
        </p>

        <p className="work-completed">
          उपर्युक्त उपकरणों पर काम से सर्किट पूरा हो गया है और सभी लागू परमिट
          बंद हो गये है। यह उपकरण/सर्किट सक्रिय हो सकता है।
        </p>

        <p className="work-completed-english">
          Work on the above mention equipment / circuit has been completed and
          all the applicable permits closed. This equipment / circuit may be
          energized.
        </p>

        <div className="signature-section">
          <div className="signature-field">
            <div className="signature-line"></div>
            <p>द्वारा अनुरोध किया गया/ Requested by</p>
          </div>

          <div className="signature-field">
            <div className="signature-line"></div>
            <p>पद /Designation</p>
          </div>

          <div className="signature-field">
            <div className="signature-line"></div>
            <p>हस्ताक्षर / Signature</p>
          </div>
        </div>
      </div>
    </div>
  );
}
