import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { MdAttachFile } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
import { FiPlus } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";

function AuditEditForm({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [prRequestData, setPrRequestData] = useState({
    id: "",
    month: null,
    year: null,
    reference_no: "",
    audit_type: "",
    attachments: [], // Array of files
  });
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const popupRef = useRef(null);

  const [rows, setRows] = useState([
    {
      point_no: "",
      responsible_person_name: "",
      person_email: "",
      observation: "",
      recommendation: "",
      target_date: "",
      organization_type: "",
    },
  ]);
 

  const organizationOptions = [
    { value: "ISPRL", label: "ISPRL" },
    { value: "Contractor", label: "Contractor" },
  ];
  


  // const handleAuditPoint = (index, field, value) => {
  //   const newRows = [...rows];
  //   newRows[index] = { ...newRows[index], [field]: value };
  //   setRows(newRows);
  // };

  const handleAuditPoint = (index, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = { ...updatedRows[index], [field]: value };
      return updatedRows;
    });
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {  point_no: "",
        responsible_person_name: "",
        person_email: "",
        observation: "",
        recommendation: "",
        target_date: "",
        organization_type: "",
       },
    ]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  // useEffect(() => {
  //   const fetchPRRequestData = async () => {
  //     try {
  //       const authKey = localStorage.getItem("authKey");
  //       const response = await axios.get(
  //         `${API_URL}/api/get_statutory_audit/${id}`,
  //         {
  //           headers: {
  //             Authorization: authKey,
  //           },
  //         }
  //       );

  //       const responseData = response.data;
  //       const prRequestData = responseData.statutory_audit;

  //       console.log(
  //         "Raw expected_delivery_date:",
  //         prRequestData.expected_delivery_date
  //       );

  //       // Safely parse the expected_delivery_date
  //       let parsedDate = null;
  //       if (prRequestData.expected_delivery_date) {
  //         parsedDate = new Date(prRequestData.expected_delivery_date);
  //         if (isNaN(parsedDate)) {
  //           console.error("Invalid date format for expected_delivery_date");
  //           parsedDate = null;
  //         }
  //       }

  //       const attachmentFiles = responseData.attachments || [];
  //       setSelectedFiles(attachmentFiles);

  //       console.log(attachmentFiles, "Attachment Files");

  //       // Extract and set file for "Technical Specification Attachment"
  //       const technicalSpecAttachment =
  //         prRequestData.attachments?.filename || null;

  //       // setTechnicalSpecFile(technicalSpecAttachment);
  //       console.log(technicalSpecAttachment, "vishal");
  //       // Set rows based on po_list_items
  //       setRows(
  //         responseData.po_list_items.map((item) => ({
  //           mat_code_id: item.mat_code_id,
  //           unit: item.unit,
  //           description: item.description,
  //           mat_code_description: item.mat_code_description,
  //           quantity: item.quantity,
  //           est_unit_rate: item.est_unit_rate,
  //         }))
  //       );

  //       // Formatting PR request data
  //       const formattedData = {
  //         reference_no: prRequestData.reference_no || "",
  //         department_id: prRequestData.department_id || "",
  //         vendor_name: prRequestData.vendor_name || "",
  //         vendor_id: prRequestData.vendor_id || "",
  //         pr_type: prRequestData.pr_type || "",
  //         description: prRequestData.description || "",
  //         mat_code: prRequestData.mat_code || "",
  //         qty: prRequestData.qty !== null ? prRequestData.qty : "",
  //         unit: prRequestData.unit || "",
  //         est_unit_rate:
  //           prRequestData.est_unit_rate !== null
  //             ? prRequestData.est_unit_rate
  //             : "",
  //         preferred_vendor: prRequestData.preferred_vendor || "",
  //         expenditure_type: prRequestData.expenditure_type || "",
  //         expected_delivery_date: parsedDate,
  //       };

  //       setPrRequestData(formattedData);
  //       // setSelectedDeliveryDate(parsedDate);
  //     } catch (error) {
  //       console.error("Error fetching department data:", error.message);
  //       setError("An error occurred while fetching department data");
  //     }
  //   };

  //   fetchPRRequestData();
  // }, [id]);
  

  useEffect(() => {
    const fetchPRRequestData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_statutory_audit/${id}`,
          {
            headers: { Authorization: authKey },
          }
        );

        const responseData = response.data;
        const prRequestData = responseData.statutory_audit;

        console.log("Raw target_date:", prRequestData.audit_points);

        // Convert audit_points target_date into Date objects if available
        const formattedAuditPoints = prRequestData.audit_points.map(
          (point) => ({
            id: point.id,
            point_no: point.point_no || "",
            responsible_person_name: point.responsible_person_name || "",
            person_email: point.person_email || "",
            target_date: point.target_date ? new Date(point.target_date) : null,
            observation: point.observation || "",
            recommendation: point.recommendation || "",
            status: point.status || "",
            organization_type: point.organization_type || "",
          })
        );

        // Handling attachments
        const attachmentFiles = prRequestData.file_details || [];
        setSelectedFiles(attachmentFiles);

        console.log(attachmentFiles, "Attachment Files");

        // Formatting PR request data
        const formattedData = {
          reference_no: prRequestData.reference_no || "",
          audit_type: prRequestData.audit_type || "",
          month: prRequestData.month || "",
          year: prRequestData.year || "",
        };

        setPrRequestData(formattedData);
        setRows(formattedAuditPoints);
      } catch (error) {
        console.error("Error fetching statutory audit data:", error.message);
        setError("An error occurred while fetching audit data");
      }
    };

    fetchPRRequestData();
  }, [id]);

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleDeleteFile = (indexToDelete) => {
    console.log("Before delete:", selectedFiles); // Debugging

    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    setSelectedFiles(updatedFiles);

    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
    }
  };

  const auditTypeOptions = [
    { value: "ESA", label: "ESA" },
    { value: "IB", label: "IB" },
    { value: "ISA", label: "ISA" },
    { value: "OISD", label: "OISD" },
  ];

  const unitOptions = [
    { value: "centimeter", label: "Centimeter (cm)" },
    { value: "visit", label: "Visit" },
    { value: "day", label: "Day" },
    { value: "gram", label: "Gram (g)" },
    { value: "inch", label: "Inch (in)" },
    { value: "kilometer", label: "Kilometer (km)" },
    { value: "kg", label: "Kilogram (kg)" },
    { value: "liters", label: "Liters (L)" },
    { value: "m", label: "Meter (m)" },
    { value: "milligram", label: "Milligram (mg)" },
    { value: "millimeter", label: "Millimeter (mm)" },
    { value: "month", label: "Month" },
    { value: "pcs", label: "Pieces (pcs)" },
    { value: "year", label: "Year" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPrRequestData({ ...prRequestData, [name]: value });
    // Clear validation error when input changes
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleCancelClick = () => {
    // navigate("/pr-request");
    window.history.back();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleUnitChange = (selectedOption, index) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].unit = selectedOption ? selectedOption.value : "";
      return updatedRows;
    });
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;
  
    // Validate primary fields
    if (!prRequestData.reference_no) {
      errors.reference_no = "Reference number is required.";
      isValid = false;
    }
    if (!prRequestData.audit_type) {
      errors.audit_type = "Audit type is required.";
      isValid = false;
    }
    if (!prRequestData.month) {
      errors.month = "Month is required.";
      isValid = false;
    }
    if (!prRequestData.year) {
      errors.year = "Year is required.";
      isValid = false;
    }
  
    // Validate rows (audit points)
    // let rowErrors = rows.map((row) => {
    //   let rowError = {};
    //   if (!row.point_no) rowError.point_no = "Point No. is required.";
    //   if (!row.responsible_person_name) rowError.responsible_person_name = "Person name is required.";
    //   if (!row.person_email) rowError.person_email = "Email is required.";
    //   if (!row.observation) rowError.observation = "Observation is required.";
    //   if (!row.recommendation) rowError.recommendation = "Recommendation is required.";
    //   if (!row.target_date) rowError.target_date = "Target date is required.";
    //   return rowError;
    // });
  
    // if (rowErrors.some((row) => Object.keys(row).length > 0)) {
    //   errors.rows = rowErrors;
    //   isValid = false;
    // }
  
    // // Validate Attachments
    // if (!attachmentFile || attachmentFile.length === 0) {
    //   errors.attachments = "At least one attachment is required.";
    //   isValid = false;
    // }
  
    setErrors(errors);
    return isValid;
  };

  // const handleSaveClick = async () => {
  //   // if (!validateForm()) {
  //   //   return; // Stop if validation fails
  //   // }

  //   const formData = new FormData();
  //   formData.append("reference_no", prRequestData.reference_no || "");
  //   formData.append("audit_type", prRequestData.audit_type || "");
  //   formData.append("month", prRequestData.month || "");
  //   formData.append("year", prRequestData.year || "");

  //   rows.forEach((row) => {
  //     formData.append("mat_code_ids[]", row.mat_code_id);
  //     formData.append("quantities[]", row.quantity);
  //     formData.append("units[]", row.unit);
  //     formData.append("est_unit_rates[]", row.est_unit_rate);
  //   });
  //   if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
  //     attachmentFile.forEach((file) => {
  //       formData.append("attachments", file);
  //     });
  //   }

  //   try {
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.put(
  //       `${API_URL}/api/update_statutory_audit/${id}`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     console.log("Response message:", response.data.message);
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("PR Request updated successfully");
  //       setTimeout(() => {
  //         navigate("/pr-request");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving PR request:", error.message);
  //     setSeverity("error");
  //     // showSnackbar("Missing item details");
  //     if (rows.length === 0) {
  //       showSnackbar("Please add at least one item to submit the PR request.");
  //     } else if (!prRequestData.department_id || !prRequestData.description) {
  //       showSnackbar(
  //         "Please add items in the Request Item Table before submitting."
  //       );
  //     } else {
  //       showSnackbar("Error saving PR request. Please try again.");
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPrRequestData((prev) => ({ ...prev, [name]: value }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      reference_no: "",
    }));
  };

  // const handleSaveClick = async () => {
    
  //   // Validate form before proceeding
  //   if (!validateForm()) {
  //     setSeverity("error");
  //     showSnackbar("Please fill in all required fields.", "error");
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("audit_point_id", id || "");
  //   formData.append("reference_no", prRequestData.reference_no || "");
  //   formData.append("audit_type", prRequestData.audit_type || "");
  //   formData.append("month", prRequestData.month || "");
  //   formData.append("year", prRequestData.year || "");

  //   const auditPoints = rows.map((row) => ({
  //     point_id: row.id || "",
  //     point_no: row.point_no || "",
  //     responsible_person_name: row.responsible_person_name || "",
  //     person_email: row.person_email || "",
  //     observation: row.observation || "",
  //     recommendation: row.recommendation || "",
  //     organization_type: row.organization_type || "",
  //     target_date: row.target_date
  //       ? new Date(row.target_date).toISOString().split("T")[0]
  //       : "", // Format as YYYY-MM-DD
  //   }));
  //   formData.append("audit_points", JSON.stringify(auditPoints));

  //   if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
  //     attachmentFile.forEach((file) => {
  //       formData.append("attachments", file);
  //     });
  //   }

  //   try {
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.put(
  //       `${API_URL}/api/update_statutory_audit`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     console.log("Response message:", response.data.message);
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Satutory Audit Reports updated successfully");
  //       setTimeout(() => {
  //         navigate("/statutory-audits");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving PR request:", error.message);
  //     setSeverity("error");
  //     // showSnackbar("Missing item details");
  //     if (rows.length === 0) {
  //       showSnackbar("Please add at least one item to submit the PR request.");
  //     } else if (!prRequestData.department_id || !prRequestData.description) {
  //       showSnackbar(
  //         "Please add items in the Request Item Table before submitting."
  //       );
  //     } else {
  //       showSnackbar("Error saving PR request. Please try again.");
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  

  const handleSaveClick = async () => {
    // Validate form before proceeding
    // if (!validateForm()) {
    //   setSeverity("error");
    //   showSnackbar("Please fill in all required fields.", "error");
    //   return;
    // }

    setIsLoading(true); // Show loading state
    setFieldErrors({}); // Reset field errors
  
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }
  
    let errors = {};
    let hasError = false;
  
    // Check if at least one item is added
    if (!Array.isArray(rows) || rows.length === 0) {
      setSeverity("error");
      showSnackbar("Please add at least one item to submit the audit request.", "error");
      setIsLoading(false);
      return;
    }
  
    // Validate each row
    rows.forEach((row, index) => {
      if (!row.point_no || !row.responsible_person_name || !row.person_email || !row.organization_type || !row.target_date || !row.observation || !row.recommendation) {
        errors[index] = {
          point_no: row.point_no ? "" : "Point Number is required",
          organization_type: row.organization_type ? "" : "Point Number is required",
          responsible_person_name: row.responsible_person_name ? "" : "Responsible Person Name is required",
          person_email: row.person_email ? "" : "Person Email is required",
          target_date: row.target_date ? "" : "Person Email is required",
          observation: row.observation ? "" : "Person Email is required",
          recommendation: row.recommendation ? "" : "Person Email is required",
        };
        hasError = true;
      }
    });
  
    // If there is an error, show a single message
    if (hasError) {
      setSeverity("error");
      showSnackbar("All fields are required.", "error");
      setFieldErrors(errors); // Update field errors for UI
      setIsLoading(false);
      return;
    }
  
    const formData = new FormData();
    formData.append("audit_id", id || "");
    formData.append("reference_no", prRequestData.reference_no || "");
    formData.append("audit_type", prRequestData.audit_type || "");
    formData.append("month", prRequestData.month || "");
    formData.append("year", prRequestData.year || "");
  
    const auditPoints = rows.map((row) => {
      let auditPoint = {
        point_no: row.point_no || "",
        responsible_person_name: row.responsible_person_name || "",
        person_email: row.person_email || "",
        observation: row.observation || "",
        recommendation: row.recommendation || "",
        organization_type: row.organization_type || "",
        target_date: row.target_date
          ? new Date(row.target_date).toISOString().split("T")[0]
          : "", // Format as YYYY-MM-DD
      };
  
      // Include point_id only if it exists (for existing rows)
      if (row.id) {
        auditPoint.point_id = row.id;
      }
  
      return auditPoint;
    });
  
    formData.append("audit_points", JSON.stringify(auditPoints));
  
    if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
      attachmentFile.forEach((file) => {
        formData.append("attachments", file);
      });
    }
  
    try {
      const authKey = localStorage.getItem("authKey");
  
      const response = await axios.put(
        `${API_URL}/api/update_statutory_audit`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      console.log("Response message:", response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Statutory Audit Reports updated successfully");
        setTimeout(() => {
          navigate("/statutory-audits");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving PR request:", error.message);
      setSeverity("error");
  
      if (rows.length === 0) {
        showSnackbar("Please add at least one item to submit the PR request.");
      } else if (!prRequestData.department_id || !prRequestData.description) {
        showSnackbar(
          "Please add items in the Request Item Table before submitting."
        );
      } else {
        showSnackbar("Error saving PR request. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleBackProject = () => {
    navigate("/pr-request");
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    // Set the actual file objects in the state for FormData
    setAttachmentFile((prevFiles) => [
      ...(prevFiles || []),
      ...Array.from(files),
    ]);
  };
  const handleIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(true);
  };

  const handleSelectChange = (selectedOption) => {
    setPrRequestData({ ...prRequestData, audit_type: selectedOption.value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      audit_type: "",
    }));
  };

  const handleUploadButtonClick = () => {
    // Trigger click event of file input when upload button is clicked
    fileInputRef.current.click();
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Statutory Audit
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="reminder_days">
                    Refrence no <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="reference_no"
                      value={prRequestData.reference_no}
                      onChange={handleChange}
                      placeholder="Enter reference no"
                      className="form-control"
                    />
                  </div>
                  {errors.reference_no && <div className="error-message">{errors.reference_no}</div>}
                </div>

                <div className="form-group">
                  <label htmlFor="month">Month <span className="text-danger">*</span></label> 
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={
                        prRequestData.month
                          ? new Date(2025, prRequestData.month - 1)
                          : null
                      }
                      onChange={(date) =>{
                        setPrRequestData({
                          ...prRequestData,
                          month: date ? date.getMonth() + 1 : null,
                        });
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          month: "",
                        }));
                      }}
                      dateFormat="MM"
                      placeholderText="Select month"
                      className="datesss"
                      showMonthYearPicker
                    />
                  </div>
                  {errors.month && <div className="error-message">{errors.month}</div>}
                </div>

                <div className="form-group">
                  <label htmlFor="year">Year <span className="text-danger">*</span></label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={
                        prRequestData.year
                          ? new Date(prRequestData.year, 0)
                          : null
                      }
                      onChange={(date) =>{
                        setPrRequestData({
                          ...prRequestData,
                          year: date ? date.getFullYear() : null,
                        });
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          year: "",
                        }));
                        
                      }}
                      dateFormat="yyyy"
                      placeholderText="Select year"
                      className="datesss"
                      showYearPicker
                    />
                  </div>
                  {errors.year && <div className="error-message">{errors.year}</div>}
                </div>

                <div className="form-group-select">
                  <label htmlFor="audit_type">Type Of Audit <span className="text-danger">*</span></label>
                  <Select
                    name="audit_type"
                    value={auditTypeOptions.find(
                      (option) => option.value === prRequestData.audit_type
                    )}
                    onChange={handleSelectChange}
                    options={auditTypeOptions}
                    placeholder="Select audit type"
                  />
  {errors.audit_type && <div className="error-message">{errors.audit_type}</div>}
                </div>

                <div className="form-group">
                  <label htmlFor="attachement">
                    Uplod Audit Report{" "} 
                    <span className="text-danger">*</span>
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={handleUploadButtonClick}
                    // ref={buttonRef}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    id="attachments"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                    multiple
                  />
                  {selectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleIconClick}
                      //   ref={buttonRef}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedFiles.length}`}
                    </span>
                  )}
                  {isPopupOpen && (
                    <div className="attach-popup-amc4" ref={popupRef}>
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.filename}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.file_size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      {" "}
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                     {/* {errors.attachments && <div className="error-message">{errors.attachments}</div>} */}
                </div>
                <div className="form-group">
                  <label htmlFor="doc_name"></label>
                  <div className="input-group">
                    <div className="error-messages125">
                      File size must be less than 20MB.
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div className="action-purchase-sheet-header">
              <div className="actions-purchase-sheet-header-content">
                <div className="actions-purchase-sheet-heading-content-left-side">
                  Audit Point
                </div>
                <div className="actions-purchase-heading-content-right-side"></div>
              </div>
            </div>

            <div className="action-purchase-body">
              <div className="action-purchase-scroll-body">
                <div className="action-purchase-tables">
                  <>
                    <table className="action-table action-multilevel-data ">
                      <thead className="action-purchase-heads">
                        <tr>
                          <th className="a-th1">
                            REPORT NO <span className="text-danger">*</span>
                          </th>
                          <th className="a-th2">
                                ORGANIZATION TYPE <span className="text-danger">*</span>
                              </th>
                          <th className="a-th2">
                            PERSON <span className="text-danger">*</span>
                          </th>
                          <th className="a-th3">
                            EMAIL ID <span className="text-danger">*</span>
                          </th>

                          <th className="a-th4">
                            TARGET DATE <span className="text-danger">*</span>
                          </th>

                          <th className="a-th5">
                            OBSERVATION
                            <span className="text-danger">*</span>
                          </th>
                          <th className="a-th6">
                            RECOMMENDATION
                            <span className="text-danger">*</span>
                          </th>
                          <th className="a-th7">ACTION</th>
                        </tr>
                      </thead>

                      <tbody className="action-purchase-scroll-bodys-audit">
                        {rows.map((row, index) => (
                          <tr key={index}>
                            {/* <td className="a-th1 audit-td"> */}
                            <td
                                  className={
                                    fieldErrors[index]?.organization_type
                                      ? "error-field"
                                      : "a-th1 audit-td-select"
                                  }
                                >
                              <input
                                type="text"
                                min="0"
                                value={row.point_no || ""} // Ensure empty input is allowed
                                onChange={(e) =>
                                  handleAuditPoint(
                                    index,
                                    "point_no",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter report no"
                              />
                            </td>
                               {/* <td className="a-th1 audit-td-select"> */}
                               <td
                                  className={
                                    fieldErrors[index]?.organization_type
                                      ? "error-field-select"
                                      : "a-th1 audit-td-select"
                                  }
                                >
                                                            <Select
                                                    name="audit_type"
                                                    value={organizationOptions.find(
                                                      (option) => option.value === rows[index].organization_type
                                                    )}
                                                    onChange={(selectedOption) =>
                                                      handleAuditPoint(
                                                        index,
                                                        "organization_type",
                                                       selectedOption ? selectedOption.value : ""
                                                      )
                                                    }
                                                    options={organizationOptions}
                                                    placeholder="Select organization type"
                                                  />
                                                            </td>
                            
                            {/* <td className="a-th2 audit-td"> */}
                            <td
                                  className={
                                    fieldErrors[index]?.responsible_person_name
                                      ? "error-field"
                                      : "a-th2 audit-td"
                                  }
                                >
                              <input
                                type="text"
                                value={row.responsible_person_name} // Ensure empty input is allowed
                                onChange={(e) =>
                                  handleAuditPoint(
                                    index,
                                    "responsible_person_name",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter person name"
                              />
                            </td>
                            {/* <td className="a-th3 audit-td"> */}
                            <td
                                  className={
                                    fieldErrors[index]?.person_email
                                      ? "error-field"
                                      : "a-th3 audit-td"
                                  }
                                >
                              <input
                                type="email"
                                value={row.person_email} // Ensure empty input is allowed
                                onChange={(e) =>
                                  handleAuditPoint(
                                    index,
                                    "person_email",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter email"
                              />
                            </td>
                            {/* <td className="a-th4 audit-td"> */}
                            <td
                                  className={
                                    fieldErrors[index]?.target_date
                                      ? "error-fields"
                                      : "a-th4 audit-td"
                                  }
                                >
                              <DatePicker
                                selected={
                                  rows[index]?.target_date
                                    ? new Date(rows[index].target_date)
                                    : null
                                }
                                onChange={(date) =>
                                  handleAuditPoint(
                                    index,
                                    "target_date",
                                    date ? date.toISOString().split("T")[0] : ""
                                  )
                                }
                                name="target_date"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Select date"
                                className="datesss"
                                onKeyDown={(e) => {
                                  if (e.key === "Backspace") {
                                    handleAuditPoint(index, "target_date", "");
                                  }
                                }}
                              />
                            </td>
                            {/* <td className="a-th5 audit-td"> */}
                            <td
                                  className={
                                    fieldErrors[index]?.observation
                                      ? "error-field"
                                      : "a-th5 audit-td"
                                  }
                                >
                              <input
                                type="text"
                                min="0"
                                value={row.observation} // Ensure empty input is allowed
                                onChange={(e) =>
                                  handleAuditPoint(
                                    index,
                                    "observation",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter observation no"
                              />
                            </td>
                            {/* <td className="a-th6 audit-td"> */}
                            <td
                                  className={
                                    fieldErrors[index]?.recommendation
                                      ? "error-field"
                                      : "a-th6 audit-td"
                                  }
                                >
                              <input

                                // type="text"
                                value={row.recommendation} // Ensure empty input is allowed
                                onChange={(e) =>
                                  handleAuditPoint(
                                    index,
                                    "recommendation",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter recommendation no"
                              />
                            </td>

                            <td className="a-th7">
                              <button
                                className="delete-row"
                                onClick={() => {
                                  const newRows = rows.filter(
                                    (_, i) => i !== index
                                  );
                                  setRows(newRows);
                                }}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="row">
                      <div className="action-caluculation">
                        <div className="new-rows">
                          <button onClick={handleAddRow} className="add-rows">
                            <FiPlus className="add-row-icon" /> Add New Row
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              {/* <button
                className="request-button"
                onClick={() => handleGenerateRequest(id)}
              >
                Send Request
              </button> */}

              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(50px)", marginLeft: "25px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default AuditEditForm;
