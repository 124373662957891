import React, { useState, useEffect, useRef } from "react";
// import "./User.css";
import "./ColdWorkPermitForm.css";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Pagination from "../Component/Pagination";
import useClickOutside from "../useClickOutside";
import { RxDownload } from "react-icons/rx";
import jsPDF from "jspdf";
import ReactDOM from "react-dom";
import html2canvas from "html2canvas";
import ColdWorkPermitTemplate from "./ColdWorkPermitTemplate";

function PermitRequestForOMHead({
  isSidebarExpanded,
  status,
  updateNewCounts,
  updateTotalItems,
  updateNotification,
  updateNotificationIds,
}) {
  const [permits, setpermits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [severity, setSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [reason, setReason] = useState(""); // Track reason input

  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedBreakdown = [...permits].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setpermits(sortedBreakdown);
  };

  const handleRejectRequest = (work_permit_id, index) => {
    navigate(`/reject-req/${work_permit_id}`);
  };

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const fetchData = async (currentPage, itemsPerPage, status) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/inspection/hot_work/get_work_permit_requests`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
            status: status, // Pass status here
          },
        }
      );

      setpermits(response.data.permit_details);
      setTotalItems(response.data.totalItems);
      updateTotalItems(response.data.tabCounts);
      updateNotification(response.data.notificationCounts);
      updateNotificationIds(response.data.notificationIds);
      updateNewCounts(response.data.newCounts);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
      setLoading(false);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage, status);
      return;
    }
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_permitss`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,

          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setpermits(response.data.permitss);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        // Fetch data when not searching and status is present
        if (status) {
          fetchData(page, itemsPerPage, status);
        }
      } else {
        // Handle search scenario
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage, status]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
  };

  const handleCloseRequests = (work_permit_id, index) => {
    navigate(`/close-request-tab/${work_permit_id}`);
  };

  const handleFillForm = async (work_permit_id, permit_type_id) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/update_status`,
        { permit_id: work_permit_id, status: "In Review" },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
      console.log(response.data);

      navigate(`/cold-work-permit-form/${work_permit_id}/${permit_type_id}`);
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };

  const handleEdit = (work_permit_id, permit_type_id) => {
    navigate(`/cold-work-permit-form/${work_permit_id}/${permit_type_id}`, {
      state: { issuer: "issuer", permit_type_id },
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleDownloadPdf = async (formId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_permit_details`, {
        headers: { Authorization: authKey },
        params: { work_permit_id: formId },
      });

      if (response.status === 200) {
        const permitDetails = response.data;

        const tempDiv = document.createElement("div");
        document.body.appendChild(tempDiv);

        ReactDOM.render(
          <ColdWorkPermitTemplate
            permitDetails={permitDetails}
            isSidebarExpanded={isSidebarExpanded}
          />,
          tempDiv
        );

        // Generate PDF from the rendered content
        const section1 = tempDiv.querySelector("#section1");
        const section2 = tempDiv.querySelector("#section2");

        if (!section1 || !section2) {
          console.error("PDF sections not found.");
          return;
        }

        const pdf = new jsPDF("p", "mm", "a4");
        const options = {
          scale: 2,
          useCORS: true,
          backgroundColor: "yellow",
          windowWidth: 1550,
        };

        // Render Section 1
        const canvas1 = await html2canvas(section1, options);
        const imgData1 = canvas1.toDataURL("image/jpeg", 0.8);
        const imgWidth1 = 190; // A4 width minus padding
        const imgHeight1 = (canvas1.height * imgWidth1) / canvas1.width;

        pdf.addImage(imgData1, "JPEG", 10, 10, imgWidth1, imgHeight1);

        // Add Section 2
        pdf.addPage();
        const canvas2 = await html2canvas(section2, options);
        const imgData2 = canvas2.toDataURL("image/jpeg", 0.8);
        const imgWidth2 = 190;
        const imgHeight2 = (canvas2.height * imgWidth2) / canvas2.width;

        pdf.addImage(imgData2, "JPEG", 10, 10, imgWidth2, imgHeight2);
        pdf.save(`Cold_Work_Permit_${formId}.pdf`);

        // Clean up the temporary div
        ReactDOM.unmountComponentAtNode(tempDiv);
        document.body.removeChild(tempDiv);
      } else {
        console.error("Failed to fetch permit details:", response.statusText);
      }
    } catch (error) {
      console.error("Error generating PDF:", error.message);
    }
  };

  const handleArchivedpermits = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_department`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedpermitss = permits.filter(
          (permits) => !selectedRows.includes(permits.ID)
        );
        setpermits(updatedpermitss);
        setSelectedRows([]);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting permitss:", error);
    }
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleView = (workPermitId) => {
    // navigate(`/cold-work-permit-inspection-view/${workPermitId}`);
    navigate(`/view-pdf-formate/${workPermitId}`);
  };
  const handleApproveExtension = (workPermitId) => {
    navigate(`/view-extension/${workPermitId}`);
  };
  const handleRefresh = () => {
    if (searchInput) {
    } else {
      fetchData(page, itemsPerPage, status);
    }
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
  };

  // const handleIssues = async (work_permit_id) => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");
  //     const response = await axios.put(
  //       `${API_URL}/api/update_status`,
  //       { permit_id: work_permit_id, status: "Issued" },
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //       // setSeverity("success");
  //       showSnackbar("Work permit issued successfully!");
  //       await fetchData(page, itemsPerPage, status);
  //     }
  //   } catch (error) {
  //     console.error("Error saving department:", error.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleIssues = async (work_permit_id) => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/hot_work/approve_work_permit`,
        { work_permit_id: work_permit_id, },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
      if (response.status === 200) {
        // setSeverity("success");
        showSnackbar("Work permit O&M head inspection successfully!");
        await fetchData(page, itemsPerPage, status);
      }
    } catch (error) {
      console.error("Error saving department:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseRequest = async (work_permit_id) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/issuer/approve_close_permit_request`,
        { work_permit_id: work_permit_id },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
      if (response.status === 200) {
        showSnackbar("Work permit issued successfully!");
        await fetchData(page, itemsPerPage, status);
      }
    } catch (error) {
      console.error("Error saving department:", error.message);
    } finally {
    }
  };

  const handleClosingRequest = (work_permit_id, request_status) => {
    // if (request_status === "Issued") {
    navigate(`/close-request-tab/${work_permit_id}`);
    // }
  };

  function getRequestStatusColor(request_status) {
    switch (request_status) {
      case "Draft":
        return "gray";
      case "In Review":
        return "blue";
      case "Submitted":
        return "orange";
      case "Safety Verified":
        return "#fb7c1f";
      case "Pending Approval":
        return "purple";
      case "Renewed":
        return "teal";
      case "Closed":
        return "#d42d2d";
      case "Rejected":
        return "red";
      case "Issued":
        return "darkgreen";
        case "O&M Verified": // ✅ Added O&M Head status
        return "#8A2BE2";
        case "Fire&Safety Verified": // ✅ Added Fire & Safety status
        return "#FF4500"; // Example: OrangeRed
      default:
        return "black";
    }
  }

  function getStatusColor(status) {
    switch (status) {
      case "Active":
        return "green";
      case "Inactive":
        return "gray";
      case "Closed":
        return "#d42d2d";
      case "Expired":
        return "red";
      default:
        return "black";
    }
  }
  return (
    <>
      <div className="data-list-content" ref={containerRef}>
        <div className="data-list-header">
          <div className="data-list-header-content">
            <div className="data-list-heading-content-left-side">
              All Work Permit Requests
            </div>

            <div className="data-list-heading-content-right-side">
              {/* <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div> */}

              <Tooltip title="Refresh List" arrow>
                <button className="refresh-button" onClick={handleRefresh}>
                  <TbRefresh className="refresh-icon" />
                </button>
              </Tooltip>
              <button
                className="sort-button"
                onClick={handleSortpopup}
                ref={buttonRef}
              >
                <PiDotsThreeOutlineVerticalFill className="sort-icon" />
              </button>
              {/* {isSortPopup && (
                    <div className="sort-popup-container" ref={containerRef}>
                      <div className="sort-popup-content">
                        <div className="sort-by">
                          SORT BY{" "}
                          {sortOrder[sortColumn] === "asc"
                            ? "(Ascending)"
                            : "(Descending)"}
                        </div>
                        <div className="sort-by-button">
                          <button
                            onClick={() => handleSort("permits_name")}
                          >
                            permitss{" "}
                            {sortOrders.permits_name === "asc" ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                          <br />
                        </div>
                        <button
                          className="delete-item"
                          onClick={handleArchivepermits}
                        >
                          Archived permitss
                        </button>
                      </div>
                    </div>
                  )} */}
            </div>
          </div>
        </div>

        <div className="data-list-body" onClick={closePopups}>
          <div className="data-list-scroll-bodys">
            <div className="data-list-table-report">
              {loading ? (
                <div className="loader-container">
                  <div className="loader"></div>
                  <div>Loading...</div>
                </div>
              ) : permits.length > 0 ? (
                <table className="table multilevel-data">
                  <thead>
                    <tr>
                      {/* <th className="center-alignment"></th> */}
                      <th className="center-alignment">SR. NO.</th>
                      <th className="center-alignment">REQUEST ID</th>
                      <th>PERMIT TYPE</th>
                      <th>RECEIVER NAME</th>
                      <th>WORK DESCRIPTION</th>
                      <th>REQUEST STATUS</th>
                      <th>STATUS</th>
                      <th>REQUESTED AT</th>

                      {permits.some(
                        (designation) =>
                          designation.request_status === "Issued" ||
                          designation.request_status === "Renewed"
                      ) && <th>ISSUED AT</th>}

                      {permits.some(
                        (designation) =>
                          designation.request_status === "Closed"
                      ) && <th>CLOSED AT</th>}
                      {/* {permits.some(
                        (designation) => designation.request_status === "Rejected"
                      ) && <th>REJECTED AT</th>} */}

                      <th className="center-alignment">ACTION</th>
                    </tr>
                  </thead>
                  <tbody className="data-list-scroll-bodys">
                    {permits.map((permit, index) => (
                      <tr key={permit.id} className="hoverable-row">
                        {/* <td className="center-alignment">
                          <div className="icon-container">
                            <span
                              className={
                                permit.is_new_row === "1"
                                  ? "new-badge blinking-new-badge"
                                  : "new-badge"
                              }
                            ></span>
                          </div>
                        </td> */}

                        <td className="center-alignment">{index + 1}</td>
                        <td
                          className="center-alignment"
                          style={{ color: "red" }}
                        >
                          {permit.request_id || "NA"}
                        </td>
                        <td>{permit.work_permit_name || "NA"}</td>
                        <td>{permit.receiver_name || "NA"}</td>
                        <td>{permit.work_description || "NA"}</td>

                        <td
                          style={{
                            color: getRequestStatusColor(permit.request_status),
                            fontWeight: "bold",
                          }}
                        >
                          {permit.request_status || "NA"}
                        </td>

                        <td
                          style={{
                            color: getStatusColor(permit.status),
                            fontWeight: "bold",
                          }}
                        >
                          {permit.status || "NA"}
                        </td>

                        <td>
                          {permit.created_at
                            ? new Date(permit.created_at)
                                .toUTCString()
                                .split("GMT")[0]
                                .trim() + " IST"
                            : "NA"}
                        </td>

                        {(permit.request_status === "Issued" ||
                          permit.request_status === "Renewed") && (
                          <td>
                            {permit.issued_date
                              ? new Date(permit.issued_date)
                                  .toUTCString()
                                  .split("GMT")[0]
                                  .trim() + " IST"
                              : "NA"}
                          </td>
                        )}

                        {/* {permit.request_status === "Rejected" && (
                          <td>
                            {permit.closed_at
                              ? new Date(permit.closed_at)
                                  .toUTCString()
                                  .split("GMT")[0]
                                  .trim() + " IST"
                              : "NA"}
                          </td>
                        )} */}

                        {permit.request_status === "Closed" && (
                          <td>
                            {permit.closed_at
                              ? new Date(permit.closed_at)
                                  .toUTCString()
                                  .split("GMT")[0]
                                  .trim() + " IST"
                              : "NA"}
                          </td>
                        )}

                        <td>
                        {permit.request_status === "Fire&Safety Verified" && (
                              <button
                              className="new-button-download"
                              onClick={() =>
                                handleIssues(permit.work_permit_id)
                              }
                            >
                              {isLoading ? "Approving..." : "Approve"}
                            </button>
                            )}
                        </td>

                        {/* <td>
                          <div className="permit-buttons1 center-alignment">
                            {permit.request_status === "Submitted" && (
                              <button
                                className="edit-header-button permit-buttons"
                                onClick={() =>
                                  handleFillForm(
                                    permit.work_permit_id,
                                    permit.permit_type_id
                                  )
                                }
                              >
                                Approve & Fill Application Form
                              </button>
                            )}
                            {permit.request_status === "Pending Approval" && (
                              <button
                                className="request-buttons permit-buttons"
                                onClick={() =>
                                  handleEdit(
                                    permit.work_permit_id,
                                    permit.permit_type_id
                                  )
                                }
                              >
                                Edit Application Form
                              </button>
                            )}
                            {permit.request_status === "In Review" && (
                              <button
                                className="request-buttons permit-buttons"
                                onClick={() =>
                                  handleEdit(
                                    permit.work_permit_id,
                                    permit.permit_type_id
                                  )
                                }
                              >
                                Resume Application Form
                              </button>
                            )}
                            {permit.request_status === "Safety Verified" && (
                              <button
                                className="new-button-download"
                                onClick={() =>
                                  handleIssues(permit.work_permit_id)
                                }
                              >
                                {isLoading ? "Issuing..." : "Issue"}
                              </button>
                            )}

                            {(permit?.request_status === "Issued" ||
                              permit?.request_status === "Renewed") && (
                              <div className="new-permit">
                                <button
                                  className="new-button-download"
                                  onClick={() =>
                                    handleDownloadPdf(permit.work_permit_id)
                                  }
                                >
                                  <RxDownload className="dnld-btn" />
                                </button>
                              </div>
                            )}

                            {(permit.request_status === "Approved" ||
                              permit.request_status === "Renewed" ||
                              permit.request_status === "Issued") && (
                              <div className="new-permit">
                                <button
                                  className="new-button-download"
                                  onClick={() =>
                                    handleView(
                                      permit.work_permit_id,
                                      permit.request_status
                                    )
                                  }
                                >
                                  View
                                </button>
                              </div>
                            )}

                            {permit?.request_status === "Closed" && (
                              <button
                                className="new-button-download"
                                onClick={() =>
                                  handleCloseRequests(permit.work_permit_id)
                                }
                              >
                                View
                              </button>
                            )}

                            {permit?.request_status === "Rejected" && (
                              <button
                                className="new-button-download"
                                onClick={() =>
                                  handleRejectRequest(permit.work_permit_id)
                                }
                              >
                                View
                              </button>
                            )}

                            {permit.has_closure_request === true && (
                              <div className="new-permit dot-relative">
                                <button
                                  className="new-button-download view-pulse-button"
                                  onClick={() =>
                                    handleClosingRequest(
                                      permit.work_permit_id,
                                      permit.request_status
                                    )
                                  }
                                >
                                  View Close Request
                                </button>
                                <span className="red-button-close"></span>
                              </div>
                            )}

                            {permit.has_extension_request === true && (
                              <div className="new-permit dot-relative ">
                                <button
                                  className="new-button-download view-pulse-button"
                                  onClick={() =>
                                    handleApproveExtension(
                                      permit.work_permit_id,
                                      permit.request_status
                                    )
                                  }
                                >
                                  View Extension Request
                                </button>
                                <div className="red-button"></div>
                              </div>
                            )}
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-users-container">
                  <div className="no-users-message">No Data Found</div>
                </div>
              )}
            </div>
            <Pagination
              showPerPage={itemsPerPage}
              total={totalItems}
              onPaginationChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PermitRequestForOMHead;
