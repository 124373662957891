import React, { useState, useEffect, useRef } from "react";
// import "./User.css";
import "./ColdWorkPermitForm.css";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Pagination from "../Component/Pagination";
import useClickOutside from "../useClickOutside";

function PermitRequestForSafety({
  isSidebarExpanded,
  designationID,
  status,
  updateNewCounts,
  updateTotalItems,
  updateNotification,
  updateNotificationIds,
}) {
  const [designations, setDesignations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);

  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };


  const handleRejectRequest = (work_permit_id, index) => {
    navigate(`/reject-req/${work_permit_id}`);
  };

  const handleCloseRequests = (work_permit_id, index) => {
    navigate(`/close-request-tab/${work_permit_id}`);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const fetchData = async (currentPage, itemsPerPage, status) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/inspection/get_work_permit_requests`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
            status: status, 
          },
        }
      );

      // Check if permit_details exists and is an array
      if (Array.isArray(response.data.permit_details)) {
        setDesignations(response.data.permit_details);
        setTotalItems(response.data.totalItems);
        updateTotalItems(response.data.tabCounts);
        updateNotification(response.data.notificationCounts);
        updateNotificationIds(response.data.notificationIds);
        updateNewCounts(response.data.newCounts);
      } else {
        setDesignations([]);
        setTotalItems(0);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_designations`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setDesignations(response.data.Designations);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        // Fetch data when not searching and status is present
        if (status) {
          fetchData(page, itemsPerPage, status);
        }
      } else {
        // Handle search scenario
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage, status]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchivedDesignation = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_department`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDesignations = designations.filter(
          (designation) => !selectedRows.includes(designation.ID)
        );
        setDesignations(updatedDesignations);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting designations:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleGenratePermit = (id) => {
    console.log("ID:", id); // Log the ID
    // navigate(`/cold-work-permit-inspection-view/${id}`);
    navigate(`/view-pdf-formate/${id}`);
  };

  const handleRefresh = () => {
    if (searchInput) {
      console.log("Search input is set:", searchInput);
    } else {
      fetchData(page, itemsPerPage, status);
    }
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  const handleApproveExtension = (workPermitId) => {
    navigate(`/view-extension/${workPermitId}`);
  };

  const handleClosingRequest = (work_permit_id) => {
    // if (request_status === "Issued") {
    navigate(`/close-request-tab-inspection/${work_permit_id}`);
    // }
  };

  function getRequestStatusColor(request_status) {
    switch (request_status) {
      case "Draft":
        return "gray";
      case "In Review":
        return "blue";
      case "Submitted":
        return "orange";
      case "Approved":
        return "green";
      case "Pending Approval":
        return "purple";
      case "Renewed":
        return "teal";
      case "Closed":
        return "#d42d2d";
      case "Rejected":
        return "red";
      case "Issued":
        return "darkgreen";
        case "Safety Verified":
          return "#fb7c1f";
        case "Fire&Safety Verified": // ✅ Added Fire & Safety status
        return "#FF4500"; // Example: OrangeRed
      default:
        return "black";
    }
  }

  function getStatusColor(status) {
    switch (status) {
      case "Active":
        return "green";
      case "Inactive":
        return "gray";
      case "Closed":
        return "#d42d2d";
      case "Expired":
        return "red";
      default:
        return "black";
    }
  }
  return (
    <>
      <div className="data-list-content" ref={containerRef}>
        {isChecked ? (
          <div className="action-header">
            <div className="action-header-content">
              <div className="action-header-content-leftside">
                <div className="action-button">
                  <button className="delete" onClick={handleDeletePopup}>
                    Move to archive
                  </button>
                  {isDeletePopup && (
                    <div className="confirm-delete-popup">
                      <div className="confirm-delete-popup-content">
                        <div className="confirm-model">
                          <div className="confirm-model-content">
                            <div className="confirm-model-content-body">
                              <p>Are you sure want to move archive?</p>
                            </div>
                            <div className="confirm-model-content-footer">
                              <button
                                className="confirm-delete-button"
                                onClick={handleArchivedDesignation}
                              >
                                Yes
                              </button>
                              <button
                                className="confirm-cancel-button"
                                onClick={handleCancel}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="action-header-content-rightside">
                <button
                  className="action-header-close-button"
                  onClick={handleCloseButtonClick}
                >
                  <IoCloseOutline className="action-header-close-icon" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="data-list-header">
            <div className="data-list-header-content">
              <div className="data-list-heading-content-left-side">
                All Work Permit Inspection
              </div>

              <div className="data-list-heading-content-right-side">
                <Tooltip title="Refresh List" arrow>
                  <button className="refresh-button" onClick={handleRefresh}>
                    <TbRefresh className="refresh-icon" />
                  </button>
                </Tooltip>
                <button
                  className="sort-button"
                  onClick={handleSortpopup}
                  ref={buttonRef}
                >
                  <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                </button>
                {/* {isSortPopup && (
                    <div className="sort-popup-container" ref={containerRef}>
                      <div className="sort-popup-content">
                        <div className="sort-by">
                          SORT BY{" "}
                          {sortOrder[sortColumn] === "asc"
                            ? "(Ascending)"
                            : "(Descending)"}
                        </div>
                        <div className="sort-by-button">
                          <button
                            onClick={() => handleSort("designation_name")}
                          >
                            Designations{" "}
                            {sortOrders.designation_name === "asc" ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                          <br />
                        </div>
                        <button
                          className="delete-item"
                          onClick={handleArchiveDesignation}
                        >
                          Archived Designations
                        </button>
                      </div>
                    </div>
                  )} */}
              </div>
            </div>
          </div>
        )}
        <div className="data-list-body" onClick={closePopups}>
          <div className="data-list-scroll-bodys">
            <div className="data-list-table-report">
              {loading ? (
                <div className="loader-container">
                  <div className="loader"></div>
                  <div>Loading...</div>
                </div>
              ) : designations.length > 0 ? (
                <table className="table multilevel-data">
                  <thead>
                    <tr>
                      {/* <th className="center-alignment"></th> */}
                      <th className="center-alignment">SR. NO.</th>
                      <th className="center-alignment">REQUEST ID</th>
                      <th>PERMIT TYPE</th>
                      <th>RECEIVER NAME</th>
                      <th>WORK DESCRIPTION</th>
                      <th>REQUEST STATUS</th>
                      <th>STATUS</th>
                      {/* <th>SECTION</th>
                      <th>UNIT</th>
                      <th>AREA</th> */}
                      <th>REQUESTED AT</th>
                      {designations.some(
                        (designation) =>
                          designation.request_status === "Issued" ||
                          designation.request_status === "Renewed"
                      ) && <th>ISSUED AT</th>}

                      {designations.some(
                        (designation) => designation.request_status === "Reject"
                      ) && <th>REJECTED AT</th>}

                      {designations.some(
                        (designation) => designation.request_status === "Closed"
                      ) && <th>CLOSED AT</th>}

                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody className="data-list-scroll-bodys">
                    {designations.map((designation, index) => (
                      <tr key={designation.id} className="hoverable-row">
                        {/* <td className="center-alignment">
                          <div className="icon-container">
                            <span
                              className={
                                designation.is_new_row === "1"
                                  ? "new-badge blinking-new-badge"
                                  : "new-badge"
                              }
                            ></span>
                          </div>
                        </td> */}

                        <td className="center-alignment">{index + 1}</td>
                        <td
                          className="center-alignment"
                          style={{ color: "red" }}
                        >
                          {designation.request_id || "NA"}
                        </td>
                        <td>{designation.work_permit_name || "NA"}</td>
                        <td>{designation.receiver_name || "NA"}</td>
                        <td>{designation.work_description || "NA"}</td>

                        <td
                          style={{
                            color: getRequestStatusColor(
                              designation.request_status
                            ),
                            fontWeight: "bold",
                          }}
                        >
                          {designation.request_status || "NA"}
                        </td>

                        <td
                          style={{
                            color: getStatusColor(designation.status),
                            fontWeight: "bold",
                          }}
                        >
                          {designation.status || "NA"}
                        </td>

                        <td>
                          {designation.created_at
                            ? new Date(designation.created_at)
                                .toUTCString()
                                .split("GMT")[0]
                                .trim() + " IST"
                            : "NA"}
                        </td>

                        {(designation.request_status === "Issued" ||
                          designation.request_status === "Renewed") && (
                          <td>
                            {designation.issued_date
                              ? new Date(designation.issued_date)
                                  .toUTCString()
                                  .split("GMT")[0]
                                  .trim() + " IST"
                              : "NA"}
                          </td>
                        )}

                        {designation.request_status === "Rejected" && (
                          <td>
                            {designation.closed_at
                              ? new Date(designation.closed_at)
                                  .toUTCString()
                                  .split("GMT")[0]
                                  .trim() + " IST"
                              : "NA"}
                          </td>
                        )}

                        {designation.request_status === "Closed" && (
                          <td>
                            {designation.closed_at
                              ? new Date(designation.closed_at)
                                  .toUTCString()
                                  .split("GMT")[0]
                                  .trim() + " IST"
                              : "NA"}
                          </td>
                        )}

                        {/* {(designation.request_status !== "Close Under Review" || designation.request_status !== "Rejected" || designation.request_status !== "Approved") && (
                            <button
                              className="new-button-download"
                              onClick={() =>
                                handleGenratePermit(designation.work_permit_id)
                              }
                            >
                              View
                            </button>
                          )} */}
                        <td className="permit-buttons1">
                          {designation.request_status !== "Closed" &&
                            designation.request_status !== "Rejected" && (
                              <button
                                className="new-button-download"
                                onClick={() =>
                                  handleGenratePermit(
                                    designation.work_permit_id
                                  )
                                }
                              >
                                View
                              </button>
                            )}

                          {designation?.request_status === "Rejected" && (
                            <button
                              className="new-button-download"
                              onClick={() =>
                                handleRejectRequest(designation.work_permit_id)
                              }
                            >
                              View
                            </button>
                          )}

                          {designation?.request_status === "Closed" && (
                            <button
                              className="new-button-download"
                              onClick={() =>
                                handleCloseRequests(designation.work_permit_id)
                              }
                            >
                              View
                            </button>
                          )}

                          {/* {(
                            designation.request_status === "Issued" ||
                            designation.request_status === "Renewed") && (
                            <button
                              className="new-button-download"
                              onClick={() =>
                                handleApproveExtension(
                                  designation.work_permit_id,
                                  designation.request_status
                                )
                              }
                            >
                               View Extension Request
                            </button>
                          )} */}

                          {designation.has_extension_request === true && (
                            <div className="new-permit dot-relative">
                              <button
                                className="new-button-download view-pulse-button"
                                onClick={() =>
                                  handleApproveExtension(
                                    designation.work_permit_id,
                                    designation.request_status
                                  )
                                }
                              >
                                View Extension Request
                              </button>
                              <div className="red-button"></div>
                            </div>
                          )}

                          {designation.has_closure_request === true && (
                            <div className="new-permit dot-relative">
                              <button
                                className="new-button-download view-pulse-button"
                                onClick={() =>
                                  handleClosingRequest(
                                    designation.work_permit_id,
                                    designation.request_status
                                  )
                                }
                              >
                                View Close Request
                              </button>
                              <div className="red-button-close"></div>
                            </div>
                          )}

                          {/* {(designation.request_status === "Renewed" ||
                              designation.request_status === "Approved" ||
                              designation.request_status === "Rejected" ||
                              designation.request_status ===
                                "Close Under Review" ||
                              designation.request_status === "Issued" ||
                              designation.request_status ===
                                "Close Requested") && (
                              <button
                                className="new-button-download"
                                onClick={() =>
                                  handleDownloadPdf(designation.work_permit_id)
                                }
                              >
                                Download
                              </button>
                            )} */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-users-container">
                  <div className="no-users-message">No Data Found</div>
                </div>
              )}
            </div>
            <Pagination
              showPerPage={itemsPerPage}
              total={totalItems}
              onPaginationChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PermitRequestForSafety;
