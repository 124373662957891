import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { BsInfoCircle } from "react-icons/bs";
import Pagination from "../Component/Pagination";
import useClickOutside from "../useClickOutside";

function Vendor({ isSidebarExpanded }) {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const fetchTimeout = useRef(null);

  const renderTooltip = (location) => (
    <div>
      <p>
        <strong>Contact Person:</strong> {location.contact_person_name || "NA"}
      </p>
      <p>
        <strong>Contact Number:</strong> {location.contact_no || "NA"}
      </p>
      <p>
        <strong>Email:</strong> {location.email || "NA"}
      </p>
      <p>
        <strong>Address:</strong> {location.address || "NA"}
      </p>
    </div>
  );

  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_vendors`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setVendors(response.data.Vendors);
        setTotalItems(response.data.TotalItems);
      } else {
        console.error("Error fetching vendors:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching vendors:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }

    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_vendors`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setVendors(response.data.Vendors);
        setTotalItems(response.data.TotalItems);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(newSelectAll ? vendors.map((vendor) => vendor.id) : []);
    setChecked(newSelectAll);
  };

  const handleSelectRow = (vendorID) => {
    const updatedSelectedRows = selectedRows.includes(vendorID)
      ? selectedRows.filter((id) => id !== vendorID)
      : [...selectedRows, vendorID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === vendors.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchiveDeparment = () => {
    // alert('..testing')
    navigate("/archived-vendor");
  };

  const handleArchivedDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_vendors`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDesignations = vendors.filter(
          (designation) => !selectedRows.includes(designation.id)
        );
        setVendors(updatedDesignations);
        fetchData()
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting designations:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setSelectAll(false);
    setChecked(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);
  };

  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedVendors = [...vendors].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setVendors(sortedVendors);
  };

  const handleRefresh = () => {
    if (searchInput) {
      console.log("Search input is set:", searchInput);
    } else {
      fetchData(page, itemsPerPage);
    }
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const handleVendorView = async (id) => {
    navigate(`/vendor-view/${id}`);
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
    
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                      <button className="delete" onClick={handleDeletePopup}>
                        Move to archive
                      </button>
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchivedDepartment}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All Vendors
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Link to="/vendor-form" className="data-list-new">
                      <Tooltip title="New Vendor" arrow>
                        <button className="new-button">
                          <HiOutlinePlus className="new-plus-icon" /> New
                        </button>
                      </Tooltip>
                    </Link>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button
                      className="sort-button"
                      onClick={handleSortpopup}
                      ref={buttonRef}
                    >
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {sortOrder[sortColumn] === "asc"
                              ? "(Ascending)"
                              : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button onClick={() => handleSort("vendor_name")}>
                              Vendor Name{" "}
                              {sortOrder.vendor_name === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("vendor_type")}>
                              Vendor Type{" "}
                              {sortOrder.vendor_type === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("contact_no")}>
                              Contact No{" "}
                              {sortOrder.contact_no === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("gst_no")}>
                              GST No{" "}
                              {sortOrder.gst_no === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("pan")}>
                              PAN No{" "}
                              {sortOrder.pan === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            </div>
                            <button
                              className="delete-item"
                              onClick={handleArchiveDeparment}
                            >
                              Archived Vendors
                            </button>
                       
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : vendors.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th className="center-alignment">
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th className="center-alignment">SR. NO.</th>
                          <th>VENDOR NAME</th>
                          <th className="center-alignment">TYPE OF VENDOR</th>
                          <th className="center-alignment">CONTACT NO</th>
                          <th className="center-alignment">GST NO</th>
                          <th className="center-alignment">PAN</th>
                          <th>ADDRESS</th>
                          <th>PLACE OF BUSINESS</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {vendors.map((vendor, index) => (
                          <tr key={vendor.id} className="hoverable-row">
                            <td className="center-alignment">
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(vendor.id)}
                                onChange={() => handleSelectRow(vendor.id)}
                              />
                            </td>
                            <td className="center-alignment">
                              {vendor.serial_no}
                            </td>
                            <td
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                              }}
                              onClick={() => handleVendorView(vendor.id)}
                            >
                              {vendor.vendor_name}
                            </td>
                            {/* <td>{vendor.vendor_type || "NA"}</td> */}
                            <td className="status-vendor center-alignment">
                              <button
                                style={{
                                  backgroundColor:
                                    vendor.vendor_type === "Service"
                                      ? "#6AAB9C"
                                      : vendor.vendor_type === "Both"
                                      ? "#E06C78"
                                      : vendor.vendor_type === "Material"
                                      ? "#384E78"
                                      : "transparent",
                                      width: "8vw",
                                }}
                               
                              >
                                {vendor.vendor_type || "NA"}
                              </button>
                            </td>
                            <td className="center-alignment">
                              {vendor.contact_no || "NA"}
                            </td>
                            <td className="center-alignment">
                              {vendor.gst_no || "NA"}
                            </td>
                            <td className="center-alignment">
                              {vendor.pan || "NA"}
                            </td>
                            <td>{vendor.address || "NA"}</td>
                            <td>
                              {vendor.locations.map((location, index) => (
                                <React.Fragment key={index}>
                                  {location.location || "NA"}
                                  <Tooltip title={renderTooltip(location)}>
                                    <span>
                                      <BsInfoCircle className="hover-icon" />
                                    </span>
                                  </Tooltip>
                                  {index !== vendor.locations.length - 1 &&
                                    ", "}
                                </React.Fragment>
                              ))}
                            </td>
                            <td className="edit-pop">
                              <button
                                className="edit-data-button"
                                onClick={() => handleButtonClick(index)}
                              >
                                <div className="edit-button">
                                  <MdExpandMore />
                                </div>
                              </button>
                            </td>
                            {selectedRowIndex === index && (
                              <div className="edit-popup">
                                <div className="edit-popup-contant">
                                  <div className="edit-popup-button">
                                    <Link
                                      to={`/edit-vendor/${vendor.id}`}
                                      className="inquiry-new"
                                    >
                                      <button className="edit-button-inquiry">
                                        <MdOutlineModeEditOutline /> Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">No Vendor Found</div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
     
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Vendor;
