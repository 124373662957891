import React, { useState, useEffect } from "react";
import "./UserForm.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";

function Manpowerrequirment({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const [manPowerData, setManPowerData] = useState({
    project_id: id || "",
    position: "",
    profile: "", // This will be renamed to expected_profile
    no_of_position: "", // This will be renamed to no_of_positions
    status: "open", // Add status with a default value
  });
  const [selectedProject, setSelectedProject] = useState(null); // Stores selected project

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setManPowerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleCancelClick = () => {
    navigate(`/manpower-requirements`);
  };
  

  const validateForm = () => {
    const validationErrors = {};

    // Log the manPowerData state to see the current values
    console.log("Current manPowerData:", manPowerData);

    if (!selectedProject) {
      validationErrors.selectedProject = "Project is required.";
      console.log("project is empty");
    } else {
      console.log("Position value:", manPowerData.selectedProject); // Log position value
    }

    // Check if position is empty
    if (!manPowerData.position) {
      validationErrors.position = "Position is required.";
      console.log("Position is empty");
    } else {
      console.log("Position value:", manPowerData.position); // Log position value
    }

    // Check if no_of_position is empty (match the state field name)
    if (!manPowerData.no_of_position) {
      validationErrors.no_of_position = "Number of positions is required.";
      console.log("No of positions is empty");
    } else {
      console.log("No of positions value:", manPowerData.no_of_position); // Log no_of_position value
    }

    // Check if profile is empty
    if (!manPowerData.profile) {
      validationErrors.profile = "Profile is required.";
      console.log("Profile is empty");
    } else {
      console.log("Profile value:", manPowerData.profile); // Log profile value
    }

    // Set errors if any
    setErrors(validationErrors);

    // Return true if no errors, otherwise false
    return Object.keys(validationErrors).length === 0;
  };

  const handleSaveClick = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Construct the payload
      const payload = {
        project_id: selectedProject?.value, // Extract only 'value'
        position: manPowerData.position,
        expected_profile: manPowerData.profile,
        no_of_positions: manPowerData.no_of_position,
        status: manPowerData.status,
      };

      const response = await axios.post(
        `${API_URL}/api/add_manpower_requirement`,
        payload,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Manpower requirement added successfully");
        setTimeout(() => {
          navigate(`/manpower-requirements`);
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving manpower requirement:", error.message);
      setSeverity("error");
      showSnackbar("Failed to save manpower requirement");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const fetchProjects = async () => {
    try {
      const authKey = localStorage.getItem("authKey"); // Retrieve auth key from local storage

      const response = await fetch(`${API_URL}/api/get_all_projects`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey, // Attach auth key in headers
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      const projectOptions = data.Projects.map((project) => ({
        value: project.id, // Unique identifier
        label: project.project_name, // Display name
      }));

      setProjects(projectOptions); // Update state with project options
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleProjectChange = (selectedOption) => {
    setSelectedProject(selectedOption);
    setErrors((prevState) => ({
      ...prevState,
      selectedProject: "", // Clear the error for selectedProject
    }));
  };
  

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Manpower Requirment
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onSubmit={handleSaveClick}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="department_name">
                    Project<span className="text-danger">*</span>
                  </label>
                  {/* <div className="d-flex-col"> */}
                  <Select
                    id="project_name"
                    options={projects}
                    value={selectedProject}
                    onChange={handleProjectChange}
                    placeholder="Select Project"
                    isSearchable
                  />

                  {/* </div> */}
                  {errors.selectedProject && (
                    <div className="error-message">{errors.selectedProject}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="position">
                    Position<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="position"
                      value={manPowerData.position}
                      onChange={handleInputChange}
                      placeholder="Enter position"
                    />
                  </div>
                  {errors.position && (
                    <div className="error-message">{errors.position}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="no_of_position">
                    No. Of Position <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="no_of_position"
                      value={manPowerData.no_of_position}
                      onChange={handleInputChange}
                      placeholder="Enter number of positions"
                    />
                  </div>
                  {errors.no_of_position && (
                    <div className="error-message">{errors.no_of_position}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="profile">
                    Expected Profile<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="profile"
                      value={manPowerData.profile}
                      onChange={handleInputChange}
                      placeholder="Enter expected profile"
                    />
                  </div>
                  {errors.profile && (
                    <div className="error-message">{errors.profile}</div>
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Manpowerrequirment;
