import React, { useState, useEffect } from "react";
// import "./Template1.css";
// import "./Template4.css";

  const HotWorkTemplate8 = ({ clearanceRenewal = [], workDetails = {} }) => {
    const totalRows = 5;
  
    // Ensure clearanceRenewal is an array before using slice
    const dataRows = Array.isArray(clearanceRenewal) ? clearanceRenewal.slice(0, totalRows) : [];
    const emptyRows = Array.from(
      { length: Math.max(totalRows - dataRows.length, 0) },
      () => ({}) // Fill remaining rows with empty objects
    );
  
    // Combine filled and empty rows
    const rows = [...dataRows, ...emptyRows];

    const formatDate = (dateString) => {
      if (!dateString) return "";
  
      // Create a Date object from the date string
      const date = new Date(dateString);
  
      // Convert to UTC and format time
   
      // Format date to DD/MM/YYYY using UTC date methods
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based in JS
      const year = date.getUTCFullYear();
  
      // Combine to form the date string in DD/MM/YYYY format
      const dateStr = `${day}/${month}/${year}`;
  
      return `${dateStr}`;
    };

    const formatDateTime = (dateString) => {
      if (!dateString) return "";
  
      // Create a Date object from the date string
      const date = new Date(dateString);
  
      // Convert to UTC and format time
      const time = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "UTC",
      });
  
      // Format date to DD/MM/YYYY using UTC date methods

      return `${time}`;
    };
  return (
    <>
  
        <div className="hot-template-container">
          <div className="hot-template-container-content">
            <div className="template-container-header">
            <div className='template-heading'>क्लीयरेंस नवीकरण /Clearance renewal </div>
            <div className="cold-remarks">
           
           
            </div>
              <div className="hot-work-table">
                <table className="cold-work-table">
                  <thead>
                    <tr>
                      <th rowSpan="2" className='permit-template-clearance renewal-permit1' >
                        दिनांक <br /> Date
                      </th>
                      <th colspan="2" className='permit-template-clearance '>समय/Time </th>
                      <th className='permit-template-clearance renewal-permit01'>विस्तार <br/> /Extension</th>
                      <th rowSpan="2" className='permit-template-clearance renewal-permit01'>
                        अतिरिक्त <br/> सावधानिया यदि <br/> कोई, अन्याथा <br/> उल्लेख कुध नहीं / <br/>
                        Additional <br/> precautions if <br/> any, Otherwise <br/> mention "NIL"
                      </th>
                      <th rowSpan="2" className='permit-template-clearance renewal-permit2'>
                        जारीकर्ता का नाम, पद <br/>और हस्ताक्षर  <br/>Issuer Name, <br/>
                        Designation & <br/> Signature
                      </th>

                      <th rowSpan="2" className='permit-template-clearance renewal-permit2'>
                        प्राप्तकर्ता नाम एवं <br/> हस्ताक्षर <br/> Receivers Name, <br/> Designation & <br/> Signature
                      </th>
                      <th rowSpan="2"className='permit-template-clearance renewal-permit2' >
                        सुरक्षा आधिकारी <br/>  नाम और हस्ताक्षर <br/> Safety Officer <br/> Name & <br/>
                        Signature
                      </th>
                    </tr>
                    <tr>
                      <th className='permit-template-clearance renewal-permit1'>
                        से <br />
                        From
                      </th>
                      <th className='permit-template-clearance renewal-permit1'>
                        तक <br />
                        to
                      </th>
                      <th className='permit-template-clearance'>
                        तक बढ़ा दिया <br/> गया है। <br/> Extended up <br/> to
                        __Hrs.
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {rows.map((row, index) => (
                    <tr className="permit-row-end" key={index}>
                      <td>{formatDate(row.date_from || "")}</td>
                      <td>{formatDateTime(row.date_from || "")}</td>
                      <td>{formatDateTime(row.date_to || "")}</td>
                      <td>{row.extended_hours || ""}</td>
                      <td>{row.additional_instruction || ""}</td>
                      <td>
                        {row.issuer_name
                          ? `${row.issuer_name} (${row.issuer_designation})`
                          : ""}
                      </td>
                      <td>
                        {row.issuer_name
                          ? `${row.receiver_name || "N/A"}`
                          : ""}
                      </td>
                      <td>{row.safety_officer_name || ""}</td>
                     
                     
                    </tr>
                  ))}
                </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
};

export default HotWorkTemplate8;
