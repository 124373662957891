import React, { useState, useEffect, useRef } from "react";
import "./ReportLog.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Pagination from "../Component/Pagination";
import useClickOutside from "../useClickOutside";
import { MdOutlineAttachEmail } from "react-icons/md";
import MonthlyReportEmail from "../Popups/MonthlyReportEmail";

function Designation({ isSidebarExpanded, designationID }) {
  const [designations, setDesignations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [isEmailTypePopupOpen, setIsEmailTypePopupOpen] = useState(false);
  const [isEmailsPopupOpen, setIsEmailsPopupOpen] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState("");

  const handleEmailSetting = () => {
    setIsEmailTypePopupOpen((prev) => !prev);
  };

  const closePopup = () => {
    setIsEmailTypePopupOpen(false);
  };
  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   const formatter = new Intl.DateTimeFormat("en-GB", {
  //     day: "2-digit",
  //     month: "2-digit",
  //     year: "2-digit",
  //   });
  //   return formatter.format(date);
  // };

  const formatDates = (dateString) => {
    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // Adjust for UTC
  
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  };
  
  
  
  const handleClosePopupEmail = () => {
    setIsEmailsPopupOpen(false);
  };

  // const handleEmailSchedules = () => {
  //   setIsEmailsPopupOpen(true); // Open the popup
    
  // };
  const handleEmailSchedules = (reportType) => {
    setSelectedReportType(reportType); // Set the selected report type
    setIsEmailsPopupOpen(true); // Open the MonthlyReportEmail component
    setIsEmailTypePopupOpen(false); // Close the initial popup
  };


  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };
  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedBreakdown = [...designations].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setDesignations(sortedBreakdown);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_monthly_report_logs`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      setDesignations(response.data.records);
      setTotalItems(response.data.TotalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
      setLoading(false);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_designations`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setDesignations(response.data.Designations);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? designations.map((designation) => designation.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (designationID) => {
    const updatedSelectedRows = selectedRows.includes(designationID)
      ? selectedRows.filter((id) => id !== designationID)
      : [...selectedRows, designationID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === designations.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchivedDesignation = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_designations`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDesignations = designations.filter(
          (designation) => !selectedRows.includes(designation.id)
        );
        setDesignations(updatedDesignations);
        fetchData();
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting designations:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveDesignation = () => {
    navigate("/archived-designations");
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRefresh = () => {
    if (searchInput) {
      console.log("Search input is set:", searchInput);
    } else {
      fetchData(page, itemsPerPage);
    }
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-list-content" ref={containerRef}>
          {isChecked ? (
            <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                    <button className="delete" onClick={handleDeletePopup}>
                      Move to archive
                    </button>
                    {isDeletePopup && (
                      <div className="confirm-delete-popup">
                        <div className="confirm-delete-popup-content">
                          <div className="confirm-model">
                            <div className="confirm-model-content">
                              <div className="confirm-model-content-body">
                                <p>Are you sure want to move archive?</p>
                              </div>
                              <div className="confirm-model-content-footer">
                                <button
                                  className="confirm-delete-button"
                                  onClick={handleArchivedDesignation}
                                >
                                  Yes
                                </button>
                                <button
                                  className="confirm-cancel-button"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="data-list-header">
              <div className="data-list-header-content">
                <div className="data-list-heading-content-left-side">
                  All Monthly Report Log
                </div>
                <div className="data-list-heading-content-right-side">
                  {/* <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div> */}

                  {/* <Link to="/designation-form" className="data-list-new">
                      <Tooltip title="New designation" arrow>
                        <button className="new-button">
                          <HiOutlinePlus className="new-plus-icon" /> New
                        </button>
                      </Tooltip>
                    </Link> */}

                  <button
                    className="email-comfig-button"
                    onClick={handleEmailSetting}
                  >
                    <MdOutlineAttachEmail className="email-comfig-button-icon" />
                  </button>

                  {isEmailTypePopupOpen && (
        <div className="popup-container-email" onClick={closePopup}>
          <div
            className="popup-container-content"
            onClick={(e) => e.stopPropagation()}
          >
            {/* <button
              className="popup-button"
              onClick={() => handleEmailSchedules("AMC Schedules")}
            >
              AMC Schedule
            </button>
            <button
              className="popup-button"
              onClick={() => handleEmailSchedules("Breakdown Schedule")}
            >
              Breakdown Schedule
            </button> */}
            <button
              className="popup-button"
              onClick={() => handleEmailSchedules("Preventive Schedule")}
            >
              Preventive Schedule
            </button>
          </div>
        </div>
      )}

      {isEmailsPopupOpen && (
        <MonthlyReportEmail
          onClose={handleClosePopupEmail}
          reportType={selectedReportType}
        />
      )}

                  <Tooltip title="Refresh List" arrow>
                    <button className="refresh-button" onClick={handleRefresh}>
                      <TbRefresh className="refresh-icon" />
                    </button>
                  </Tooltip>
                  <button
                    className="sort-button"
                    onClick={handleSortpopup}
                    ref={buttonRef}
                  >
                    <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                  </button>
                  {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {sortOrder[sortColumn] === "asc"
                              ? "(Ascending)"
                              : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button onClick={() => handleSort("to_emails")}>
                              To Email{" "}
                              {sortOrder.customer_name === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button
                              onClick={() =>
                                handleSort("subject")
                              }
                            >
                              Subject{" "}
                              {sortOrder.contact_no === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button
                              onClick={() =>
                                handleSort("body")
                              }
                            >
                              Body{" "}
                              {sortOrder.address === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("status")}>
                              Status{" "}
                              {sortOrder.locations === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("uploaded_at")}>
                              Date & Time{" "}
                              {sortOrder.locations === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />

                          </div>
                          {/* <button className='delete-item' onClick={handleArchiveCustomer}>Archived </button> */}
                        </div>
                      </div>
                    )} 
                </div>
              </div>
            </div>
          )}
          <div className="data-list-body" onClick={closePopups}>
            <div className="data-list-scroll-body">
              <div className="data-list-table">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : designations.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        {/* <th className="center-alignment">
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th> */}
                        <th className="center-alignment">SR. NO.</th>
                        <th>TO EMAIL</th>
                        <th>SUBJECT</th>
                        <th>BODY</th>
                        <th>STATUS</th>
                        <th>DATE & TIME</th>
                        {/* <th></th> */}

                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {designations.map((designation, index) => (
                        <tr key={designation.id} className="hoverable-row">
                          {/* <td className="center-alignment">
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(designation.id)}
                              onChange={() => handleSelectRow(designation.id)}
                            />
                          </td> */}

                          <td className="center-alignment">
                            {index+1}
                          </td>
                          <td>{designation.to_emails || "NA"}</td>
                          <td>{designation.subject || "NA"}</td>
                          <td>{designation.body || "NA"}</td>
                          <td>{designation.status || "NA"}</td>
                          <td>{formatDates(designation.date_time || "NA")}</td>
                          {/* <td>{designation.uploaded_at || "NA"}</td> */}

                          {/* <td className="edit-pop">
                            <button
                              className="edit-data-button"
                              onClick={() => handleButtonClick(index)}
                            >
                              <div className="edit-button">
                                <MdExpandMore />
                              </div>
                            </button>
                          </td> */}
                          {/* {selectedRowIndex === index && (
                            <div className="edit-popup">
                              <div className="edit-popup-contant">
                                <div className="edit-popup-button">
                                  <Link
                                    to={`/edit-designation/${designation.id}`}
                                    className="inquiry-new"
                                  >
                                    <button className="edit-button-inquiry">
                                      <MdOutlineModeEditOutline /> Edit
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )} */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">
                      No Monthly Report Log Found
                    </div>
                  </div>
                )}
              </div>
              <Pagination
                showPerPage={itemsPerPage}
                total={totalItems}
                onPaginationChange={handlePaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Designation;
