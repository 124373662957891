import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import "./PrVendorDetails.css";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import { FiUpload } from "react-icons/fi";

function PrVendorDetails({ isSidebarExpanded }) {
  const [loading, setLoading] = useState(true);
  const fileInputRef = useRef(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [attachments, setAttachments] = useState([]);
  const [quotationHistory, setQuotationHistory] = useState([]);
  const [quotationData, setQuotationData] = useState({
    date: "",
    description: "",
    attachments: [],
  });

  const navigate = useNavigate();
  const { vendor_id } = useParams();
  const location = useLocation();
  const { pr_id } = location.state || {};

  const [showPopup, setShowPopup] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [selectedQuotationId, setSelectedQuotationId] = useState(null);
  const handleRadioClick = (quotation_id) => {
    setSelectedQuotationId(quotation_id); // Store selected quotation_id
    setShowPopup(true); // Show popup for confirmation
  };
  
  const handleConfirm = () => {
    console.log("Confirmed:", selectedQuotation);
    setShowPopup(false);
  };

  const handleCancel = () => {
    setSelectedQuotation(null);
    setShowPopup(false);
  };
  console.log("Received pr_id:", pr_id);

  const handleBackClick = () => {
    navigate(`/approved-pr-view/${pr_id}?tab=vendorSelection`);
  };

  useEffect(() => {
    fetchQuotationHistory();
  }, [vendor_id]);

  const validateForm = () => {
    let tempErrors = {};
    if (!quotationData.date) tempErrors.date = "Date is required.";
    if (!quotationData.description)
      tempErrors.description = "Description is required.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; // Returns true if no errors
  };

  // const handleSaveClick = async () => {
  //   // Validate the quotationData before submission
  //   if (!quotationData.date) {
  //     setSnackbarMessage("Date is required");
  //     setSeverity("warning");
  //     setSnackbarOpen(true);
  //     return;
  //   }

  //   try {
  //     setIsLoading(true);
  //     const formData = new FormData();
  //     formData.append("pr_vendor_id", vendor_id);
  //     formData.append("date", quotationData.date);
  //     formData.append("description", quotationData.description);

  //     // Append attachments
  //     if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
  //       attachmentFile.forEach((file) => {
  //         formData.append("attachments", file); // Directly append file to attachments
  //       });
  //     }

  //     const response = await axios.post(
  //       `${API_URL}/api/add_vendor_quotation_history`,
  //       formData,
  //
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: localStorage.getItem("authKey"),
  //         },
  //       }
  //     );

  //     if (response.status === 201) {
  //       setSnackbarMessage("Vendor Quotation History added successfully");
  //       setSeverity("success");
  //       setSnackbarOpen(true);
  //       fetchQuotationHistory();

  //       // Reset form fields after successful submission
  //       setQuotationData({ date: "", description: "" });
  //       setAttachments([]); // Clear attachments
  //       setSelectedFiles([]); // Clear selected files display
  //     }
  //   } catch (error) {
  //     console.error("Error saving vendor quotation history:", error);
  //     setSnackbarMessage("Error adding quotation history");
  //     setSeverity("error");
  //     setSnackbarOpen(true);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const handleSaveClick = async () => {
  //   if (!validateForm()) return; // Stop if validation fails

  //   try {
  //     setIsLoading(true);
  //     const formData = new FormData();
  //     formData.append("pr_vendor_id", vendor_id);
  //     formData.append("date", quotationData.date);
  //     formData.append("description", quotationData.description);

  //     // Append attachments
  //     if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
  //       attachmentFile.forEach((file) => {
  //         formData.append("attachments", file);
  //       });
  //     }

  //     const response = await axios.post(
  //       `${API_URL}/api/add_vendor_quotation_history`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: localStorage.getItem("authKey"),
  //         },
  //       }
  //     );

  //     if (response.status === 201) {
  //       setSnackbarMessage("Vendor Quotation History added successfully");
  //       setSeverity("success");
  //       setSnackbarOpen(true);
  //       fetchQuotationHistory();
  //       setQuotationData({ date: "", description: "" });
  //       setSelectedFiles([]);
  //       setAttachments([]);
  //       setErrors({}); // Clear errors on success
  //     }
  //   } catch (error) {
  //     console.error("Error saving vendor quotation history:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveClick = async () => {
    if (!validateForm()) return; // Stop if validation fails

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("pr_vendor_id", vendor_id);
      formData.append("date", quotationData.date);
      formData.append("description", quotationData.description);

      // Append only NEWLY selected files
      if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
        attachmentFile.forEach((file) => {
          formData.append("attachments", file);
        });
      }

      // Clear attachmentFile state before sending request
      setAttachmentFile([]);

      const response = await axios.post(
        `${API_URL}/api/add_vendor_quotation_history`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("authKey"),
          },
        }
      );

      if (response.status === 201) {
        setSnackbarMessage("Vendor Quotation History added successfully");
        setSeverity("success");
        setSnackbarOpen(true);
        fetchQuotationHistory();

        // Reset state to remove old files
        setQuotationData({ date: "", description: "" });
        setSelectedFiles([]); // Clear displayed file names
        setAttachmentFile([]); // Ensure old files are not stored in state
        if (fileInputRef.current) fileInputRef.current.value = ""; // Reset input field
        setErrors({}); // Clear errors
      }
    } catch (error) {
      console.error("Error saving vendor quotation history:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchQuotationHistory = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_URL}/api/get_vendor_quotation_history/${vendor_id}`,
        {
          headers: { Authorization: localStorage.getItem("authKey") },
        }
      );
      setQuotationHistory(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching vendor quotation history:", error);
      setLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    // Set the actual file objects in the state for FormData
    setAttachmentFile((prevFiles) => [
      ...(prevFiles || []),
      ...Array.from(files),
    ]);
  };
  const handleInputChange = (field, value) => {
    setQuotationData((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => ({ ...prev, [field]: "" })); // Clear error for the field
  };

  // const handleConfirmClick = async (quotation_id) => {
  //   if (!quotation_id) {
  //     console.error("Quotation history ID is required");
  //     return;
  //   }

  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const response = await axios.post(
  //       `${API_URL}/api/make_final_offer/${quotation_id}`,
  //       {},
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );

  //     if (response.status === 201) {
  //       alert("Final offer created successfully!");
  //       // Refresh data or perform any additional action
  //     }
  //   } catch (error) {
  //     console.error("Error making final offer:", error.response?.data || error.message);
  //     alert("Failed to make final offer. Please try again.");
  //   }
  // };

  const handleConfirmClick = async () => {
    if (!selectedQuotationId) {
      setSnackbarMessage("Quotation history ID is required");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
  
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.post(
        `${API_URL}/api/make_final_offer/${selectedQuotationId}`, // Use selected ID
        {},
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
  
      if (response.status === 201) {
        setSnackbarMessage("Final offer created successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setShowPopup(false); // Close popup on success
      }
    } catch (error) {
      console.error(
        "Error making final offer:",
        error.response?.data || error.message
      );
      setSnackbarMessage("Failed to make final offer. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackClick}
                  />
                </Tooltip>
                New Vendor Quotation
              </div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <div className="pr-form">
              <form className="form-detail">
                <div className="new-data-form">
                  <div className="form-group">
                    <label htmlFor="date">
                      Date<span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="date"
                        name="date"
                        value={quotationData.date}
                        // onChange={(e) =>
                        //   setQuotationData({
                        //     ...quotationData,
                        //     date: e.target.value,
                        //   })
                        // }
                        onChange={(e) =>
                          handleInputChange("date", e.target.value)
                        }
                        placeholder="Enter date"
                        className="your-custom-classname-date"
                      />
                    </div>
                    {errors.date && (
                      <div className="error-message">{errors.date}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">
                      Description <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <textarea
                        name="Description"
                        id="Description"
                        cols="35"
                        rows="3"
                        placeholder="Enter description"
                        value={quotationData.description}
                        // onChange={(e) =>
                        //   setQuotationData({
                        //     ...quotationData,
                        //     description: e.target.value,
                        //   })
                        // }
                        onChange={(e) =>
                          handleInputChange("description", e.target.value)
                        }
                      ></textarea>
                    </div>
                    {errors.description && (
                      <div className="error-message">{errors.description}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="attachments">Attachments</label>
                    <div className="input-groups">
                      {/* <input
                        type="file"
                        multiple
                        name="attachments"
                        onChange={handleFileChange}
                      /> */}

                      <button
                        type="button"
                        className="challan-btn"
                        onClick={handleUploadButtonClick}
                      >
                        <FiUpload /> Upload File
                      </button>
                      <input
                        id="attachments"
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                        multiple
                      />
                      {selectedFiles.length > 0 && (
                        <div className="attchment-file-lists">
                          <ul
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {selectedFiles.map((file, index) => (
                              <li key={index}>{file.name}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
              <div className="history-button">
                <div className="button-group">
                  <button
                    className="data-form-save-button"
                    type="button"
                    onClick={handleSaveClick}
                    disabled={isLoading}
                  >
                    {isLoading ? "Saving..." : "Save"}
                  </button>
                  <button
                    className="data-form-cancel-button"
                    onClick={handleBackClick}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            {/* Quotation History Table */}
            <div className="quotation-history">
              <h2>Quotation History</h2>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      {/* <div className="loader"></div>
                      <div>Loading...</div> */}
                    </div>
                  ) : quotationHistory.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>SR. NO.</th>
                          <th>DATE</th>
                          <th>DESCRIPTION</th>
                          <th>ATTACHMENTS</th>
                          <th>CHOOSE FINAL QUOTATION</th>
                        </tr>
                      </thead>

                      <tbody>
                        {quotationHistory.length > 0 &&
                          quotationHistory.map((quotation, index) => (
                            <tr key={quotation.id || index} className="quotation-row">
                              {" "}
                              {/* Use a unique ID if available */}
                              <td>{index + 1}</td>
                              <td>{formatDate(quotation.date)}</td>
                              <td>{quotation.description || "NA"}</td>
                              <td>
                                {quotation.attachments &&
                                quotation.attachments.length > 0 ? (
                                  quotation.attachments.map((file) => (
                                    <div
                                      key={file.file_id}
                                      className="attachment-item"
                                    >
                                      {" "}
                                      {/* Use a unique ID for the file */}
                                      <span>
                                      <a
                                                  href={`${API_URL}/api/view_pr_attachment/${file.file_id}/${file.file_type}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="download-link"
                                                >
                                        {file.filename.length > 15
                                          ? `${file.filename.substring(
                                              0,
                                              15
                                            )}...`
                                          : file.filename}
                                        ({file.file_size} KB)
                                        </a>
                                      </span>
                                      <a
                                        href={`${API_URL}/api/download_pr_attachment/${file.file_id}/${file.file_type}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download // Force download
                                      >
                                        <FiDownload className="download-icon" />
                                      </a>
                                    </div>
                                  ))
                                ) : (
                                  <span>No attachments available</span> // Keep this line if you want to indicate that there are no attachments
                                )}
                              </td>
                              <td>
                              <input
    type="radio"
    name="quotationSelection"
    value={quotation.quotation_id}
    checked={selectedQuotationId === quotation.quotation_id || quotation.is_final_quotation === 1} 
    onChange={() => handleRadioClick(quotation.quotation_id)}
  />
                                Select Quotation
                              </td>
                              {showPopup && (
                                <div className="pr-vendor-popup-container">
                                  <div className="pr-vendor-popup">
                                    <div className="pr-vendor-popup-content">
                                      <p>
                                        Are you sure you want to select this
                                        final Quotation?
                                      </p>
                                      <div className="final-vendor-button">
                                      <button className="data-form-save-button" onClick={handleConfirmClick}>
            Confirm
          </button>
                                        <button
                                          className="data-form-cancel-button"
                                          onClick={handleCancel}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No pr request history available
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PrVendorDetails;
