import React, { useState, useEffect } from "react";
// import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";

function TemplateForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedPermitType, setSelectedPermitType] = useState(null);
  const navigate = useNavigate();
  const [permitType, setPermitType] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [formData, setFormData] = useState({
    permit_type_id: "",
    field_name: "",
    field_type: "",
    placeholder: "",
    field_label: "",
    is_required: false,
  });
  const [dropdownSource, setDropdownSource] = useState(""); // "static" or "api"
  const [apiUrl, setApiUrl] = useState("");

  const fetchPermitType = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_work_permit_types`, {
        headers: {
          Authorization: authKey,
        },
      });

      const permitTypeOptions = response.data.work_permit_types.map(
        (permitType) => ({
          value: permitType.id,
          label: permitType.work_permit_name,
        })
      );

      setPermitType(permitTypeOptions);
    } catch (error) {
      console.error("Error fetching customers data:", error.message);
    }
  };

  useEffect(() => {
    fetchPermitType();
  }, []);

  const handleRadioChange = (event) => {
    setFormData((prev) => ({ ...prev, is_required: event.target.value }));
  };

  const handlePermitTypeChange = (selectedOption) => {
    setSelectedPermitType(selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      permit_type_id: selectedOption ? selectedOption.value : "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      selectedPermitType: selectedOption ? "" : prevErrors.selectedPermitType,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCancelClick = () => {
    navigate("/department");
  };

  // const handleSaveClick = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.post(
  //       `${API_URL}/api/add_permit_field`,
  //       formData,
  //       { headers: { Authorization: authKey } }
  //     );

  //     if (response.status === 201) {
  //       setSeverity("success");
  //       showSnackbar("Permit field added successfully");
  //       // setTimeout(() => navigate("/department"), 1000);
  //     }
  //   } catch (error) {
  //     setSeverity("error");
  //     showSnackbar("Error adding permit field");
  //     console.error("Error:", error.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const handleSaveClick = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const requestData = {
  //       ...formData,
  //       options: formData.field_type === "dropdown" ? dropdownOptions : [],
  //     };

  //     const response = await axios.post(
  //       `${API_URL}/api/add_permit_field`,
  //       requestData,
  //       { headers: { Authorization: authKey } }
  //     );

  //     if (response.status === 201) {
  //       setSeverity("success");
  //       showSnackbar("Permit field added successfully");
  //     }
  //   } catch (error) {
  //     setSeverity("error");
  //     showSnackbar("Error adding permit field");
  //     console.error("Error:", error.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };


  // const handleSaveClick = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");
  
  //     const requestData = {
  //       permit_type_id: formData.permit_type_id,
  //       field_name: formData.field_name,
  //       field_type: formData.field_type,
  //       is_required: formData.is_required,
  //       options_source: dropdownSource, // "static" or "api"
  //       options: dropdownSource === "static" ? dropdownOptions : [], // Send options array for static
  //       api_url: dropdownSource === "api" ? apiUrl : null, // Send API URL if source is "api"
  //     };
  
  //     const response = await axios.post(`${API_URL}/api/add_permit_field`, requestData, {
  //       headers: { Authorization: authKey },
  //     });
  
  //     if (response.status === 201) {
  //       setSeverity("success");
  //       showSnackbar("Permit field added successfully");
  //     }
  //   } catch (error) {
  //     setSeverity("error");
  //     showSnackbar("Error adding permit field");
  //     console.error("Error:", error.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  
  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      // Create base request data
      let requestData = {
        permit_type_id: formData.permit_type_id,
        field_name: formData.field_name,
        field_label: formData.field_label,
        field_type: formData.field_type,
        placeholder: formData.placeholder,
        is_required: formData.is_required,
      };
  
      // Include dropdown-specific fields only when field_type is "dropdown"
      if (formData.field_type === "dropdown") {
        requestData.options_source = dropdownSource;
        if (dropdownSource === "static") {
          requestData.options = dropdownOptions;
        } else if (dropdownSource === "api") {
          requestData.api_url = apiUrl;
        }
      }
  
      const response = await axios.post(`${API_URL}/api/add_permit_field`, requestData, {
        headers: { Authorization: authKey },
      });
  
      if (response.status === 201) {
        setSeverity("success");
        showSnackbar("Permit field added successfully");
      }
    } catch (error) {
      setSeverity("error");
      showSnackbar("Error adding permit field");
      console.error("Error:", error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };
  const fieldTypeOptions = [
    { value: "text", label: "Text" },
    { value: "number", label: "Number" },
    { value: "date", label: "Date" },
    { value: "textarea", label: "Textarea" },
    { value: "dropdown", label: "Dropdown" },
  ];
  // const handleFieldTypeChange = (selectedOption) => {
  //   setFormData((prev) => ({ ...prev, field_type: selectedOption.value }));
  // };

  // const handleFieldTypeChange = (selectedOption) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     field_type: selectedOption.value,
  //   }));

  //   if (selectedOption.value === "dropdown") {
  //     setDropdownOptions(["Option 1", "Option 2"]); // Default options
  //   } else {
  //     setDropdownOptions([]); // Clear options for other field types
  //   }
  // };

  const handleFieldTypeChange = (selectedOption) => {
    setFormData({ ...formData, field_type: selectedOption.value });
  };

  const handleDropdownSourceChange = (e) => {
    setDropdownSource(e.target.value);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Filed Add
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="form_type">
                    Permit Type<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="form_type"
                    options={permitType}
                    value={selectedPermitType}
                    onChange={handlePermitTypeChange}
                    placeholder="Select permit type"
                  />
                </div>
                <div className="form-group">
                  <label>
                    Field Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="field_name"
                      value={formData.field_name}
                      onChange={handleInputChange}
                      placeholder="Enter field name"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Field Value<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="field_label"
                      value={formData.field_label}
                      onChange={handleInputChange}
                      placeholder="Enter field label"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Placeholder Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="placeholder"
                      value={formData.placeholder}
                      onChange={handleInputChange}
                      placeholder="Enter placeholder"
                    />
                  </div>
                </div>
                <div className="form-group-select">
                  <label>
                    Field Type<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={fieldTypeOptions}
                    onChange={handleFieldTypeChange}
                    placeholder="Select field type"
                  />
                </div>

                {formData.field_type === "dropdown" && (
                  <div className="form-group-select">
                    <label>Dropdown Source</label>
                    <div className="checkbox-input">
                      <label>
                        <input
                          type="radio"
                          value="static"
                          checked={dropdownSource === "static"}
                          onChange={handleDropdownSourceChange}
                        />
                        Static
                      </label>
                    </div>
                    <div className="checkbox-input">
                      <label style={{ marginLeft: "15px" }}>
                        <input
                          type="radio"
                          value="api"
                          checked={dropdownSource === "api"}
                          onChange={handleDropdownSourceChange}
                        />
                        API
                      </label>
                    </div>
                    </div>
                )}


{dropdownSource === "static" && (
                      <div className="form-group">
                        <label>Dropdown Options (comma-separated)</label>
                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="Enter options, separated by commas"
                            onChange={(e) =>
                              setDropdownOptions(e.target.value.split(","))
                            }
                          />
                        </div>
                      </div>
                    )}

                    {dropdownSource === "api" && (
                      <div className="form-group">
                        <label>API URL</label>
                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="Enter API URL"
                            onChange={(e) => setApiUrl(e.target.value)}
                          />
                        </div>
                      </div>
                    )}

             

                <div className="form-group-select">
                  <label>
                    Is Required?<span className="text-danger">*</span>
                  </label>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      id="requiredYes"
                      name="is_required"
                      value="1"
                      checked={formData.is_required === "1"}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="requiredYes">Yes</label>
                  </div>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      id="requiredNo"
                      name="is_required"
                      value="0"
                      checked={formData.is_required === "0"}
                      onChange={handleRadioChange}
                      style={{ marginLeft: "10px" }}
                    />
                    <label htmlFor="requiredNo">No</label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default TemplateForm;
