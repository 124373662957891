import React, { useState, useEffect, useRef } from "react";
import "./Audit.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { BsInfoCircle } from "react-icons/bs";
import Pagination from "../Component/Pagination";
import useClickOutside from "../useClickOutside";
import { MdAttachFile } from "react-icons/md";

function AuditTable({ isSidebarExpanded, customerID }) {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    column: null,
    direction: "asc",
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  // const handleViewAttachment = (attachment) => {
  //   window.open(
  //     `${API_URL}/api/view_statutory_audit_attachment/${attachment.file_id}`,
  //     "_blank"
  //   );
  // };
  const handleViewAttachment = (attachment) => {
    if (!attachment || !attachment.file_id) {
      console.error("Invalid file attachment");
      return;
    }

    window.open(
      `${API_URL}/api/view_statutory_audit_attachment/${attachment.file_id}`,
      "_blank"
    );
  };

  const renderTooltip = (location) => (
    <div>
      <p>
        <strong>Contact Person:</strong> {location.contact_person_name || "NA"}
      </p>
      <p>
        <strong>Contact Number:</strong> {location.contact_no || "NA"}
      </p>
      <p>
        <strong>Email:</strong> {location.email || "NA"}
      </p>
      <p>
        <strong>Address:</strong> {location.address || "NA"}
      </p>
    </div>
  );
  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };
  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedBreakdown = [...customers].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setCustomers(sortedBreakdown);
  };
  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_statutory_audits`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      setCustomers(response.data.StatutoryAudits || []);
      setTotalItems(response.data.TotalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching customers data:", error.message);
      setLoading(false);
    }
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? customers.map((customer) => customer.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (customerID) => {
    const updatedSelectedRows = selectedRows.includes(customerID)
      ? selectedRows.filter((id) => id !== customerID)
      : [...selectedRows, customerID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === customers.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleIconClick = (index) => {
    // Toggle the popup open/close based on the current state
    setIsPopupOpen((prev) => (prev === index ? null : index));

    setSelectedRowIndex(false); // Reset the selected row
  };

  const handleArchivedCustomer = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_customers`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");

        // Filter out archived customers
        const updatedCustomers = customers.filter(
          (customer) => !selectedRows.includes(customer.id)
        );

        // Recalculate serial numbers
        const customersWithUpdatedSerials = updatedCustomers.map(
          (customer, index) => ({
            ...customer,
            serial_no: index + 1, // Assign new serial number based on index
          })
        );

        setCustomers(customersWithUpdatedSerials);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to archive customers");
      }
    } catch (error) {
      console.error("An error occurred while archiving customers:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveCustomer = () => {
    navigate("/archive-customer");
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }

    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/search_statutory_audit`,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
          params: {
            query: searchInput,
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      if (response.status === 200) {
        setCustomers(response.data.statutory_audits);
        setTotalItems(response.data.total);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRefresh = () => {
    if (searchInput) {
      console.log("Search input is set:", searchInput);
    } else {
      fetchData(page, itemsPerPage);
    }
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const togglePopup = (customerID) => {
    navigate(`/customer-view/${customerID}`);
  };

  const handleViewClick = async (id) => {
    navigate(`/audit-view/${id}`);
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-list-content" ref={containerRef}>
          {isChecked ? (
            <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                    <button className="delete" onClick={handleDeletePopup}>
                      Move to archive
                    </button>
                    {isDeletePopup && (
                      <div className="confirm-delete-popup">
                        <div className="confirm-delete-popup-content">
                          <div className="confirm-model">
                            <div className="confirm-model-content">
                              <div className="confirm-model-content-body">
                                <p>Are you sure want to move archive?</p>
                              </div>
                              <div className="confirm-model-content-footer">
                                <button
                                  className="confirm-delete-button"
                                  onClick={handleArchivedCustomer}
                                >
                                  Yes
                                </button>
                                <button
                                  className="confirm-cancel-button"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="data-list-header">
              <div className="data-list-header-content">
                <div className="data-list-heading-content-left-side">
                  All Statutory Audit Reports
                </div>
                <div className="data-list-heading-content-right-side">
                  <div className="search-bar-container">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      className="search-bar"
                    />
                    <IoSearchOutline className="search-bar-icon" />
                  </div>

                  <Link to="/audit-form" className="data-list-new">
                    <Tooltip title="New Audit report form" arrow>
                      <button className="new-button">
                        <HiOutlinePlus className="new-plus-icon" /> New
                      </button>
                    </Tooltip>
                  </Link>

                  <Tooltip title="Refresh List" arrow>
                    <button className="refresh-button" onClick={handleRefresh}>
                      <TbRefresh className="refresh-icon" />
                    </button>
                  </Tooltip>
                  <button
                    className="sort-button"
                    onClick={handleSortpopup}
                    ref={buttonRef}
                  >
                    <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                  </button>
                   {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {sortOrder[sortColumn] === "asc"
                              ? "(Ascending)"
                              : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button onClick={() => handleSort("reference_no")}>
                              Refrence_No{" "}
                              {sortOrder.customer_name === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button
                              onClick={() =>
                                handleSort("month")
                              }
                            >
                              Month{" "}
                              {sortOrder.contact_no === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button
                              onClick={() =>
                                handleSort("year")
                              }
                            >
                              Year{" "}
                              {sortOrder.address === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("audit_type")}>
                              Audit Type{" "}
                              {sortOrder.locations === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />


                          </div>
                          {/* <button className='delete-item' onClick={handleArchiveCustomer}>Archived </button> */}
                        </div>
                      </div>
                    )} 
                </div>
              </div>
            </div>
          )}
          <div className="data-list-body" onClick={closePopups}>
            <div className="data-list-scroll-body">
              <div className="data-list-table">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : customers.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th className="center-alignment">
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th className="center-alignment">SR. NO.</th>
                        <th>REFRENCE NO</th>
                        <th>MONTH</th>
                        <th>YEAR</th>
                        <th>AUDIT TYPE</th>
                        <th>STATUS</th>
                        <th>CREATED AT</th>
                        <th className="center-alignment">ATTACHMENT</th>
                        <th className="center-alignment">ACTION</th>

                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {customers.map((customer, index) => (
                        <tr key={customer.id} className="hoverable-row">
                          <td className="center-alignment">
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(customer.id)}
                              onChange={() => handleSelectRow(customer.id)}
                            />
                          </td>

                          <td className="center-alignment">
                            {customer.serial_no}
                          </td>
                          <td>{customer.reference_no || "NA"}</td>
                          <td>{customer.month || "NA"}</td>
                          <td>{customer.year || "NA"}</td>
                          <td>{customer.audit_type || "NA"}</td>
                          <td
                            style={{
                              color:
                                customer.status === "Open" ? "green" : "red",
                            }}
                          >
                            {customer.status || "NA"}
                          </td>

                          <td>
                            {customer.created_at
                              ? new Date(customer.created_at)
                                  .toUTCString()
                                  .split("GMT")[0]
                                  .trim() + " IST"
                              : "NA"}
                          </td>
                          <td className="center-alignment">
                            <span
                              className="inquiry-file-btns"
                              onClick={() => handleIconClick(index)}
                              ref={buttonRef}
                            >
                              <MdAttachFile className="inquiry-file-icon" />
                            </span>
                            {isPopupOpen === index && (
                              <div
                                className="inquiry-attach-popup prRequest-popup"
                                ref={popupRef}
                              >
                                <div className="attach-popup-contant">
                                  <div className="attachment-header">
                                    <div className="attachment-header-content">
                                      Attachments
                                    </div>
                                    <button
                                      className="fiile-close-btn"
                                      onClick={handleCloseAttachment}
                                    >
                                      <IoCloseOutline className="file-close-icon" />
                                    </button>
                                  </div>
                                  <div
                                    className="attach-popup-contant-body"
                                    key={index}
                                  >
                                    <div className="inquiry-attach-file-containerssss">
                                      <div className="attach-file-showsss">
                                        <div className="text-attachmentss">
                                          {customer.file_details &&
                                          customer.file_details.length > 0 ? (
                                            <div className="attach-popup-content-body">
                                              {customer.file_details.map(
                                                (
                                                  attachment,
                                                  attachmentIndex
                                                ) => (
                                                  <div
                                                    className="inquiry-attach-file-container"
                                                    key={attachmentIndex}
                                                  >
                                                    <div className="inquiry-attacth-file-model">
                                                      <div className="attach-file-show">
                                                        <div className="text-attachment">
                                                          {attachment.filename
                                                            .length > 20
                                                            ? `${attachment.filename.substring(
                                                                0,
                                                                15
                                                              )}...`
                                                            : attachment.filename}
                                                        </div>
                                                        <div className="text-attachment-file">
                                                          File Size:{" "}
                                                          {attachment.file_size ||
                                                            "NA"}{" "}
                                                          KB
                                                        </div>
                                                      </div>

                                                      <div className="file-footer">
                                                        {/* <div
                                                                className="file-download-attachment file-txt"
                                                                onClick={() =>
                                                                  handleViewAttachment(prRequest)
                                                                }
                                                              >
                                                                Download
                                                              </div> */}
                                                        <a
                                                          href={`${API_URL}/api/download_statutory_audit_attachment/${attachment.file_id}`}
                                                          download
                                                          className="file-download-attachment file-txt"
                                                        >
                                                          Download
                                                        </a>
                                                        <div
                                                          className="file-download-attachment file-txt"
                                                          onClick={() =>
                                                            handleViewAttachment(
                                                              attachment
                                                            )
                                                          }
                                                        >
                                                          View
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          ) : (
                                            <div className="no-attachments">
                                              No attachments found
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="file-delete-btn"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>

                          <td className="center-alignment">
                            <button
                              onClick={() => handleViewClick(customer.id)}
                              className="view-button"
                            >
                              View
                            </button>
                          </td>

                          <td className="edit-pop">
                            <button
                              className="edit-data-button"
                              onClick={() => handleButtonClick(index)}
                            >
                              <div className="edit-button">
                                <MdExpandMore />
                              </div>
                            </button>
                          </td>
                          {selectedRowIndex === index && (
                            <div className="edit-popup">
                              <div className="edit-popup-contant">
                                <div className="edit-popup-button">
                                  <Link
                                    to={`/audit-edit-form/${customer.id}`}
                                    className="inquiry-new"
                                  >
                                    <button className="edit-button-inquiry">
                                      <MdOutlineModeEditOutline /> Edit
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">
                      No Satutory Audit Reports Found
                    </div>
                  </div>
                )}
              </div>
              <Pagination
                showPerPage={itemsPerPage}
                total={totalItems}
                onPaginationChange={handlePaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default AuditTable;
