import React, { useState, useEffect, useRef,useContext } from "react";
import "./ReportPage.css";
import Select from "react-select";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../Config";
import {TextField } from "@mui/material";
import { MdArrowForwardIos } from "react-icons/md";
import DatePicker from "react-datepicker";
import ExportPopup from "../Popups/ExportPopup";
import { ThemeContext } from "../ThemeContext";

function AmcReport() {
  const [amcreports, setAmcReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reportStartDate, setReportStartDate] = useState(null);
  const [reportEndDate, setReportEndDate] = useState(null);
  const navigate = useNavigate();

  const handleExportButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsExportPopupOpen(true);
  };
  const { theme } = useContext(ThemeContext);

  const handleExport = async (format, filter, startDate, endDate) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const params = new URLSearchParams({
        format,
        filter_option: filter.value,
        ...(filter.value === "custom_date" && {
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
        }),
      }).toString();

      const response = await axios.get(
        `${API_URL}/api/export_amc_schedule_report?${params}`,
        {
          headers: {
            Authorization: authKey,
          },
          responseType: "blob", // To handle binary file data
        }
      );

      if (response.status === 200) {
        // Handle file download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `amc_schedule_report.${format}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.error("Error exporting schedule:", response.statusText);
      }
    } catch (error) {
      console.error("Error exporting schedule:", error.message);
    }
  };

  const fetchData = async (params) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/amc_schedule_report`, {
        headers: {
          Authorization: authKey,
        },
        params: params,
      });

      setAmcReports(response.data.report);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching schedule report:", error.message);
      setLoading(false);
    }
  };
  const filterOptions = [
    { label: "All", value: "all" },
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Tomorrow", value: "tomorrow" },
    { label: "Next 7 Days", value: "next7days" },
    { label: "Next Month", value: "next_month" },
    { label: "Current Week", value: "current_week" },
    { label: "Previous Month", value: "previous_month" },
    { label: "Previous Week", value: "past_week" },
    { label: "Current Month", value: "current_month" },
    { label: "Custom Date", value: "custom_date" },
  ];
  const [selectedFilter, setSelectedFilter] = useState(
    filterOptions.find((option) => option.value === "current_month")
  );
  const formatDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(date)
      .toLocaleDateString("en-CA", options)
      .replace(/-/g, "/");
  };

  // const handleFilterScheduler = async (
  //   filter_option,
  //   start_date = null,
  //   end_date = null
  // ) => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");

  //     let params = {};

  //     if (filter_option === "custom_date" && start_date && end_date) {
  //       params.start_date = formatDate(start_date);
  //       params.end_date = formatDate(end_date);
  //     } else {
  //       params.filter_option = filter_option;
  //     }
  //     fetchData(params);

  //     const response = await axios.get(`${API_URL}/api/amc_schedule_report`, {
  //       headers: {
  //         Authorization: authKey,
  //         "Content-Type": "application/json",
  //       },
  //       params: params,
  //     });

  //     if (response.status === 200) {
  //       console.log("Filtered response:", response.data); // Log the response
  //       if (response.data) {
  //         // Check the structure of date_range
  //         const dateRange = response.data.date_range;
  //         if (dateRange.start_date && dateRange.end_date) {
  //           setReportStartDate(dateRange.start_date);
  //           setReportEndDate(dateRange.end_date);
  //         } else if (dateRange.date) {
  //           setReportStartDate(dateRange.date);
  //           setReportEndDate(null); // Clear end date if not available
  //         }

  //         // Assuming report data is stored here
  //         setAmcReports(response.data.report);
  //       } else {
  //         console.error("No data found for the selected filter option.");
  //       }
  //     } else {
  //       console.error("Error fetching scheduler data:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching scheduler data:", error.message);
  //   }
  // };
  
  const handleFilterScheduler = async (
    filter_option,
    start_date = null,
    end_date = null
  ) => {
    try {
  
      let params = {};
  
      if (filter_option === "custom_date" && start_date && end_date) {
        params.start_date = formatDate(start_date);
        params.end_date = formatDate(end_date);
      } else {
        params.filter_option = filter_option;
      }
  
      // Only this fetch call, removing the duplicate axios.get request
      await fetchData(params);
    } catch (error) {
      console.error("Error fetching scheduler data:", error.message);
    }
  };
  
  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption);
  };

  useEffect(() => {
    if (selectedFilter && selectedFilter.value !== "custom_date") {
      handleFilterScheduler(selectedFilter.value);
    } else if (
      selectedFilter &&
      selectedFilter.value === "custom_date" &&
      selectedStartDate &&
      selectedEndDate
    ) {
      handleFilterScheduler(
        selectedFilter.value,
        selectedStartDate,
        selectedEndDate
      );
    }
  }, [selectedFilter, selectedStartDate, selectedEndDate]);

  const containerRef = useRef(null);

  const handleRefresh = () => {
    if (selectedFilter && selectedFilter.value !== "custom_date") {
      handleFilterScheduler(selectedFilter.value);
    } else if (
      selectedFilter &&
      selectedFilter.value === "custom_date" &&
      selectedStartDate &&
      selectedEndDate
    ) {
      handleFilterScheduler(
        selectedFilter.value,
        selectedStartDate,
        selectedEndDate
      );
    }
  };

  const handleViewClick = (department_id, status) => {
    navigate(
      `/amc-report-view/${department_id}/${selectedFilter.value}/${status}`
    );
  };

  const formatDates = (dateString) => {
    if (!dateString || typeof dateString !== "string") {
      return ""; // Return an empty string if the date is invalid
    }

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Return an empty string for invalid dates
    }

    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  return (
    <>
    
        <div className="data-list-content" ref={containerRef}>
          <div className="data-list-header">
            <div className="data-list-header-content">
              <div className="data-list-heading-content-left-side">
                AMC Reports
          
                {selectedFilter.value === "all" &&
                reportStartDate === "All dates" ? (
                  <span>
                    <span style={{ color: "#007bff" }}> (Date: All dates)</span>
                  </span>
                ) : selectedFilter.value !== "all" &&
                  reportStartDate &&
                  reportEndDate ? (
                  <span>
                    <span style={{ color: "#007bff" }}>
                      {" "}
                      (Date Range: {formatDates(reportStartDate)} -{" "}
                      {formatDates(reportEndDate)}){" "}
                    </span>
                  </span>
                ) : selectedFilter.value !== "all" && reportStartDate ? (
                  <span>
                    <span style={{ color: "#007bff" }}>
                      {" "}
                      (Date: {formatDates(reportStartDate)}){" "}
                    </span>
                  </span>
                ) : null}
              </div>

              <div className="data-list-heading-content-right-side">
                <div className="dropdown-container">
                  <Select
                    value={selectedFilter}
                    onChange={handleFilterChange}
                    options={filterOptions}
                    className="project-dropdown"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 999,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                </div>
                {selectedFilter.value === "custom_date" && (
                  <div className="input-group-datepickers">
                    <DatePicker
                      placeholderText="Select Date Range"
                      className="dates"
                      startDate={selectedStartDate}
                      endDate={selectedEndDate}
                      selectsRange={true}
                      dateFormat="dd/MM/yyyy"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      onChange={(dates) => {
                        const [start, end] = dates;
                        setSelectedStartDate(start);
                        setSelectedEndDate(end);
                      }}
                      renderInput={(startProps, endProps) => (
                        <>
                          <TextField {...startProps} variant="standard" />
                          <TextField {...endProps} variant="standard" />
                        </>
                      )}
                      startText="Start Date"
                      endText="End Date"
                    />
                  </div>
                )}

                <button
                  className="export-btn"
                  onClick={handleExportButtonClick}
                >
                  Export
                </button>
                <ExportPopup
                  isOpen={isExportPopupOpen}
                  onClose={() => {
                    setIsExportPopupOpen(false);
                    setAnchorEl(null);
                  }}
                  onExport={handleExport}
                  selectedFilter={selectedFilter}
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                  anchorEl={anchorEl}
                />

                <Tooltip title="Refresh List" arrow>
                  <button className="refresh-button" onClick={handleRefresh}>
                    <TbRefresh className="refresh-icon" />
                  </button>
                </Tooltip>
            
              </div>
            </div>
          </div>
     
          <div className="data-list-body">
            <div className="data-list-scroll-bodys">
              <div className="data-list-table-report">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : amcreports.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th className="center-alignment">SR. NO.</th>
                        <th>DEPARTMENT</th>
                        <th className="active-status center-alignment">ACTIVE</th>
                        {/* <th className="progress-status center-alignment">IN PROGRESS</th> */}
                        <th className="complet-status center-alignment">COMPLETED</th>
                        <th className="hold center-alignment">HOLD</th>
                        <th className="cancel center-alignment">CANCELLED</th>
                        <th className="overdue-status center-alignment">OVERDUE</th>
                        <th className="overdue-status center-alignment">PENDING</th>
                        {/* <th className="tenure-status center-alignment">NOT IN TENURE</th> */}
                        <th className="total-statuss center-alignment">TOTAL SCHEDULES</th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {amcreports.map((report, index) => (
                        <tr key={index} className="hoverable-row">
                          <td className="center-alignment">{index + 1}</td>
                          <td>{report.department_name || "0"}</td>
                          <td
                            className={`center-alignment active-status ${
                              report.active === "0" ? "non-clickable" : ""
                            }`}
                            onClick={() =>
                              report.active &&
                              report.active > 0 &&
                              handleViewClick(report.department_id, "active")
                            }
                          >
                            {report.active || "0"}
                            {report.active !== null &&
                              report.active !== "0" && (
                                <MdArrowForwardIos className="total-report-icon" />
                              )}
                          </td>
                          {/* <td
                            className={`center-alignment progress-status ${
                              report.in_progress === "0" ? "non-clickable" : ""
                            }`}
                            onClick={() =>
                              report.in_progress &&
                              report.in_progress > 0 &&
                              handleViewClick(
                                report.department_id,
                                "in_progress"
                              )
                            }
                          >
                            {report.in_progress || "0"}
                            {report.in_progress !== null &&
                              report.in_progress !== "0" && (
                                <MdArrowForwardIos className="total-report-icon" />
                              )}
                          </td> */}
                          <td
                            className={`center-alignment complet-status ${
                              report.completed === "0" ? "non-clickable" : ""
                            }`}
                            onClick={() =>
                              report.completed &&
                              report.completed > 0 &&
                              handleViewClick(report.department_id, "completed")
                            }
                          >
                            {report.completed || "0"}
                            {report.completed !== null &&
                              report.completed !== "0" && (
                                <MdArrowForwardIos className="total-report-icon" />
                              )}
                          </td>
                          <td
                            className={`center-alignment hold-status ${
                              report.hold === "0" ? "non-clickable" : ""
                            }`}
                            onClick={() =>
                              report.hold &&
                              report.hold > 0 &&
                              handleViewClick(report.department_id, "hold")
                            }
                          >
                            {report.hold || "0"}
                            {report.hold !== null && report.hold !== "0" && (
                              <MdArrowForwardIos className="total-report-icon" />
                            )}
                          </td>
                          <td
                            className={`center-alignment cancelled-status ${
                              report.cancelled === "0" ? "non-clickable" : ""
                            }`}
                            onClick={() =>
                              report.cancelled &&
                              report.cancelled > 0 &&
                              handleViewClick(report.department_id, "cancelled")
                            }
                          >
                            {report.cancelled || "0"}
                            {report.cancelled !== null &&
                              report.cancelled !== "0" && (
                                <MdArrowForwardIos className="total-report-icon" />
                              )}
                          </td>
                          <td
                            className={`center-alignment overdue-status ${
                              report.overdue === "0" ? "non-clickable" : ""
                            }`}
                            onClick={() =>
                              report.overdue &&
                              report.overdue > 0 &&
                              handleViewClick(report.department_id, "overdue")
                            }
                          >
                            {report.overdue || "0"}
                            {report.overdue !== null &&
                              report.overdue !== "0" && (
                                <MdArrowForwardIos className="total-report-icon" />
                              )}
                          </td>
                          <td
                            className={`center-alignment overdue-status ${
                              report.pending === "0" ? "non-clickable" : ""
                            }`}
                            onClick={() =>
                              report.pending &&
                              report.pending > 0 &&
                              handleViewClick(report.department_id, "overdue")
                            }
                          >
                            {report.pending || "0"}
                            {report.pending !== null &&
                              report.pending !== "0" && (
                                <MdArrowForwardIos className="total-report-icon" />
                              )}
                          </td>
                          {/* <td
                            className={`center-alignment tenure-status ${
                              report.not_in_tenure === "0"
                                ? "non-clickable"
                                : ""
                            }`}
                            onClick={() =>
                              report.not_in_tenure &&
                              report.not_in_tenure > 0 &&
                              handleViewClick(
                                report.department_id,
                                "not_in_tenure"
                              )
                            }
                          >
                            {report.not_in_tenure || "0"}
                            {report.not_in_tenure !== null &&
                              report.not_in_tenure !== "0" && (
                                <MdArrowForwardIos className="total-report-icon" />
                              )}
                          </td> */}
                          <td
                            className={`center-alignment total-status ${
                              report.total_schedules === "0"
                                ? "non-clickable"
                                : ""
                            }`}
                            onClick={() =>
                              handleViewClick(
                                report.department_id,
                                "total_schedules",
                                report.total_schedules
                              )
                            }
                          >
                            {report.total_schedules || "0"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">
                      No Preventive Schedules Report Found
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default AmcReport;
