import React from "react";
import { useParams } from "react-router-dom";

  const HotWorkTemplate4 = ({ checklist, signatures}) => {
  const { formId } = useParams();

  const fireSafetySignature = signatures?.find(sig => sig.type === "fire & safety");

  return (
    <>
        <div className="hot-template-container">
          <div className="hot-template-container-content">
            <div className="template-container-header">
              <div className="hot-work-table">
                <table className="hot-work-tables">
                  <thead>
                    <tr>
                      {/* <th className="permit-center-align permit-1">क्रम सं <br /> Sr.no</th> */}
                      <th className="permit-center-align permit-2">Item</th>
                      <th className="permit-center-align permit-3">Done</th>
                      <th className="permit-center-align permit-4">Not Reqd.</th>
                      {/* <th className="permit-center-align permit-1">क्रम सं <br /> Sr.no</th> */}
                      <th className="permit-center-align permit-2">Item</th>
                      <th className="permit-center-align permit-3">Done</th>
                      <th className="permit-center-align permit-4">Not Reqd.</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>I.Road Closure Crane Operation/Vehicle Entry/
                        <br /> Photography
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Done' based on work_status */}
                        {checklist[0] && checklist[0].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[0] && checklist[0].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                      <td> 10. Iron sulphide removed & kept wet.</td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Done' based on work_status */}
                        {checklist[15] && checklist[15].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[15] && checklist[15].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td> 1. Equipment & Area inspected
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Done' based on work_status */}
                        {checklist[3] && checklist[3].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[3] && checklist[3].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                      <td> 11. Flame arrester on exhaust checked.</td>
                      <td className="permit-center-align">
                     
                          {checklist[16] && checklist[16].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[16] && checklist[16].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td> 2. Area cordoned off
                      </td>
                      <td className="permit-center-align">
                     
                          {checklist[4] && checklist[4].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[4] && checklist[4].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                      <td> 12. Area cordoned off & Precautionary Tag Boards provided.</td>
                      <td className="permit-center-align">
                     
                     {checklist[17] && checklist[17].work_status === 1 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                   ) : (
                     ""
                   )}
                 </td>
                 <td className='permit-center-align'>
                   {/* Conditional rendering for 'Not Required' based on work_status */}
                   {checklist[17] && checklist[17].work_status === 0 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                   ) : (
                     ""
                   )}
                 </td>
                    </tr>
                  
                    <tr>
                      <td> 3. Clearance From F&S Dept.
                      </td>
                      <td className="permit-center-align">
                     
                          {checklist[7] && checklist[7].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[7] && checklist[7].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                      <td> 13. HC gas testing......%LEL</td>
                      <td className="permit-center-align">
                     
                          {checklist[18] && checklist[18].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[18] && checklist[18].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td> 4. Checked Flame/Spark Arrestor for mobile Eopt. <br/> No/Vehicle Ragd No...............
                      </td>
                      <td className="permit-center-align">
                     
                     {checklist[9] && checklist[9].work_status === 1 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                   ) : (
                     ""
                   )}
                 </td>
                 <td className='permit-center-align'>
                   {/* Conditional rendering for 'Not Required' based on work_status */}
                   {checklist[9] && checklist[9].work_status === 0 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                   ) : (
                     ""
                   )}
                 </td>
                      <td> 14. H2S gas testing............PPM</td>
                      <td className="permit-center-align">
                     
                          {checklist[19] && checklist[19].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[19] && checklist[19].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td> 5.Danger sign displayed
                      </td>
                      <td className="permit-center-align">
                     
                     {checklist[11] && checklist[11].work_status === 1 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                   ) : (
                     ""
                   )}
                 </td>
                 <td className='permit-center-align'>
                   {/* Conditional rendering for 'Not Required' based on work_status */}
                   {checklist[11] && checklist[11].work_status === 0 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                   ) : (
                     ""
                   )}
                 </td>
                      <td> 15.  O2 gas testing............% by vol of air</td>
                      <td className="permit-center-align">
                     
                     {checklist[20] && checklist[20].work_status === 1 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                   ) : (
                     ""
                   )}
                 </td>
                 <td className='permit-center-align'>
                   {/* Conditional rendering for 'Not Required' based on work_status */}
                   {checklist[20] && checklist[20].work_status === 0 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                   ) : (
                     ""
                   )}
                 </td>
                    </tr>
                    <tr>
                      <td> II. Hot Work/Hot Tapping
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td className='permit-center-align'>
                      </td>
                      <td> 16. Surrounding area checked, cleaned up, oil, <br/> grass etc removed.</td>
                      <td className="permit-center-align">
                     
                          {checklist[21] && checklist[21].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[21] && checklist[21].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td> 1. Equipment & Area inspected
                      </td>
                      <td className="permit-center-align">
                     
                          {checklist[1] && checklist[1].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[1] && checklist[1].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                      <td> 17. Fire Water hose, Portable extinguishers <br/> provided & system checked for readiness.
                      .</td>
                      <td className="permit-center-align">
                     
                     {checklist[22] && checklist[22].work_status === 1 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                   ) : (
                     ""
                   )}
                 </td>
                 <td className='permit-center-align'>
                   {/* Conditional rendering for 'Not Required' based on work_status */}
                   {checklist[22] && checklist[22].work_status === 0 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                   ) : (
                     ""
                   )}
                 </td>
                    </tr>
                    <tr>
                      <td> 2. Sewers, manholes, CBD etc & hot surface nearby <br/>covered
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Done' based on work_status */}
                        {checklist[2] && checklist[2].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[2] && checklist[2].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                      <td> 18. Equipemnt properly drained & depressurized</td>
                      <td className="permit-center-align">
                     
                     {checklist[23] && checklist[23].work_status === 1 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                   ) : (
                     ""
                   )}
                 </td>
                 <td className='permit-center-align'>
                   {/* Conditional rendering for 'Not Required' based on work_status */}
                   {checklist[23] && checklist[23].work_status === 0 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                   ) : (
                     ""
                   )}
                 </td>
                    </tr>
                    <tr>
                      <td> 3. Equipment Electrically isolated & tagged. <br/>Electrical Permit No.......
                      </td>
                      <td className="permit-center-align">
                     
                          {checklist[5] && checklist[5].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[5] && checklist[5].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                      <td> 19. Portable equipment, Nozzles property<br/> grounded.</td>
                      <td className="permit-center-align">
                     
                     {checklist[24] && checklist[24].work_status === 1 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                   ) : (
                     ""
                   )}
                 </td>
                 <td className='permit-center-align'>
                   {/* Conditional rendering for 'Not Required' based on work_status */}
                   {checklist[24] && checklist[24].work_status === 0 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                   ) : (
                     ""
                   )}
                 </td>
                    </tr>
                    <tr>
                      <td> 4. Equipment: Blinded/Isolated/Disconnected/ <br/> Closed/Edge open.
                      </td>
                      <td className="permit-center-align">
                     
                          {checklist[6] && checklist[6].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[6] && checklist[6].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                      <td> 20. Welding machine checked for safe location</td>
                      <td className="permit-center-align">
                     
                     {checklist[25] && checklist[25].work_status === 1 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                   ) : (
                     ""
                   )}
                 </td>
                 <td className='permit-center-align'>
                   {/* Conditional rendering for 'Not Required' based on work_status */}
                   {checklist[25] && checklist[25].work_status === 0 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                   ) : (
                     ""
                   )}
                 </td>
                    </tr>
                    <tr>
                      <td> 5. Proper ventilation & Lighting provided.
                      </td>
                      <td className="permit-center-align">
                     
                     {checklist[8] && checklist[8].work_status === 1 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                   ) : (
                     ""
                   )}
                 </td>
                 <td className='permit-center-align'>
                   {/* Conditional rendering for 'Not Required' based on work_status */}
                   {checklist[8] && checklist[8].work_status === 0 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                   ) : (
                     ""
                   )}
                 </td>
                      <td> 21. Standby person provided for fire watch</td>
                      <td className="permit-center-align">
                     
                     {checklist[26] && checklist[26].work_status === 1 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                   ) : (
                     ""
                   )}
                 </td>
                 <td className='permit-center-align'>
                   {/* Conditional rendering for 'Not Required' based on work_status */}
                   {checklist[26] && checklist[26].work_status === 0 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                   ) : (
                     ""
                   )}
                 </td>
                    </tr>
                    <tr>
                      <td> 6. Shield against spark provided
                      </td>
                      <td className="permit-center-align">
                     
                     {checklist[10] && checklist[10].work_status === 1 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                   ) : (
                     ""
                   )}
                 </td>
                 <td className='permit-center-align'>
                   {/* Conditional rendering for 'Not Required' based on work_status */}
                   {checklist[10] && checklist[10].work_status === 0 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                   ) : (
                     ""
                   )}
                 </td>
                      <td> 22. Approval obtained from inspection for Hot<br/> Tapping.</td>
                      <td className="permit-center-align">
                     
                     {checklist[27] && checklist[27].work_status === 1 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                   ) : (
                     ""
                   )}
                 </td>
                 <td className='permit-center-align'>
                   {/* Conditional rendering for 'Not Required' based on work_status */}
                   {checklist[27] && checklist[27].work_status === 0 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                   ) : (
                     ""
                   )}
                 </td>
                    </tr>
                    <tr>
                      <td> 7. Check for earthing & return connection to the <br/>equipment being welded.</td>
                      <td className="permit-center-align">
                     
                          {checklist[12] && checklist[12].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[12] && checklist[12].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                      <td> 23. Proper means of exit/ escape provided.</td>
                      <td className="permit-center-align">
                     
                          {checklist[28] && checklist[28].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[28] && checklist[28].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td> 8. Oxygen & Acetylene cylinders kept outside the <br/>vessel/tank</td>
                      <td className="permit-center-align">
                     
                     {checklist[13] && checklist[13].work_status === 1 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                   ) : (
                     ""
                   )}
                 </td>
                 <td className='permit-center-align'>
                   {/* Conditional rendering for 'Not Required' based on work_status */}
                   {checklist[13] && checklist[13].work_status === 0 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                   ) : (
                     ""
                   )}
                 </td>
                      <td rowSpan="2"> 24. Checked for oil & gas trapped behind the lining <br/>in Equipment.</td>
                      <td rowSpan="2" className="permit-center-align">
                     
                     {checklist[29] && checklist[29].work_status === 1 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                   ) : (
                     ""
                   )}
                 </td>
                 <td  rowSpan="2" className='permit-center-align'>
                   {/* Conditional rendering for 'Not Required' based on work_status */}
                   {checklist[29] && checklist[29].work_status === 0 ? (
                     <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                   ) : (
                     ""
                   )}
                 </td>
                    </tr>
                    <tr>
                      <td> 9. Considered hazards from routine/ non routine <br/>operations & concerned persons alerted</td>
                      <td className="permit-center-align">
                     
                          {checklist[14] && checklist[14].work_status === 1 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Done'
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='permit-center-align'>
                        {/* Conditional rendering for 'Not Required' based on work_status */}
                        {checklist[14] && checklist[14].work_status === 0 ? (
                          <span className="checkmarks">&#10003;</span> // Tick mark for 'Not Required'
                        ) : (
                          ""
                        )}
                      </td>
                      {/* <td> </td> */}
                     
                    </tr>
                    <tr>
                      {/* <td colSpan="6"> Gas Test Done By: Name.......................................................Signature..............................................Date & Time........................................................</td> */}
                      <td colSpan="6">
            Gas Test Done By: <strong>{fireSafetySignature ? fireSafetySignature.name : "................................................"}......</strong> &nbsp;&nbsp;
            Signature: <strong>..............................................</strong> &nbsp;&nbsp;
            Date & Time: <strong>{fireSafetySignature ? new Date(fireSafetySignature.date_time).toLocaleString() : "........................................................"}</strong>
          </td>
                    </tr> 
                
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
   
    </>
  );
};

export default HotWorkTemplate4;
