import "./IsolationTemplate2.css"

export default function IsolationPermitForm() {
  return (

      <div className="permit-form">
        <div className="form-header">
          <h1 className="form-title">अनुभाग -ए/ Section-A: अलगाव की अनुमति / Isolation Permit</h1>
          <div className="serial-number">
            <p>क्रम सं. / Sl. No. : ...................</p>
          </div>
        </div>

        <div className="request-header">
          <h2 className="request-title">अलगाव के लिए अनुरोध / Request for Isolation:</h2>
          <div className="date-time">
            <p>दिनांक / Date:........................समय / Time:...............</p>
          </div>
        </div>

        <div className="form-field">
          <p className="field-label line1-isolation">
            विभाग/अनुभाग/परमिट जारी करने वाला क्षेत्र। Department/Section/Area issuing the permit:
          </p>
          <span className="input-line"></span>
        </div>

        <div className="input-line"></div>

        <div className="form-field">
          <p className="field-label line2-isolation">उपकरण संख्या को अलगाव किया जाना है/Equipment number to be isolated :</p>
          <div className="input-line"></div>
        </div>

        <div className="form-field">
          <p className="field-label line3-isolation">उपकरण का नाम/अलग होने के लिए सर्किट/Name of the equipment/circuit to be isolated:</p>
          <div className="input-line"></div>
        </div>

        <div className="form-field">
          <p className="field-label line4-isolation">
         <div className='field-line1'>   उपर्युक्त उपकरण/सर्किट को रखरखाव के काम को पूरा करने के लिए सभी लाइव्ड कंडक्टर्से अलग किया जाएगा।/The above-mentioned
            equipment 
            </div>
            <div className='field-line2'>
            /circuit shall be de-energized and isolated from all live conductors to carry out the maintenance
            work by
            </div>
          </p>
          {/* <div className="input-line input-line-last"></div> */}

        </div>

        <div className="signature-section">
          <div className="signature-field">
            <div className="signature-line"></div>
            <p>द्वारा अनुरोध किया गया/ Requested by</p>
          </div>
          <div className="signature-field">
            <div className="signature-line"></div>
            <p>पद / Designation</p>
          </div>
          <div className="signature-field">
            <div className="signature-line"></div>
            <p>हस्ताक्षर / Signature</p>
          </div>
        </div>
      </div>

  )
}

