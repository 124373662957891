import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import POConfigurationlist from "./POConfigurationlist";
import { Tabs, Tab } from "@mui/material";
import axios from "axios";
import API_URL from "../../src/Config";

function PermitRequestForRequesterTab({ isSidebarExpanded }) {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get("tab");

  const [tabValue, setTabValue] = useState(tabFromUrl || "all");

  const updateTotalItems = (counts) => {
    console.log("Updated counts:", counts);
    // setTabCounts({
    //   Issued: counts.Issued || 0,
    //   Rejected: counts.Rejected || 0,
    //   Closed: counts.Closed || 0,
    //   Submitted: counts.Submitted || 0,
    //   Draft: counts.Draft || 0,
    // });
  };


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    navigate(`?tab=${newValue}`);
  };
  
  useEffect(() => {
    if (tabFromUrl !== tabValue) {
      setTabValue(tabFromUrl || "all");
    }
  }, [tabFromUrl, location.search]);
  
  const getStatusForTab = (tabValue) => {
    switch (tabValue) {
      case "all":
        return "all"; // Draft
      case "1":
        return "1"; // Submitted
      case "0":
        return "0"; // Submitted 
      default:
        return null;
    }
  };
  console.log("Passing inwarded_filter to POConfigurationlist:", getStatusForTab(tabValue));
 

  
  
  // Mock function to return notification IDs for a tab

  

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
       
          <div className="data-list-content permit-tab" ref={containerRef}>
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => handleTabChange(event, newValue)}
              aria-label="report tabs"
              className="tabs-container"
            >
              <Tab
                label={
                  <div className="tab-label-container">
                    <span className="tab-status-label">All</span>
                  </div>
                }
                value="all"
              />
                <Tab
                label={
                  <div className="tab-label-container">
                    <span className="tab-status-label">Open</span>
                  </div>
                }
                value="0"
              />
                <Tab
                label={
                  <div className="tab-label-container">
                    <span className="tab-status-label">Closed</span>
                  </div>
                }
                value="1"
              />

            </Tabs>
            <div className="header-separater"></div>
            <div className="tabs-content">
              <POConfigurationlist
                inwarded_filter={getStatusForTab(tabValue)}
                updateTotalItems={updateTotalItems}
              />
            </div>
          </div>

      </div>
    </>
  );
}

export default PermitRequestForRequesterTab;
