import React, { useState, useEffect, useRef } from "react";
// import "./User.css";
import { Link } from "react-router-dom";
import {HiOutlinePlus } from "react-icons/hi";
import {
  IoCloseOutline,
  IoSearchOutline,
} from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Pagination from "../Component/Pagination";
import { TbRefresh } from "react-icons/tb";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { MdOutlineModeEditOutline } from "react-icons/md";
import useClickOutside from "../useClickOutside";
import EmailConfigurationRecorder from "../Popups/EmailConfigurationRecorder";


function MatCode({ isSidebarExpanded, departmentID }) {
  const [matCodeData, setMatCodeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const fetchTimeout = useRef(null);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
 const [isEmailsPopupOpen, setIsEmailsPopupOpen] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const user_role = localStorage.getItem("user_role"); // Get user role
  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedschedules = [...matCodeData].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setMatCodeData(sortedschedules);
  };

  const handleExport = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/export_mat_codes`, {
        headers: {
          Authorization: authKey,
        },
        responseType: "blob", // Important for handling file downloads
      });
  
      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Material_Codes_${new Date().toISOString().replace(/[:.]/g, "_")}.xlsx`
      ); // Set file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting material codes:", error);
      setSnackbarMessage("Failed to export material codes.");
      setSnackbarOpen(true);
    }
  };
    

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_mat_code_details`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      setMatCodeData(response.data.mat_code_details);
      setTotalItems(response.data.total);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error.message);
      }
      setLoading(false);
    }
  };

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }

    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_mat_code`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setMatCodeData(response.data.mat_code_details);
        setTotalItems(response.data.total);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);

    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };


  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(newSelectAll ? matCodeData.map((mat) => mat.mat_code_id) : []);
    setChecked(newSelectAll);
  };

  const handleSelectRow = (departmentID) => {
    const updatedSelectedRows = selectedRows.includes(departmentID)
      ? selectedRows.filter((id) => id !== departmentID)
      : [...selectedRows, departmentID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === matCodeData.length);
    setChecked(updatedSelectedRows.length > 0);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRefresh = () => {
    fetchData(page, itemsPerPage);
  };

  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const handleArchiveMatcode = () =>{
    // alert('..test')
    navigate('/archived-mat-code')
  }
  const handleArchivedMatcode = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_all_matcode`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar('Matcode archived successfully');
        setSelectedRows([]);
        setSelectAll(false);
        fetchData();
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting departments:", error);
    }
  };

  const handleEmailSetting = () => {
    setIsEmailsPopupOpen(true); // Open the popup
  };

  const handleClosePopupEmail = () => {
    setIsEmailsPopupOpen(false);
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                    <button
                        className="delete"
                        onClick={handleDeletePopup}
                      >
                        Move to archive
                      </button>
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button className="confirm-delete-button" onClick={handleArchivedMatcode}>
                                  
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All Mat Code
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Link to="/new-mat-code" className="data-list-new">
                      <Tooltip title="New Department" arrow>
                        <button className="new-button">
                          <HiOutlinePlus className="new-plus-icon" /> New
                        </button>
                      </Tooltip>
                    </Link>

                    {user_role === "admin" && (
  <button className="new-button" onClick={handleExport}>Export</button>
)}

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button
                      className="sort-button"
                      onClick={handleSortpopup}
                      ref={buttonRef}
                    >
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button onClick={() => handleSort("mat_code")}>
                              Mat Code{" "}
                              {sortOrder.mat_code === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            
                            <br />
                          
                          </div>
                          <button className='delete-item' onClick={handleEmailSetting}>Reorder Reminder Config</button>
                          <button className='delete-item' onClick={handleArchiveMatcode}>Archived Mat Code</button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
             {isEmailsPopupOpen && (
            <EmailConfigurationRecorder onClose={handleClosePopupEmail} />
          )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : matCodeData.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th className="center-alignment">SR. NO.</th>
                          {/* <th>LEVEL 1</th>
                          <th>LEVEL 2</th>
                          <th>LEVEL 3</th>
                          <th>LEVEL 4</th>
                          <th>LEVEL 5</th> */}
                          <th className="center-alignment">MAT CODE</th>
                          <th className="center-alignment">REORDER LEVEL QTY</th>
                          <th>DESCRIPTION</th>
                          <th>CREATED AT</th>
                          {/* <th>UPDATED IT</th> */}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {matCodeData.map((matCode, index) => (
                          <tr key={matCode.id} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(matCode.mat_code_id)}
                                onChange={() => handleSelectRow(matCode.mat_code_id)}
                              />
                            </td>
                            <td className="center-alignment">
                              {matCode.serial_no}
                            </td>
                            {/* <td>{matCode.level_one || "NA"}</td>
                            <td>{matCode.level_two || "NA"}</td>
                            <td>{matCode.level_three || "NA"}</td>
                            <td>{matCode.level_four || "NA"}</td>
                            <td>{matCode.level_five || "NA"}</td> */}
                            {/* <td>
                              {matCode.mat_code
                                ? matCode.mat_code.replace(/-/g, "")
                                : "NA"}
                            </td> */}
                            <td className="center-alignment">
                              {matCode.mat_code || "NA"}
                            </td>
                            <td className="center-alignment">
                              {matCode.reorder_level || "NA"}
                            </td>
                            <td>
                              {`${matCode.level_one_description || "NA"}, ${
                                matCode.level_two_description || "NA"
                              }, ${matCode.level_three_description || "NA"}, ${
                                matCode.description || "NA"
                              }`}
                            </td>
                          
                            <td>
                          {matCode.created_at
                            ? new Date(matCode.created_at)
                                .toUTCString()
                                .split("GMT")[0]
                                .trim() + " IST"
                            : "NA"}
                        </td>
                            <td className="edit-pop">
                              <button
                                className="edit-data-button"
                                onClick={() => handleButtonClick(index)}
                              >
                                <div className="edit-button">
                                  <MdExpandMore />
                                </div>
                              </button>
                            </td>
                            {selectedRowIndex === index && (
                              <div className="edit-popup">
                                {console.log(
                                  `Edit popup rendered for row index: ${index}`
                                )}{" "}
                                {/* Console log statement */}
                                <div className="edit-popup-contant">
                                  <div className="edit-popup-button">
                                    <Link
                                      to={`/update-mat-code/${matCode.mat_code_id}`}
                                      className="inquiry-new"
                                    >
                                      <button className="edit-button-inquiry">
                                        <MdOutlineModeEditOutline /> Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">No Mat Code Found</div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
     
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default MatCode;
