import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import Select from "react-select";

function EditManpower({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [projectId, setProjectId] = useState("");
  const [manPowerData, setManPowerData] = useState({
    project_id: id || "",
    position: "",
    expected_profile: "",
    no_of_positions: "",
    status: "open",
  });
  const [selectedProject, setSelectedProject] = useState(null); // Stores selected project
  const [projects, setProjects] = useState([]);

  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };
  useEffect(() => {
    const fetchManpowerData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_manpower_requirement/${id}`,
          {
            headers: { Authorization: authKey },
          }
        );

        const responseData = response.data;
        if (!responseData) throw new Error("Invalid API response");

        // Set formatted data
        const formattedData = {
          project_name: responseData.project_name || "",
          project_id: responseData.project_id || "",
          position: responseData.position || "",
          expected_profile: responseData.expected_profile || "",
          no_of_positions: responseData.no_of_positions || "",
          status: responseData.status || "open",
        };

        setManPowerData(formattedData);

        // Set selected project for the dropdown
        setSelectedProject({
          value: responseData.project_id,
          label: responseData.project_name,
        });

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching manpower data:", error.message);
        setError("An error occurred while fetching manpower data");
        setIsLoading(false);
      }
    };

    fetchManpowerData();
  }, [id]);

  const validateForm = () => {
    const validationErrors = {};

    // Log the manPowerData state to see the current values
    console.log("Current manPowerData:", manPowerData);

    if (!selectedProject) {
      validationErrors.selectedProject = "Project is required.";
      console.log("project is empty");
    } else {
      console.log("Position value:", manPowerData.selectedProject); // Log position value
    }

    // Check if position is empty
    if (!manPowerData.position) {
      validationErrors.position = "Position is required.";
      console.log("Position is empty");
    } else {
      console.log("Position value:", manPowerData.position); // Log position value
    }

    // Check if no_of_position is empty (match the state field name)
    if (!manPowerData.no_of_positions) {
      validationErrors.no_of_positions = "Number of positions is required.";
      console.log("No of positions is empty");
    } else {
      console.log("No of positions value:", manPowerData.no_of_positions); // Log no_of_position value
    }

    // Check if profile is empty
    if (!manPowerData.expected_profile) {
      validationErrors.expected_profile = "Profile is required.";
      console.log("Profile is empty");
    } else {
      console.log("Profile value:", manPowerData.expected_profile); // Log profile value
    }

    // Set errors if any
    setErrors(validationErrors);

    // Return true if no errors, otherwise false
    return Object.keys(validationErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setManPowerData({ ...manPowerData, [name]: value });
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  // const handleCancelClick = () => {
  //   navigate(`/man-powers/${projectId}`);
  // };
  const handleCancelClick = () => {
    window.history.back();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    if (!validateForm()) {
      return;
    }

      try {
        setIsLoading(true);
        const authKey = localStorage.getItem("authKey");
        const response = await axios.put(
          `${API_URL}/api/update_manpower_requirement/${id}`,
          manPowerData,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        console.log(response.data.message);
        if (response.status === 200) {
          setSeverity("success");
          showSnackbar("Data updated successfully");
          setTimeout(() => {
            navigate("/manpower-requirements");
          }, 1000);
        }
      } catch (error) {
        console.error("Error updating company:", error.message);
        setError("An error occurred while updating company data");
        setIsLoading(false);
      }
    
  };

  const handleBackProject = () => {
    window.history.back();
  };
  const fetchProjects = async () => {
    try {
      const authKey = localStorage.getItem("authKey"); // Retrieve auth key from local storage

      const response = await fetch(`${API_URL}/api/get_all_projects`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey, // Attach auth key in headers
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      const projectOptions = data.Projects.map((project) => ({
        value: project.id, // Unique identifier
        label: project.project_name, // Display name
      }));

      setProjects(projectOptions); // Update state with project options
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleProjectChange = (selectedOption) => {
    console.log("Selected Project:", selectedOption);

    // Update state
    setSelectedProject(selectedOption);

    // Ensure project_id updates in manPowerData
    setManPowerData((prevData) => ({
      ...prevData,
      project_id: selectedOption.value,
      project_name: selectedOption.label,
    }));
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />
                </Tooltip>
                Edit Manpower Requirement
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="department_name">
                    Project<span className="text-danger">*</span>
                  </label>
                  {/* <div className="d-flex-col"> */}
                  <Select
                    id="project_name"
                    options={projects} // Ensure `projects` is in the correct format
                    value={selectedProject} // Ensure this is an object {value, label}
                    onChange={handleProjectChange}
                    placeholder="Select Project"
                    isSearchable
                  />

{errors.selectedProject && (
                    <div className="error-message">{errors.selectedProject}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="position">
                    Position<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="position"
                      value={manPowerData.position}
                      onChange={handleInputChange}
                      placeholder="Enter position"
                    />
                  </div>
                  {errors.position && (
                    <div className="error-message">{errors.position}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="no_of_positions">
                    No. Of Position<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="no_of_positions"
                      value={manPowerData.no_of_positions}
                      onChange={handleInputChange}
                      placeholder="Enter number of positions"
                    />
                  </div>
                  {errors.no_of_positions && (
                    <div className="error-message">{errors.no_of_positions}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="expected_profile">
                    Expected Profile<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="expected_profile"
                      value={manPowerData.expected_profile}
                      onChange={handleInputChange}
                      placeholder="Enter expected profile"
                    />
                  </div>
                  {errors.expected_profile && (
                    <div className="error-message">{errors.expected_profile}</div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditManpower;
