import React, { useState, useEffect, useRef } from "react";
import "./AllNotification.css";
import { Link } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Pagination from "../Component/Pagination";
import useClickOutside from "../useClickOutside";

function AllNotification({ isSidebarExpanded, customerID }) {
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/notifications`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      setNotifications(response.data.notifications);
      setTotalItems(response.data.total);
    } catch (error) {
      console.error("Error fetching customers data:", error.message);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };


  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }

    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_customers`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setNotifications(response.data.Customers);
        setTotalItems(response.data.TotalItems);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const markNotificationAsRead = async (notificationId) => {
    try {
      const authKey = localStorage.getItem("authKey"); // Retrieve authKey from localStorage
      const response = await axios.put(
        `${API_URL}/api/notifications/mark-read`, // API URL
        {
          notification_ids: [notificationId], // Payload: Sending the clicked notification ID
        },
        {
          headers: {
            Authorization: authKey, // Setting the Authorization header
          },
        }
      );
  
      console.log(response.data.message); // Log success message
      // Optionally refresh notifications or update state here
      fetchData();
    } catch (error) {
      console.error("Error marking notification as read:", error); // Log error for debugging
    }
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

//   const handleRefresh = () => {
//     if (searchInput) {
//       console.log("Search input is set:", searchInput);
//     } else {
//       fetchData(page, itemsPerPage);
//     }
//     setSearchInput("");
//   };
const handleRefresh = () => {
    fetchData(page, itemsPerPage);
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  function formatTimeAgo(dateString) {
    // Parse the created time as UTC
    const createdAt = new Date(dateString); 

    // Force `now` to use UTC instead of local time
    const nowUTC = new Date(dateString);; 

    console.log("Created At (GMT):", createdAt.toISOString());
    console.log("Now (Corrected UTC Time):", nowUTC.toISOString());

    // Convert both timestamps to milliseconds
    const diffInMilliseconds = nowUTC.getTime() - createdAt.getTime();

    console.log("Time Difference (ms):", diffInMilliseconds);

    // Prevent negative values (for future dates)
    if (diffInMilliseconds < 0) {
        console.log("Future date detected, returning 'Just now'");
        return "Just now";
    }

    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    console.log("Difference in Seconds:", diffInSeconds);
    console.log("Difference in Minutes:", diffInMinutes);
    console.log("Difference in Hours:", diffInHours);
    console.log("Difference in Days:", diffInDays);

    if (diffInMinutes < 1) {  
        return "Less than a minute ago";  
    } else if (diffInMinutes < 60) {  
        return `${diffInMinutes} minutes ago`;  
    } else if (diffInHours < 24) {  
        return `${diffInHours} hours ago`;  
    } else if (diffInDays === 1) {  
        return "Yesterday";  
    } else {  
        return `${diffInDays} days ago`;  
    }
}

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-list-content" ref={containerRef}>
      
            <div className="data-list-header">
              <div className="data-list-header-content">
                <div className="data-list-heading-content-left-side">
                  All Notifications
                </div>
                <div className="data-list-heading-content-right-side">
                  {/* <div className="search-bar-container">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      className="search-bar"
                    />
                    <IoSearchOutline className="search-bar-icon" />
                  </div> */}

                 
                  <Tooltip title="Refresh List" arrow>
                    <button className="refresh-button" onClick={handleRefresh}>
                      <TbRefresh className="refresh-icon" />
                    </button>
                  </Tooltip>
                  <button
                    className="sort-button"
                    onClick={handleSortpopup}
                    ref={buttonRef}
                  >
                    <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                  </button>
                  {isSortPopup && (
                    <div className="sort-popup-container" ref={containerRef}>
                    
                    </div>
                  )}
                </div>
              </div>
            </div>
        
          <div className="data-list-body" onClick={closePopups}>
            <div className="data-list-scroll-body">
              <div className="data-list-table">
              {notifications.length > 0 ? (
                <div className="notification-body-container">
                  {notifications.map((notification, index) => (
                    <>
                      <div
                        key={notification.id}
                        className={`notification-body-contents ${
                          notification.read_status === 0 ? "unread" : "read"
                        }`}
                        onClick={() => markNotificationAsRead(notification.id)} // Call the function with the notification ID
                        style={{ cursor: "pointer" }} // Add pointer cursor for better UX
                      >
                        <div className="username-letter-n">
                          <div className="letter-background-notification">
                            {/* <div className="first-letter-n">
                              {notification.requested_by}
                            </div> */}
                            <div className="first-letter-n">
  {notification.requested_by
    ? notification.requested_by
        .split(" ")
        .map((word) => word[0])
        .join("")
        .toUpperCase()
    : ""}
</div>
                          </div>
                        </div>
                        <div className="notification-title-message align">
                          <div className="notification-title">
                            {notification.title}
                          </div>
                          <div className="notification-message">
                            {notification.message}
                          </div>
                          <div className="notification-message">
                        {formatTimeAgo(notification.created_at)}
                      </div>
                        </div>
                        <div className="">
                          {/* <div className="notification-dot"></div> */}
                          <div
                            className={`notification-dot ${
                              notification.read_status === 0
                                ? "bg-blue"
                                : "bg-gray"
                            }`}
                          ></div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              ) : (
                <div className="no-users-container">
                <div className="no-users-message">No notifications available</div>
              </div>
              )}
              </div>
              <Pagination
                showPerPage={itemsPerPage}
                total={totalItems}
                onPaginationChange={handlePaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default AllNotification;
