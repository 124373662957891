import React from "react";
import { useParams } from "react-router-dom";

const HotWorkTemplate2 = ({ workDetails }) => {
  const { formId } = useParams();
  console.log("ID from URL:", formId);

  const formatDateTime = (dateString) => {
    if (!dateString) return 'N/A';
    
    // Create a Date object from the date string
    const date = new Date(dateString);
  
    // Convert to UTC and format time
    const time = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC',
    });
  
    // Format date to DD/MM/YYYY using UTC date methods
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
    const year = date.getUTCFullYear();
  
    // Combine to form the date string in DD/MM/YYYY format
    const dateStr = `${day}/${month}/${year}`;
  
    return `${time} बजे, दिनांक /hrs. of date ${dateStr}`;
  };
  
  const getIssuedToDetails = () => {
    const issuedTo = workDetails.issued_to;
    const section = workDetails.section;
    const departmentName = workDetails.department_name; // Assuming department_id exists
    const contractor = workDetails.contractor; // Assuming contractor exists
  
    switch (issuedTo) {
      case "department":
        return `${departmentName} (Department)`; // department_id followed by "Department"
      case "section":
        return `${section} (Section)`; // section followed by "Section"
      case "contractor":
        return `${contractor} (Contractor)`; // contractor followed by "Contractor"
      default:
        return "Not Issued"; // Default case when issued_to is unknown or empty
    }
  };
  

  return (
    <>
      <div className="hot-template-container">
        <div className="hot-template-container-content">
          <div className="template-container-pera">
          <div className="">वर्क क्लीयरेंस से /Work clearance from <span className="work-text">{formatDateTime(workDetails.valid_from)}</span> तक /To <span className="work-text">{formatDateTime(workDetails.valid_to)}</span></div>
            {/* <div className="">
            date .............(नवीनीकृत न होने तक पारी के लिए वैध / valid for the shift unless renewed).
            </div> */}
            {/* <div className="">
              वर्क क्लीयरेंस से / Work clearance from <span className="work-text">.............</span> बजे, दिनांक /hrs. of date <span className="work-text">.................. तक/To</span> बजे, दिनांक/hrs. of date .............
            </div> */}
            <div className="">
              (नवीनीकृत न होने तक पारी के लिए वैध / valid for the shift unless renewed).
            </div>
            <div className="">
              किस (विभाग/अनुभाग/ ठेकेदार) को जारी किया गया / Issued to (Department/Section/Contractor) <span className="work-text">{getIssuedToDetails()}</span>
            </div>
            <div className="">
              कार्य का वास्तविक स्थान/ क्षेत्र/ इकाई /उपकरण सं इत्यादि / Exact location of work (Area/Unit/Equipment No. etc.) <span className="work-text">{workDetails.area} / {workDetails.unit} / {workDetails.TagNos}</span>
            </div>
            <div className="">
              कार्य का ब्यौरा / Description of work: <span className="work-text">{workDetails?.work_description || ".................."}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HotWorkTemplate2;
