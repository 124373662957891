import React, { useState, useEffect, useRef } from "react";
// import "./UserForm.css";
// import "./PRRequestForm.css";
import "../Responsive/FormResponsive.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import { FiUpload } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import { MdAttachFile } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import useClickOutside from "../useClickOutside";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";

function AuditForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const popupRef = useRef(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const [rows, setRows] = useState([
    {
      point_no: "",
      responsible_person_name: "",
      person_email: "",
      observation: "",
      recommendation: "",
      target_date: "",
      organization_type: "",
    },
  ]);
  const [prRequestData, setPrRequestData] = useState({
    month: null,
    year: null,
    reference_no: "",
    audit_type: "",
    attachments: [], // Array of files
  });
  const { theme } = useContext(ThemeContext);

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        point_no: "",
        responsible_person_name: "",
        person_email: "",
        observation: "",
        recommendation: "",
        target_date: "",
        organization_type: "",
      },
    ]);
  };

  const organizationOptions = [
    { value: "ISPRL", label: "ISPRL" },
    { value: "Contractor", label: "Contractor" },
  ];

  const auditTypeOptions = [
    { value: "ESA", label: "ESA" },
    { value: "IB", label: "IB" },
    { value: "ISA", label: "ISA" },
    { value: "OISD", label: "OISD" },
  ];

  const handleInputChanges = () => {};

  const handleUploadButtonClick = () => {
    // Ensure the ref is not null before triggering the click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is null");
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  // useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));
  useClickOutside([containerRef, buttonRef], handleClosePopup, 200); // 200ms delay
  useClickOutside([popupRef, buttonRef], handleClosePopup, 300);

  const handleCancelClick = () => {
    navigate("/statutory-audits");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPrRequestData((prev) => ({ ...prev, [name]: value }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      reference_no: "",
    }));
  };

  const handleAuditPoint = (index, field, value) => {
    const newRows = [...rows];
    newRows[index] = { ...newRows[index], [field]: value };
    setRows(newRows);
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Validate primary fields
    if (!prRequestData.reference_no) {
      errors.reference_no = "Reference number is required.";
      isValid = false;
    }
    if (!prRequestData.audit_type) {
      errors.audit_type = "Audit type is required.";
      isValid = false;
    }
    if (!prRequestData.month) {
      errors.month = "Month is required.";
      isValid = false;
    }
    if (!prRequestData.year) {
      errors.year = "Year is required.";
      isValid = false;
    }

    // // Validate rows (audit points)
    // let rowErrors = rows.map((row) => {
    //   let rowError = {};
    //   if (!row.point_no) rowError.point_no = "Point No. is required.";
    //   if (!row.responsible_person_name) rowError.responsible_person_name = "Person name is required.";
    //   if (!row.person_email) rowError.person_email = "Email is required.";
    //   if (!row.observation) rowError.observation = "Observation is required.";
    //   if (!row.recommendation) rowError.recommendation = "Recommendation is required.";
    //   if (!row.target_date) rowError.target_date = "Target date is required.";
    //   return rowError;
    // });

    // if (rowErrors.some((row) => Object.keys(row).length > 0)) {
    //   errors.rows = rowErrors;
    //   isValid = false;
    // }

    // Validate Attachments
    if (!attachmentFile || attachmentFile.length === 0) {
      errors.attachments = "At least one attachment is required.";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  // const handleSaveClick = async () => {
  //   setIsLoading(true); // Optional: show loading state

  //   // Validate the form before proceeding
  //   // if (!validateForm()) {
  //   //   setIsLoading(false);
  //   //   return;
  //   // }

  //   const formData = new FormData();

  //   // Append the necessary fields

  //   formData.append("reference_no", prRequestData.reference_no || "");
  //   formData.append("audit_type", prRequestData.audit_type || "");
  //   formData.append("month", prRequestData.month);
  //   formData.append("year", prRequestData.year);

  //   // Append material codes, quantities, etc.
  //   if (Array.isArray(rows) && rows.length > 0) {
  //     rows.forEach((row) => {
  //       if (row.point_no) formData.append("point_no[]", row.point_no);
  //       if (row.responsible_person_name) formData.append("responsible_person_name[]", row.responsible_person_name);
  //       if (row.person_email) formData.append("person_email[]", row.person_email);
  //       if (row.observation) formData.append("observation[]", row.observation);

  //       if (row.recommendation) formData.append("recommendation[]", row.recommendation);

  //       if (row.target_date) formData.append("target_date[]", row.target_date);

  //     });
  //   }

  //   // Append attachments
  //   if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
  //     attachmentFile.forEach((file) => {
  //       formData.append("attachments", file);
  //     });
  //   }

  //   // Append file types if applicable
  //   // if (Array.isArray(prRequestData.filetype)) {
  //   //   prRequestData.filetype.forEach((type) => {
  //   //     formData.append("filetype", type);
  //   //   });
  //   // }

  //   console.log("FormData to be sent:", formData);

  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const response = await axios.post(
  //       `${API_URL}/api/add_statutory_audit`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     console.log("Response message:", response.data.message);
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("PR Request added successfully");
  //       setTimeout(() => {
  //         navigate("/pr-request");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving PR request:", error.message);
  //     setSeverity("error");
  //     // showSnackbar("Missing item details");
  //     if (rows.length === 0) {
  //       showSnackbar("Please add at least one item to submit the PR request.");
  //     } else if (!prRequestData.department_id || !prRequestData.description) {
  //       showSnackbar(
  //         "Please add items in the Request Item Table before submitting."
  //       );
  //     } else {
  //       showSnackbar("Error saving PR request. Please try again.");
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  //   const handleSaveClick = async () => {
  //     setIsLoading(true); // Show loading state
  //     // Validate the form before proceeding
  //    if (!validateForm()) {
  //     setIsLoading(false);
  //     return;
  //  }

  //     const formData = new FormData();

  //     // Append primary fields
  //     formData.append("reference_no", prRequestData.reference_no || "");
  //     formData.append("audit_type", prRequestData.audit_type || "");
  //     formData.append("month", prRequestData.month || "");
  //     formData.append("year", prRequestData.year || "");

  //     // Prepare audit points as JSON string
  //     const auditPoints = rows.map((row) => ({
  //       point_no: row.point_no || "",
  //       responsible_person_name: row.responsible_person_name || "",
  //       person_email: row.person_email || "",
  //       observation: row.observation || "",
  //       recommendation: row.recommendation || "",
  //       target_date: row.target_date
  //         ? new Date(row.target_date).toISOString().split("T")[0]
  //         : "", // Format as YYYY-MM-DD
  //     }));
  //     formData.append("audit_points", JSON.stringify(auditPoints));

  //     // Append attachments
  //     if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
  //       attachmentFile.forEach((file) => {
  //         formData.append("attachments", file);
  //       });
  //     }

  //     console.log("FormData to be sent:", formData);

  //     try {
  //       const authKey = localStorage.getItem("authKey");
  //       const response = await axios.post(
  //         `${API_URL}/api/add_statutory_audit`,
  //         formData,
  //         {
  //           headers: {
  //             Authorization: authKey,
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       console.log("Response message:", response.data.message);
  //       if (response.status === 200) {
  //         setSeverity("success");
  //         showSnackbar("Statutory audit added successfully");
  //         setTimeout(() => {
  //           navigate("/statutory-audits");
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error saving audit request:", error.message);
  //       setSeverity("error");
  //       showSnackbar("Error saving audit request. Please try again.");
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  // const handleSaveClick = async () => {
  //   setIsLoading(true); // Show loading state

  //   // Validate form before proceeding
  //   if (!validateForm()) {
  //     setIsLoading(false);
  //     setSeverity("error");
  //     showSnackbar("Please fill in all required fields.", "error");
  //     return;
  //   }

  //   // Check if at least one item is added
  //   if (!Array.isArray(rows) || rows.length === 0) {
  //     setSeverity("error");
  //     showSnackbar("Please add at least one item to submit the audit request.");
  //     setIsLoading(false);
  //     return;
  //   }

  //   // Validate item fields in each row
  //   for (const row of rows) {
  //     if (
  //       !row.point_no ||
  //       !row.responsible_person_name ||
  //       !row.person_email ||
  //       !row.observation ||
  //       !row.recommendation ||
  //       !row.organization_type ||
  //       !row.target_date
  //     ) {
  //       setSeverity("error");
  //       showSnackbar(
  //         "All item fields (Report No, Organization Type, Person Name, Email, Target Date, Observation, Recommendation) must be filled."
  //       );
  //       setIsLoading(false);
  //       return;
  //     }
  //   }

  //   const formData = new FormData();
  //   formData.append("reference_no", prRequestData.reference_no);
  //   formData.append("audit_type", prRequestData.audit_type);
  //   formData.append("month", prRequestData.month);
  //   formData.append("year", prRequestData.year);

  //   const auditPoints = rows.map((row) => ({
  //     point_no: row.point_no,
  //     responsible_person_name: row.responsible_person_name,
  //     person_email: row.person_email,
  //     observation: row.observation,
  //     recommendation: row.recommendation,
  //     organization_type: row.organization_type,
  //     target_date: row.target_date
  //       ? new Date(row.target_date).toISOString().split("T")[0]
  //       : "",
  //   }));
  //   formData.append("audit_points", JSON.stringify(auditPoints));

  //   // Append attachments
  //   if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
  //     attachmentFile.forEach((file) => {
  //       formData.append("attachments", file);
  //     });
  //   }

  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const response = await axios.post(
  //       `${API_URL}/api/add_statutory_audit`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Statutory audit added successfully", "success");
  //       setTimeout(() => {
  //         navigate("/statutory-audits");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     setSeverity("error");
  //     showSnackbar("Error saving audit request. Please try again.", "error");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveClick = async () => {
    setIsLoading(true); // Show loading state
    setFieldErrors({}); // Reset field errors
  
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }
  
    let errors = {};
    let hasError = false;
  
    // Check if at least one item is added
    if (!Array.isArray(rows) || rows.length === 0) {
      setSeverity("error");
      showSnackbar("Please add at least one item to submit the audit request.", "error");
      setIsLoading(false);
      return;
    }
  
    // Validate each row
    rows.forEach((row, index) => {
      if (!row.point_no || !row.responsible_person_name || !row.person_email || !row.organization_type || !row.target_date || !row.observation || !row.recommendation) {
        errors[index] = {
          point_no: row.point_no ? "" : "Point Number is required",
          organization_type: row.organization_type ? "" : "Point Number is required",
          responsible_person_name: row.responsible_person_name ? "" : "Responsible Person Name is required",
          person_email: row.person_email ? "" : "Person Email is required",
          target_date: row.target_date ? "" : "Person Email is required",
          observation: row.observation ? "" : "Person Email is required",
          recommendation: row.recommendation ? "" : "Person Email is required",
        };
        hasError = true;
      }
    });
  
    // If there is an error, show a single message
    if (hasError) {
      setSeverity("error");
      showSnackbar("All fields are required.", "error");
      setFieldErrors(errors); // Update field errors for UI
      setIsLoading(false);
      return;
    }
  
    // Proceed with form submission if no errors
    const formData = new FormData();
    formData.append("reference_no", prRequestData.reference_no);
    formData.append("audit_type", prRequestData.audit_type);
    formData.append("month", prRequestData.month);
    formData.append("year", prRequestData.year);
  
    const auditPoints = rows.map((row) => ({
      point_no: row.point_no,
      responsible_person_name: row.responsible_person_name,
      person_email: row.person_email,
      observation: row.observation,
      recommendation: row.recommendation,
      organization_type: row.organization_type,
      target_date: row.target_date
        ? new Date(row.target_date).toISOString().split("T")[0]
        : "",
    }));
    formData.append("audit_points", JSON.stringify(auditPoints));
  
    // Append attachments
    if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
      attachmentFile.forEach((file) => {
        formData.append("attachments", file);
      });
    }
  
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.post(
        `${API_URL}/api/add_statutory_audit`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Statutory audit added successfully", "success");
        setTimeout(() => {
          navigate("/statutory-audits");
        }, 1000);
      }
    } catch (error) {
      setSeverity("error");
      showSnackbar("Error saving audit request. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSelectChange = (selectedOption) => {
    setPrRequestData({
      ...prRequestData,
      audit_type: selectedOption.value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      audit_type: "",
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };
  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    // Set the actual file objects in the state for FormData
    setAttachmentFile((prevFiles) => [
      ...(prevFiles || []),
      ...Array.from(files),
    ]);
    setErrors((prevErrors) => ({
      ...prevErrors,
      attachmentFile: "",
    }));
  };
  const handleIconClick = () => {
    setIsPopupOpen((prev) => !prev); // Toggle popup on button click
  };

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleDeleteFile = (indexToDelete) => {
    console.log("Before delete:", selectedFiles); // Debugging

    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state with filtered files
    setSelectedFiles(updatedFiles);

    console.log("After delete:", updatedFiles); // Debugging

    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
    }
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content" ref={containerRef}>
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Statutory Audit
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <div className="form-detail">
              <div className="new-data-form">
                <form className="form-detail123" onKeyDown={handleKeyDown}>
                  <div className="new-data-form123">
                    <div className="form-group">
                      <label htmlFor="reference_no">
                        Refrence no <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          min="1"
                          name="reference_no"
                          value={prRequestData.reference_no}
                          onChange={handleChange}
                          placeholder="Enter refrence no"
                        />
                      </div>
                      {errors.reference_no && (
                        <div className="error-message">
                          {errors.reference_no}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="month">
                        Month <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <DatePicker
                          selected={selectedMonth}
                          onChange={(date) => {
                            setSelectedMonth(date);
                            setPrRequestData((prevData) => ({
                              ...prevData,
                              month: date ? date.getMonth() + 1 : null, // Month as number
                            }));
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              month: "",
                            }));
                          }}
                          name="date_of_seizure"
                          id="date_of_seizure"
                          dateFormat="MM"
                          placeholderText="Select month"
                          className="datesss"
                          isClearable={false}
                          showMonthYearPicker
                          onKeyDown={(e) => {
                            if (e.key === "Backspace") {
                              setSelectedMonth(null);
                              setPrRequestData((prevData) => ({
                                ...prevData,
                                month: null,
                              }));
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                month: "",
                              }));
                            }
                          }}
                        />
                      </div>
                      {errors.month && (
                        <div className="error-message">{errors.month}</div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="year">
                        Year <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <DatePicker
                          selected={selectedYear}
                          onChange={(date) => {
                            setSelectedYear(date);
                            setPrRequestData((prevData) => ({
                              ...prevData,
                              year: date ? date.getFullYear() : null, // Year as number
                            }));
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              year: "",
                            }));
                          }}
                          name="date_of_seizure"
                          id="date_of_seizure"
                          dateFormat="yyyy"
                          placeholderText="Select year"
                          className="datesss"
                          isClearable={false}
                          showYearPicker
                          onKeyDown={(e) => {
                            if (e.key === "Backspace") {
                              setSelectedYear(null);
                              setPrRequestData((prevData) => ({
                                ...prevData,
                                year: null,
                              }));
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                year: "",
                              }));
                            }
                          }}
                        />
                      </div>
                      {errors.year && (
                        <div className="error-message">{errors.year}</div>
                      )}
                    </div>

                    <div className="form-group-select">
                      <label htmlFor="audit_type">
                        Type Of Audit <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="audit_type"
                        value={auditTypeOptions.find(
                          (option) => option.value === prRequestData.audit_type
                        )}
                        onChange={handleSelectChange}
                        options={auditTypeOptions}
                        placeholder="Select audit type"
                      />

                      {errors.audit_type && (
                        <div className="error-message">{errors.audit_type}</div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="attachement">
                        Uplod Audit Report{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <button
                        type="button"
                        className="challan-btn"
                        onClick={handleUploadButtonClick}
                        ref={buttonRef}
                      >
                        <FiUpload /> Upload File
                      </button>
                      <input
                        id="attachments"
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                        multiple
                      />
                      {selectedFiles.length > 0 && (
                        <span
                          className="inquiry-file-btn"
                          onClick={handleIconClick}
                          ref={buttonRef}
                        >
                          <MdAttachFile className="inquiry-file-icon" />{" "}
                          {`${selectedFiles.length}`}
                        </span>
                      )}
                      {isPopupOpen && (
                        <div className="attach-popup-amc4" ref={popupRef}>
                          <div className="attach-popup-contant">
                            <div className="attachment-header">
                              <div className="attachment-header-content">
                                Attachments
                              </div>
                              <button
                                className="fiile-close-btn"
                                onClick={handleCloseAttachment}
                              >
                                <IoCloseOutline className="file-close-icon" />
                              </button>
                            </div>
                            {selectedFiles.length > 0 && (
                              <div className="attach-scrroll-body">
                                {selectedFiles.map((file, index) => (
                                  <div
                                    className="attach-popup-contant-body"
                                    key={index}
                                  >
                                    <div className="attach-file-container">
                                      <div className="attach-file-show">
                                        <div className="text-attachment">
                                          {file.name}
                                        </div>
                                        <div className="text-attachment-file">
                                          File Size:(
                                          {(file.size / 1024).toFixed(2)} KB)
                                        </div>
                                      </div>
                                      <div
                                        className="file-delete-btn"
                                        onClick={() => handleDeleteFile(index)}
                                      >
                                        <span>
                                          {" "}
                                          <MdDeleteOutline className="file-delete-icon" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {errors.attachments && (
                        <div className="error-message">
                          {errors.attachments}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                  <label htmlFor="doc_name"></label>
                  <div className="input-group">
                    <div className="error-messages125">
                      File size must be less than 20MB.
                    </div>
                  </div>
                </div>
                  </div>
                </form>
                <div className="action-purchase-sheet-header">
                  <div className="actions-purchase-sheet-header-content">
                    <div className="actions-purchase-sheet-heading-content-left-side">
                      Audit Point
                    </div>
                    <div className="actions-purchase-heading-content-right-side"></div>
                  </div>
                </div>

                <div className="action-purchase-body">
                  <div className="action-purchase-scroll-body">
                    <div className="action-purchase-tabless">
                      <>
                        <table className="action-table action-multilevel-data ">
                          <thead className="action-purchase-heads">
                            <tr>
                              <th className="a-th1">
                                REPORT NO <span className="text-danger">*</span>
                              </th>
                              <th className="a-th2">
                                ORGANIZATION TYPE{" "}
                                <span className="text-danger">*</span>
                              </th>
                              <th className="a-th2">
                                PERSON <span className="text-danger">*</span>
                              </th>
                              <th className="a-th3">
                                EMAIL ID <span className="text-danger">*</span>
                              </th>

                              <th className="a-th4">
                                TARGET DATE{" "}
                                <span className="text-danger">*</span>
                              </th>

                              <th className="a-th5">
                                OBSERVATION
                                <span className="text-danger">*</span>
                              </th>
                              <th className="a-th6">
                                RECOMMENDATION
                                <span className="text-danger">*</span>
                              </th>
                              <th className="a-th7">ACTION</th>
                            </tr>
                          </thead>

                          <tbody className="action-purchase-scroll-bodys-audit">
                            {rows.map((row, index) => (
                              <tr key={index}>
                                {/* <td className="a-th1 audit-td"> */}
                                <td
                                  className={
                                    fieldErrors[index]?.point_no
                                      ? "error-field"
                                      : "a-th1 audit-td"
                                  }
                                >
                                  <input
                                    type="text"
                                    min="0"
                                    value={row.point_no || ""} // Ensure empty input is allowed
                                    onChange={(e) =>
                                      handleAuditPoint(
                                        index,
                                        "point_no",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Enter report no"
                                  />
                                </td>
                                {/* <td className="a-th1 audit-td-select"> */}
                                <td
                                  className={
                                    fieldErrors[index]?.organization_type
                                      ? "error-field-select"
                                      : "a-th1 audit-td-select"
                                  }
                                >
                                  <Select
                                    name="audit_type"
                                    value={organizationOptions.find(
                                      (option) =>
                                        option.value === rows.organization_type
                                    )}
                                    onChange={(selectedOption) =>
                                      handleAuditPoint(
                                        index,
                                        "organization_type",
                                        selectedOption
                                          ? selectedOption.value
                                          : ""
                                      )
                                    }
                                    options={organizationOptions}
                                    placeholder="Select organization type"
                                  />
                                </td>
                                {/* <td className="a-th2 audit-td"> */}
                                <td
                                  className={
                                    fieldErrors[index]?.responsible_person_name
                                      ? "error-field"
                                      : "a-th2 audit-td"
                                  }
                                >
                                  <input
                                    type="text"
                                    value={row.responsible_person_name} // Ensure empty input is allowed
                                    onChange={(e) =>
                                      handleAuditPoint(
                                        index,
                                        "responsible_person_name",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Enter person name"
                                  />
                                </td>
                                {/* <td className="a-th3 audit-td"> */}
                                <td
                                  className={
                                    fieldErrors[index]?.person_email
                                      ? "error-field"
                                      : "a-th3 audit-td"
                                  }
                                >
                                  <input
                                    type="email"
                                    value={row.person_email} // Ensure empty input is allowed
                                    onChange={(e) =>
                                      handleAuditPoint(
                                        index,
                                        "person_email",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Enter email"
                                  />
                                </td>
                                {/* <td className="a-th4 audit-td"> */}
                                <td
                                  className={
                                    fieldErrors[index]?.target_date
                                      ? "error-fields"
                                      : "a-th4 audit-td"
                                  }
                                >
                                  {/* <input
                                    type="text"
                                    value={row.target_date} // Ensure empty input is allowed
                                    onChange={(e) => handleAuditPoint(index, "target_date", e.target.value)}
                                    placeholder="Enter report no"
                                  /> */}
                                  {/* <DatePicker
                      selected={selectedDate}
                      onChange={(e) => handleAuditPoint(index, "target_date", e.target.value)}
                      name="target_date"
                      dateFormat="yyyy/MM/dd"
                      placeholderText="Select date"
                      className="datesss"
                      onKeyDown={(e) => {
                        if (e.key === "Backspace") {
                          setSelectedDate(null);
                        }
                      }}
                    /> */}
                                  <DatePicker
                                    selected={
                                      rows[index]?.target_date
                                        ? new Date(rows[index].target_date)
                                        : null
                                    }
                                    onChange={(date) =>
                                      handleAuditPoint(
                                        index,
                                        "target_date",
                                        date
                                          ? date.toISOString().split("T")[0]
                                          : ""
                                      )
                                    }
                                    name="target_date"
                                    // dateFormat="yyyy-MM-dd"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select date"
                                    className="datesss"
                                    onKeyDown={(e) => {
                                      if (e.key === "Backspace") {
                                        handleAuditPoint(
                                          index,
                                          "target_date",
                                          ""
                                        );
                                      }
                                    }}
                                  />
                                </td>
                                {/* <td className="a-th5 audit-td"> */}
                                <td
                                  className={
                                    fieldErrors[index]?.observation
                                      ? "error-field"
                                      : "a-th5 audit-td"
                                  }
                                >
                                  <input
                                    type="text"
                                    min="0"
                                    value={row.observation} // Ensure empty input is allowed
                                    onChange={(e) =>
                                      handleAuditPoint(
                                        index,
                                        "observation",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Enter observation no"
                                  />
                                </td>
                                {/* <td className="a-th6 audit-td"> */}
                                <td
                                  className={
                                    fieldErrors[index]?.recommendation
                                      ? "error-field"
                                      : "a-th6 audit-td"
                                  }
                                >
                                  <input
                                    type="text"
                                    min="0"
                                    value={row.recommendation} // Ensure empty input is allowed
                                    onChange={(e) =>
                                      handleAuditPoint(
                                        index,
                                        "recommendation",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Enter recommendation no"
                                  />
                                </td>

                                <td className="a-th7">
                                  <button
                                    className="delete-row"
                                    onClick={() => {
                                      const newRows = rows.filter(
                                        (_, i) => i !== index
                                      );
                                      setRows(newRows);
                                    }}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="row">
                          <div className="action-caluculation">
                            <div className="new-rows">
                              <button
                                onClick={handleAddRow}
                                className="add-rows"
                              >
                                <FiPlus className="add-row-icon" /> Add New Row
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>

              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={15000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(50px)", marginLeft: "25px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default AuditForm;
