import React, { useState, useEffect } from "react";
// import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function TemplategetForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState({});
  const navigate = useNavigate();
  const [selectedPermitType, setSelectedPermitType] = useState(null);
  const [permitType, setPermitType] = useState([]);
  const [permitFields, setPermitFields] = useState([]);
  const [dateValues, setDateValues] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [formData, setFormData] = useState({
    permit_type_id: "",
    field_name: "",
    field_type: "",
    field_label: "",
    is_required: false,
  });

  const handlePermitTypeChange = async (selectedOption) => {
    setSelectedPermitType(selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      permit_type_id: selectedOption ? selectedOption.value : "",
    }));

    if (selectedOption) {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_permit_fields/${selectedOption.value}`,
          {
            headers: { Authorization: authKey },
          }
        );

        if (response.status === 200) {
          const fields = response.data.fields;
          setPermitFields(fields);

          // Fetch data for dropdown fields
          const dropdownFields = fields.filter(
            (field) => field.field_type === "dropdown" && field.api_endpoint
          );

          const optionsData = {};
          await Promise.all(
            dropdownFields.map(async (field) => {
              try {
                const dropdownResponse = await axios.get(
                  `${API_URL}/${field.api_endpoint}`,
                  {
                    headers: { Authorization: authKey },
                  }
                );

                if (dropdownResponse.status === 200) {
                  // Handle different API response formats
                  const responseData = dropdownResponse.data;

                  optionsData[field.id] = Array.isArray(responseData)
                    ? responseData.map((item) => ({
                        value: item.ID,
                        label:
                          item.DepartmentName ||
                          item.TagNo ||
                          item.Category ||
                          "Unknown",
                      }))
                    : responseData.Items?.map((item) => ({
                        value: item.ID,
                        label: item.TagNo || item.Category || "Unknown",
                      })) || [];
                }
              } catch (error) {
                console.error(
                  `Error fetching options for ${field.field_name}:`,
                  error
                );
              }
            })
          );

          setDropdownOptions(optionsData);
        }
      } catch (error) {
        console.error("Error fetching permit fields:", error.message);
      }
    }
  };

  const fetchPermitType = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_work_permit_types`, {
        headers: {
          Authorization: authKey,
        },
      });

      const permitTypeOptions = response.data.work_permit_types.map(
        (permitType) => ({
          value: permitType.id,
          label: permitType.work_permit_name,
        })
      );

      setPermitType(permitTypeOptions);
    } catch (error) {
      console.error("Error fetching customers data:", error.message);
    }
  };

  useEffect(() => {
    fetchPermitType();
  }, []);

  const handleCancelClick = () => {
    navigate("/department");
  };

  // const handleSaveClick = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     // Collect form values
  //     const payload = {
  //       department_id:
  //         selectedOptions["588d83aa-d33e-45b9-8e09-04f25566c86f"]?.value || "", // Department
  //       permit_type_id: selectedPermitType?.value || "",
  //       section: formData["Section"] || "",
  //       location: formData["Location"] || "",
  //       receiver_name: formData["Receiver Name"] || "",
  //       request_status: "Draft",
  //       area: formData["Area"] || "",
  //       equipment_no:
  //         selectedOptions["14df21f1-6ceb-4e85-af46-53671560d9e3"]?.value || "", // Equipment No
  //       work_description: formData["Description of work"] || "",
  //       issued_to:
  //         selectedOptions["52997de4-b52b-452f-976e-9866159f846b"]?.value || "", // Issued to
  //       contractor: formData["Contractor"] || "",
  //       unit: formData["Unit"] || "",
  //       valid_from: dateValues["d7d98bc6-4ae1-4d04-a849-fb680850dfc1"]
  //         ? new Date(dateValues["d7d98bc6-4ae1-4d04-a849-fb680850dfc1"])
  //             .toISOString()
  //             .replace("T", " ")
  //             .split(".")[0]
  //         : "",
  //       valid_to: dateValues["46b2f7af-54a4-41ed-ba1b-93b9fb529af2"]
  //         ? new Date(dateValues["46b2f7af-54a4-41ed-ba1b-93b9fb529af2"])
  //             .toISOString()
  //             .replace("T", " ")
  //             .split(".")[0]
  //         : "",
  //       equipment_ids: [], // Add if multiple equipment selections are needed
  //     };

  //     // Validate mandatory fields
  //     // if (!payload.department_id || !payload.section || !payload.location || !payload.issued_to || !payload.valid_from || !payload.valid_to) {
  //     //   showSnackbar("All mandatory fields are required");
  //     //   setSeverity("error");
  //     //   return;
  //     // }

  //     const response = await axios.post(
  //       `${API_URL}/api/add_permit_with_work_details`,
  //       payload,
  //       { headers: { Authorization: authKey } }
  //     );

  //     if (response.status === 201) {
  //       setSeverity("success");
  //       showSnackbar("Permit field added successfully");
  //     }
  //   } catch (error) {
  //     setSeverity("error");
  //     showSnackbar("Error adding permit field");
  //     console.error("Error:", error.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Construct payload dynamically based on permitFields
      const payload = permitFields.reduce((acc, field) => {
        if (field.field_type === "dropdown") {
          acc[field.field_label] = selectedOptions[field.id]?.value || "";
        } else if (field.field_type === "date") {
          acc[field.field_label] = dateValues[field.id]
            ? new Date(dateValues[field.id]).toISOString().replace("T", " ").split(".")[0]
            : "";
        } else {
          acc[field.field_label] = formData[field.field_name] || "";
        }
        return acc;
      }, {});

      payload["request_status"] = "Draft";

      const response = await axios.post(
        `${API_URL}/api/add_permit_with_work_details`,
        payload,
        { headers: { Authorization: authKey } }
      );

      if (response.status === 201) {
        setSeverity("success");
        showSnackbar("Permit field added successfully");
      }
    } catch (error) {
      setSeverity("error");
      showSnackbar("Error adding permit field");
      console.error("Error:", error.message);
    } finally {
      setIsLoading(false);
    }
};


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                Work Permit Form
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
          <form className="form-detail" onKeyDown={handleKeyDown}>
  <div className="new-data-form">
    <div className="form-group-select">
      <label htmlFor="form_type">
        Permit Type<span className="text-danger">*</span>
      </label>
      <Select
        id="form_type"
        options={permitType}
        value={selectedPermitType}
        onChange={handlePermitTypeChange}
        placeholder="Select permit type"
      />
    </div>

    <div className="permit-fields-container">
      {permitFields.map((field) => (
        <div
          key={field.id}
          className={
            field.field_type === "dropdown" ? "form-group-select" : "form-group"
          }
        >
          <label>
            {field.field_name}
            {field.is_required ? <span className="text-danger">*</span> : ""}
          </label>

          {field.field_type === "text" && (
            <div className="input-group">
              <input
                type="text"
                name={field.field_name}
                className="form-control"
                value={formData[field.field_label] || ""}
                required={field.is_required}
                placeholder={field.placeholder}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [field.field_name]: e.target.value,
                  })
                }
              />
            </div>
          )}

          {field.field_type === "number" && (
            <div className="input-group">
              <input
                type="number"
                name={field.field_name}
                className="form-control"
                value={formData[field.field_label] || ""}
                required={field.is_required}
                placeholder={field.placeholder}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [field.field_name]: e.target.value,
                  })
                }
              />
            </div>
          )}

          {field.field_type === "textarea" && (
            <div className="input-group">
              <textarea
                name={field.field_name}
                className="form-control"
                value={formData[field.field_label] || ""}
                required={field.is_required}
                placeholder={field.placeholder}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [field.field_name]: e.target.value,
                  })
                }
              />
            </div>
          )}

          {field.field_type === "date" && (
            <div className="input-group">
              <DatePicker
                selected={dateValues[field.id] || null}
                onChange={(date) =>
                  setDateValues({ ...dateValues, [field.id]: date })
                }
                className="form-control"
                placeholderText="Select a date"
                required={field.is_required}
                placeholder={field.placeholder}
              />
            </div>
          )}

          {field.field_type === "dropdown" && (
            <Select
              options={
                dropdownOptions[field.id] ||
                field.options?.map((opt) => ({
                  value: opt,
                  label: opt,
                }))
              }
              value={selectedOptions[field.id] || null}
              onChange={(selected) =>
                setSelectedOptions({
                  ...selectedOptions,
                  [field.id]: selected,
                })
              }
              isClearable
              placeholder={field.placeholder}
            />
          )}

          {field.field_type === "checkbox" && (
            <input
              type="checkbox"
              name={field.field_name}
              className="form-check-input"
              checked={formData[field.field_name] || false}
              required={field.is_required}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  [field.field_name]: e.target.checked,
                })
              }
            />
          )}
        </div>
      ))}
    </div>
  </div>
</form>

          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default TemplategetForm;
