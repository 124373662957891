import React from "react";
import "./HotWorkPermitTemplate.css";
import HotWorkTemplate1 from "./HotWorkTemplate1";
import HotWorkTemplate2 from "./HotWorkTemplate2";
import HotWorkTemplate3 from "./HotWorkTemplate3";
import HotWorkTemplate4 from "./HotWorkTemplate4";
import HotWorkTemplate5 from "./HotWorkTemplate5";
import HotWorkTemplate6 from "./HotWorkTemplate6";
import HotWorkTemplate7 from "./HotWorkTemplate7";
import HotWorkTemplate8 from "./HotWorkTemplate8";
import HotWorkTemplate9 from "./HotWorkTemplate9";

const HotWorkPermitTemplate = ({ permitDetails, isSidebarExpanded }) => {
  const {
    checklistItems = [{}],
    workDetails = {},
    form_data: formData = {},
    hazardsPrecautions = [{}],
    signatures = [{}],
    clearanceRenewal = [{}],
    permitClosure = {},
    generalInstructions = [],
  } = permitDetails || {};

  return (
   <>
        <div id="section1">
           <div className="hot-work-template">
           <div className="hot-work-template-containere">
          <HotWorkTemplate1 />
          <HotWorkTemplate2
            workDetails={workDetails}
            isSidebarExpanded={isSidebarExpanded}
          />
          <HotWorkTemplate3 />
          <HotWorkTemplate4
               signatures={signatures}
               formData={formData}
            checklist={checklistItems} // Passing checklistItems correctly
            isSidebarExpanded={isSidebarExpanded}
          />
          <HotWorkTemplate5
            hazardsPrecautions={hazardsPrecautions}
            isSidebarExpanded={isSidebarExpanded}
          />
          <HotWorkTemplate6
            signatures={signatures}
            formData={formData}
            workDetails={workDetails}
            isSidebarExpanded={isSidebarExpanded}
          />
        </div>
        </div>
        </div>
        <div id="section2">
        <div className="hot-work-template">
        <div className="hot-work-template-containere">
          <HotWorkTemplate7
            hazardsPrecautions={hazardsPrecautions}
            isSidebarExpanded={isSidebarExpanded}
          />
          <HotWorkTemplate8
            clearanceRenewal={clearanceRenewal}
            workDetails={workDetails}
            isSidebarExpanded={isSidebarExpanded}
          />
          <HotWorkTemplate9
            permitClosure={permitClosure}
            workDetails={workDetails}
          />
        </div>
        </div>
        </div>
        </>
  );
};

export default HotWorkPermitTemplate;
