import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns"; // Import format from date-fns
import { RxCrossCircled } from "react-icons/rx";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import { setHours, setMinutes } from "date-fns";

function PermitRequestFormEdit({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { work_permit_id } = useParams();
  const location = useLocation();
  const { issuer } = location.state || {};
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [issuedTo, setIssuedTo] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDepartment, setShowDepartment] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [permitType, setPermitType] = useState([]);
  const [selectedPermitType, setSelectedPermitType] = useState(null);
  const [addedTags, setAddedTags] = useState([]);
  const [showContractor, setShowContractor] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedTagNo, setSelectedTagNo] = useState(null);
  const [formData, setFormData] = useState({
    section: "",
    department_id: "",
    form_id: "",
    area: "",
    equipment_no: "",
    issued_to: "",
    work_description: "",
    contractor: "",
    unit: "",
    location: "",
    valid_from: "",
    valid_to: "",
    receiver_name: "",
    permit_type_id: "",
    work_permit_id: "",
  });
  const [issuedToOptions] = useState([
    { value: "department", label: "Department" },
    { value: "section", label: "Section" },
    { value: "contractor", label: "Contractor" },
  ]);

  const handleIssuedToChange = (selectedOption) => {
    setIssuedTo(selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      issued_to: selectedOption ? selectedOption.value : "", 
    }));

    setShowDepartment(selectedOption?.value === "department");
    setShowSection(selectedOption?.value === "section");
    setShowContractor(selectedOption?.value === "contractor");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  useEffect(() => {
    const fetchPermitDetails = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_permit_work_details/${work_permit_id}`,
          {
            headers: { Authorization: authKey },
          }
        );
  
        const permit = response.data.permit_details;
  
        if (permit) {
          // Update formData with the fetched data
          setFormData({
            area: permit.area || "",
            contractor: permit.contractor || "",
            department_id: permit.department_id || "",
            department_name: permit.department_name || "",
            equipment_no: permit.equipment_no || "",
            issued_to: permit.issued_to || "",
            location: permit.location || "",
            permit_type_id: permit.permit_type_id || "",
            receiver_name: permit.receiver_name || "",
            section: permit.section || "",
            unit: permit.unit || "",
            work_permit_name: permit.work_permit_name || "",
            work_description: permit.work_description || "",
            permit_id: permit.permit_id || "",
          });
  
          const formatDate = (dateString) => {
            const date = new Date(dateString);
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
            return date;
          };
  
          setStartDate(permit.valid_from ? formatDate(permit.valid_from) : null);
          setEndDate(permit.valid_to ? formatDate(permit.valid_to) : null);
  
          setShowDepartment(!!permit.department_id);
          setShowSection(!!permit.section);
          setShowContractor(!!permit.contractor);
  
          if (Array.isArray(permit.equipment_details)) {
            // setExistingEquipmentIds(
            //   permit.equipment_details.map(
            //     (equipment) => equipment.equipment_id
            //   )
            // );
  
            setAddedTags(
              permit.equipment_details.map((equipment) => ({
                id: equipment.equipment_id,
                tagNo: equipment.TagNo,
              }))
            );
          } else {
            console.warn("No equipment details found or it's not an array.");
          }
        } else {
          console.warn("No permit details found.");
        }
      } catch (error) {
        console.error("Error fetching permit details:", error);
      }
    };
  
    fetchPermitDetails();
  }, [work_permit_id]);
  

  const handleSaveClick = async () => {
    const errors = {};

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      showSnackbar("Please fill all required fields.");
      return; 
    }
    // Format dates
    const formattedValidFrom = startDate
      ? format(startDate, "yyyy-MM-dd HH:mm:ss")
      : "";
    const formattedValidTo = endDate
      ? format(endDate, "yyyy-MM-dd HH:mm:ss")
      : "";

    const combinedEquipmentIds = [...addedTags.map((tag) => tag.id)];

    const payload = {
      ...formData,
      valid_from: formattedValidFrom,
      valid_to: formattedValidTo,
      equipment_ids: combinedEquipmentIds, 
      id: work_permit_id,
    };

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/update_permit_work_details`,
        payload,
        {
          headers: { Authorization: authKey },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Permit details updated successfully.");
        setTimeout(() => {
          if (issuer === "issuer") {
            navigate(
              `/cold-work-permit-form/${work_permit_id}?tab=Checklist Items`
            );
          } else {
            navigate(`/permit-requests`);
          }
        }, 1000);
      } else {
        throw new Error(
          response.data.message || "Failed to update permit details."
        );
      }
    } catch (error) {
      console.error("Error saving permit details:", error);
      setSeverity("error");
      showSnackbar("An error occurred while saving permit details.");
    } finally {
      setIsLoading(false);
    }
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCancelClick = () => {
    navigate("/permit-requests");
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setFormData((prevUserData) => ({
      ...prevUserData,
      department_id: selectedOption ? selectedOption.value : "",
      department_name: selectedOption ? selectedOption.label : "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      department: null,
    }));
  };


  const fetchOptions = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_equipment_tag_no`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      const optionsData = response.data.Items.map((item) => ({
        value: item.ID,
        label: `${item.TagNo}`,
      }));

      setTagOptions(optionsData);
      if (optionsData.length === 0) {
        console.warn("No options available from API");
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  // const handleTagNoChange = (selectedOption) => {
  //   setSelectedTagNo(selectedOption);
  // };
  const handleTagNoChange = (selectedOption) => {
    setSelectedTagNo(selectedOption);
  
    // Automatically add the selected tag
    if (selectedOption) {
      console.log("Selected Tag No:", selectedOption); // Log the selected tag details
      setAddedTags((prevTags) => {
        const updatedTags = [
          ...prevTags,
          { tagNo: selectedOption.label, id: selectedOption.value },
        ];
        console.log("Updated Added Tags:", updatedTags); // Log the updated added tags
        return updatedTags;
      });
      setSelectedTagNo(null);
    } else {
      console.log("No tag selected."); // Log if no tag was selected
    }
  };

  

  const handleAddTag = () => {
    if (selectedTagNo) {
      setAddedTags((prevTags) => {
        const updatedTags = [
          ...prevTags,
          { tagNo: selectedTagNo.label, id: selectedTagNo.value }, 
        ];
        setSelectedTagNo(null);
        return updatedTags; 
      });
    }
  };

  const handleRemoveTag = (tagId) => {
    setAddedTags((prevTags) => prevTags.filter((tag) => tag.id !== tagId));
  };


  const fetchPermitType = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_work_permit_types`, {
        headers: {
          Authorization: authKey,
        },
      });

      const permitTypeOptions = response.data.work_permit_types.map(
        (permitType) => ({
          value: permitType.id,
          label: permitType.work_permit_name,
        })
      );

      setPermitType(permitTypeOptions);
    } catch (error) {
      console.error("Error fetching customers data:", error.message);
    }
  };

  useEffect(() => {
    fetchPermitType();
  }, []);

  const handlePermitTypeChange = (selectedOption) => {
    setSelectedPermitType(selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      permit_type_id: selectedOption ? selectedOption.value : "",
    }));
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={() => navigate("/permit-requests")}
                  />
                </Tooltip>
                Edit Cold Work Permit Request
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="form_type">
                    Permit Type<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="form_type"
                    options={permitType}
                    value={permitType.find(
                      (option) => option.value === formData.permit_type_id
                    )}
                    onChange={handlePermitTypeChange}
                    placeholder="Select permit type"
                  />

                  {errors.form_type && (
                    <div className="error-message">{errors.form_type}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="receiver_name">
                    Receiver Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="receiver_name"
                      value={formData.receiver_name}
                      onChange={handleInputChange}
                      placeholder="Enter name"
                    />
                  </div>
                  {errors.receiver_name && (
                    <div className="error-message">{errors.receiver_name}</div>
                  )}
                </div>
                <div className="form-group">
                  <label
                    htmlFor="DepartmentName"
                    style={{ fontWeight: "bold" }}
                  >
                    Work clearance from
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentName">Start Date & Time</label>
                  <div className="input-group">
                    <DatePicker
                      id="start-date"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showTimeSelect
                         dateFormat="dd/MM/yyyy hh:mm a"
                      placeholderText="Select start date and time"
                      className="datepicker"
                      minDate={new Date()}
                      // minTime={
                      //   startDate && startDate.toDateString() === new Date().toDateString()
                      //     ? new Date() // Disable past times for today
                      //     : setHours(setMinutes(new Date(), 0), 0) // Allow full range on future dates
                      // }
                      // maxTime={setHours(setMinutes(new Date(), 59), 23)}
                      minTime={
                        startDate && startDate.toDateString() === new Date().toDateString()
                          ? new Date() // Disable past times for today
                          : setHours(setMinutes(new Date(), 0), 0) // Allow all times for future dates
                      }
                      maxTime={setHours(setMinutes(new Date(), 59), 23)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentName">End Date & Time</label>
                  <div className="input-group">
                    <DatePicker
                      id="end-date"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      showTimeSelect
                        dateFormat="dd/MM/yyyy hh:mm a"
                      placeholderText="Select end date and time"
                      className="datepicker"
                      // minDate={startDate} 
                      // minTime={
                      //   startDate && startDate.toDateString() === new Date().toDateString()
                      //     ? new Date() // Disable past times for today
                      //     : setHours(setMinutes(new Date(), 0), 0) // Allow full range on future dates
                      // }
                      // maxTime={setHours(setMinutes(new Date(), 59), 23)}
                      minDate={startDate || new Date()} // Ensures the end date is today or later
                      minTime={
                        endDate &&
                        startDate &&
                        endDate.toDateString() === startDate.toDateString()
                          ? startDate // Prevents selecting time before the start time on the same day
                          : setHours(setMinutes(new Date(), 0), 0) // Allow all times for future dates
                      }
                      maxTime={setHours(setMinutes(new Date(), 59), 23)}
                    />
                  </div>
                </div>
                <div className="form-group-select">
                  <label htmlFor="issued_to">
                    Issued to:
                  </label>
                  <Select
                    id="issued_to"
                    options={issuedToOptions}
                    value={issuedToOptions.find(
                      (option) => option.value === formData.issued_to
                    )} 
                    onChange={handleIssuedToChange}
                    placeholder="Select Issued To"
                    isClearable
                  />
                </div>
                {showDepartment && (
                  <div className="form-group-select">
                    <label htmlFor="department-select">Department</label>
                    <Select
                      id="department-select"
                      options={departments}
                      value={
                        departments.find(
                          (dept) => dept.value === formData.department_id
                        ) || null
                      }
                      onChange={handleDepartmentChange}
                      placeholder="Select Department"
                      isClearable
                    />
                  </div>
                )}

                {showSection && (
                  <div className="form-group">
                    <label htmlFor="">Section</label>

                    <div className="input-group">
                      <input
                        type="text"
                        name="section"
                        value={formData.section}
                        onChange={handleInputChange}
                        placeholder="Enter section"
                      />
                    </div>
                  </div>
                )}

                {showContractor && (
                  <div className="form-group">
                    <label htmlFor="contractor">Contractor</label>

                    <div className="input-group">
                      <input
                        type="text"
                        name="contractor"
                        value={formData.contractor}
                        onChange={handleInputChange}
                        placeholder="Enter contractor"
                      />
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <label
                    htmlFor="area-unit-select"
                    style={{ fontWeight: "bold" }}
                  >
                    Exact Location of work
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="area">Area</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="area"
                      value={formData.area}
                      onChange={handleInputChange}
                      placeholder="Enter area"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="unit">Unit</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="unit"
                      value={formData.unit}
                      onChange={handleInputChange}
                      placeholder="Enter unit"
                    />
                  </div>
                </div>

                <div className="form-group-tag-no">
                  <div className="form-group-select form-group-tag-no">
                    <label htmlFor="ContactNo">Equipment No</label>
                    <Select
                      id="TagNo"
                      options={tagOptions}
                      value={selectedTagNo}
                      onChange={handleTagNoChange}
                      placeholder="Select Equipment No"
                      isSearchable
                    />
                    {/* <button
                      className="new-button"
                      type="button"
                      onClick={handleAddTag}
                    >
                      ADD
                    </button> */}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor=""></label>
                  <div className="input-group-4">
                    <div className="added-tags">
                      {addedTags.map((tag) => (
                        <>
                          <div key={tag.id} className="added-tag-item">
                            <span>{tag.tagNo}</span>
                          </div>
                          <button
                            className="remove-btn"
                            onClick={() => handleRemoveTag(tag.id)}
                          >
                            <RxCrossCircled className="remove-btn-icon" />
                          </button>
                        </>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="work_description">Description of work</label>
                  <div className="input-group permit-input">
                    <textarea
                      name="work_description"
                      rows={4}
                      cols={5}
                      value={formData.work_description}
                      onChange={handleInputChange}
                      className="textarea"
                      placeholder="Enter description"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PermitRequestFormEdit;
