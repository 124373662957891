import React from "react";
import isprl from "../isprl.png";  
const Template1 = () => {
  return (
    <>
        <div className="hot-template-container">
          <div className="hot-template-container-content">
            <div className="template-container-first">
              {/* <img src={isprl} alt="Example" /> */}
              <img src={isprl} alt="ISPRL Logo" className="isprl-img" />
            </div>
            <div className="template-container-second">
            <div className="line1">इंडियन स्ट्रेटेजिक पेट्रोलियम रिज़र्वस लिमिटेड</div>
            <div className="line2">INDIAN STRATEGIC PETROLEUM RESERVES LIMITED</div>
            <div className="line3">स्ट्रेटेजिक क्रूड स्टोरेज फैसिलिटी, पादूर</div>
            <div className="line4">STRATEGIC CRUDE STORAGE FACILITY, PADUR</div>
            <div className="line5">HOT WORK PERMIT</div>
            <div className="line6">(ओ.आई. एस. डी. के अनुसार-१०५ अनुबंध -१) (As per OISD STD-105, Annexure-1)</div>
           
            </div>
            <div className="template-container-third">
            <div className="line11">क्रम सं /SL.NO.  <div className="prno"></div></div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Template1;
