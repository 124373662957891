import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../Config";
import DatePicker from "react-datepicker";
import { FiUpload } from "react-icons/fi";
import "react-datepicker/dist/react-datepicker.css";
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";

function EditReturnable({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [departmentData, setDepartmentData] = useState({
    DepartmentName: "",
  });
  const fileInputRef = useRef(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    date: null,
    description: "",
    attachments: [],
  });
  const handleUploadButtonClick = () => {
    // Ensure the ref is not null before triggering the click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is null");
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // setBreakdowntData((prevState) => ({
    //   ...prevState,
    //   date: date,
    // }));

    // Clear schedule start date error when it changes
    setErrors((prevErrors) => ({
      ...prevErrors,
      date: null,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };


  
  useEffect(() => {
    const fetchReturnableItem = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_returnable_item/${id}`, {
          headers: {
            Authorization: authKey,
          },
        });

        if (response.data) {
          const { date, description } = response.data;
          
          // Convert the date to a JavaScript Date object
          setSelectedDate(date ? new Date(date) : null);
          setDescription(description || "");

          const formattedFiles =
            response.data.attachments?.map((file) => ({
              file_id: file.file_id,
              filename: file.filename,
              file_size: file.file_size
                ? (file.file_size / 1024).toFixed(2) + " KB"
                : "Unknown size",
              file_type: file.file_type,
            })) || [];
  
          // setSelectedFiles(formattedFiles);
          // setSelectedFiles(response.data.attachments);
          // setSelectedFiles(response.data.attachments || []);
          // const attachmentFiles = response.data.attachments || [];
          // setSelectedFiles(attachmentFiles);
          const attachmentFiles = response.data.attachments || [];
          setSelectedFiles(attachmentFiles);
        }
      } catch (error) {
        console.error("Error fetching returnable item:", error);
      }
    };

    if (id) fetchReturnableItem();
  }, [id]);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const validateForm = () => {
    const validationErrors = {};
    if (!selectedDate) {
      validationErrors.date = "Date is required.";
    }
 
    if (selectedFiles.length === 0) {
      validationErrors.attachment = "At least one attachment is required.";
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };


  // const handleSaveClick = async () => {
  //   if (!validateForm()) {
  //     return;
  //   }

  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");
  //     const response = await axios.put(
  //       `${API_URL}/api/update_returnable_item/${id}`,
  //       departmentData,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );
  //     console.log(response.data.message);
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Data updated successfully");
  //       setTimeout(() => {
  //         navigate("/department");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error updating company:", error.message);
  //     setErrors("An error occurred while updating company data");
  //     setIsLoading(false);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveClick = async () => {
    console.log("Save button clicked");
  
    // if (!validateForm()) {
    //   console.log("Validation failed:", errors);
    //   return;
    // }
  
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Prepare FormData for multipart request
      const formData = new FormData();
      formData.append("item_id", id);
      formData.append("date", selectedDate ? selectedDate.toISOString().split("T")[0] : "");
      formData.append("description", description);

   
      // selectedFiles.forEach((file) => {
      //   formData.append("attachments", file); // No need to add file.name separately
      // });
      // selectedFiles.forEach((file) => formData.append("attachments", file));
  
      // if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
      //   attachmentFile.forEach((file) => {
      //     formData.append("attachments", file);
      //   });
      // }

      if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
        attachmentFile.forEach((file) => {
          formData.append("attachments", file);
        });
      }
      
      // Append files (if any)
     
      const response = await axios.put(`${API_URL}/api/update_returnable_item`, formData, {
        headers: {
          Authorization: authKey,
          "Content-Type": "multipart/form-data",
        },
      });

  
      console.log("API Response:", response);
  
      if (response.status === 200) {
        console.log("Success! Data updated.");
        setSeverity("success");
        showSnackbar("Data updated successfully");
        setTimeout(() => {
          navigate("/returnable-items");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating returnable item:", error);
      setErrors("An error occurred while updating returnable item");
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleBack = () => {
    navigate("/returnable-items");
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    // Set the actual file objects in the state for FormData
    setAttachmentFile((prevFiles) => [
      ...(prevFiles || []),
      ...Array.from(files),
    ]);
  };
  const handleIconClick = () => {
    setIsPopupOpen((prev) => !prev); // Toggle popup on button click
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleDeleteFile = (indexToDelete) => {
    console.log("Before delete:", selectedFiles); // Debugging

    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state with filtered files
    setSelectedFiles(updatedFiles);

    console.log("After delete:", updatedFiles); // Debugging

    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
    }
    setAttachmentFile("");
  };
  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBack}
                  />{" "}
                </Tooltip>
                Edit Returnable Item
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="" onKeyDown={handleKeyDown}>
              <div className="new-data-edit-form">
                <div className="form-group">
                  <label htmlFor="DepartmentName">
                    Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                    
                      name="date"
                      id="date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {errors.date && (
                    <div className="error-message">{errors.date}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <div className="input-group">
                    <textarea
                      type="text"
                      name="description"
                      value={description}
                      onChange={handleDescriptionChange }
                      placeholder="Enter description"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="attachement">
                    Attachment <span className="text-danger">*</span>
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={handleUploadButtonClick}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    id="attachments"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                    multiple
                  />
                  {selectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleIconClick}
                      ref={buttonRef}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedFiles.length}`}
                    </span>
                  )}
                  {isPopupOpen && (
                    <div className="attach-popup-amc4" ref={containerRef}>
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.filename}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.file_size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      {" "}
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                      {errors.attachment && <div className="error-message">{errors.attachment}</div>}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditReturnable;
