import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import { MdAttachFile } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { FiUpload } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";

function EditLibraryCategory({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [categoryData, setCategoryData] = useState({
    library_name: "",
    doc_name: "",
    attachments: null,
  });
  const fileInputRef = useRef(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [libraryId, setLibraryId] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_product_library_by_id/${id}`,
          {
            headers: {
              Authorization: authKey,
            }
          }
        );
  
        // Accessing nested data structure
        const responseData = response.data;
        const formattedData = {
          library_name: responseData.library_name,
          doc_name:responseData.doc_name,
        };
        const attachmentFiles = responseData.attachments || [];
        setSelectedFiles(attachmentFiles);
        setCategoryData(formattedData); // Updates the state with the fetched library_name
        setLibraryId(responseData.library_type_id || "");
      } catch (error) {
        console.error("Error fetching department data:", error.message);
        setError("An error occurred while fetching department data");
      }
    };
  
    fetchDepartmentData();
  }, [id]);
  

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategoryData({ ...categoryData, [name]: value });
    // Clear validation error when input changes
  };

  const handleCancelClick = () => {
    // navigate("/department");
    window.history.back();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // const handleSaveClick = async () => {
  //   const errors = {};
  //   let error = false;
  
  //   // Check for required fields in categoryData
  //   Object.keys(categoryData).forEach((key) => {
  //     if (!categoryData[key]) {
  //       errors[key] = `${key} is required`;
  //       setSeverity("warning");
  //       showSnackbar("Required fields are empty");
  //       error = true;
  //     }
  //   });
  
  //   if (error === false) {
  //     try {
  //       setIsLoading(true);
  //       const authKey = localStorage.getItem("authKey");
  
  //       // Create a new FormData object
  //       const formData = new FormData();
  
  //       // Append category data (doc_name, library_type_id, etc.)
  //       formData.append("doc_name", categoryData.doc_name);
  //       formData.append("library_name", categoryData.library_name);
  
  //       // Append attachments and their file types (if any)
  //       if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
  //         attachmentFile.forEach((file) => {
  //           formData.append("attachments", file);
  //         });
  //       }
  
  //       // Send the request to the backend with FormData
  //       const response = await axios.put(
  //         `${API_URL}/api/update_product_library/${id}`,
  //         formData,
  //         {
  //           headers: {
  //             Authorization: authKey,
  //             "Content-Type": "multipart/form-data",  // Important for sending files
  //           },
  //         }
  //       );
  
  //       // Handle response
  //       console.log(response.data.message);
  //       if (response.status === 200) {
  //         setSeverity("success");
  //         showSnackbar("Data updated successfully");
  //         setTimeout(() => {
  //           window.history.back();
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error updating company:", error.message);
  //       setError("An error occurred while updating company data");
  //       setIsLoading(false);
  //     }
  //   }
  // };

  const handleSaveClick = async () => {
    const errors = {};
    let error = false;
  
    // Check for required fields in categoryData
    Object.keys(categoryData).forEach((key) => {
      if (!categoryData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning");
        showSnackbar("Required fields are empty");
        error = true;
      }
    });
  
    if (error === false) {
      try {
        setIsLoading(true);
        setUploadProgress(0);
      setShowProgress(true);
        const authKey = localStorage.getItem("authKey");
  
        // Create a new FormData object
        const formData = new FormData();
  
        // Append category data (doc_name, library_type_id, etc.)
        formData.append("doc_name", categoryData.doc_name);
        formData.append("library_name", categoryData.library_name);
  
        // Append attachments and their file types (if any)
        if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
          attachmentFile.forEach((file) => {
            formData.append("attachments", file);
          });
        }
  
        // Send the request to the backend with FormData
        const response = await axios.put(
          `${API_URL}/api/update_product_library/${id}`,
          formData,
          {
            headers: {
              Authorization: authKey,
              "Content-Type": "multipart/form-data",  // Important for sending files
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setUploadProgress(percentCompleted);
            },
          }
        );
  
        // Handle response
        console.log(response.data.message);
        if (response.status === 200) {
          setSeverity("success");
          showSnackbar("Data updated successfully");

          setTimeout(() => {
            setShowProgress(false);
            setUploadProgress(0);
          }, 5000);
    
          setTimeout(() => {
            // navigate(`/library-documents/${id}`);
            window.history.back();
          }, 6000);
        }
      } catch (error) {
        console.error("Error updating company:", error.message);
        setError("An error occurred while updating company data");
        setIsLoading(false);
      }
    }
  };
  
  const handleBackProject = () => {
    navigate("/product-library-categories");
  };

  const handleUploadButtonClick = () => {
    // Ensure the ref is not null before triggering the click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is null");
    }
  };


  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    // Set the actual file objects in the state for FormData
    setAttachmentFile((prevFiles) => [
      ...(prevFiles || []),
      ...Array.from(files),
    ]);
  };
  const handleIconClick = () => {
    setIsPopupOpen((prev) => !prev); // Toggle popup on button click
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  const handleDeleteFile = (indexToDelete) => {
    console.log("Before delete:", selectedFiles); // Debugging

    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state with filtered files
    setSelectedFiles(updatedFiles);

    console.log("After delete:", updatedFiles); // Debugging

    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
    }
    setAttachmentFile("");
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Category Documents
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="" onKeyDown={handleKeyDown}>
              <div className="new-data-edit-form">
                <div className="form-group">
                  <label htmlFor="library_name">
                  Category<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="library_name"
                      value={categoryData.library_name}
                      onChange={handleInputChange}
                      placeholder="Enter category"
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="doc_name">
                    Document Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="doc_name"
                      value={categoryData.doc_name}
                      onChange={handleInputChange}
                      placeholder="Enter document name"
                    />
                   
                  </div>
                </div>
             
                <div className="form-group">
                  <label htmlFor="attachement">
                  Upload Document{" "}
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={handleUploadButtonClick}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    id="attachments"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                    multiple
                  />
                  {selectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleIconClick}
                      ref={buttonRef}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedFiles.length}`}
                    </span>
                  )}
                  {isPopupOpen && (
                    <div className="attach-popup-amc4" ref={containerRef}>
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.filename}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.file_size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      {" "}
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
              <button className="uplod-progress-button">
              {showProgress && (
  <div className="upload-progress-container">
    <div>Uploading... {uploadProgress}%</div>
    <div className="upload-progress-bar">
      <div style={{ width: `${uploadProgress}%` }}></div>
    </div>
  </div>
 )} 
</button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditLibraryCategory;
