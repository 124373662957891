import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import { useParams } from "react-router-dom";
import "./PrVendorDetails.css";
import Select from "react-select"; // Import react-select
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { FiUpload } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";

function ConfirmPRRequest() {
  const { pr_id } = useParams();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedEmails, setSelectedEmails] = useState(null);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [prRequestHistory, setPRRequestHistory] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpens, setIsPopupOpens] = useState(false);
  const buttonRef = useRef(null);
  const popupRef = useRef(null);
  const fileInputRef = useRef(null);
  const containerRef = useRef(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [emailData, setEmailData] = useState({
    to_email: "",
    subject: "",
    body: "",
    dailyId: "",
  });

  const handleUploadButtonClick = () => {
    // Ensure the ref is not null before triggering the click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is null");
    }
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    // Set the actual file objects in the state for FormData
    setAttachmentFile((prevFiles) => [
      ...(prevFiles || []),
      ...Array.from(files),
    ]);
  };

  const handleDeleteFile = (indexToDelete) => {
    console.log("Before delete:", selectedFiles); // Debugging

    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state with filtered files
    setSelectedFiles(updatedFiles);

    console.log("After delete:", updatedFiles); // Debugging

    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
    }
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmailData((prevData) => ({
      ...prevData,
      [name]: value, // Dynamically update the correct field
    }));
  };

  const handleEmail = (selectedOption) => {
    setSelectedEmails(selectedOption);

    // Clear the error message when a valid email is selected
    // if (selectedOption) {
    //   setErrorMessages((prevErrors) => ({
    //     ...prevErrors,
    //     from_email: "", // Clear the error for the 'from_email' field
    //   }));
    // }
  };

  const handleEmailChange = (selectedOption) => {
    setSelectedEmail(selectedOption); // Update the selected email in state

    // Find the selected template data from the response
    const selectedTemplate = emailTemplates.find(
      (template) => template.id === selectedOption.value
    );

    if (selectedTemplate) {
      setEmailData({
        ...emailData,
        to_email: selectedTemplate.to_emails || "", // Populate To email
        subject: selectedTemplate.subject || "", // Populate Subject
        body: selectedTemplate.body || "", // Populate Body
      });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const fetchPRRequestHistory = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_URL}/api/pr_vendor/get_email_history/${pr_id}`,
        {
          headers: { Authorization: localStorage.getItem("authKey") },
        }
      );
      setPRRequestHistory(response.data.items);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching vendor pr request history:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPRRequestHistory();
  }, [pr_id]);

  const fetchEmail = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_email_configs`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const options = data.configs.map((email) => {
          const label =
            email.is_default === 1 ? `${email.email} (default)` : email.email;
          return {
            label: label, // Append (default) to the email if it is the default
            value: email.id,
          };
        });

        setEmail(options);
      } else {
        console.error("Failed to fetch email configs");
      }
    } catch (error) {
      console.error("An error occurred while fetching email configs:", error);
    }
  };

  useEffect(() => {
    fetchEmail();
  }, []);

  const fetchEmails = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_email_templates`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });

      if (response.ok) {
        const data = await response.json();

        setEmailTemplates(data.templates); // Store the raw templates

        const options = data.templates.map((email) => {
          const label = email.temp_name || "No Template Name"; // Use temp_name or fallback
          return {
            label: label,
            value: email.id,
          };
        });

        setEmails(options); // Update state with formatted options
      } else {
        console.error("Failed to fetch email templates");
      }
    } catch (error) {
      console.error("An error occurred while fetching email templates:", error);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  // const validateForm = () => {
  //   let tempErrors = {};
  //   if (!prRequestData.date) tempErrors.date = "Date is required.";
  //   if (!prRequestData.description)
  //     tempErrors.description = "Description is required.";
  //   setErrors(tempErrors);
  //   return Object.keys(tempErrors).length === 0; // Returns true if no errors
  // };

  // const handleSaveClick = async () => {
  //   // if (!validateForm()) return;

  //   try {
  //     setIsLoading(true);

  //     const payload = {
  //       config_id: selectedEmails.value, // Ensure this is set from UI
  //       pr_vendor_id: selectedVendor.value, // Assuming you have selected vendor
  //       pr_request_id: pr_id,
  //       subject: emailData.subject,
  //       // to_email: emailData.to_email.split(","),
  //       to_email: emailData.to_email
  //         ? emailData.to_email.split(",").map((email) => email.trim()) // Ensure array format
  //         : [],
  //       body: emailData.body,
  //     };

  //     const response = await axios.post(
  //       `${API_URL}/api/purchase_request/customer/send_email`,
  //       payload,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: localStorage.getItem("authKey"),
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setSnackbarMessage(response.data.message || "Email sent successfully");
  //       setSeverity("success");
  //       setSnackbarOpen(true);
  //       await fetchPRRequestHistory();
  //       setSelectedEmail("");
  //       setSelectedEmails("");
  //       setSelectedVendor("");
  //       setEmailTemplates("");
  //       setEmailData({ to_email: "", subject: "", body: "", dailyId: "" });
  //     } else {
  //       throw new Error(response.data.message || "Failed to send email");
  //     }
  //   } catch (error) {
  //     console.error("Error sending email:", error);
  //     setSnackbarMessage("Error sending email");
  //     setSeverity("error");
  //     setSnackbarOpen(true);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const handleSaveClick = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");
  //     // Create FormData for multipart request
  //     const formData = new FormData();
  
  //     // Append basic fields
  //     formData.append("config_id", selectedEmails.value);
  //     formData.append("pr_request_id", pr_id);
  //     formData.append("subject", emailData.subject);
  //     formData.append("body", emailData.body);
  
  //     // Convert to_email array to JSON string and append
  //     const toEmailArray = emailData.to_email
  //       ? emailData.to_email.split(",").map((email) => email.trim())
  //       : [];
  //     formData.append("to_email", JSON.stringify(toEmailArray));
  
  //     // Append attachments as a list
  //     if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
  //       attachmentFile.forEach((file, index) => {
  //         formData.append(`attachments[]`, file, file.name); // Using `attachments[]` to ensure it is treated as a list
  //       });
  //     }
  
  //     const response = await axios.post(
  //       `${API_URL}/api/purchase_request/customer/send_email`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: authKey,
  //         },
  //       }
  //     );
  
  //     if (response.status === 200) {
  //       setSnackbarMessage(response.data.message || "Email sent successfully");
  //       setSeverity("success");
  //       setSnackbarOpen(true);
  //       await fetchPRRequestHistory();
  //       setSelectedEmail("");
  //       setSelectedEmails("");
  //       setSelectedVendor("");
  //       setEmailTemplates("");
  //       setEmailData({ to_email: "", subject: "", body: "", dailyId: "" });
  //     } else {
  //       throw new Error(response.data.message || "Failed to send email");
  //     }
  //   } catch (error) {
  //     console.error("Error sending email:", error);
  //     setSnackbarMessage("Error sending email");
  //     setSeverity("error");
  //     setSnackbarOpen(true);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  
  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      // Create FormData for multipart request
      const formData = new FormData();
  
      // Append basic fields
      formData.append("config_id", selectedEmails.value);
      formData.append("pr_request_id", pr_id);
      formData.append("subject", emailData.subject);
      formData.append("body", emailData.body);
  
      // Convert to_email array to JSON string and append
      const toEmailArray = emailData.to_email
        ? emailData.to_email.split(",").map((email) => email.trim())
        : [];
      formData.append("to_email", toEmailArray.join(",")); // Send as comma-separated string
  
      // Append attachments correctly as expected by the backend
      if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
        attachmentFile.forEach((file) => {
          formData.append("attachments", file, file.name); // Use "attachments" without []
        });
      }
  
      const response = await axios.post(
        `${API_URL}/api/purchase_request/customer/send_email`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: authKey,
          },
        }
      );
  
      if (response.status === 200) {
        setSnackbarMessage(response.data.message || "Email sent successfully");
        setSeverity("success");
        setSnackbarOpen(true);
        await fetchPRRequestHistory();
        setSelectedEmail("");
        setSelectedEmails("");
        setSelectedVendor("");
        setEmailTemplates("");
        setSelectedFiles("");
        setEmailData({ to_email: "", subject: "", body: "", dailyId: "" });
      } else {
        throw new Error(response.data.message || "Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setSnackbarMessage("Error sending email");
      setSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  const fetchPRVendorsData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await fetch(`${API_URL}/api/get_pr_vendors/${pr_id}`, {
        method: "GET",
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json(); // ✅ Correct JSON parsing
      const vendors = responseData.pr_vendors || []; // ✅ Ensure pr_vendors exists

      const options = vendors.map((vendor) => {
        const fileName =
          vendor.attachments?.length > 0
            ? vendor.attachments[0].filename
            : "No File";

        return {
          value: vendor.id,
          label: `${vendor.vendor_name} - ${fileName}`,
        };
      });

      setVendorOptions(options);
    } catch (error) {
      console.error("Error fetching vendors:", error);
    }
  };

  useEffect(() => {
    fetchPRVendorsData();
  }, [pr_id]);

  const handleIconClick = (index) => {
    // Toggle the popup open/close based on the current state
    setIsPopupOpen((prev) => (prev === index ? null : index));

    setSelectedRowIndex(false); // Reset the selected row
  };

  const handleIconClicks = (index) => {
    // Toggle the popup open/close based on the current state
    setIsPopupOpens((prev) => (prev === index ? null : index));

    setSelectedRowIndex(false); // Reset the selected row
  };

  const handleCloseAttachments = () => {
    setIsPopupOpens(false);
  };

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  const handleViewAttachment = (attachment) => {
    window.open(
      `${API_URL}/api/view_pr_attachment/${attachment.file_id}/${attachment.file_type}`,
      "_blank"
    );
  };
  return (
    <>
      <div className="data-form-content">
        <div className="data-form-bodys12">
          <div className="pr-form">
            <form className="form-detail">
              <div className="new-data-form">
                {/* <div className="form-group-select">
                  <label htmlFor="date">
                    Vendor<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={vendorOptions}
                    value={selectedVendor}
                    onChange={setSelectedVendor}
                    placeholder="Select a vendor..."
                    isSearchable
                  />
                </div> */}

                <div className="form-group-select">
                  <label htmlFor="department_name">
                    From<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department_name"
                    options={email}
                    value={selectedEmails}
                    onChange={handleEmail}
                    placeholder="Select email"
                    isSearchable
                  />
                </div>

                <div className="form-group-select">
                  <label htmlFor="department_name">
                    Select Template<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department_name"
                    options={emails} // emails should be in the format { label, value }
                    value={selectedEmail}
                    onChange={handleEmailChange}
                    placeholder="Select email template"
                    isSearchable
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="to_email">To</label>
                  <div className="input-group">
                    <input
                      type="email"
                      id="to_email"
                      name="to_email"
                      value={emailData.to_email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="subject">Subject</label>
                  <div className="input-group">
                    <textarea
                      id="subject"
                      name="subject"
                      value={emailData.subject}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="body">Body</label>
                  <div className="input-group">
                    <textarea
                      id="body"
                      name="body"
                      value={emailData.body}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                      <label htmlFor="attachement">
                        Attachment{" "}
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <button
                        type="button"
                        className="challan-btn"
                        onClick={handleUploadButtonClick}
                        ref={buttonRef}
                      >
                        <FiUpload /> Upload File
                      </button>
                      <input
                        id="attachments"
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                        multiple
                      />
                      {selectedFiles.length > 0 && (
                        <span
                          className="inquiry-file-btn"
                          onClick={handleIconClicks}
                          ref={buttonRef}
                        >
                          <MdAttachFile className="inquiry-file-icon" />{" "}
                          {`${selectedFiles.length}`}
                        </span>
                      )}
                      {isPopupOpens && (
                        <div className="attach-popup-amc4" ref={popupRef}>
                          <div className="attach-popup-contant">
                            <div className="attachment-header">
                              <div className="attachment-header-content">
                                Attachments
                              </div>
                              <button
                                className="fiile-close-btn"
                                onClick={handleCloseAttachments}
                              >
                                <IoCloseOutline className="file-close-icon" />
                              </button>
                            </div>
                            {selectedFiles.length > 0 && (
                              <div className="attach-scrroll-body">
                                {selectedFiles.map((file, index) => (
                                  <div
                                    className="attach-popup-contant-body"
                                    key={index}
                                  >
                                    <div className="attach-file-container">
                                      <div className="attach-file-show">
                                        <div className="text-attachment">
                                          {file.name}
                                        </div>
                                        <div className="text-attachment-file">
                                          File Size:(
                                          {(file.size / 1024).toFixed(2)} KB)
                                        </div>
                                      </div>
                                      <div
                                        className="file-delete-btn"
                                        onClick={() => handleDeleteFile(index)}
                                      >
                                        <span>
                                          {" "}
                                          <MdDeleteOutline className="file-delete-icon" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
              </div>
            </form>
            <div className="history-button">
              <div className="button-group">
                <button
                  className="data-form-save-button"
                  type="button"
                  onClick={handleSaveClick}
                  disabled={isLoading}
                >
                  {isLoading ? "Sending Email..." : "Send Email"}
                </button>
                <button
                  className="data-form-cancel-button"
                  // onClick={() => navigate("/vendor")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          {/* Quotation History Table */}
          <div className="quotation-history">
            <h2>PR Request History</h2>
            <div className="data-list-scroll-body">
              <div className="data-list-tabless">
                {loading ? (
                  <div className="loader-container">
                    {/* <div className="loader"></div>
                      <div>Loading...</div> */}
                  </div>
                ) : prRequestHistory.length > 0 ? (
                  <table className="tables multilevel-data">
                    <thead>
                      <tr>
                        <th>SR. NO.</th>
                        {/* <th>VENDOR NAME</th> */}
                        <th>EMAIL</th>
                        <th>SUBJECT</th>
                        <th>BODY</th>
                        <th>STATUS</th>
                        <th>ATTACHMENTS</th>
                      </tr>
                    </thead>

                    <tbody>
                      {prRequestHistory.length > 0 &&
                        prRequestHistory.map((prRequest, index) => (
                          <tr key={prRequest.pr_request_id || index} className="hoverable-row">
                            {" "}
                            {/* Use a unique ID if available */}
                            <td>{index + 1}</td>
                            {/* <td>{formatDate(prRequest.date)}</td> */}
                            {/* <td>{prRequest.vendor_name || "NA"}</td> */}
                            <td>{prRequest.to_email}</td>
                            <td>{prRequest.subject || "NA"}</td>
                            <td>{prRequest.body || "NA"}</td>
                            <td>{prRequest.status || "NA"}</td>
                            <td className="center-alignment">
                              <span
                                className="inquiry-file-btns"
                                onClick={() => handleIconClick(index)}
                                ref={buttonRef}
                              >
                                <MdAttachFile className="inquiry-file-icon" />
                              </span>
                              {isPopupOpen === index && (
                                <div
                                  className="inquiry-attach-popup prRequest-popup-vendor"
                                  ref={popupRef}
                                >
                                  <div className="attach-popup-contant">
                                    <div className="attachment-header">
                                      <div className="attachment-header-content">
                                        Attachments
                                      </div>
                                      <button
                                        className="fiile-close-btn"
                                        onClick={handleCloseAttachment}
                                      >
                                        <IoCloseOutline className="file-close-icon" />
                                      </button>
                                    </div>
                                    <div
                                      className="attach-popup-contant-body"
                                      key={index}
                                    >
                                      <div className="inquiry-attach-file-containerssss">
                                        <div className="attach-file-showsss">
                                          <div className="text-attachmentss">
                                            {prRequest.attachments &&
                                            prRequest.attachments.length > 0 ? (
                                              <div className="attach-popup-content-body">
                                                {prRequest.attachments.map(
                                                  (
                                                    attachment,
                                                    attachmentIndex
                                                  ) => (
                                                    <div
                                                      className="inquiry-attach-file-container"
                                                      key={attachmentIndex}
                                                    >
                                                      <div className="inquiry-attacth-file-model">
                                                        <div className="attach-file-show">
                                                          <div className="text-attachment">
                                                            {attachment.filename
                                                              .length > 20
                                                              ? `${attachment.filename.substring(
                                                                  0,
                                                                  15
                                                                )}...`
                                                              : attachment.filename}
                                                          </div>
                                                          <div className="text-attachment-file">
                                                            File Size:{" "}
                                                            {attachment.file_size ||
                                                              "NA"}{" "}
                                                            KB
                                                          </div>
                                                        </div>

                                                        <div className="file-footer">
                                                          {/* <div
                                                                                          className="file-download-attachment file-txt"
                                                                                          onClick={() =>
                                                                                            handleViewAttachment(prRequest)
                                                                                          }
                                                                                        >
                                                                                          Download
                                                                                        </div> */}
                                                          <a
                                                            href={`${API_URL}/api/download_pr_attachment/${attachment.file_id}/${attachment.file_type}`}
                                                            download
                                                            className="file-download-attachment file-txt"
                                                          >
                                                            Download
                                                          </a>
                                                          <a
                                                            href={`${API_URL}/api/view_pr_attachment/${attachment.file_id}/${attachment.file_type}`}
                                                           target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="file-download-attachment file-txt"
                                                          >
                                                            View
                                                          </a>
                                                          {/* <div
                                                            className="file-download-attachment file-txt"
                                                            onClick={() =>
                                                              handleViewAttachment(
                                                                attachment
                                                              )
                                                            }
                                                          >
                                                            View
                                                          </div> */}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            ) : (
                                              <div className="no-attachments">
                                                No attachments found
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="file-delete-btn"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">
                      No pr request history available
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ConfirmPRRequest;
