import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PermitRequestForSafety from "../ColdWorkPermit/PermitRequestForSafety";
import axios from "axios";
import API_URL from "../../src/Config";
import { Tabs, Tab } from "@mui/material";

function PermitRequestForSafetyTab({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [newCounts, setNewCounts] = useState({
    "In Review": 0,
    Approved: 0,
    Issued: 0,
    Rejected: 0,
    Closed: 0,
    pending: 0,
  });

  const [tabCounts, setTabCounts] = useState({
    Issued: 0,
    Rejected: 0,
    Closed: 0,
    Draft: 0,
    Submitted: 0,
  });

  const [notificationCounts, setNotificationCounts] = useState({
    Issued: 0,
    Rejected: 0,
    Closed: 0,
    Draft: 0,
    Submitted: 0,
  });

  const [notificationCountsIds, setNotificationCountsIds] = useState({
    Issued: 0,
    Rejected: 0,
    Closed: 0,
    Draft: 0,
    Submitted: 0,
  });

  // Extracting tab from URL query
  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get("tab");

  const [tabValue, setTabValue] = useState(tabFromUrl || "NewRequests");


  useEffect(() => {
    console.log("Checking when useEffect runs...");
    console.log("Tab Value:", tabValue);
    console.log("Notification Counts IDs:", notificationCountsIds);
  
    if (tabValue === "NewRequests") {
      console.log("notificationCountsIds.Submitted:", notificationCountsIds.Submitted);
      console.log("Length Check:", notificationCountsIds["Pending Approval"]?.length);
    }
  
    if (tabValue === "NewRequests" && notificationCountsIds["Pending Approval"]?.length > 0) {
      console.log("✅ Triggering handleTabClick for NewRequests");
      handleTabClick("Pending Approval");
    }
}, [tabValue, notificationCountsIds]);



  const updateNewCounts = (counts) => {
    console.log("Updated counts:", counts); // Check the counts being passed
    setNewCounts({
      "In Review": counts["In Review"] || 0,
      Approved: counts.Approved || 0,
      Issued: counts.Issued || 0,
      Rejected: counts.Rejected || 0,
      Closed: counts.Closed || 0,
      pending: counts.pending || 0,
    });
  };

  const updateTotalItems = (counts) => {
    console.log("Updated counts:", counts);
    setTabCounts({
      Approved: counts.Approved || 0,
      Issued: counts.Issued || 0,
      Rejected: counts.Rejected || 0,
      Closed: counts.Closed || 0,
      pending: counts.pending || 0,
      "Pending Approval": counts["Pending Approval"] || 0,
    });
  };

  const updateNotification = (counts) => {
    console.log("Updated counts:", counts);
    setNotificationCounts({
      Approved: counts.Approved || 0,
      Issued: counts.Issued || 0,
      Rejected: counts.Rejected || 0,
      Closed: counts.Closed || 0,
      pending: counts.pending || 0,
      "Pending Approval": counts["Pending Approval"] || 0,
    });
  };

  const updateNotificationIds = (counts) => {
    console.log("Updated ids:", counts);
    setNotificationCountsIds({
      Approved: counts.Approved || 0,
      Issued: counts.Issued || 0,
      Rejected: counts.Rejected || 0,
      Closed: counts.Closed || 0,
      pending: counts.pending || 0,
      "Pending Approval": counts["Pending Approval"] || 0,
    });
  };

  useEffect(() => {
    // Update tab value if URL param changes
    if (tabFromUrl && tabFromUrl !== tabValue) {
      setTabValue(tabFromUrl);
    }
  }, [location.search]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // Update URL query parameter to reflect the current tab
    navigate(`?tab=${newValue}`);
  };
  const getStatusForTab = (tabValue) => {
    switch (tabValue) {
      case "NewRequests":
        return "Pending Approval"; // Draft
      case "Approved":
        return "Approved"; // Submitted
        case "Issued":
        return "Issued"; // Submitted
      case "Rejected":
        return "Rejected"; // Submitted
        case "Closed":
          return "Closed"; // Submitted
      default:
        return null;
    }
  };

  const handleTabClick = async (tabName) => {
    try {
      // Fetch notification_ids from state (notificationCountsIds) based on the selected tab
      const notificationIds = notificationCountsIds[tabName];
  
      if (!notificationIds || notificationIds.length === 0) return;
  
      const authKey = localStorage.getItem("authKey");
  
      const response = await axios.put(
        `${API_URL}/api/notifications/update_read_status`,
        { notification_ids: notificationIds }, // Send correct payload
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );
  
      console.log(`Notifications for ${tabName} marked as read.`, response.data);
    } catch (error) {
      console.error("Error updating read status:", error);
    }
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {/* <div className="data-list-header">
          <div className="data-list-header-content">
            <div className="data-list-heading-content-left-side">
              All  Work Permit Inspection
            </div>

            <div className="data-list-heading-content-right-side"></div>
          </div>
        </div> */}

        <div className="data-list-content permit-tab" ref={containerRef}>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => handleTabChange(event, newValue)}
            aria-label="report tabs"
            className="tabs-container"
          >
            <Tab
              label={
                <div className="tab-label-container">
                  <span className="tab-status-label">TO Be Approved</span>

                  <span className="tab-count">
                    ({tabCounts["Pending Approval"] || 0})
                    {notificationCounts["Pending Approval"] > 0 && (
                    <span className="new-tab-count">
                    {notificationCounts["Pending Approval"] || 0}
                    </span>
                    )}
                  </span>
                </div>
              }
              value="NewRequests"
              onClick={() => handleTabClick("Pending Approval")}
            />

            <Tab
              label={
                <div className="tab-label-container">
                  <span className="tab-status-label">Approved</span>

                  <span className="tab-count">({tabCounts.Approved || 0}) 
                  {notificationCounts.Approved > 0 && (
                  <span className="new-tab-count">
                  {notificationCounts.Approved || 0}
                  </span>
                  )}
                  </span>
                </div>
              }
              value="Approved"
              onClick={() => handleTabClick("Approved")}
            />

            <Tab
              label={
                <div className="tab-label-container">
                  <span className="tab-status-label">Issued</span>

                  <span className="tab-count">({tabCounts.Issued || 0})
                  {notificationCounts.Issued > 0 && (
                  <span className="new-tab-count">
                  {notificationCounts.Issued || 0}
                  </span>
                  )}
                  </span>
                </div>
              }
              value="Issued"
              onClick={() => handleTabClick("Issued")}
            />

            <Tab
              label={
                <div className="tab-label-container">
                  <span className="tab-status-label">Rejected</span>

                  <span className="tab-count">({tabCounts.Rejected || 0})
                  {notificationCounts.Rejected > 0 && (
                  <span className="new-tab-count">
                  {notificationCounts.Rejected || 0}
                  </span>
                  )}
                  </span>
                </div>
              }
              value="Rejected"
              onClick={() => handleTabClick("Rejected")}
            />
             <Tab
              label={
                <div className="tab-label-container">
                  <span className="tab-status-label">Closed</span>

                  <span className="tab-count">({tabCounts.Closed || 0})
                  {notificationCounts.Closed > 0 && (
                  <span className="new-tab-count">
                  {notificationCounts.Closed || 0}
                  </span>
                  )}
                  </span>
                </div>
              }
              value="Closed"
              onClick={() => handleTabClick("Closed")}
            />
          </Tabs>
          
          <div className="header-separater"></div>
          <div className="tabs-content">
            <PermitRequestForSafety
              status={getStatusForTab(tabValue)}
              updateNewCounts={updateNewCounts}
              updateTotalItems={updateTotalItems}
              updateNotification={updateNotification}
              updateNotificationIds={updateNotificationIds}
            />
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PermitRequestForSafetyTab;
