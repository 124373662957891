import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";

function EditDesignation({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();

 

  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [emailData, setEmailData] = useState({
    to_emails: "",
    subject: "",
    body: "",
    temp_name: "",
  });
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  useEffect(() => {
    const fetchEmailTemplateData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_email_template/${id}`, {
          headers: {
            Authorization: authKey,
          },
        });
  
        const templateData = response.data?.template;
  
        if (templateData) {
          setEmailData({
            to_emails: templateData.to_emails || "",
            subject: templateData.subject || "",
            body: templateData.body || "",
            temp_name: templateData.temp_name || "",
          });
        }
  
        setIsDataLoading(false);
      } catch (error) {
        console.error("Error fetching email template data:", error.message);
        setError("An error occurred while fetching email template data.");
        setIsDataLoading(false);
      }
    };
  
    fetchEmailTemplateData();
  }, [id]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmailData({ ...emailData, [name]: value });
  };

  const handleCancelClick = () => {
    // navigate("/designations");
    window.history.back();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
  
      // Transform `to_emails` from a string to an array
      const updatedEmailData = {
        ...emailData,
        to_emails: emailData.to_emails
          ? emailData.to_emails.split(",").map(email => email.trim())
          : [], // Fallback to an empty array if no emails are provided
      };
  
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/update_email_template/${id}`,
        updatedEmailData,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
  
      if (response.status === 200) {
        showSnackbar("Data updated successfully");
        setTimeout(() => {
          navigate("/email-templates");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating email template:", error.message);
      setError("An error occurred while updating the email template");
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const handleBackProject = () => {
    navigate("/email-templates");
  };

  if (isDataLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />
                </Tooltip>
                Edit Email Template
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="" onKeyDown={handleKeyDown}>
              <div className="new-data-edit-form">
              <div className="form-group">
                  <label htmlFor="temp_name">
                    Template Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="temp_name"
                      name="temp_name"
                      value={emailData.temp_name}
                      onChange={handleInputChange}
                      placeholder="Enter tempalte name"
                    />
                    {errors.to_emails && (
                      <div className="error-message">{errors.temp_name}</div>
                    )}
                  </div>
                </div>
              <div className="form-group">
                  <label htmlFor="to_emails">
                    To<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="to_emails"
                      name="to_emails"
                      value={emailData.to_emails}
                      onChange={handleInputChange}
                      placeholder="Enter recipient emails (comma-separated)"
                    />
                    {errors.to_emails && (
                      <div className="error-message">{errors.to_emails}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="subject">
                    Subject<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      id="subject"
                      name="subject"
                      value={emailData.subject}
                      onChange={handleInputChange}
                      placeholder="Enter subject"
                    />
                    {errors.subject && (
                      <div className="error-message">{errors.subject}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="body">
                    Body<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      id="body"
                      name="body"
                      value={emailData.body}
                      onChange={handleInputChange}
                      placeholder="Enter email body"
                      rows={10}
                      cols={50}
                    />

                    {errors.body && (
                      <div className="error-message">{errors.body}</div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                type="button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditDesignation;
