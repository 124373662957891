import React, { useState, useEffect, useMemo } from "react";
import Header from "./Header";
import { Tooltip } from "@mui/material";
import "./Sidebar.css";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosArrowDown,
} from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import API_URL from "../../src/Config";
import { FaSearch } from "react-icons/fa";

import {
  RxDashboard, RxCube
} from "react-icons/rx";
import { VscGraph } from "react-icons/vsc";
import { MdApproval } from "react-icons/md";
import { AiOutlineAudit } from "react-icons/ai";
import { TbReportSearch, TbChartPie, TbBuildingStore, TbSettingsStar } from "react-icons/tb";
import { PiNewspaperClipping, PiUserListBold, PiDotOutlineFill } from "react-icons/pi";
import { LiaDumpsterFireSolid, LiaFileInvoiceSolid } from "react-icons/lia";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { LuHistory, LuLibrary, LuUsers } from "react-icons/lu";
import { FiShoppingCart } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { FaExchangeAlt } from "react-icons/fa";




const iconMap = {
  RxDashboard: RxDashboard,
  VscGraph: VscGraph,
  MdApproval: MdApproval,
  PiNewspaperClipping: PiNewspaperClipping,
  LiaDumpsterFireSolid: LiaDumpsterFireSolid,
  RiCalendarScheduleLine: RiCalendarScheduleLine,
  LiaFileInvoiceSolid: LiaFileInvoiceSolid,
  TbChartPie: TbChartPie,
  LuHistory: LuHistory,
  FiShoppingCart: FiShoppingCart,
  LuLibrary: LuLibrary,
  PiUserListBold: PiUserListBold,
  TbBuildingStore: TbBuildingStore,
  RxCube: RxCube,
  TbSettingsStar: TbSettingsStar,
  IoSettingsOutline: IoSettingsOutline,
  LuUsers: LuUsers,
  AiOutlineAudit: AiOutlineAudit,
  PiDotOutlineFill: PiDotOutlineFill,
  TbReportSearch: TbReportSearch,
  FaExchangeAlt : FaExchangeAlt,
};


function Sidebar({ onSidebarToggle }) {
  const location = useLocation();
  // const [menuData, setMenuData] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [isExpanded, setIsExpanded] = useState(true);
  const [showSide, setShowSide] = useState(true);
  const [dropdownState, setDropdownState] = useState({});
  const [authorizedRoutes, setAuthorizedRoutes] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Add search query state
  const [searchResults, setSearchResults] = useState([]); // Store search results
  const [isSearching, setIsSearching] = useState(false); // Track search state

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
    setShowSide(!showSide);
  };

  useEffect(() => {
    onSidebarToggle(isExpanded);
  }, [isExpanded, onSidebarToggle]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/v2/get_group_menu_items`, {
          headers: {
            Authorization: authKey,
          },
        });
        
        const data = response.data;
        if (data) {
          setMenuItems(data);
          
          // Extract all routes for authorization
          const routes = [];
          Object.values(data).forEach(category => {
            if (category.menu_items) {
              category.menu_items.forEach(item => {
                if (item.route) routes.push(item.route);
                if (item.sub_menu) {
                  item.sub_menu.forEach(subItem => {
                    if (subItem.route) routes.push(subItem.route);
                    if (subItem.sub_menu) {
                      subItem.sub_menu.forEach(subSubItem => {
                        if (subSubItem.route) routes.push(subSubItem.route);
                      });
                    }
                  });
                }
              });
            }
          });
          
          setAuthorizedRoutes(routes);
          localStorage.setItem("menuData", JSON.stringify(data));
        }
      } catch (error) {
        console.error("Error fetching menu items:", error.message);
      }
    };

    const cachedMenuData = localStorage.getItem("menuData");
    if (cachedMenuData) {
      setMenuItems(JSON.parse(cachedMenuData));
    } else {
      fetchData();
    }
  }, []);



  const handleLinkClick = (path, department_id) => {
    if (activeLink !== path) {
      setActiveLink(path); // Update the active link only if it's different
    }
  };

  const toggleDropdown = (menuItemId) => {
    if (isExpanded) {
      setIsExpanded(true);
      setShowSide(true);
    }

    setDropdownState((prevState) => ({
      ...prevState,
      [menuItemId]: !prevState[menuItemId],
    }));
  };

  // const renderIcon = (icon) => {
  //   const extensions = ["png", "jpg", "jpeg", "svg"];
  //   const className = icon === "dot" ? "sidebar-dot-icon" : "sidebar-icon";

  //   for (let ext of extensions) {
  //     try {
  //       const IconComponent = icons[`${icon}.${ext}`];
  //       if (IconComponent) {
  //         return <img src={IconComponent} className={className} alt={icon} />;
  //       }
  //     } catch (error) {
  //       console.error(`Icon not found: ${icon}.${ext}`);
  //     }
  //   }
  //   return null;
  // };

  const renderIcon = (iconName) => {
    const IconComponent = iconMap[iconName]; // Get icon from map
    return IconComponent ? <IconComponent /> : null; // Render if found
  };

  useEffect(() => {
    if (searchQuery.length > 1) {
      setIsSearching(true);
      const fetchSearchResults = async () => {
        try {
          const authKey = localStorage.getItem("authKey");
          const response = await axios.get(`${API_URL}/api/search_menu_items`, {
            headers: { Authorization: authKey },
            params: { query: searchQuery },
          });
          setSearchResults(response.data);
        } catch (error) {
          console.error("Error searching menu items:", error.message);
          setSearchResults([]); // Clear search results if error occurs
        } finally {
          setIsSearching(false);
        }
      };
      fetchSearchResults();
    } else {
      setSearchResults([]); // Reset search results when input is cleared
    }
  }, [searchQuery]);

  const renderExpandCollapseIcon = (isOpen) => {
    return isOpen ? (
      <IoIosArrowDown className="expand-collapse-icon" />
    ) : (
      <IoIosArrowForward className="expand-collapse-icon" />
    );
  };


  // const renderMenuItems = (items) => {
  //   return (
  //     <div className="submenu">
  //       {items.map(({ id, name, route, icon, sub_menu, department_id }) => {
  //         // Debugging the department_id here
       

  //         return (
  //           <div key={id}>
  //             {route ? (
  //               <Link
  //                 to={route}
  //                 onClick={() => {
                  
  //                   handleLinkClick(route, department_id); // Pass department_id here
  //                 }}
  //                 className={`user-link ${activeLink === route ? "active" : ""}`}
  //               >
  //                 <div className="user sidebar-hover">
  //                   <div className="user-icon">{renderIcon(icon)}</div>
  //                   {isExpanded && <div className="user-font">{name}</div>}
  //                 </div>
  //               </Link>
  //             ) : department_id ? (
  //               // If this is a department, pass department_id as well
  //               <Link
  //                 to={`/electrical-department/${department_id}`} // Dynamic route
  //                 onClick={() => {
                  
  //                   handleLinkClick(`/electrical-department/${department_id}`, department_id); // Pass department_id here
  //                 }} // Pass department_id here
  //                 className={`user-link ${activeLink === `/electrical-department/${department_id}` ? "active" : ""}`}
  //               >
  //                 <div className="user sidebar-hover">
  //                   <div className="user-icon">{renderIcon(icon)}</div>
  //                   {isExpanded && <div className="user-font">{name}</div>}
  //                 </div>
  //               </Link>
  //             ) : (
  //               <div
  //                 className={`user sidebar-hover ${dropdownState[id] ? "active" : ""}`}
  //                 onClick={() => toggleDropdown(id)}
  //               >
  //                 <div className="user-icon">{renderIcon(icon)}</div>
  //                 {isExpanded && <div className="user-font">{name}</div>}
  //                 {isExpanded && (
  //                   <div className="expand-collapse-icon">
  //                     {renderExpandCollapseIcon(dropdownState[id])}
  //                   </div>
  //                 )}
  //               </div>
  //             )}

  //             {dropdownState[id] && sub_menu.length > 0 && isExpanded && (
  //               <div className="submenu-menu">
  //                 {renderMenuItems(sub_menu)}
  //               </div>
  //             )}
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // };

  // const renderedMenuItems = useMemo(
  //   () => renderMenuItems(menuItems),
  //   [menuItems, activeLink, dropdownState, isExpanded]
  // );

  const renderMenuItem = (item) => {
    // For department items (special case in your data)
    if (item.department_id) {
      return (
        <Link
          key={item.department_id}
          to={`/electrical-department/${item.department_id}`}
          onClick={() => handleLinkClick(`/electrical-department/${item.department_id}`, item.department_id)}
          className={`user-link ${activeLink === `/electrical-department/${item.department_id}` ? "active" : ""}`}
        >
          <div className="user sidebar-hover">
            <div className="user-icon">{renderIcon(item.icon)}</div>
            {isExpanded && <div className="user-font">{item.name}</div>}
          </div>
        </Link>
      );
    }
    
    // For items with direct routes and no sub-menu
    if (item.route && (!item.sub_menu || item.sub_menu.length === 0)) {
      return (
        <Link
          key={item.id}
          to={item.route}
          onClick={() => handleLinkClick(item.route)}
          className={`user-link  ${activeLink === item.route ? "active" : ""}`}
        >
          <div className="user sidebar-hover">
            <div className="user-icon">{renderIcon(item.icon)}</div>
            {isExpanded && <div className="user-font">{item.name}</div>}
          </div>
        </Link>
      );
    }
    
    // For items with sub-menus - create a dropdown
    const hasSubMenu = item.sub_menu && item.sub_menu.length > 0;
    const isOpen = dropdownState[item.id] || false;
    
    return (
      <div key={item.id} className="menu-dropdown">
        <div 
          className={`user sidebar-hover ${isOpen ? "active" : ""}`}
          onClick={() => toggleDropdown(item.id)}
        >
          {/* <div className="menu-item-content"> */}
            <div className="user-icon">{renderIcon(item.icon)}</div>
            {isExpanded && (
              <>
                <div className="user-font">{item.name}</div>
                {hasSubMenu && isExpanded && (
                  <div className="expand-collapse-icon">
                    {isOpen ? <IoIosArrowDown /> : <IoIosArrowForward />}
                    {/* {isOpen ? <IoIosArrowBack /> : <IoIosArrowForward />} */}

                  </div>
                )}
              </>
            )}
          {/* </div> */}
        </div>
        
        {hasSubMenu && isOpen && isExpanded && (
          <div className="dropdown-menu">
            {item.sub_menu.map(subItem => renderMenuItem(subItem))}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
   

      <div className="product-content-container">

        <div className="sidebar-container">

          {showSide && (
            <div className="sidebar-content">
              <div className="heading-border">
              {/* <div className="heading-start"> */}
                <img src="/logo23231.png" alt="Logo" className="header-logos" />
              {/* </div> */}
              {/* <div className="heading-start-theme">
                <div className="color-them1"></div>
                <div className="color-them2"></div>
                <div className="color-them3"></div>
              </div> */}
              </div>
              <div className="search-sidebar">
              <div className="search-start" style={{ border: "1px solid rgb(163 158 158)", borderRadius: "4px", }}>
                <FaSearch style={{ margin: "6px 3px", color: "#B1BAD4", verticalAlign: "sub", fontSize: "15px"}} />
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    border: "none",
                    outline: "none",
                    flex: 1,
                    fontSize: "16px",
                  }}
                />
              </div>
              </div>
              <div className="sidebar-content1">
              {/* {renderedMenuItems} */}
              {/* {Object.entries(menuItems).map(([categoryKey, categoryData]) => (
                <div key={categoryKey} className="menu-category">
                  {isExpanded && (
                    <div className="menu-category-header">
                      {categoryData.category}
                    </div>
                  )}
                  <div className="category-items">
                    {categoryData.menu_items.map(item => renderMenuItem(item))}
                  </div>
                </div>
              ))} */}
              {searchQuery.length > 2 ? (
    searchResults.length > 0 ? (
      searchResults.map((item) => (
        <Link
          key={item.id}
          to={item.route}
          // onClick={() => setSearchQuery("")} 
          onClick={() => handleLinkClick(item.route)}
          className="user-link"
        >
          <div className="user dropdown-menu sidebar-hover">
            <div className="user-icon">{renderIcon(item.icon)}</div>
            {isExpanded && <div className="user-font">{item.name}</div>}
          </div>
        </Link>
      ))
    ) : (
      <div className="no-results">No results found</div>
    )
  ) : (
    Object.entries(menuItems).map(([categoryKey, categoryData]) => (
      <div key={categoryKey} className="menu-category">
        {isExpanded && (
          <div className="menu-category-header">{categoryData.category.toUpperCase()}</div>
        )}
        <div className="category-items">
          {categoryData.menu_items.map((item) => renderMenuItem(item))}
        </div>
      </div>
    ))
  )}
              </div>
              </div>
          )}
          {!showSide && (
            <div className="sidebar-content2">
              {menuItems.map((menuItem) => (
                <Tooltip
                  key={menuItem.id}
                  title={menuItem.name}
                  arrow
                  placement="right"
                >
                  <Link
                    to={menuItem.route}
                    onClick={() => handleLinkClick(menuItem.route)}
                    className="user sidebar-hover"
                  >
                    {renderIcon(menuItem.icon)}
                  </Link>
                </Tooltip>
              ))}
            </div>
          )}
          {/* <div
            className="sidebar-footer"
            style={{
              width: isExpanded ? "250px" : "50px",
              transition: "width 0.3s ease-in-out",
              zIndex: 2,
            }}
            onClick={toggleSidebar}
          >
            <div className="expand-sidebar">
              {isExpanded ? <IoIosArrowBack /> : <IoIosArrowForward />}
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
