import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PRRequestList from "./PRRequestList";
import { Tabs, Tab } from "@mui/material";
import axios from "axios";
import API_URL from "../../src/Config";

function PermitRequestForRequesterTab({ isSidebarExpanded }) {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  // Extracting tab from URL query
  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get("tab");

  const [tabValue, setTabValue] = useState(tabFromUrl || "all");

  const updateTotalItems = (counts) => {
    console.log("Updated counts:", counts);
    // setTabCounts({
    //   Issued: counts.Issued || 0,
    //   Rejected: counts.Rejected || 0,
    //   Closed: counts.Closed || 0,
    //   Submitted: counts.Submitted || 0,
    //   Draft: counts.Draft || 0,
    // });
  };

  // useEffect(() => {
  //   if (tabFromUrl !== tabValue) {
  //     setTabValue(tabFromUrl || "all");
  //   }
  // }, [tabFromUrl, location.search]);

  useEffect(() => {
    // Update tab value if URL param changes
    if (tabFromUrl && tabFromUrl !== tabValue) {
      setTabValue(tabFromUrl);
    }
  }, [location.search]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    navigate(`?tab=${newValue}`);
  };

  const getStatusForTab = (tabValue) => {
    switch (tabValue) {
      case "all":
        return "all"; // Draft
      case "Pending":
        return "Pending"; // Submitted
      case "Approved":
        return "Approved"; // Submitted
      case "Rejected":
        return "Rejected"; // Submitted
      case "Returned":
        return "Returned"; // Submitted
      default:
        return null;
    }
  };
  console.log(
    "Passing status_filter to POConfigurationlist:",
    getStatusForTab(tabValue)
  );

  // Mock function to return notification IDs for a tab

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-list-content permit-tab" ref={containerRef}>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => handleTabChange(event, newValue)}
            aria-label="report tabs"
            className="tabs-container"
          >
            <Tab
              label={
                <div className="tab-label-container">
                  <span className="tab-status-label">All</span>
                </div>
              }
              value="all"
            />
            <Tab
              label={
                <div className="tab-label-container">
                  <span className="tab-status-label">Pending</span>
                </div>
              }
              value="Pending"
            />
            <Tab
              label={
                <div className="tab-label-container">
                  <span className="tab-status-label">Approved</span>
                </div>
              }
              value="Approved"
            />
            <Tab
              label={
                <div className="tab-label-container">
                  <span className="tab-status-label">Rejected</span>
                </div>
              }
              value="Rejected"
            />
            <Tab
              label={
                <div className="tab-label-container">
                  <span className="tab-status-label">Returned</span>
                </div>
              }
              value="Returned"
            />
          </Tabs>
          <div className="header-separater"></div>
          <div className="tabs-content">
            <PRRequestList
              status_filter={getStatusForTab(tabValue)}
              // updateTotalItems={updateTotalItems}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PermitRequestForRequesterTab;
