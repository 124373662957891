import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./ColdWorkPermitForm.css";
import "./ColdWorkPermitInspectionView.css";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import "react-datepicker/dist/react-datepicker.css";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import ColdWorkPermitTemplate from "./ColdWorkPermitTemplate";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function PermitPdfFormate({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { work_permit_id } = useParams();
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [workDetails, setWorkDetails] = useState({});
  const [clearanceRenewal, setClearanceRenewal] = useState({});
  const [permitDetails, setPermitDetails] = useState(null);
  const [checklist, setChecklist] = useState([]);
  const [precautions, setPrecautions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // For better UX
  const [reason, setReason] = useState(""); // Track reason input
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const pdfRef = useRef(null);
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);

  const handleApproveConfirm = () => {
    setIsApprovePopupOpen(true);
  };

  const handleRejectConfirm = () => {
    handleRejectClick(); // Call the original reject function
    setShowRejectPopup(false); // Close popup after rejection
  };

  const closeApprovePopup = () => {
    setIsApprovePopupOpen(false);
  };

  const confirmApprove = () => {
    handleSaveClick(); // Call the actual approve function
    setIsApprovePopupOpen(false); // Close popup after confirming
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const generatePDF = async () => {
    if (!pdfRef.current) return;

    const pdf = new jsPDF("p", "mm", "a4");

    const tempDiv = document.createElement("div");
    tempDiv.style.width = "794px"; // A4 width in px
    tempDiv.style.padding = "20px";
    tempDiv.style.background = "white";
    tempDiv.appendChild(pdfRef.current.cloneNode(true));

    document.body.appendChild(tempDiv);

    const section1 = tempDiv.querySelector("#section1");
    const section2 = tempDiv.querySelector("#section2");

    if (section1) {
      const canvas1 = await html2canvas(section1, { scale: 2 });
      const imgData1 = canvas1.toDataURL("image/png");
      pdf.addImage(imgData1, "PNG", 10, 10, 190, 277); // Fit A4 size
    }

    if (section2) {
      pdf.addPage();
      const canvas2 = await html2canvas(section2, { scale: 2 });
      const imgData2 = canvas2.toDataURL("image/png");
      pdf.addImage(imgData2, "PNG", 10, 10, 190, 277);
    }

    document.body.removeChild(tempDiv);

    const pdfBlob = pdf.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setPdfUrl(pdfUrl);
  };

  useEffect(() => {
    if (!permitDetails) return;
    generatePDF();
  }, [permitDetails]);

  useEffect(() => {
    const fetchPermitDetails = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_permit_details?work_permit_id=${work_permit_id}`,
          {
            headers: {
              Authorization: authKey, // Use 'Bearer' prefix if required
            },
          }
        );

        if (response.data) {
          // Set all states from the single API response
          setPermitDetails(response.data);
          setChecklist(response.data.checklistItems || []);
          setWorkDetails(response.data.workDetails || {});
          setClearanceRenewal(response.data.workDetails || {});
          setPrecautions(response.data.hazardsPrecautions || [{}]);
        } else {
          console.warn("No permit details found.");
        }
      } catch (err) {
        console.error("Error fetching permit details:", err);
        setError("Failed to load permit details.");
      } finally {
        setLoading(false);
      }
    };

    fetchPermitDetails();
  }, [work_permit_id]);

  // Render the component conditionally
  if (!permitDetails || !permitDetails.workDetails) {
    return <div>Loading...</div>; // or some other loading state
  }

  const handleSaveRejectClick = async () => {
    const payload = {
      work_permit_id: work_permit_id, // Maps to `permit_id` in backend
      request_type: "new", // Set `request_type` as "new"
      reason: reason, // From the form input
    };

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      if (!authKey) {
        throw new Error("Authorization key not found");
      }

      // Make the API call
      const response = await axios.post(
        `${API_URL}/api/reject_permit`, // Ensure the method is POST
        payload,
        {
          headers: { Authorization: authKey },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Permit rejected successfully.");
        setTimeout(() => {
          navigate(`/cold-work-permit`);
        }, 1000);
      } else {
        throw new Error(response.data.message || "Failed to reject permit.");
      }
    } catch (error) {
      console.error("Error rejecting permit:", error);
      setSeverity("error");
      showSnackbar(
        error.response?.data?.message ||
          "An error occurred while rejecting the permit."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveClick = async () => {
    try {
      // Retrieve the authKey from localStorage
      const authKey = localStorage.getItem("authKey");

      if (!authKey) {
        console.error("Authorization key not found");
        return;
      }

      // API call to approve the work permit
      const response = await axios.put(
        `${API_URL}/api/approve_work_permit`,
        { work_permit_id: work_permit_id }, // Send form_id in the request body
        {
          headers: {
            Authorization: authKey, // Pass the Authorization header
          },
        }
      );

      // Check the API response status

      if (response.status === 200) {
        console.log(response.data.message);
        setSeverity("success");
        showSnackbar("Work permit approved successfully!");
        setTimeout(() => {
          navigate("/work-permit-inspection");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving department:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRejectClick = () => {
    setIsPopupOpen(true);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleBack = () => {
    window.history.back();
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBack}
                  />
                </Tooltip>
                Cold Work Permit Request View
              </div>
              <div className="data-form-heading-content-right-side">
                {/* {workDetails.request_status !== "Rejected" && (
                  <button
                    className={`data-form-save-button ${
                      workDetails.request_status === "Approved" ||
                      workDetails.request_status === "Issued" ||
                      workDetails.request_status === "Rejected" ||
                      workDetails.request_status === "Renewed" ||
                      workDetails.request_status === "Close Under Review" ||
                      workDetails.request_status === "Extension Under Review"
                        ? "disabled"
                        : ""
                    }`}
                    type="button"
                    onClick={handleSaveClick}
                    disabled={
                      isLoading ||
                      workDetails.request_status === "Approved" ||
                      workDetails.request_status === "Close Under Review" ||
                      workDetails.request_status === "Issued" ||
                      workDetails.request_status === "Rejected" ||
                      workDetails.request_status === "Renewed" ||
                      workDetails.request_status === "Extension Under Review"
                    }
                  >
                    {workDetails.request_status === "Approved" ||
                    workDetails.request_status === "Renewed" ||
                    workDetails.request_status === "Issued" ||
                    workDetails.request_status === "Rejected" ||
                    workDetails.request_status === "Close Under Review" ||
                    workDetails.request_status === "Extension Under Review"
                      ? "Approved"
                      : isLoading
                      ? "Processing..."
                      : "Approve"}
                  </button>
                )} */}
                {workDetails.request_status !== "Rejected" && (
                  <button
                    className={`data-form-save-button approverd-button-permit${
                      workDetails.request_status === "Approved" ||
                      workDetails.request_status === "Issued" ||
                      workDetails.request_status === "Rejected" ||
                      workDetails.request_status === "Renewed" ||
                      workDetails.request_status === "Closed" ||
                      workDetails.request_status === "Close Under Review" ||
                      workDetails.request_status === "Extension Under Review"
                        ? "disabled"
                        : ""
                    }`}
                    type="button"
                    onClick={handleApproveConfirm} // Open confirmation popup
                    disabled={
                      isLoading ||
                      workDetails.request_status === "Approved" ||
                      workDetails.request_status === "Close Under Review" ||
                      workDetails.request_status === "Issued" ||
                      workDetails.request_status === "Rejected" ||
                      workDetails.request_status === "Renewed" ||
                      workDetails.request_status === "Closed" ||
                      workDetails.request_status === "Extension Under Review"
                    }
                  >
                    {workDetails.request_status === "Approved" ||
                    workDetails.request_status === "Renewed" ||
                    workDetails.request_status === "Issued" ||
                    workDetails.request_status === "Rejected" ||
                    workDetails.request_status === "Closed" ||
                    workDetails.request_status === "Close Under Review" ||
                    workDetails.request_status === "Extension Under Review"
                      ? "Approved"
                      : isLoading
                      ? "Processing..."
                      : "Approve"}
                  </button>
                )}
                {isApprovePopupOpen && (
                  <div className="permit-approval-popup">
                    <div className="permit-approval-popup-content">
                      <div className="approval-popup-header">
                        Confirm Approval
                      </div>
                      <p className="approval-popup-message">
                        Are you sure you want to approve this permit?
                      </p>
                      <div className="approval-popup-buttons">
                        <button
                          className="approve-button"
                          onClick={confirmApprove}
                        >
                          Yes, Approve
                        </button>
                        <button
                          className="cancel-button"
                          onClick={closeApprovePopup}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {/* {workDetails.request_status !== "Approved" && (
                  <button
                    className={`data-form-reject-button ${
                      workDetails.request_status === "Approved" ||
                      workDetails.request_status === "Issued" ||
                      workDetails.request_status === "Rejected" ||
                      workDetails.request_status === "Renewed" ||
                      workDetails.request_status === "Close Under Review" ||
                      workDetails.request_status === "Extension Under Review"
                        ? "disabled"
                        : ""
                    }`}
                    type="button"
                    onClick={handleRejectClick}
                    disabled={
                      isLoading ||
                      workDetails.request_status === "Approved" ||
                      workDetails.request_status === "Close Under Review" ||
                      workDetails.request_status === "Issued" ||
                      workDetails.request_status === "Rejected" ||
                      workDetails.request_status === "Renewed" ||
                      workDetails.request_status === "Extension Under Review"
                    }
                  >
                    {workDetails.request_status === "Rejected"
                      ? "Rejected"
                      : isLoading
                      ? "Processing..."
                      : "Reject"}
                  </button>
                )} */}

                {workDetails.request_status !== "Approved" && (
                  <button
                    className={`data-form-reject-button ${
                      workDetails.request_status === "Approved" ||
                      workDetails.request_status === "Issued" ||
                      workDetails.request_status === "Rejected" ||
                      workDetails.request_status === "Renewed" ||
                      workDetails.request_status === "Closed" ||
                      workDetails.request_status === "Close Under Review" ||
                      workDetails.request_status === "Extension Under Review"
                        ? "disabled"
                        : ""
                    }`}
                    type="button"
                    onClick={() => setShowRejectPopup(true)}
                    disabled={
                      isLoading ||
                      workDetails.request_status === "Approved" ||
                      workDetails.request_status === "Close Under Review" ||
                      workDetails.request_status === "Issued" ||
                      workDetails.request_status === "Rejected" ||
                      workDetails.request_status === "Renewed" ||
                      workDetails.request_status === "Closed" ||
                      workDetails.request_status === "Extension Under Review"
                    }
                  >
                    {workDetails.request_status === "Rejected"
                      ? "Rejected"
                      : isLoading
                      ? "Processing..."
                      : "Reject"}
                  </button>
                )}

                {/* Reject Confirmation Popup */}
                {showRejectPopup && (
                 <div className="confirm-delete-popup">
                 <div className="confirm-delete-popup-content">
                   <div className="confirm-model">
                     <div className="confirm-model-content">
                       <div className="confirm-model-content-body">
                      <p>Are you sure you want to reject this request?</p>
                      </div>
                      <div className="confirm-model-content-footer">
                        <button
                          className="reject-confirm-button"
                          onClick={handleRejectConfirm}
                        >
                          Yes, Reject
                        </button>
                        <button
                          className="reject-cancel-button"
                          onClick={() => setShowRejectPopup(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="header-separator"></div>

          <div className="data-form-body5656">
            {pdfUrl ? (
              <iframe
                src={pdfUrl}
                width="100%"
                height="600px"
                title="PDF Preview"
              ></iframe>
            ) : (
              <p>Generating PDF...</p>
            )}
          </div>
          <div className="pdf-view-data" style={{ display: "none" }}>
            <div ref={pdfRef}>
              {permitDetails ? (
                <ColdWorkPermitTemplate
                  permitDetails={permitDetails}
                  isSidebarExpanded={isSidebarExpanded}
                />
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </div>

        {isPopupOpen && (
          <div className="permit-popup-model">
            <div className="permit-popup-content">
              <div className="reminder-popup-model-header">
                <div className="reminder-popup-model-content-header">
                  <div className="reminder-popup-model-content-header-left-part">
                    Reject Request Permit
                  </div>
                  <div className="reminder-popup-model-content-header-right-part"></div>
                </div>
                <div className="popup-new-form">
                  <form className="permit-popup-form-detail">
                    <div className="new-data-form">
                      <div className="form-group email-filed">
                        <label htmlFor="reason">
                          Reason <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <textarea
                            id="reason"
                            name="reason"
                            placeholder="Enter reason for rejected"
                            value={reason}
                            onChange={handleReasonChange}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="footers">
                  <div className="button-group-email">
                    <div>
                      <button
                        className="person-user-save-button"
                        type="button"
                        // onClick={() => handleSaveRejectClick(selectedWorkPermitId)}
                        onClick={handleSaveRejectClick}
                        disabled={isLoading}
                      >
                        {/* {isLoading ? "Saving..." : "Save"} */}
                        Ok
                      </button>

                      <button
                        className="person-user-cancel-button"
                        onClick={closePopup}
                      >
                        Cancel
                      </button>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PermitPdfFormate;
