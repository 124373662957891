import React, { useState, useRef } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../Config";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiUpload } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";

function ReturnableForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [departmentData, setDepartmentData] = useState({
    description: "",
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setErrors((prevErrors) => ({ ...prevErrors, date: null }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDepartmentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleDeleteFile = (indexToDelete) => {
    console.log("Delete function called for index:", indexToDelete);
    console.log("Before delete:", selectedFiles);

    // Filter out the file at the given index
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state
    setSelectedFiles(updatedFiles);
    console.log("After delete:", updatedFiles);

    // Check if no files remain, then close the popup
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
      console.log("Popup closed");
    }
  };

  const handleIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(true);
  };

  const handleCancelClick = () => {
    navigate("/returnable-items");
  };

  const validateForm = () => {
    const validationErrors = {};
    if (!selectedDate) {
      validationErrors.date = "Date is required.";
    }
 
    if (selectedFiles.length === 0) {
      validationErrors.attachment = "At least one attachment is required.";
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSaveClick = async () => {
    if (!validateForm()) return;

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      const formData = new FormData();

      const formattedDate = selectedDate.toLocaleDateString('en-CA'); // 'en-CA' format is YYYY-MM-DD
      formData.append("description", departmentData.description);
      formData.append("date", formattedDate);
      selectedFiles.forEach((file) => formData.append("attachments", file));
      
      const response = await axios.post(`${API_URL}/api/add_returnable_item`, formData, {
        headers: {
          Authorization: authKey,
          "Content-Type": "multipart/form-data",
        },
      });
      
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Department added successfully");
        setTimeout(() => navigate("/returnable-items"), 1000);
      }
    } catch (error) {
      console.error("Error saving department:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleUploadButtonClick = (inputRef) => {
    // This function handles the file upload button click
    // Open the file input dialog
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileInputChange = (files, setFiles) => {
    const fileArray = Array.from(files);
    setFiles(fileArray);
  };

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Returnable Item
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
           
                <div className="form-group">
                  <label htmlFor="DepartmentName">
                    Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                  <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      name="date"
                      id="date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {errors.date && <div className="error-message">{errors.date}</div>}
                </div>
              
                  <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <div className="input-group">
                        <textarea
                          type="text"
                          name="description"
                          // value={prRequestData.description}
                          onChange={handleInputChange}
                          placeholder="Enter description"
                        />
                      </div>
                    </div>
                <div className="form-group">
                  {/* <div className="form-group"> */}
                  <label htmlFor="wage_register">Attachment<span className="text-danger">*</span></label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={() => handleUploadButtonClick(fileInputRef)}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    id="wage_register"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={(e) =>
                      handleFileInputChange(e.target.files, setSelectedFiles)
                    }
                    multiple
                  />
                  {selectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleIconClick}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedFiles.length}`}
                    </span>
                  )}
                  {isPopupOpen && (
                    <div className="attach-popup-attachments">
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.name}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      {" "}
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {/* </div> */}
                  {errors.attachment && <div className="error-message">{errors.attachment}</div>}
                </div>
                
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ReturnableForm;
