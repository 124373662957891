import React, { useState, useEffect } from "react";
// import "./Template2.css";
import { useParams } from "react-router-dom";

const HotWorkTemplate7 = ({ hazardsPrecautions, isSidebarExpanded }) => {
  const { formId } = useParams();
  return (
    <>
      <div className="hot-template-container">
        <div className="hot-template-container-content underline-bottom">
          <div className="template-container-pera">
            <div className="cold-remarks">
              <div className="template-heading hot-heading">
                सामान्य अनुदेश/General Instruction
              </div>
              <div className="pera-flex hot-pera">
                <span style={{ width: "15px", paddingRight: "15px" }}>1.</span>
                <div>
                  कार्य के अनुमती पत्र को स्पष्ट रूप से और सही रूपसे स्पष्ट
                  अक्षरों में भरा जाएगा, ताकी यह सुनिश्चित हो सके कि सभी
                  अनुभागों / उपखंडों में पुरी जानकारी प्रदान <br /> की गई है।
                  गलत संचार से बचने के लिए जो भी संभव हो सके प्रदान किया जाना
                  चाहिए ।/ The work permit shall be filed up carefully and
                  accurately in clear <br /> handwriting ensuring that complete
                  information is provided in all sections / subsections and none of column is left blank. Sketches should be provided <br /> whatever possible to avoid mis
                  communication.
                </div>
              </div>
              <div className="pera-flex hot-pera">
                <span style={{ width: "15px", paddingRight: "15px" }}>2.</span>
                <div>
                  उपयुकत सुरक्षा उपायों और कर्मचारियों के व्यक्तिगत सुरक्षा
                  उपकरण (पीपीई) को संभावित खतरों के सावधानीपुर्वक विश्लेषन और
                  काम शुरू करने से पहले किये <br /> जाने वाले संचालन द्वारा
                  निर्धारित किया जाएग । / Appropriate safeguards and required
                  personnel protective equipment (PPEs) shall be determined by a
                  careful analysis <br /> of the potential hazards and
                  the operation to be performed prior to starting the work.
                </div>
              </div>
              <div className="pera-flex hot-pera">
                <span style={{ width: "15px", paddingRight: "15px" }}>3.</span>
                <div>
                  आग की चेतावनी / सावरन होने पर सभी कार्य तुरंत बंद दिया जाए।/In
                  case of fire alarm/ siren, all work must immediately be
                  stopped.
                </div>
              </div>
              <div className="pera-flex hot-pera">
                <span style={{ width: "15px", paddingRight: "15px" }}>4.</span>
                <div>
                  ऑपरेटिंग क्षेत्रों में केवल प्रमाणित वाहन इंजन और अनुमति
                  प्रकार के विद्युत उपकरण और उपकरण की अनुमति है।/Only certified
                  vehicle/engines and permitted <br /> type of electrical
                  equipment and tools are allowed in operating areas.
                </div>
              </div>
              <div className="pera-flex hot-pera">
                <span style={{ width: "15px", paddingRight: "15px" }}>5.</span>
                <div>
                  वेल्डिंग मशीन गैर खतरनाक और पर्याप्त हवा मात्रा क्षेत्रों में
                  श्थित होनी चाहिए। / Welding machines should be located in
                  non-hazardous and ventilated areas.
                </div>
              </div>
              <div className="pera-flex hot-pera">
                <span style={{ width: "15px", paddingRight: "15px" }}>6.</span>
                <div>
                  जब तक विस्फोटक मीटर रीडिंग शुन्य न हो, तब तक किसी भी हॉट काम
                  की अनुमति नहीं दी जानी चाहिए। / No hot work should be
                  permitted unless the
                  <br />
                  explosive meter reading is Zero.
                </div>
              </div>

              <div className="pera-flex hot-pera">
                <span style={{ width: "15px", paddingRight: "15px" }}>7.</span>
                <div>
                  कोई भी हॉट काम सीमित स्थान पर नहीं होना चाहिए, प्राप्तकर्ता को
                  मैनहोल या प्रवेश बिंद्र पर न्यूनतम दो स्टैंडबाई नामित व्यक्ति
                  रखना बाहिए। / When a person
                  <br />
                  entering confined space, the receive must keep minimum two
                  standby designated persons at the manhole or entry point.
                </div>
              </div>
              <div className="pera-flex hot-pera">
                <span style={{ width: "15px", paddingRight: "15px" }}>8.</span>
                <div>
                  किसी भी पोत मैनहोल ढका होना चाहिए बॉक्स उप होने से पहले
                  सुनिश्चित करें करें कि कोई भी पुरुष / सामग्री पोत के अंदर न हो
                  / Before box up of any vessel
                  <br />
                  manhole cover, ensure that no men/material are inside the
                  vessel.
                </div>
              </div>
              <div className="pera-flex hot-pera">
                <span style={{ width: "15px", paddingRight: "15px" }}>9.</span>
                <div>
                  कार्य निकासी के नवीनीकरण हेतु जारीकर्ता यह सुनिश्चित करेगा की
                  कार्य को जारी रखने के लिए स्थिती संतोषप्रद है। यदि स्थिती बदल
                  गई है तो यह जरूरी है की
                  <br />
                  नया अनुमती पत्र जारी किया जाए अथवा मौजूदा परमिट में संशोधन
                  किया जाए। / For renewal of work clearance, the issuer shall
                  ensure that the conditions are
                  <br />
                  satisfactory for the work to continue. If the conditions have
                  changed, it may be necessary to issue a new permit or amend
                  the existing permit.
                </div>
              </div>
              <div className="pera-flex hot-pera">
                <span style={{ width: "15px", paddingRight: "15px" }}>10.</span>
                <div>
                  उसी अनुमती पत्र पर क्लीयरेंस का नवीनीकरण। उसको बढाया जाना
                  आछिकतम सात कैलेंडर दिनों तक किया जा सकता है। / This clearance
                  on the same permit
                  <br />
                  can be renewed/ extended up to a maximum of seven calendar
                  days.
                </div>
              </div>
              <div className="pera-flex hot-pera">
                <span style={{ width: "15px", paddingRight: "15px" }}>11.</span>
                <div>
                यह अनुमती पत्र कार्यक्षेत्र पर हमेशा उपलब्ध रहना चाहिए। / This permit must be available at work site at all times. 
                </div>
              </div>
              <div className="pera-flex hot-pera">
                <span style={{ width: "15px", paddingRight: "15px" }}>12.</span>
                <div>
                काम पूरा होने पर, अनुमती पत्र को बंद कर दिया जाएगा और रिकॉर्ड के रूप में रखा जाएगा। /On completion of the work, the permit must be closed and kept as
                  <br />
                  can be renewed/ extended up to a maximum of seven calendar
                  days.
                </div>
              </div>
              <div className="pera-flex hot-pera">
                <span style={{ width: "15px", paddingRight: "15px" }}>13.</span>
                <div>
                संचालन और रखरखाव प्रथाओं के आधार पर अतिरिक्त निर्देश / The industry may add other relevant instruction based on their operating and maintenance
                  <br />
                  practices 
                </div>
              </div>
              <div className="pera-flex hot-pera">
                <span style={{ width: "15px", paddingRight: "15px" }}>14.</span>
                <div>
                सुरक्षा अधिकारी के परामर्श के बाद ही उसी दिन के कार्य अनुमती पत्र को विस्तारित किया जाता है। In case of permit extension for the same day, permit to be
                  <br />
                  extended only after consulting with the safety officer.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HotWorkTemplate7;
