import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../../src/Config";
import { FaPlusCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

function AMCCustomerEditForm({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [customerData, setCustomerData] = useState({
    customer_name: "",
    locations: [
      {
        location: "",
        contact_person_name: "",
        location_contact_no: "",
        location_email: "",
        location_address: "",
      },
    ],
    address: "",
    contact_no: "",
  });

  const [error, setError] = useState({
    contact_no: '', // Specific error message for contact_no
  });
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_amc_customer/${id}`, {
          headers: {
            Authorization: authKey,
          },
        });
        const responseData = response.data;

        // Update state with fetched data
        setCustomerData({
          customer_name: responseData.customer_name || "",
          locations:
            responseData.locations.map((location) => ({
              ...location,
              contact_person_name: location.contact_person_name || "",
              location_contact_no: location.contact_no || "",
              location_email: location.email || "",
              location_address: location.address || "",
            })) || [],
          address: responseData.address || "",
          contact_no: responseData.contact_no || "",
        });
      } catch (error) {
        console.error("Error fetching customer data:", error.message);
        setError("An error occurred while fetching customer data");
      }
    };

    fetchCustomerData();
  }, [id]);

  // const handleInputChange = (e, index = null, field = null) => {
  //   const { name, value } = e.target;
  //   if (index !== null && field) {
  //     const updatedLocations = [...customerData.locations];
  //     updatedLocations[index] = {
  //       ...updatedLocations[index],
  //       [field]: value,
  //     };
  //     setCustomerData({ ...customerData, locations: updatedLocations });
  //   } else {
  //     setCustomerData({ ...customerData, [name]: value });
  //   }
  // };
  const handleInputChange = (e, index = null, field = null) => {
    const { name, value } = e.target;

    if (name === "contact_no") {
      if (value.length > 10) {
        setError((prevState) => ({
          ...prevState,
          contact_no: "Contact number cannot exceed 10 digits",
        }));
        return; // Exit early if validation fails
      } else {
        setError((prevState) => ({
          ...prevState,
          contact_no: "", // Clear error if valid
        }));
      }
    }
  



    if (index !== null && field) {
      const updatedLocations = [...customerData.locations];
      updatedLocations[index] = {
        ...updatedLocations[index],
        [field]: value,
      };
      setCustomerData({ ...customerData, locations: updatedLocations });
    } else {
      setCustomerData({ ...customerData, [name]: value });
    }
  };
  const handleCancelClick = () => {
    // navigate("/customer");
    window.history.back();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };


  const handleSaveClick = async () => {
    let error = false;
  
    // Check for empty required fields
    if (!customerData.customer_name || !customerData.contact_no) {
      error = true;
      setSeverity("error");
      showSnackbar("Customer name and contact number are required");
      return; // Exit the function if there are validation errors
    }
    if (customerData.contact_no.length < 10) {
      setSeverity("error");
      showSnackbar("Contact number must be at least 10 digits", "error");
      return; // Exit the function if there are validation errors
    }
    
    // Validate if any location has an empty location name
    const updatedLocations = customerData.locations.filter(
      (location) => location.location.trim() !== ""
    );
  
    const updatedCustomerData = {
      ...customerData,
      locations: updatedLocations,
    };
  
    if (!error) {
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem("authKey");
        const response = await axios.put(
          `${API_URL}/api/update_amc_customer/${id}`,
          updatedCustomerData,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        console.log(response.data.message);
        if (response.status === 200) {
          setSeverity("success");
          showSnackbar("Data updated successfully");
          setTimeout(() => {
            navigate("/amc-customers");
          }, 1000);
        }
      } catch (error) {
        console.error("Error updating company:", error.message);
        setSeverity("error");
        showSnackbar("An error occurred while updating customer data");
        setIsLoading(false);
      }
    }
  };
  
  const handleAddLocation = () => {
    setCustomerData({
      ...customerData,
      locations: [
        ...customerData.locations,
        {
          location: "",
          contact_person_name: "",
          location_contact_no: "",
          location_email: "",
          location_address: "",
        },
      ],
    });
  };

  const handleRemoveLocation = (index) => {
    const updatedLocations = customerData.locations.filter(
      (_, i) => i !== index
    );
    setCustomerData({ ...customerData, locations: updatedLocations });
  };

  const handleBackProject = () => {
    navigate("/amc-customers");
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />
                </Tooltip>
                Edit AMC Customer
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="" onKeyDown={handleKeyDown}>
              <div className="new-data-edit-form">
                <div className="form-group">
                  <label htmlFor="customer_name">
                    Customer Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="customer_name"
                      value={customerData.customer_name}
                      onChange={handleInputChange}
                      placeholder="Enter customer name"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="contact_no">
                    Contact No<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="contact_no"
                      value={customerData.contact_no}
                      onChange={handleInputChange}
                      placeholder="Enter contact no"
                    />
                  </div>
                  {error.contact_no && (
                      <div className="error-message">{error.contact_no}</div>
                    )}
                </div>
                <div className="form-group">
                  <label htmlFor="address">
                    Address
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="address"
                      value={customerData.address}
                      onChange={handleInputChange}
                      placeholder="Enter address"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Locations
                  </label>
                  <div className="new-frequency-rowss">
                    {customerData.locations.map((location, index) => (
                      <div key={index} className="location-group-content">
                        <div className="location-group">
                          <div className="input-group frequency-row">
                            <input
                              type="text"
                              className="point-fr"
                              name={`location_${index}`}
                              value={location.location}
                              onChange={(e) =>
                                handleInputChange(e, index, "location")
                              }
                              placeholder={`Enter location ${index + 1}`}
                            />
                          </div>
                          <div className="flex-group">
                          <div>
                          <div className="input-group frequency-row">
                            <input
                              type="text"
                              className="point-fr"
                              name={`contact_person_name_${index}`}
                              value={location.contact_person_name}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  index,
                                  "contact_person_name"
                                )
                              }
                              placeholder={`Enter contact person name`}
                            />
                          </div>
                          <div className="input-group frequency-row">
                            <input
                              type="text"
                              className="point-fr"
                              name={`location_contact_no_${index}`}
                              value={location.location_contact_no}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  index,
                                  "location_contact_no"
                                )
                              }
                              placeholder={`Enter location contact number`}
                            />
                          </div>
                          <div className="input-group frequency-row">
                            <input
                              type="text"
                              className="point-fr"
                              name={`location_email_${index}`}
                              value={location.location_email}
                              onChange={(e) =>
                                handleInputChange(e, index, "location_email")
                              }
                              placeholder={`Enter location email`}
                            />
                          </div>
                          </div>
                          <div>
                          <div className="input-group frequency-row">
                            
                             <textarea
                                  className="point-fr"
                                  name={`location_address_${index}`}
                              value={location.location_address}
                              onChange={(e) =>
                                handleInputChange(e, index, "location_address")
                              }
                              placeholder={`Enter location address`}
                                  cols="30"
                                  rows="6.9"
                                ></textarea>
                          </div>
                        </div>
                        </div>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="add-close-button"
                            onClick={() => handleRemoveLocation(index)}
                            disabled={customerData.locations.length === 1}
                          >
                            <IoClose className="frequency-close-icon" />
                          </button>
                        </div>
                      </div>
                    ))}

                    <div className="add-new-frequency-customer">
                      <button
                        className="add-new-buttons"
                        type="button"
                        onClick={handleAddLocation}
                      >
                        <FaPlusCircle className="frequency-plus-icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default AMCCustomerEditForm;
