import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import "./PRRequestView.css";

function PRRequest({ isSidebarExpanded }) {
  const { pr_request_id } = useParams();
  const [prRequestData, setPRRequestData] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchPRRequestData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await fetch(
          `${API_URL}/api/get_pr_request_list/${pr_request_id}`,
          {
            method: "GET",
            headers: {
              Authorization: authKey,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setPRRequestData(data);
        } else {
          console.error("Error fetching PR request data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching PR request data:", error.message);
      }
    };

    fetchPRRequestData();
  }, [pr_request_id]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const fetchPRRequestMatcodeData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const prRequestResponse = await fetch(
        `${API_URL}/api/get_pr_request/${pr_request_id}`,
        {
          method: "GET",
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (prRequestResponse.ok) {
        const prRequestData = await prRequestResponse.json();

        const prRequest = prRequestData.pr_request || {};
        const poListItems = prRequestData.po_list_items || [];

        setPRRequestData({ ...prRequest, po_list_items: poListItems });
      } else {
        console.error(
          "Error fetching PR request:",
          prRequestResponse.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching PR request data:", error.message);
    }
  };

  useEffect(() => {
    fetchPRRequestMatcodeData();
  }, [pr_request_id]);
  // const handleAction = async (action) => {
  //   const authKey = localStorage.getItem("authKey");
  //   const status = action;
  //   if (
  //     (status === "Rejected" || status === "Returned") &&
  //     remark.trim() === ""
  //   ) {
  //     setSnackbarMessage(`Remarks are required for ${status} action`);
  //     setSnackbarSeverity("error");
  //     setSnackbarOpen(true);
  //     return;
  //   }
  //   try {
  //     const response = await axios.put(
  //       `${API_URL}/api/accept_pr_request/${pr_request_id}`,
  //       {
  //         pr_status: status,
  //         remarks: remark,
  //       },
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setSnackbarSeverity("success");
  //       setSnackbarMessage(`PR Request ${status}ed successfully`);
  //       setTimeout(() => navigate("/pr-requests-list"), 1000);
  //       setSnackbarOpen(true);
  //       // navigate("/pr-requests-list");
  //     } else {
  //       setSnackbarMessage(`Failed to ${status} PR Request`);
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //     }
  //   } catch (error) {
  //     setSnackbarMessage(`An error occurred while ${status}ing PR Request`);
  //     setSnackbarSeverity("error");
  //     setSnackbarOpen(true);
  //   }
  // };

  

  if (!prRequestData) {
    return <div>Loading...</div>;
  }

  const handleBackScheduler = () => {
    navigate("/pr-request");
  };


  return (
    <>
      <div
        className={`invoice-request-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="invoice-request-content">
          <div className="invoice-request-header">
            <div className="invoice-request-header-content">
              <div className="invoice-request-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackScheduler}
                  />
                </Tooltip>
                PR Request Details
              </div>
            </div>
            <div className="action-purchase-header">
              <div className="action-purchase-header-content">
                {prRequestData && (
                  <div>
                    <div className="project-detailss">
                      <div className="project-detail-arround">
                        <div className="project-details-left-side">
                          {/* <div className="project-detail">
                            <div className="detail-header">PR Request ID:</div>
                            <div className="detail-part">
                              {prRequestData.pr_request_id}
                            </div>
                          </div> */}
                          <div className="project-detail">
                            <div className="detail-header">Department:</div>
                            <div className="detail-part">
                              {prRequestData.department_name || "NA"}
                            </div>
                          </div>
                          <div className="project-detail">
                            <div className="detail-header">Description:</div>
                            <div className="detail-part">
                              {prRequestData.description || "NA"}
                            </div>
                          </div>

                          <div className="project-detail">
                            <div className="detail-header">
                              Expected Delivery Date:
                            </div>
                            <div className="detail-part">
                              {prRequestData.expected_delivery_date
                                ? new Date(prRequestData.expected_delivery_date).toLocaleDateString('en-IN', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit'
                                })
                                : "NA"}

                            </div>
                          </div>
                          <div className="project-detail">
                            <div className="detail-header">PR Type:</div>
                            <div className="detail-part">
                              {prRequestData.pr_type || "NA"}
                            </div>
                          </div>
                          <div className="project-detail">
                            <div className="detail-header">Vendor Name:</div>
                            <div className="detail-part">
                              {prRequestData.vendor_name || "NA"}
                            </div>
                          </div>
                          <div className="project-detail">
                            <div className="detail-header">Status:</div>
                            <div className="detail-part">
                              {prRequestData.pr_status || "NA"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          
            <div className="tab-pane">
                    <div className="action-purchase-sheet-header">
                      <div className="actions-purchase-sheet-header-content">
                        <div className="actions-purchase-sheet-heading-content-left-side">
                          Item Table
                        </div>
                        <div className="actions-purchase-heading-content-right-side"></div>
                      </div>
                    </div>

                    <div className="action-purchase-body">
                      <div className="action-purchase-scroll-body">
                        <div className="action-purchase-tables">
                          <>
                            <table className="action-table action-multilevel-data ">
                              <thead className="action-purchase-heads">
                                <tr>
                                  <th className="th2">MAT CODE</th>
                                  <th className="th2">MAT CODE DESCRIPTION</th>
                                
                                  <th className="th4">QUANTITY</th>
                                  <th className="th3">UNIT</th>
                                  <th className="th5">ESTIMATED UNIT RATE</th>
                                </tr>
                              </thead>

                              <tbody className="action-purchase-scroll-bodys">
                                {prRequestData.po_list_items &&
                                prRequestData.po_list_items.length > 0 ? (
                                  prRequestData.po_list_items.map((item) => (
                                    <tr key={item.id}>
                                      <td>
                                        {item.mat_code ? item.mat_code : "N/A"}
                                      </td>
                                      <td>
                                        {item.mat_code_description
                                          ? item.mat_code_description
                                          : "N/A"}
                                      </td>
                                    
                                      <td>
                                        {item.quantity !== null
                                          ? item.quantity
                                          : "N/A"}
                                      </td>
                                      <td>{item.unit ? item.unit : "N/A"}</td>
                                      <td>
                                        {item.est_unit_rate
                                          ? item.est_unit_rate
                                          : "N/A"}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="5">No items available</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PRRequest;
