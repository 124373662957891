import React, { useState, useEffect, useRef } from "react";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { IoMdArrowBack } from "react-icons/io";
import useClickOutside from "../useClickOutside";
import ColdWorkPermitTemplate from "./ColdWorkPermitTemplate";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactDOM from "react-dom";

function Closerequesttab({ isSidebarExpanded }) {
  const [designations, setDesignations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const { work_permit_id } = useParams();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isActionPopupOpen, setIsActionPopupOpen] = useState(false);
  const [reason, setReason] = useState(""); // Track reason input
  const [selectedRenewalId, setSelectedRenewalId] = useState(null);
  const [actionDesignations, setActionDesignations] = useState([]);
  const [workPermitId, setWorkPermitId] = useState(null); // State for work_permit_id
  const [renewalId, setRenewalId] = useState(null); // State for renewal_id
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState(false);
  const [selectedPermitId, setSelectedPermitId] = useState(null);
  const [isRejectPopupOpen, setIsRejectPopupOpen] = useState(false);
  const [workDetails, setWorkDetails] = useState({});
  const [clearanceRenewal, setClearanceRenewal] = useState({});
  const [permitDetails, setPermitDetails] = useState(null);
  const [checklist, setChecklist] = useState([]);
  const [precautions, setPrecautions] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const pdfRef = useRef(null);
  const mapStatusToYesNo = (status) => (status === 1 ? "Yes" : "No");
  const handleApproveClick = (permitId) => {
    setSelectedPermitId(permitId);
    setIsApprovePopupOpen(true);
  };

  // useEffect(() => {
  //   const fetchPermitDetails = async () => {
  //     try {
  //       const authKey = localStorage.getItem("authKey");
  //       const response = await axios.get(
  //         `${API_URL}/api/get_permit_details?work_permit_id=${work_permit_id}`,
  //         {
  //           headers: {
  //             Authorization: authKey, // Use 'Bearer' prefix if required
  //           },
  //         }
  //       );

  //       if (response.data) {
  //         // Set all states from the single API response
  //         setPermitDetails(response.data);
  //         setChecklist(response.data.checklistItems || []);
  //         setWorkDetails(response.data.workDetails || {});
  //         setClearanceRenewal(response.data.workDetails || {});
  //         setPrecautions(response.data.hazardsPrecautions || [{}]);
  //       } else {
  //         console.warn("No permit details found.");
  //       }
  //     } catch (err) {
  //       console.error("Error fetching permit details:", err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchPermitDetails();
  // }, [work_permit_id]);

  // const handleSaveRejectClick = async (work_permit_id, renewal_id) => {
  //   if (!reason.trim()) {
  //     setSeverity("error");
  //     showSnackbar("Reason is required to reject the permit.");
  //     return;
  //   }

  //   const payload = {
  //     work_permit_id, // Maps to `permit_id` in backend
  //     renewal_id,
  //     request_type: "Extension", // Set `request_type` as "Close"
  //     reason: reason.trim(), // Trim whitespace from the reason
  //   };

  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");
  //     if (!authKey) {
  //       throw new Error("Authorization key not found");
  //     }

  //     // Make the API call
  //     const response = await axios.post(
  //       `${API_URL}/api/reject_permit`,
  //       payload,
  //       {
  //         headers: { Authorization: authKey },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Permit rejected successfully.");
  //       closePopup();
  //       // setTimeout(() => {
  //       //   navigate(`/cold-work-permit`);
  //       // }, 1000);
  //     } else {
  //       throw new Error(response.data.message || "Failed to reject permit.");
  //     }
  //   } catch (error) {
  //     console.error("Error rejecting permit:", error);
  //     setSeverity("error");
  //     showSnackbar(
  //       error.response?.data?.message ||
  //         "An error occurred while rejecting the permit."
  //     );
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveRejectClick = async () => {
    if (!reason.trim()) {
      setSeverity("error");
      showSnackbar("Reason is required to reject the permit.");
      return;
    }

    const payload = {
      work_permit_id: workPermitId, // Get from state
      renewal_id: renewalId, // Get from state
      request_type: "Closer", // Set `request_type` as "Close"
      reason: reason.trim(), // Trim whitespace from the reason
    };

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      if (!authKey) {
        throw new Error("Authorization key not found");
      }

      // Make the API call
      const response = await axios.post(
        `${API_URL}/api/reject_permit`,
        payload,
        {
          headers: { Authorization: authKey },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Permit rejected successfully.");
        // closePopup();
        setTimeout(() => {
          navigate(`/cold-work-permit?tab=Closed`);
        }, 1000);
      } else {
        throw new Error(response.data.message || "Failed to reject permit.");
      }
    } catch (error) {
      console.error("Error rejecting permit:", error);
      setSeverity("error");
      showSnackbar(
        error.response?.data?.message ||
          "An error occurred while rejecting the permit."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRejectClick = (work_permit_id, renewal_id) => {
    setIsPopupOpen(true);
    // Save these IDs in the state or use them directly in the popup
    setWorkPermitId(work_permit_id); // Save work_permit_id in the state
    setRenewalId(renewal_id); // Save renewal_id in the state
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return "N/A";

    // Create a Date object from the date string
    const date = new Date(dateString);

    // Convert to UTC and format time
    const time = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC",
    });

    // Format date to DD/MM/YYYY using UTC date methods
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based in JS
    const year = date.getUTCFullYear();

    // Combine to form the date string in DD/MM/YYYY format
    const dateStr = `${day}/${month}/${year}`;

    return `${dateStr}, ${time}`;
  };

  const handleBack = () => {
    window.history.back();
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const fetchData = async () => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_permit_closure`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          work_permit_id: work_permit_id,
        },
      });

      setDesignations(response.data);
      // setDesignations(response.data || [{}]);
      setTotalItems(response.data.TotalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
      setLoading(false);
    }
  };

  // const fetchRenewalData = async (id) => {
  //   try {
  //     setLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.get(`${API_URL}/api/get_permit_renewal`, {
  //       headers: {
  //         Authorization: authKey,
  //       },
  //       params: {
  //         renewal_id: id,
  //       },
  //     });

  //     setDesignations(response.data);
  //     // setDesignations(response.data || [{}]);
  //     setTotalItems(response.data.TotalItems);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching department data:", error.message);
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchRenewalData();
  // }, []); // Empty dependency array

  const handleView = (workPermitId) => {
    // navigate(`/cold-work-permit-inspection-view/${workPermitId}`);
    navigate(`/view-pdf-formate/${workPermitId}`);
  };

  const fetchRenewalData = async (renewalId) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_permit_renewal`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          renewal_id: renewalId, // Use the passed renewal ID
        },
      });

      setActionDesignations(response.data); // Update state with the fetched data
      setLoading(false);
    } catch (error) {
      console.error("Error fetching renewal data:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isActionPopupOpen && selectedRenewalId) {
      fetchRenewalData(selectedRenewalId);
    }
  }, [isActionPopupOpen, selectedRenewalId]);

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_designations`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setDesignations(response.data.Designations);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchivedDesignation = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_designations`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDesignations = designations.filter(
          (designation) => !selectedRows.includes(designation.id)
        );
        setDesignations(updatedDesignations);
        fetchData();
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting designations:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
  };

  const handleRefresh = () => {
    if (searchInput) {
      console.log("Search input is set:", searchInput);
    } else {
      fetchData(page, itemsPerPage);
    }
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  const handleCloseRequest = async (work_permit_id) => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/issuer/approve_close_permit_request`,
        { work_permit_id: work_permit_id },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
      if (response.status === 200) {
        // setSeverity("success");
        showSnackbar("Work permit closed successfully!");
        await fetchData(page, itemsPerPage);
      }
    } catch (error) {
      console.error("Error saving department:", error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleConfirmApprove = (permitId) => {
    console.log(`Approving permit with ID: ${permitId}`);

    // Call your existing approve function
    handleCloseRequest(permitId);

    // Close the popup
    setIsApprovePopupOpen(false);
  };
  // Open reject popup
  const handleRejectOpen = () => {
    setIsRejectPopupOpen(true);
  };

  // Close reject popup
  const handleRejectClose = () => {
    setIsRejectPopupOpen(false);
  };

  const handleViewGenratePermit = async (formId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_permit_details`, {
        headers: { Authorization: authKey },
        params: { work_permit_id: formId },
      });

      if (response.status === 200) {
        const permitDetails = response.data;

        // Create a temporary container to render the template
        const tempDiv = document.createElement("div");
        document.body.appendChild(tempDiv);

        // Render the template to the temporary container
        ReactDOM.render(
          <ColdWorkPermitTemplate
            permitDetails={permitDetails}
            isSidebarExpanded={false} // Adjust based on your layout
          />,
          tempDiv
        );

        // Retrieve sections for PDF rendering
        const section1 = tempDiv.querySelector("#section1");
        const section2 = tempDiv.querySelector("#section2");

        if (!section1 || !section2) {
          console.error("Required sections for PDF generation not found.");
          ReactDOM.unmountComponentAtNode(tempDiv);
          document.body.removeChild(tempDiv);
          return;
        }

        const pdf = new jsPDF("p", "mm", "a4");
        const options = {
          scale: 2,
          useCORS: true,
          backgroundColor: "yellow",
          windowWidth: 1550,
        };

        // Render Section 1
        const canvas1 = await html2canvas(section1, options);
        const imgData1 = canvas1.toDataURL("image/jpeg", 0.8);
        const imgWidth1 = 190; // A4 width minus padding
        const imgHeight1 = (canvas1.height * imgWidth1) / canvas1.width;

        pdf.addImage(imgData1, "JPEG", 10, 10, imgWidth1, imgHeight1);

        // Render Section 2 (on a new page)
        pdf.addPage();
        const canvas2 = await html2canvas(section2, options);
        const imgData2 = canvas2.toDataURL("image/jpeg", 0.8);
        const imgWidth2 = 190;
        const imgHeight2 = (canvas2.height * imgWidth2) / canvas2.width;

        pdf.addImage(imgData2, "JPEG", 10, 10, imgWidth2, imgHeight2);

        // Generate PDF Blob and open in a new tab
        const pdfBlob = pdf.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");

        // Clean up the temporary container
        ReactDOM.unmountComponentAtNode(tempDiv);
        document.body.removeChild(tempDiv);
      } else {
        console.error("Failed to fetch permit details:", response.statusText);
      }
    } catch (error) {
      console.error("Error generating PDF:", error.message);
    }
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-list-content" ref={containerRef}>
          {isChecked ? (
            <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                    <button className="delete" onClick={handleDeletePopup}>
                      Move to archive
                    </button>
                    {isDeletePopup && (
                      <div className="confirm-delete-popup">
                        <div className="confirm-delete-popup-content">
                          <div className="confirm-model">
                            <div className="confirm-model-content">
                              <div className="confirm-model-content-body">
                                <p>Are you sure want to move archive?</p>
                              </div>
                              <div className="confirm-model-content-footer">
                                <button
                                  className="confirm-delete-button"
                                  onClick={handleArchivedDesignation}
                                >
                                  Yes
                                </button>
                                <button
                                  className="confirm-cancel-button"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="data-list-header">
              <div className="data-list-header-content">
                <div className="data-list-heading-content-left-side">
                  <Tooltip title="Back" arrow>
                    <IoMdArrowBack
                      className="back-company"
                      onClick={handleBack}
                    />
                  </Tooltip>
                  View Closing Request
                </div>
                <div className="data-list-heading-content-right-side">
                  {/* <button
                    className="new-button-download"
                    onClick={() => {
                      if (designations.length > 0) {
                        handleViewGenratePermit(designations[0].work_permit_id);
                      }
                    }}
                  >
                    View Permit Request
                  </button> */}
                  <Tooltip title="Refresh List" arrow>
                    <button className="refresh-button" onClick={handleRefresh}>
                      <TbRefresh className="refresh-icon" />
                    </button>
                  </Tooltip>
                  {/* <button
                    className="new-button-download"
                    onClick={() =>
                      handleViewGenratePermit(designation.work_permit_id)
                    }
                  >
                    View Permit Request
                  </button> */}
                </div>
              </div>
            </div>
          )}
          <div className="data-list-body" onClick={closePopups}>
            <div className="data-list-scroll-body">
              <div className="data-list-table">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : designations.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        {/* <th className="center-alignment">
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th> */}
                        <th className="center-alignment">SR. NO.</th>
                        <th>COMPLETED</th>
                        <th>STOPPED</th>
                        <th>AREA CLEANED</th>
                        <th>CLOSING REQ TIME</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {designations.map((designation, index) => (
                        <tr key={designation.id} className="hoverable-row">
                          {/* <td className="center-alignment">
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(designation.id)}
                              onChange={() => handleSelectRow(designation.id)}
                            />
                          </td> */}

                          <td className="center-alignment">{index + 1}</td>
                          <td>
                            {mapStatusToYesNo(
                              designation.completion_status || "NA"
                            )}
                          </td>
                          <td>
                            {mapStatusToYesNo(
                              designation.stoppage_status || "NA"
                            )}
                          </td>
                          <td>
                            {mapStatusToYesNo(
                              designation.area_cleaned_status || "NA"
                            )}
                          </td>
                          <td>
                            {formatDateTime(
                              designation.receiver_date_time || "NA"
                            )}
                          </td>
                          <td>
                            <>
                            {/* <button
                                          className="new-button-download"
                                          onClick={() =>
                                            handleViewGenratePermit(
                                              designation.work_permit_id
                                            )
                                          }
                                        >
                                          View Permit Request
                                        </button> */}

                                        <button
                                  className="new-button-download"
                                  onClick={() =>
                                    handleView(
                                      designation.work_permit_id,
                                     
                                    )
                                  }
                                >
                                  View
                                </button>
                              {/* Approve Button */}
                              <button
                                className="new-button-download"
                                onClick={() =>
                                  handleApproveClick(designation.work_permit_id)
                                }
                                disabled={
                                  designation.issuer_approval_status === 1
                                }
                              >
                                Approve
                              </button>

                              {/* Reject Button */}
                              <button
                                className="data-form-reject-button"
                                onClick={() => setIsPopupOpen(true)}
                                disabled={
                                  designation.issuer_approval_status === 1
                                }
                              >
                                Reject
                              </button>

                            

                              {/* Reject Popup */}
                              {isPopupOpen && (
                                <div className="permit-popup-model">
                                  <div className="permit-popup-content">
                                    <div className="reminder-popup-model-header">
                                      <div className="reminder-popup-model-content-header">
                                        <div className="reminder-popup-model-content-header-left-part">
                                          Reject Request Permit
                                        </div>
                                      </div>
                                      <div className="popup-new-form">
                                        <form className="permit-popup-form-detail">
                                          <div className="new-data-form">
                                            <div className="form-group email-field">
                                              <label htmlFor="reason">
                                                Reason{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <div className="input-group">
                                                <textarea
                                                  id="reason"
                                                  name="reason"
                                                  placeholder="Enter reason for rejection"
                                                  value={reason}
                                                  onChange={handleReasonChange}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                      <div className="footers">
                                        <div className="button-group-email">
                                          <button
                                            className="person-user-save-button reject-closing-request"
                                            type="button"
                                            onClick={() => {
                                              handleRejectClick(
                                                designation.work_permit_id,
                                                reason
                                              );
                                              closePopup();
                                            }}
                                          >
                                            Ok
                                          </button>
                                          <button
                                            className="person-user-cancel-button"
                                            onClick={closePopup}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Approval Confirmation Popup */}
                              {isApprovePopupOpen && (
                                <div className="confirm-delete-popup">
                                  <div className="confirm-delete-popup-content">
                                    <div className="confirm-model">
                                      <div className="confirm-model-content">
                                        <div className="confirm-model-content-body">
                                          <p>Are you sure want to approved?</p>
                                        </div>
                                        <div className="confirm-model-content-footer">
                                          <button
                                            className="confirm-delete-button"
                                            onClick={() =>
                                              handleConfirmApprove(
                                                selectedPermitId
                                              )
                                            }
                                          >
                                            Yes
                                          </button>
                                          <button
                                            className="confirm-cancel-button"
                                            onClick={() =>
                                              setIsApprovePopupOpen(false)
                                            }
                                          >
                                            No
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Reject Popup */}
                              {isRejectPopupOpen && (
                                <div className="permit-approval-popup">
                                  <div className="permit-approval-popup-content">
                                    <div className="approval-popup-header">
                                      Reject Request
                                    </div>
                                    <div className="popup-new-form">
                                      <label htmlFor="reason">
                                        Reason{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <textarea
                                        id="reason"
                                        name="reason"
                                        placeholder="Enter reason for rejection"
                                        value={reason}
                                        onChange={handleReasonChange}
                                      />
                                    </div>
                                    <div className="approval-popup-buttons">
                                      <button
                                        className="approve-button"
                                        onClick={() => {
                                          handleRejectClick(
                                            designation.work_permit_id,
                                            reason
                                          );
                                          handleRejectClose();
                                        }}
                                      >
                                        Reject
                                      </button>
                                      <button
                                        className="cancel-button"
                                        onClick={handleRejectClose}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">No Data Found</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className="data-form-body5656">
            {pdfUrl ? (
              <iframe
                src={pdfUrl}
                width="100%"
                height="600px"
                title="PDF Preview"
              ></iframe>
            ) : (
              <p>Generating PDF...</p>
            )}
          </div>
          <div className="pdf-view-data" style={{ display: "none" }}>
            <div ref={pdfRef}>
              {permitDetails ? (
                <ColdWorkPermitTemplate
                  permitDetails={permitDetails}
                  isSidebarExpanded={isSidebarExpanded}
                />
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div> */}
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Closerequesttab;
