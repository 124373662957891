import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import API_URL from "../../src/Config";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";

const ManPowerJoiningHistory = ({ isSidebarExpanded }) => {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [projectId, setProjectId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/v1/man_power_joining_history/${id}`, {
          headers: {
            Authorization: authKey, // Replace with actual auth key
          },
        });
        const formattedData = {
            project_id: response.project_id || "", // Added project_id
          };
        setProjectId(response.project_id);
        setHistoryData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleBackProject = () => {
    window.history.back()
  };

  return (
    <>
    <div
  className={`data-list-content-container page-content ${
    isSidebarExpanded ? "expanded" : "collapsed"
  }`}
  
> <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Man Power Joining History (Status Only)
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
      <table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {historyData.map((history, index) => (
            <tr key={index}>
              <td>{history.status}</td>
              <td>{new Date(history.updated_at).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default ManPowerJoiningHistory;



















