import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import API_URL from "../../../src/Config";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { FiPlus } from "react-icons/fi";
import Select from "react-select";
import { IoMdArrowBack } from "react-icons/io";

function BOQForm({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [poData, setPoData] = useState({
    project_id: "",
  });
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const unitOptions = [
    { value: "centimeter", label: "Centimeter (cm)" },
    { value: "day", label: "Day" },
    { value: "gram", label: "Gram (g)" },
    { value: "inch", label: "Inch (in)" },
    { value: "kg", label: "Kilogram (kg)" },
    { value: "kilometer", label: "Kilometer (km)" },
    { value: "liters", label: "Liters (L)" },
    { value: "m", label: "Meter (m)" },
    { value: "milligram", label: "Milligram (mg)" },
    { value: "millimeter", label: "Millimeter (mm)" },
    { value: "month", label: "Month" },
    { value: "numbers", label: "Numbers (nos)" },
    { value: "pcs", label: "Pieces (pcs)" },
    { value: "visit", label: "Visit" },
    { value: "year", label: "Year" },
  ];

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleInputChanges = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        service_code: "",
        description: "",
        uom: "",
        qty: "",
        unit_rate: "",
        gst: ","
      },
    ]);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      const boqPayload = {
        project_id: poData.project_id, // Ensure this is correctly set
        boq_list: rows.map((row) => ({
          service_code: row.service_code, // Match input field names
          description: row.description,
          uom: row.uom, // Directly use row.unit, since it's a string
          qty: row.qty,
          unit_rate: row.unit_rate,
          gst: row.gst,
        })),
      };
  
      const response = await axios.post(`${API_URL}/api/add_amc_boq_bulk`, boqPayload, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
      });
  
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("BOQ added successfully");
        setTimeout(() => navigate("/amc-projects"), 1000);
      }
    } catch (error) {
      console.error("Error adding BOQ:", error.message);
      setSeverity("error");
      showSnackbar("Failed to add BOQ");
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const handleCancelClick = () => {
    navigate("/amc-projects");
  };

  const fetchAuditData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_amc_project/${id}`, {
        headers: { Authorization: authKey },
      });

      const responseData = response.data;

      // Format and set project data
      const formattedData = {
        company_id: responseData.company_id || "",
        created_at: responseData.created_at
          ? new Date(responseData.created_at).toLocaleDateString("en-GB")
          : "N/A",
        customer_id: responseData.customer_id || "",
        customer_location: responseData.customer_location || "",
        customer_name: responseData.customer_name || "",
        end_date: responseData.end_date
          ? new Date(responseData.end_date).toLocaleDateString("en-GB")
          : "N/A",
        project_id: responseData.id || "",
        isArchive: responseData.isArchive ? "Yes" : "No",
        location_id: responseData.location_id || "N/A",
        po_number: responseData.po_number || "",
        project_name: responseData.project_name || "",
        billing_frequency: responseData.billing_frequency || "",
        project_type: responseData.project_type || "",
        project_type_id: responseData.project_type_id || "",
        start_date: responseData.start_date
          ? new Date(responseData.start_date).toLocaleDateString("en-GB")
          : "N/A",
        updated_at: responseData.updated_at
          ? new Date(responseData.updated_at).toLocaleDateString("en-GB")
          : "N/A",
      };

      setPoData(formattedData);
    } catch (error) {
      console.error("Error fetching project data:", error.message);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAuditData();
    }
  }, [id]);

  const handleBackProject = () => {
    navigate("/amc-projects");
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />
                </Tooltip>
                BOQ List Titem
              </div>

              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <div className="action-purchase-header">
              <div className="project-detailss">
                <div className="project-detail-arround">
                  <div className="project-details-left-side-audit">
                    <div className="project-detail">
                      <div className="detail-header">Project Name:</div>
                      <div className="detail-part">
                        {poData.project_name || "N/A"}
                      </div>
                    </div>
                    <div className="project-detail">
                      <div className="detail-header">Customer Name:</div>
                      <div className="detail-part">
                        {poData.customer_name || "N/A"}
                      </div>
                    </div>
                    <div className="project-detail">
                      <div className="detail-header">PO Number:</div>
                      <div className="detail-part">
                        {poData.po_number || "N/A"}
                      </div>
                    </div>
                    <div className="project-detail">
                      <div className="detail-header">Project Type:</div>
                      <div className="detail-part">
                        {poData.project_type || "N/A"}
                      </div>
                    </div>
                    <div className="project-detail">
                      <div className="detail-header">Start Date:</div>
                      <div className="detail-part">
                        {poData.start_date || "N/A"}
                      </div>
                    </div>
                    <div className="project-detail">
                      <div className="detail-header">End Date:</div>
                      <div className="detail-part">
                        {poData.end_date || "N/A"}
                      </div>
                    </div>
                    <div className="project-detail">
                      <div className="detail-header">Location:</div>
                      <div className="detail-part">
                        {poData.customer_location || "N/A"}
                      </div>
                    </div>
                    <div className="project-detail">
                      <div className="detail-header">Frequency:</div>
                      <div className="detail-part">
                        {poData.billing_frequency || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="action-purchase-sheet-header">
              <div className="actions-purchase-sheet-header-content">
                <div className="actions-purchase-sheet-heading-content-left-side">
                  BOQ ITEM LIST
                </div>
              </div>
            </div>

            <div className="action-purchase-body">
              <div className="action-purchase-scroll-body">
                <div className="action-purchase-tabless">
                  <table className="action-table action-multilevel-data">
                    <thead className="action-purchase-heads">
                      <tr>
                        <th className="b-th1">SERVICE CODE</th>

                        <th className="b-th2">DESCRIPTION</th>
                        <th className="b-th3">UOM</th>

                        <th className="b-th4">QTY</th>
                        <th className="b-th5">UNIT RATE</th>
                        <th className="b-th5">GST (%)</th>

                        <th className="b-th6">ACTION</th>
                      </tr>
                    </thead>

                    <tbody className="action-purchase-scroll-bodys-pr">
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td className="b-th1">
                            <input
                              type="text"
                              value={row.service_code}
                              onChange={(e) =>
                                handleInputChanges(
                                  index,
                                  "service_code",
                                  e.target.value
                                )
                              }
                              placeholder="Enter Service Code"
                            />
                          </td>

                          <td className="b-th2">
                            <input
                              type="text"
                              value={row.description}
                              onChange={(e) =>
                                handleInputChanges(
                                  index,
                                  "description",
                                  e.target.value
                                )
                              }
                              placeholder="Enter Description"
                            />
                          </td>

                          <td className="b-th3">
                            <Select
                              options={unitOptions}
                              value={unitOptions.find(
                                (option) => option.value === row.uom
                              )}
                              onChange={(selectedOption) =>
                                handleInputChanges(
                                  index,
                                  "uom",
                                  selectedOption?.value || ""
                                )
                              }
                              placeholder="Select Unit"
                            />
                          </td>

                          <td className="b-th4">
                            <input
                              type="number"
                              min="0"
                              value={row.qty}
                              onChange={(e) => {
                                const newValue =
                                  e.target.value === ""
                                    ? ""
                                    : Math.max(0, Number(e.target.value));
                                handleInputChanges(index, "qty", newValue);
                              }}
                              placeholder="Enter Quantity"
                            />
                          </td>

                          <td className="b-th5">
                            <input
                              type="number"
                              min="0"
                              value={row.unit_rate}
                              onChange={(e) => {
                                const newValue =
                                  e.target.value === ""
                                    ? ""
                                    : Math.max(0, Number(e.target.value));
                                handleInputChanges(
                                  index,
                                  "unit_rate",
                                  newValue
                                );
                              }}
                              placeholder="Enter Unit Rate"
                            />
                          </td>
                          <td className="b-th5">
                            <input
                              type="number"
                              min="0"
                              value={row.gst}
                              onChange={(e) => {
                                const newValue =
                                  e.target.value === ""
                                    ? ""
                                    : Math.max(0, Number(e.target.value));
                                handleInputChanges(
                                  index,
                                  "gst",
                                  newValue
                                );
                              }}
                              placeholder="Enter gst"
                            />
                          </td>

                          <td className="b-th6">
                            <button
                              className="delete-row"
                              onClick={() => {
                                const newRows = rows.filter(
                                  (_, i) => i !== index
                                );
                                setRows(newRows);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="row">
                    <div className="action-caluculation">
                      <div className="new-rows">
                        <button onClick={handleAddRow} className="add-rows">
                          <FiPlus className="add-row-icon" /> Add New Row
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
            
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={15000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(50px)", marginLeft: "25px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default BOQForm;
