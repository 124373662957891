import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../../src/Config";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
function EditBOQForm({ isSidebarExpanded }) {
  const { id } = useParams(); // Get BOQ ID from URL
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [projects, setProjects] = useState([]);
  
  const [boqData, setBOQData] = useState({
    projectId: null,
    serviceCode: "",
    description: "",
    uom: null,
    qty: "",
    unitRate: "",
  });

  const uomOptions = [
    { value: "m", label: "Meter (m)" },
    { value: "kg", label: "Kilogram (kg)" },
    { value: "litre", label: "Litre (L)" },
    { value: "pcs", label: "Pieces (pcs)" },
    { value: "sq.m", label: "Square Meter (sq.m)" },
    { value: "cu.m", label: "Cubic Meter (cu.m)" },
  ];

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };


  // Fetch BOQ details by ID
  useEffect(() => {
    const fetchBOQDetails = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_amc_boq/${id}`, {
          headers: { Authorization: authKey },
        });
  
        const boq = response.data; // No need for `.BOQ` if response directly contains the object
        setBOQData({
          projectId: { value: boq.project_id, label: boq.project_name }, // Project as Select dropdown
          serviceCode: boq.service_code.toString(), // Convert number to string for input field
          description: boq.description,
          uom: { value: boq.uom, label: boq.uom }, // UOM as Select dropdown
          qty: boq.qty.toString(), // Convert number to string for input field
          unitRate: boq.unit_rate.toString(), // Convert number to string for input field
        });
      } catch (error) {
        console.error("Error fetching BOQ:", error.message);
      }
    };
    
    const fetchProjectData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_all_amc_projects`, {
          headers: { Authorization: authKey },
        });

        const projectOptions = response.data.Projects.map((project) => ({
          value: project.id,
          label: project.project_name,
        }));
        setProjects(projectOptions);
      } catch (error) {
        console.error("Error fetching projects:", error.message);
      }
    };

    fetchBOQDetails();
    fetchProjectData();
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBOQData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: "" }));
  };

  const handleSelectChange = (selectedOption, action) => {
    setBOQData((prevState) => ({
      ...prevState,
      [action.name]: selectedOption,
    }));
    setErrors((prevState) => ({ ...prevState, [action.name]: "" }));
  };

  const validateForm = () => {
    const validationErrors = {};
    
    if (!boqData.projectId) validationErrors.projectId = "Project is required.";
    if (!boqData.serviceCode) validationErrors.serviceCode = "Service Code is required.";
    if (!boqData.description) validationErrors.description = "Description is required.";
    if (!boqData.uom) validationErrors.uom = "UOM is required.";
    if (!boqData.qty) validationErrors.qty = "Quantity is required.";
    if (!boqData.unitRate) validationErrors.unitRate = "Unit Rate is required.";
  
    setErrors(validationErrors);
    
    return Object.keys(validationErrors).length === 0;
  };
  
  const handleSaveClick = async () => {
    if (!validateForm()) return;
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const boqPayload = {
        service_code: boqData.serviceCode,
        description: boqData.description,
        uom: boqData.uom.value,
        qty: boqData.qty,
        unit_rate: boqData.unitRate,
      };

      const response = await axios.put(`${API_URL}/api/update_amc_boq/${id}`, boqPayload, {
        headers: { Authorization: authKey },
      });

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("BOQ updated successfully");
        setTimeout(() => navigate("/boqs"), 1000);
      }
    } catch (error) {
      console.error("Error updating BOQ:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleBack = () => {
    navigate("/boqs");
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  return (
    <>
    <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBack}
                  />{" "}
                </Tooltip>
                Edit BOQ
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
            {/* <div className="new-data-edit-form"> */}
              <div className="form-group-select">
                <label>Project<span className="text-danger">*</span></label>
                <Select
                  name="projectId"
                  value={boqData.projectId}
                  onChange={handleSelectChange}
                  options={projects}
                  placeholder="Select Project"
                />
                {errors.projectId && <div className="error-message">{errors.projectId}</div>}
              </div>

              {/* Service Code */}
              <div className="form-group">
                <label>Service Code<span className="text-danger">*</span></label>
                <div className="input-group">
                <input type="text" name="serviceCode" value={boqData.serviceCode} onChange={handleInputChange} placeholder="Enter service code" />
                </div>
                {errors.serviceCode && <div className="error-message">{errors.serviceCode}</div>}
              </div>

              {/* Description */}
              <div className="form-group">
                <label>Description<span className="text-danger">*</span></label>
                <div className="input-group">
                <textarea name="description" value={boqData.description} onChange={handleInputChange} placeholder="Enter description"></textarea>
                </div>
                {errors.description && <div className="error-message">{errors.description}</div>}
              </div>

              {/* UOM */}
              <div className="form-group-select">
                <label>UOM (Unit of Measure)<span className="text-danger">*</span></label>
                <Select
                  name="uom"
                  value={boqData.uom}
                  onChange={handleSelectChange}
                  options={uomOptions}
                  placeholder="Select UOM"
                />
                {errors.uom && <div className="error-message">{errors.uom}</div>}
              </div>

              {/* Quantity & Unit Rate */}
              <div className="form-group">
                <label>Quantity<span className="text-danger">*</span></label>
                <div className="input-group">
                <input type="number" name="qty" value={boqData.qty} onChange={handleInputChange} placeholder="Enter quantity" />
                </div>
                {errors.qty && <div className="error-message">{errors.qty}</div>}
              </div>

              <div className="form-group">
                <label>Unit Rate<span className="text-danger">*</span></label>
                <div className="input-group">
                <input type="number" name="unitRate" value={boqData.unitRate} onChange={handleInputChange} placeholder="Enter unit rate" />
                </div>
                {errors.unitRate && <div className="error-message">{errors.unitRate}</div>}
              </div>
            </form>
          </div>
         
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditBOQForm;
