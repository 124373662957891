import React from "react";
import "./Template1.css";
import "./Template4.css";
import { useParams } from "react-router-dom";

const Template6 = ({ signatures, formData, workDetails}) => {
  const { formId } = useParams();
  console.log("ID from URL:", formId);
  console.log("formData in Template6:", formData);

  const getSignatureByType = (type) =>
    signatures.find((signature) => signature.type === type) || {};

  const issuer = getSignatureByType("issuer");
  const safetyOfficer = getSignatureByType("fire & safety");

  const formatDateTime = (dateString) => {
    if (!dateString) return "N/A";

    const date = new Date(dateString);

    const dateStr = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });

    const time = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });

    return `${dateStr}, ${time}`;
};

const formatDateTimes = (dateString) => {
  if (!dateString) return 'N/A';
  
  // Create a Date object from the date string
  const date = new Date(dateString);

  // Convert to UTC and format time
  const time = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC',
  });

  // Format date to DD/MM/YYYY using UTC date methods
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
  const year = date.getUTCFullYear();

  // Combine to form the date string in DD/MM/YYYY format
  const dateStr = `${day}/${month}/${year}`;

  return `${dateStr}, ${time}`;
};


  return (
    <>
      <div className="template-container cold-work">
        <div className="template-container-content">
          <div className="template-container-header">
            <div className="cold-work-table">
              <table className="cold-work-tables">
                <thead>
                  <tr>
                    <th
                      colspan="2"
                      className="permit-center-align permit-template6 "
                    >
                      जारीकर्ता / Issuer
                    </th>
                    <th
                      colspan="2"
                      className="permit-center-align permit-template6"
                    >
                      प्राप्तकर्ता / Receiver
                    </th>
                    <th
                      colspan="2"
                      className="permit-center-align permit-template6"
                    >
                      सुरक्षा आधिकारी / Safety Officer
                    </th>
                  </tr>
                  <tr>
                    <th className="permit-center-align permit-template6">
                      नाम और पद <br /> Name & Designation
                    </th>
                    <th className="permit-center-align permit-template6">
                      हस्ताक्षर <br /> Signature
                    </th>
                    <th className="permit-center-align permit-template6">
                      नाम और पद <br /> Name & Designation
                    </th>
                    <th className="permit-center-align permit-template6">
                      हस्ताक्षर <br />
                      Signature
                    </th>
                    <th className="permit-center-align permit-template6">
                      नाम <br /> Name{" "}
                    </th>
                    <th className="permit-center-align permit-template6">
                      हस्ताक्षर <br /> Signature
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr className="permit-row">
                    <td className="permit-center-align">{signatures.name || "NA"}</td>
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align">{signatures.name || "NA"}</td>
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align"></td>
                  </tr> */}

                  <tr className="permit-row">
                    <td className="permit-center-align">
                      {issuer.name || "NA"} <br />({issuer.designation || "NA"})  <br />
                      {formatDateTime(issuer.date_time || "NA")}
                    </td>
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align">
                    {workDetails.receiver_name || "NA"}  <br />
                    {formatDateTimes(workDetails.created_at || "NA")}
                    </td>
                    <td className="permit-center-align"></td>
                    <td className="permit-center-align">
                      {safetyOfficer.name || "NA"}
                      <br />
                      {formatDateTime(safetyOfficer.date_time || "NA")}
                    </td>
                    <td className="permit-center-align"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template6;
