import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import API_URL from "../../src/Config";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import { MdDeleteOutline } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function AuditView({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedDate, setSelectedDate] = useState(null);
  const [poData, setPoData] = useState({
    reference_no: "",
    month: "",
    year: "",
    audit_type: "",
  });
  const [rows, setRows] = useState([]);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [isAuditPopupOpen, setIsAuditPopupOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const popupRef = useRef(null);
  const [currentAuditPointId, setCurrentAuditPointId] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedPointFiles, setSelectedPointFiles] = useState([]);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(null);
  const [isPointPopupOpen, setIsPointPopupOpen] = useState(null);
  const [isFilePopupOpen, setIsFilePopupOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // "success", "error", "warning", "info"

  const handlePointIconClick = (index, event) => {
    setIsPointPopupOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log("Selected Date:", date);
  };

  const handleSubmit = async () => {
    if (!selectedDate || selectedFiles.length === 0) {
      setSnackbarMessage("Please select a date and upload at least one file.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("audit_point_id", currentAuditPointId);
    formData.append(
      "completion_date",
      selectedDate.toISOString().split("T")[0]
    );

    // Append actual file objects, not just file details
    attachmentFiles.forEach((file) => {
      formData.append("completion_report", file);
    });

    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/update_audit_point_status`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSnackbarMessage("Audit point updated successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        handleClosePopup(); // Close popup on success
        fetchAuditData(); // Refresh data after update
      }
    } catch (error) {
      console.error("Error updating audit point:", error);
      setSnackbarMessage("Failed to update audit point.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is null");
    }
  };

  const handleDeleteFile = (indexToDelete) => {
    // Update both the file details and actual file objects
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );
    const updatedAttachments = attachmentFiles.filter(
      (_, index) => index !== indexToDelete
    );

    setSelectedFiles(updatedFiles);
    setAttachmentFiles(updatedAttachments);

    if (updatedFiles.length === 0) {
      handleFileCloseAttachment();
    }
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);
    setAttachmentFiles([...attachmentFiles, ...Array.from(files)]);
  };

  const handleOpenPopup = (auditPointId) => {
    setCurrentAuditPointId(auditPointId);
    setIsAuditPopupOpen(true);
    // Reset files and date when opening popup
    setSelectedFiles([]);
    setAttachmentFiles([]);
    setSelectedDate(null);
  };

  const handleClosePopup = () => {
    setIsAuditPopupOpen(false);
    setCurrentAuditPointId(null);
  };

  const handleCloseAttachment = () => {
    setIsPopupOpen(null);
  };

  const handleClosePointAttachment = () => {
    setIsPointPopupOpen(null);
  };

  const handleFileCloseAttachment = () => {
    setIsFilePopupOpen(false);
  };

  const handleIconClick = (index, event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsPopupOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleIconFileClick = () => {
    setIsFilePopupOpen((prev) => !prev);
  };

  const fetchAuditData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_statutory_audit_points/${id}`,
        {
          headers: { Authorization: authKey },
        }
      );

      const responseData = response.data;
      const auditData = responseData.statutory_audit;

      // Format audit points data
      const formattedAuditPoints = auditData.audit_points.map((point) => ({
        id: point.id,
        point_no: point.point_no || "",
        responsible_person_name: point.responsible_person_name || "",
        person_email: point.person_email || "",
        target_date: point.target_date ? new Date(point.target_date) : null,
        target_dates: point.target_date
          ? new Date(point.target_date).toLocaleDateString("en-GB") // ✅ Convert to dd/mm/yyyy
          : "N/A",
        completion_date: point.completion_date
          ? new Date(point.completion_date).toLocaleDateString("en-GB") // ✅ Convert to dd/mm/yyyy
          : "N/A",
        file_details: point.file_details || [], // Handle attached files if any
        observation: point.observation || "",
        recommendation: point.recommendation || "",
        status: point.status || "",
        completion_report: point.completion_report || null,
      }));

      // Handle attachments
      const attachmentFiles = auditData.audit_files || [];
      const attachmentPointFiles = auditData.audit_points.file_details || [];

      // Format main audit data
      const formattedData = {
        reference_no: auditData.reference_no || "",
        audit_type: auditData.audit_type || "",
        month: auditData.month || "",
        year: auditData.year || "",
      };

      setPoData(formattedData);
      setRows(formattedAuditPoints);

      // Set existing attachments
      if (attachmentFiles.length > 0) {
        setSelectedFiles(attachmentFiles);
      }
      if (attachmentPointFiles.length > 0) {
        setSelectedPointFiles(attachmentPointFiles);
      }
    } catch (error) {
      console.error("Error fetching statutory audit data:", error.message);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAuditData();
    }
  }, [id]);

  const handleBackProject = () => {
    navigate("/statutory-audits");
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />
                </Tooltip>
                Audit Report
              </div>

              <div className="data-form-heading-content-right-side">
                <div>
                  <span
                    className="inquiry-file-btns"
                    onClick={(event) => handleIconClick(0, event)}
                    ref={buttonRef}
                  >
                    <MdAttachFile className="inquiry-file-icon" />
                  </span>
                  {isPopupOpen === 0 && (
                    <div className="polist-popup1" ref={containerRef}>
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles && selectedFiles.length > 0 ? (
                          <div className="attach-popup-contant-body">
                            <div className="inquiry-attach-file-containerssss">
                              {selectedFiles.map(
                                (attachment, attachmentIndex) => (
                                  <div
                                    className="inquiry-attach-file-container"
                                    key={attachmentIndex}
                                  >
                                    <div className="inquiry-attacth-file-model">
                                      <div className="attach-file-show">
                                        <div className="text-attachment">
                                          {attachment.filename &&
                                          attachment.filename.length > 20
                                            ? `${attachment.filename.substring(
                                                0,
                                                15
                                              )}...`
                                            : attachment.filename ||
                                              attachment.name}
                                        </div>
                                        <div className="text-attachment-file">
                                          File Size:{" "}
                                          {attachment.file_size ||
                                            (attachment.size
                                              ? (
                                                  attachment.size / 1024
                                                ).toFixed(2)
                                              : "NA")}{" "}
                                          KB
                                        </div>
                                      </div>

                                      <div className="file-footer">
                                        {attachment.file_id && (
                                          <>
                                            <a
                                              href={`${API_URL}/api/download_statutory_audit_attachment/${attachment.file_id}`}
                                              download
                                              className="file-download-attachment file-txt"
                                            >
                                              Download
                                            </a>
                                            <a
                                              href={`${API_URL}/api/view_statutory_audit_attachment/${attachment.file_id}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="file-download-attachment file-txt"
                                            >
                                              View
                                            </a>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="no-attachments">
                            No attachments found
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <div className="action-purchase-header">
              <div className="project-detailss">
                <div className="project-detail-arround">
                  <div className="project-details-left-side-audit">
                    <div className="project-detail">
                      <div className="detail-header">Reference No:</div>
                      <div className="detail-part">
                        {poData.reference_no || "N/A"}
                      </div>
                    </div>
                    <div className="project-detail">
                      <div className="detail-header">Month:</div>
                      <div className="detail-part">{poData.month || "N/A"}</div>
                    </div>
                    <div className="project-detail">
                      <div className="detail-header">Year:</div>
                      <div className="detail-part">{poData.year || "N/A"}</div>
                    </div>
                    <div className="project-detail">
                      <div className="detail-header">Type of Audit:</div>
                      <div className="detail-part">
                        {poData.audit_type || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="action-purchase-sheet-header">
              <div className="actions-purchase-sheet-header-content">
                <div className="actions-purchase-sheet-heading-content-left-side">
                  Audit Point
                </div>
              </div>
            </div>

            <div className="action-purchase-body">
              <div className="action-purchase-scroll-body">
                <div className="action-purchase-table">
                  <table className="action-table action-multilevel-data">
                    <thead className="action-purchase-heads">
                      <tr>
                        <th className="a-th1">REPORT NO</th>

                        <th className="a-th2">PERSON</th>
                        <th className="a-th3">EMAIL ID</th>

                        <th className="a-th4">TARGET DATE</th>
                        <th className="a-th">STATUS</th>

                        <th className="a-th5">OBSERVATION</th>
                        <th className="a-th6">RECOMMENDATION</th>

                        <th className="a-th">COMPLETION DATE</th>
                        <th className="a-th">ATTACHMENT</th>

                        <th className="a-th7">ACTION</th>
                      </tr>
                    </thead>
                    <tbody className="action-purchase-scroll-bodys">
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td className="a-th1">{row.point_no || "N/A"}</td>
                          <td className="a-th2">
                            {row.responsible_person_name || "N/A"}
                          </td>
                          <td className="a-th3">{row.person_email || "N/A"}</td>
                          <td className="a-th4">{row.target_dates || "N/A"}</td>
                          <td
                            className="a-th6"
                            style={{
                              color: row.status === "Open" ? "green" : "red",
                            }}
                          >
                            {row.status || "N/A"}
                          </td>
                          <td className="a-th5">{row.observation || "N/A"}</td>
                          <td className="a-th6">
                            {row.recommendation || "N/A"}
                          </td>
                          <td className="a-th6">
                            {row.completion_date || "N/A"}
                          </td>
                          <td className="a-th6">
                            <div>
                              <span
                                className="inquiry-file-btns"
                                onClick={(event) =>
                                  handlePointIconClick(0, event)
                                }
                                ref={buttonRef}
                              >
                                <MdAttachFile className="inquiry-file-icon" />
                              </span>
                              {isPointPopupOpen === 0 && (
                                <div
                                  className="polist-popup1-audit"
                                  ref={containerRef}
                                >
                                  <div className="attach-popup-contant">
                                    <div className="attachment-header">
                                      <div className="attachment-header-content">
                                        Attachments
                                      </div>
                                      <button
                                        className="fiile-close-btn"
                                        onClick={handleClosePointAttachment}
                                      >
                                        <IoCloseOutline className="file-close-icon" />
                                      </button>
                                    </div>
                                    {row.file_details.length > 0 ? (
                                      <div className="attach-popup-contant-body">
                                        <div className="inquiry-attach-file-containerssss">
                                          {row.file_details.map(
                                            (file, fileIndex) => (
                                              <div
                                                className="inquiry-attach-file-container"
                                                key={file}
                                              >
                                                <div className="inquiry-attacth-file-model">
                                                  <div className="attach-file-show">
                                                    <div className="text-attachment">
                                                      {file.filename &&
                                                      file.filename.length > 20
                                                        ? `${file.filename.substring(
                                                            0,
                                                            15
                                                          )}...`
                                                        : file.filename ||
                                                          file.name}
                                                    </div>
                                                    <div className="text-attachment-file">
                                                      File Size:{" "}
                                                      {file.file_size ||
                                                        (file.size
                                                          ? (
                                                              file.size / 1024
                                                            ).toFixed(2)
                                                          : "NA")}{" "}
                                                      KB
                                                    </div>
                                                  </div>

                                                  <div className="file-footer">
                                                    {file.file_id && (
                                                      <>
                                                        <a
                                                          href={`${API_URL}/api/download_statutory_audit_attachment/${file.file_id}`}
                                                          download
                                                          className="file-download-attachment file-txt"
                                                        >
                                                          Download
                                                        </a>
                                                        <a
                                                          href={`${API_URL}/api/view_statutory_audit_attachment/${file.file_id}`}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                          className="file-download-attachment file-txt"
                                                        >
                                                          View
                                                        </a>
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="no-attachments">
                                        No attachments found
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </td>

                          <td className="a-th7">
                            <button
                              className="data-form-save-button-save"
                              onClick={() => handleOpenPopup(row.id)}
                            >
                              {row.status === "Closed" ? "Update" : "Save"}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isAuditPopupOpen && (
          <div className="reminder-popup-model">
            <div className="reminder-popup-content">
              <div className="reminder-popup-model-header">
                <div className="reminder-popup-model-content-header">
                  <div className="reminder-popup-model-content-header-left-part">
                    Audit Upload Report
                  </div>
                  <div className="reminder-popup-model-content-header-right-part"></div>
                </div>
                <div className="popup-new-form">
                  <form className="popup-form-detail">
                    <div className="new-data-form">
                      <div className="form-group email-filed">
                        <label htmlFor="date">Completion Date</label>
                        <div className="input-group">
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            name="date"
                            id="date"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select date"
                            className="form-control"
                            isClearable
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="attachement">Upload Audit Report</label>
                        <button
                          type="button"
                          className="challan-btn"
                          onClick={handleUploadButtonClick}
                        >
                          <FiUpload /> Upload File
                        </button>
                        <input
                          id="attachments"
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileInputChange}
                          multiple
                        />
                        {selectedFiles.length > 0 && (
                          <span
                            className="inquiry-file-btn"
                            onClick={handleIconFileClick}
                          >
                            <MdAttachFile className="inquiry-file-icon" />{" "}
                            {`${selectedFiles.length}`}
                          </span>
                        )}
                        {isFilePopupOpen && (
                          <div className="attach-popup-amc4" ref={popupRef}>
                            <div className="attach-popup-contant">
                              <div className="attachment-header">
                                <div className="attachment-header-content">
                                  Attachments
                                </div>
                                <button
                                  className="fiile-close-btn"
                                  onClick={handleFileCloseAttachment}
                                >
                                  <IoCloseOutline className="file-close-icon" />
                                </button>
                              </div>
                              {selectedFiles.length > 0 && (
                                <div className="attach-scrroll-body">
                                  {selectedFiles.map((file, index) => (
                                    <div
                                      className="attach-popup-contant-body"
                                      key={index}
                                    >
                                      <div className="attach-file-container">
                                        <div className="attach-file-show">
                                          <div className="text-attachment">
                                            {file.name || file.filename}
                                          </div>
                                          <div className="text-attachment-file">
                                            File Size:(
                                            {file.size
                                              ? (file.size / 1024).toFixed(2)
                                              : file.file_size || "NA"}{" "}
                                            KB)
                                          </div>
                                        </div>
                                        <div
                                          className="file-delete-btn"
                                          onClick={() =>
                                            handleDeleteFile(index)
                                          }
                                        >
                                          <span>
                                            <MdDeleteOutline className="file-delete-icon" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="footers">
                  <div className="button-group-email">
                    <div>
                      <button
                        className="person-user-save-button"
                        type="button"
                        onClick={handleSubmit}
                        disabled={isLoading}
                      >
                        {isLoading ? "Saving..." : "Save"}
                      </button>

                      <button
                        className="person-user-cancel-button"
                        onClick={handleClosePopup}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default AuditView;
