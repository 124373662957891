import React, { useState, useEffect, useRef } from "react";
import "./Reportmanpower.css";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { IoMdArrowBack } from "react-icons/io";
import useClickOutside from "../useClickOutside";
import Select from "react-select";
import Pagination from "../Component/Pagination";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoCopyOutline } from "react-icons/io5";

function Department({ isSidebarExpanded }) {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedProject, setSelectedProject] = useState(null); // Stores selected project
  const [projects, setProjects] = useState([]);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [totalSummary, setTotalSummary] = useState({});
  const [ecnoDetails, setEcnoDetails] = useState([]);
  const [showInfo, setShowInfo] = useState(false); // ✅ Define state
  const [activeRows, setActiveRows] = useState(null);
  const [activeRow, setActiveRow] = useState(null); // Stores the row ID of the open popup
  const [copiedText, setCopiedText] = useState(null);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async (currentPage, itemsPerPage, projectId) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const params = {
        page: currentPage,
        per_page: itemsPerPage,
      };

      if (projectId) {
        params.project_id = projectId; // Use correct key 'project_id'
      }

      const response = await axios.get(
        `${API_URL}/api/reports/manpower_summary`,
        {
          headers: {
            Authorization: authKey,
          },
          params, // Pass correctly formatted params object
        }
      );

      setDepartments(response.data.Items);
      setTotalSummary(response.data.TotalSummary);
      setTotalItems(response.data.TotalItems);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // const handleSearch = async () => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.get(`${API_URL}/api/search_manpower_summary`, {
  //       headers: {
  //         Authorization: authKey,
  //         "Content-Type": "application/json",
  //       },
  //       params: {
  //         query: searchInput,
  //         page: currentPage,
  //         per_page: itemsPerPage,
  //       },
  //     });

  //     if (response.status === 200) {
  //       setDepartments(response.data);
  //     } else {
  //       console.error("Error fetching search results:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching search results:", error.message);
  //   }
  // };

  const handleSearch = async (currentPage, itemsPerPage, projectId) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage, projectId);
      return;
    }

    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/search_manpower_summary`,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
          params: {
            query: searchInput,
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      if (response.status === 200) {
        setDepartments(response.data.Items);
        setTotalItems(response.data.TotalItems);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      // setIsSearching(false)
    }
  };

  // useEffect(() => {
  //   const delay = setTimeout(() => {
  //     handleSearch();
  //   }, 600);
  //   return () => clearTimeout(delay);
  // }, [searchInput]);

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  // useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));
  useClickOutside([containerRef, buttonRef], () => {
    setIsSortPopup(false);
    setActiveRow(false); // Ensure active row is also reset
    setActiveRows(false);
  });
  

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? departments.map((department) => department.ID) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (departmentID) => {
    const updatedSelectedRows = selectedRows.includes(departmentID)
      ? selectedRows.filter((id) => id !== departmentID)
      : [...selectedRows, departmentID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === departments.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchiveDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDepartments = departments.filter(
          (department) => !selectedRows.includes(department.ID)
        );
        setDepartments(updatedDepartments);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting departments:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveDeparment = () => {
    navigate("/archive-department");
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  // const handleRefresh = () => {
  //   fetchData();
  // };

  const handleRefresh = () => {
    if (searchInput) {
      console.log("Search input is set:", searchInput);
    } else {
      fetchData(page, itemsPerPage);
    }
    setSelectedProject("");
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  const handleBackView = () => {
    navigate(`/project-view/${id}`);
  };
  const fetchProjects = async () => {
    try {
      const authKey = localStorage.getItem("authKey"); // Retrieve auth key from local storage

      const response = await fetch(`${API_URL}/api/get_all_projects`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey, // Attach auth key in headers
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      const projectOptions = data.Projects.map((project) => ({
        value: project.id, // Unique identifier
        label: project.project_name, // Display name
      }));

      setProjects(projectOptions); // Update state with project options
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleProjectChange = (selectedOption) => {
    if (selectedProject?.value === selectedOption?.value) return; // Prevent duplicate API calls

    setSelectedProject(selectedOption);

    if (selectedOption) {
      fetchData(1, 10, selectedOption.value);
    }
  };


  // const handleInfoAtSiteClick = (dept) => {
  //   if (!dept) return;

  //   setActiveRow((prevRow) =>
  //     prevRow === dept.man_power_id ? null : dept.man_power_id
  //   ); // ✅ Toggle popup
  //   // setLoading(true);

  //   const authKey = localStorage.getItem("authKey");

  //   axios
  //     .get(`${API_URL}/api/reports/employee_details`, {
  //       params: {
  //         status: "Present at site",
  //         man_power_id: dept.man_power_id,
  //       },
  //       headers: {
  //         Authorization: authKey,
  //       },
  //     })
  //     .then((response) => {
  //       setEcnoDetails(response.data.Employees);
  //     })
  //     .catch((err) => console.error("API Error:", err))
  //     // .finally(() => setLoading(false));
  // };

  const handleInfoAtSiteClick = async (dept) => {
    if (!dept) return;
    setActiveRows(false);
    
    setActiveRow((prevRow) =>
      prevRow === dept.man_power_id ? null : dept.man_power_id
    ); // ✅ Toggle popup
  
    try {
      const authKey = localStorage.getItem("authKey");
  
      const response = await axios.get(`${API_URL}/api/reports/employee_details`, {
        headers: {
          Authorization: authKey, // Attach auth key in headers
        },
        params: {
          status: "Present at site",
          man_power_id: dept.man_power_id,
        },
      });
  
      setEcnoDetails(response.data.Employees);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
    } finally {

    }
  };
  

  const handleInfoIssuedClick = async (dept) => {
    if (!dept) return;
    setActiveRow(false);
  
    setActiveRows((prevRow) =>
      prevRow === dept.man_power_id ? null : dept.man_power_id
    ); // ✅ Toggle popup
  
    try {
      const authKey = localStorage.getItem("authKey");
  
      const response = await axios.get(`${API_URL}/api/reports/employee_details`, {
        headers: {
          Authorization: authKey, // Attach auth key in headers
        },
        params: {
          status: "Appointment issued (yet to join)",
          man_power_id: dept.man_power_id,
        },
      });
  
      setEcnoDetails(response.data.Employees);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
    } finally {

    }
  };



  // useEffect(() => {
  //   if (showInfo) {
  //     // Fetch details only when showInfo is true
  //     handleInfoClick(departments);
  //   }
  // }, [showInfo]); // ✅ Only runs when `showInfo` changes

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text);

    // Remove the copied text after 2 seconds
    setTimeout(() => setCopiedText(null), 2000);
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-list-content" ref={containerRef}>
          {isChecked ? (
            <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                    <button className="print">
                      <IoPrintOutline className="print-icon" />
                    </button>
                    <button className="bulk-update">Bulk Update</button>
                    <button
                      className="delete"
                      onClick={handleDeleteButtonClick}
                    >
                      <HiOutlineDotsHorizontal className="delete-icon" />
                    </button>
                    {isDeletePopupOpen && (
                      <div className="delete-popup">
                        <div className="delete-popup-content">
                          <button onClick={handleDeletePopup}>
                            Move to archive
                          </button>
                        </div>
                      </div>
                    )}
                    {isDeletePopup && (
                      <div className="confirm-delete-popup">
                        <div className="confirm-delete-popup-content">
                          <div className="confirm-model">
                            <div className="confirm-model-content">
                              <div className="confirm-model-content-body">
                                <p>Are you sure want to move archive?</p>
                              </div>
                              <div className="confirm-model-content-footer">
                                <button
                                  className="confirm-delete-button"
                                  onClick={handleArchiveDepartment}
                                >
                                  Yes
                                </button>
                                <button
                                  className="confirm-cancel-button"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="data-list-header">
              <div className="data-list-header-content">
                <div className="data-list-heading-content-left-side">
                  All Manpower Reports
                </div>
                <div className="data-list-heading-content-right-side">
                  <div className="dropdown-container">
                    <Select
                      id="project_name"
                      options={projects}
                      value={selectedProject}
                      onChange={handleProjectChange}
                      placeholder="Select Project"
                      isSearchable
                      className="project-dropdown"
                    />
                  </div>
                  <div className="search-bar-container">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      className="search-bar"
                    />
                    <IoSearchOutline className="search-bar-icon" />
                  </div>

                  {/* <Link to="/actualmanpower-form" className="data-list-new"> */}
                  {/* <Tooltip title="New Department" arrow>
                        <button className="new-button" onClick={handleNew}>
                          <HiOutlinePlus className="new-plus-icon" /> New
                        </button>
                      </Tooltip> */}
                  {/* </Link> */}

                  <Tooltip title="Refresh List" arrow>
                    <button className="refresh-button" onClick={handleRefresh}>
                      <TbRefresh className="refresh-icon" />
                    </button>
                  </Tooltip>
                  <button
                    className="sort-button"
                    onClick={handleSortpopup}
                    ref={buttonRef}
                  >
                    <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                  </button>
                  {isSortPopup && (
                    <div className="sort-popup-container" ref={containerRef}>
                      <div className="sort-popup-content">
                        <div className="sort-by">
                          SORT BY {isAscending ? "(Ascending)" : "(Descending)"}
                        </div>
                        <div className="sort-by-button"></div>
                        <button
                          className="delete-item"
                          onClick={handleArchiveDeparment}
                        >
                          Archived Department
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="data-list-body" onClick={closePopups}>
            <div className="data-list-scroll-body">
              <div className="data-list-table">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : departments.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th>SR. NO.</th>
                        <th>PROJECT NAME</th>
                        <th>POSITION</th>
                        <th>TENDER REQUIREMENT</th>
                        <th>PRESENT AT SITE</th>
                        {/* <th>RESIGNED</th> */}
                        <th>APPOINTMENT ISSUED</th>
                        {/* <th>DIDN'T JOIN</th> */}
                        <th>BALANCE POSITION</th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {departments.map((department, index) => (
                        // <>
                        <tr key={department.ID} className="hoverable-row">
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(department.ID)}
                              onChange={() => handleSelectRow(department.ID)}
                            />
                          </td>

                          <td>{index + 1}</td>
                          <td>{department.project_name || "NA"}</td>
                          <td>{department.position || "NA"}</td>
                          <td className="status-color1">
                            {department.tender_requirement || "0"}
                          </td>
                          <td className="status-color2">
                            {department.present_at_site || "0"}
                            {department.present_at_site > 0 && (
                            <AiOutlineInfoCircle
                              size={18}
                              className="info-icon"
                              title="Number of people present at site"
                              onClick={() => handleInfoAtSiteClick(department)} // ✅ Pass department object
                            />
                          )}

                            {activeRow === department.man_power_id && (
                              <div className="info-popup" ref={containerRef}>
                                 <div className="info-popup-content">
                                 <div className="info-popup-content-header">
                                  Employees Details
                                 </div>
                                {loading ? (
                                  <p className="loading-text">Loading...</p>
                                ) : ecnoDetails.length > 0 ? (
                                  <table className="employee-table">
                                    <thead>
                                      <tr>
                                      <th className="popup-heading-manpower">
                                          SR NO
                                        </th>
                                        <th className="popup-heading-manpower">
                                          EC NO
                                        </th>
                                        <th className="popup-heading-manpower">
                                          PERSON NAME
                                        </th>
                                        <th className="popup-heading-manpower">
                                          CONTACT NO.
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ecnoDetails.map((person, index) => (
                                        <tr key={index}>
                                          <td>{index+1}</td>
                                          <td>
                                            {person.ec_no || "N/A"}{" "}
                                            {person.ec_no && (
                                            <span
                                              className="copy-icon"
                                              onClick={() =>
                                                handleCopy(person.ec_no)
                                              }
                                              title="Copy to clipboard"
                                            >
                                              <IoCopyOutline />
                                              {copiedText === person.ec_no && (
                                                <span className="copied-text">
                                                  Copied!
                                                </span> 
                                              )}
                                            </span>
                                            )}
                                          </td>
                                          <td>
                                            {person.person_name || "N/A"}
                                            {person.person_name && (
                                            <span
                                              className="copy-icon"
                                              onClick={() =>
                                                handleCopy(person.person_name)
                                              }
                                              title="Copy to clipboard"
                                            >
                                              <IoCopyOutline />
                                              {copiedText === person.person_name && (
                                                <span className="copied-text">
                                                  Copied!
                                                </span> 
                                              )}
                                            </span>
                                            )}
                                          </td>
                                          <td>
                                            {person.contact_no || "N/A"}{" "}
                                            {person.contact_no && (
                                            <span
                                              className="copy-icon"
                                              onClick={() =>
                                                handleCopy(person.contact_no)
                                              }
                                              title="Copy to clipboard"
                                            >
                                              <IoCopyOutline />
                                              {copiedText === person.contact_no && (
                                                <span className="copied-text">
                                                  Copied!
                                                </span> 
                                              )}
                                            </span>
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <p className="no-data">No data available</p>
                                )}
                              </div>
                              </div>
                            )}
                          </td>

                          <td className="status-color4">
                            {department.appointment_issued || "0"}
                            {department.appointment_issued > 0 && ( 
                            <AiOutlineInfoCircle
                              size={18}
                              className="info-icons"
                              title="Number of people present at site"
                              onClick={() => handleInfoIssuedClick(department)} // ✅ Pass department object
                            />
                            )}

{activeRows === department.man_power_id && (
                              <div className="info-popups" ref={containerRef}>
                                 <div className="info-popup-contents">
                                 <div className="info-popup-content-header">
                                  Employees Details
                                 </div>
                                {loading ? (
                                  <p className="loading-text">Loading...</p>
                                ) : ecnoDetails.length > 0 ? (
                                  <table className="employee-table">
                                    <thead>
                                      <tr>
                                      <th className="popup-heading-manpower">
                                          SR NO
                                        </th>
                                        <th className="popup-heading-manpower">
                                          EC NO
                                        </th>
                                        <th className="popup-heading-manpower">
                                          PERSON NAME
                                        </th>
                                        <th className="popup-heading-manpower">
                                          CONTACT NO.
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ecnoDetails.map((person, index) => (
                                        <tr key={index}>
                                          <td>{index+1}</td>
                                          <td>
                                            {person.ec_no || "N/A"}{" "}
                                            {person.ec_no && (
                                            <span
                                              className="copy-icon"
                                              onClick={() =>
                                                handleCopy(person.ec_no)
                                              }
                                              title="Copy to clipboard"
                                            >
                                              <IoCopyOutline />
                                              {copiedText === person.ec_no && (
                                                <span className="copied-text">
                                                  Copied!
                                                </span> 
                                              )}
                                            </span>
                                            )}
                                          </td>
                                          <td>
                                            {person.person_name || "N/A"}
                                            {person.person_name && (
                                            <span
                                              className="copy-icon"
                                              onClick={() =>
                                                handleCopy(person.person_name)
                                              }
                                              title="Copy to clipboard"
                                            >
                                              <IoCopyOutline />
                                              {copiedText === person.person_name && (
                                                <span className="copied-text">
                                                  Copied!
                                                </span> 
                                              )}
                                            </span>
                                            )}
                                          </td>
                                          <td>
                                            {person.contact_no || "N/A"}{" "}
                                            {person.contact_no && (
                                            <span
                                              className="copy-icon"
                                              onClick={() =>
                                                handleCopy(person.contact_no)
                                              }
                                              title="Copy to clipboard"
                                            >
                                              <IoCopyOutline />
                                              {copiedText === person.contact_no && (
                                                <span className="copied-text">
                                                  Copied!
                                                </span> 
                                              )}
                                            </span>
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <p className="no-data">No data available</p>
                                )}
                              </div>
                              </div>
                            )}

                          </td>
                          {/* <td className="status-color5">
                            {department.didnt_join_count || "0"}
                          </td> */}
                          <td className="status-color6">
                            {department.balance_position || "0"}
                          </td>
                        </tr>
                      ))}
                    </tbody>

                    <tfoot>
                      <tr className="report-footer report-footer-container">
                        <td colSpan="4" className="text-right">
                          <strong>Total</strong>
                        </td>
                        <td className="status-color1">
                          <strong>
                            {totalSummary.TotalTenderRequirement || 0}
                          </strong>
                        </td>
                        <td className="status-color2">
                          <strong>
                            {totalSummary.TotalPresentAtSite || 0}
                          </strong>
                        </td>
                        <td className="status-color4">
                          <strong>
                            {totalSummary.TotalAppointmentIssued || 0}
                          </strong>
                        </td>
                        <td className="status-color6">
                          <strong>
                            {totalSummary.TotalBalancePosition || 0}
                          </strong>
                        </td>
                      </tr>
                    </tfoot>
                    {/* // </> */}
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">No Report Found</div>
                  </div>
                )}
                {/* <div className="reort-footer">
                  <div className="reort-footer-container">
                    <div className="reort-footer-container-part3">
                      <div className="">
                        Total Tender Requirement:{" "}
                        {totalSummary.TotalTenderRequirement}
                      </div>
                      <div className=""></div>
                    </div>
                    <div className="reort-footer-container-part3">
                      <div className="">
                        Total Present At Site: {totalSummary.TotalPresentAtSite}
                      </div>
                      <div className=""></div>
                    </div>
                    <div className="reort-footer-container-part1">
                      <div className="">
                        Total Appointment Issued:{" "}
                        {totalSummary.TotalAppointmentIssued}
                      </div>
                      <div className=""></div>
                    </div>
                    <div className="reort-footer-container-part2">
                      <div className="">
                        Total Balance Position:{" "}
                        {totalSummary.TotalBalancePosition}
                      </div>
                      <div className=""></div>
                    </div>
                  </div>
                </div> */}
              </div>

              <Pagination
                showPerPage={itemsPerPage}
                total={totalItems}
                onPaginationChange={handlePaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Department;
