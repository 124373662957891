import React, { useState, useEffect, useRef } from "react";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { IoMdArrowBack } from "react-icons/io";
import useClickOutside from "../useClickOutside";
import jsPDF from "jspdf";
import ReactDOM from "react-dom";
import html2canvas from "html2canvas";
import ColdWorkPermitTemplate from "./ColdWorkPermitTemplate";

function PermitExtensionRequestView({ isSidebarExpanded }) {
  const [designations, setDesignations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const { work_permit_id } = useParams();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isActionPopupOpen, setIsActionPopupOpen] = useState(false);
  const [reason, setReason] = useState(""); // Track reason input
  const [selectedRenewalId, setSelectedRenewalId] = useState(null);
  const [actionDesignations, setActionDesignations] = useState([]);
  const [workPermitId, setWorkPermitId] = useState(null); // State for work_permit_id
  const [renewalId, setRenewalId] = useState(null); // State for renewal_id
  const [isApprovePopupOpen, setApprovePopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    extension_reason: "",
    work_permit_id: "",
    id: "",
  });
  const [selectedPermitId, setSelectedPermitId] = useState(null);


  const handleSaveRejectClick = async () => {
    if (!reason.trim()) {
      setSeverity("error");
      showSnackbar("Reason is required to reject the permit.");
      return;
    }

    const payload = {
      work_permit_id: workPermitId, // Get from state
      renewal_id: renewalId, // Get from state
      request_type: "Extension", // Set `request_type` as "Close"
      reason: reason.trim(), // Trim whitespace from the reason
    };

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      if (!authKey) {
        throw new Error("Authorization key not found");
      }

      // Make the API call
      const response = await axios.post(
        `${API_URL}/api/reject_permit`,
        payload,
        {
          headers: { Authorization: authKey },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Permit rejected successfully.");
        closePopup();
      } else {
        throw new Error(response.data.message || "Failed to reject permit.");
      }
    } catch (error) {
      console.error("Error rejecting permit:", error);
      setSeverity("error");
      showSnackbar(
        error.response?.data?.message ||
          "An error occurred while rejecting the permit."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRejectClick = (work_permit_id, renewal_id) => {
    setIsPopupOpen(true);
    setIsActionPopupOpen(false);
    // Save these IDs in the state or use them directly in the popup
    setWorkPermitId(work_permit_id); // Save work_permit_id in the state
    setRenewalId(renewal_id); // Save renewal_id in the state
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return "N/A";

    // Create a Date object from the date string
    const date = new Date(dateString);

    // Convert to UTC and format time
    const time = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC",
    });

    // Format date to DD/MM/YYYY using UTC date methods
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based in JS
    const year = date.getUTCFullYear();

    // Combine to form the date string in DD/MM/YYYY format
    const dateStr = `${day}/${month}/${year}`;

    return `${dateStr}, ${time}`;
  };

  const handleBack = () => {
    window.history.back();
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_permit_renewal`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          work_permit_id: work_permit_id,
        },
      });

      setDesignations(response.data);
      // setDesignations(response.data || [{}]);
      setTotalItems(response.data.TotalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
      setLoading(false);
    }
  };

  // const fetchRenewalData = async (id) => {
  //   try {
  //     setLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.get(`${API_URL}/api/get_permit_renewal`, {
  //       headers: {
  //         Authorization: authKey,
  //       },
  //       params: {
  //         renewal_id: id,
  //       },
  //     });

  //     setDesignations(response.data);
  //     // setDesignations(response.data || [{}]);
  //     setTotalItems(response.data.TotalItems);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching department data:", error.message);
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchRenewalData();
  // }, []); // Empty dependency array

  const fetchRenewalData = async (renewalId) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_permit_renewal`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          renewal_id: renewalId, // Use the passed renewal ID
        },
      });

      setActionDesignations(response.data); // Update state with the fetched data
      setLoading(false);
    } catch (error) {
      console.error("Error fetching renewal data:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isActionPopupOpen && selectedRenewalId) {
      fetchRenewalData(selectedRenewalId);
    }
  }, [isActionPopupOpen, selectedRenewalId]);

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_designations`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setDesignations(response.data.Designations);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchivedDesignation = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_designations`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDesignations = designations.filter(
          (designation) => !selectedRows.includes(designation.id)
        );
        setDesignations(updatedDesignations);
        fetchData();
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting designations:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
  };

  const handleRefresh = () => {
    if (searchInput) {
      console.log("Search input is set:", searchInput);
    } else {
      fetchData(page, itemsPerPage);
    }
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  const handleClosepopup = () => {
    setIsActionPopupOpen(false);
  };

  const handleExtensionIssuClick = async (renewal_id) => {
    try {
      setIsLoading(true);
      // Retrieve the authKey from localStorage
      const authKey = localStorage.getItem("authKey");

      if (!authKey) {
        console.error("Authorization key not found");
        return;
      }

      // API call to approve the work permit
      const response = await axios.put(
        `${API_URL}/api/renew_permit_extension`,
        { renewal_id }, // Send form_id in the request body
        {
          headers: {
            Authorization: authKey, // Pass the Authorization header
          },
        }
      );

      // Check the API response status

      if (response.status === 200) {
        console.log(response.data.message);
        setSeverity("success");
        showSnackbar("Extension Work permit Issued successfully!");
        // setTimeout(() => {
        //   setIsActionPopupOpen(false);
        // }, 1000);
        setTimeout(() => {
          navigate(`/cold-work-permit?tab=Issued`);
        }, 1000);
      }
      // await fetchData();
    } catch (error) {
      console.error("Error saving department:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExtensionSaveClick = async (renewal_id) => {
    try {
      setIsLoading(true);
      // Retrieve the authKey from localStorage
      const authKey = localStorage.getItem("authKey");

      if (!authKey) {
        console.error("Authorization key not found");
        return;
      }

      // API call to approve the work permit
      const response = await axios.put(
        `${API_URL}/api/approve_permit_extension`,
        { renewal_id }, // Send form_id in the request body
        {
          headers: {
            Authorization: authKey, // Pass the Authorization header
          },
        }
      );

      // Check the API response status

      if (response.status === 200) {
        console.log(response.data.message);
        setSeverity("success");
        showSnackbar("Exteension permit approved successfully!");
        // setTimeout(() => {
        //   setIsActionPopupOpen(false);
        // }, 1000);
        setTimeout(() => {
          navigate(`/work-permit-inspection?tab=Issued`);
        }, 1000);
      }
      // await fetchData();
    } catch (error) {
      console.error("Error saving department:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleActionButton = () => {
  //   setIsActionPopupOpen(true);
  // };

  const handleActionButton = async (renewalId) => {
    try {
      console.log(`Clicked on designation ID: ${renewalId}`);

      // Open the popup
      setIsActionPopupOpen(true);

      // Fetch the renewal data
      await fetchRenewalData(renewalId);
    } catch (error) {
      console.error("Error handling action button:", error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleExtensionReason = async (id) => {
    // Prepare payload
    const payload = {
      work_permit_id: work_permit_id,
      renewal_id: id,
      additional_instruction: formData.additional_instruction, // Get from formData
    };

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.put(
        `${API_URL}/api/update_permit_extension`,
        payload,
        {
          headers: { Authorization: authKey },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Extension Permit Approve successfully.");
        // setTimeout(() => {
        //   setIsActionPopupOpen(false);
        // }, 1000);

        setTimeout(() => {
          navigate(`/cold-work-permit?tab=Issued`);
        }, 1000);

        // await fetchData();
      } else {
        throw new Error(response.data.message || "Failed to renew permit.");
      }
    } catch (error) {
      console.error("Error renewing permit:", error);
      setSeverity("error");
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while renewing the permit.";
      showSnackbar(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewGenratePermit = async (formId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_permit_details`, {
        headers: { Authorization: authKey },
        params: { work_permit_id: formId },
      });

      if (response.status === 200) {
        const permitDetails = response.data;

        // Create a temporary container to render the template
        const tempDiv = document.createElement("div");
        document.body.appendChild(tempDiv);

        // Render the template to the temporary container
        ReactDOM.render(
          <ColdWorkPermitTemplate
            permitDetails={permitDetails}
            isSidebarExpanded={false} // Adjust based on your layout
          />,
          tempDiv
        );

        // Retrieve sections for PDF rendering
        const section1 = tempDiv.querySelector("#section1");
        const section2 = tempDiv.querySelector("#section2");

        if (!section1 || !section2) {
          console.error("Required sections for PDF generation not found.");
          ReactDOM.unmountComponentAtNode(tempDiv);
          document.body.removeChild(tempDiv);
          return;
        }

        const pdf = new jsPDF("p", "mm", "a4");
        const options = {
          scale: 2,
          useCORS: true,
          backgroundColor: "yellow",
          windowWidth: 1550,
        };

        // Render Section 1
        const canvas1 = await html2canvas(section1, options);
        const imgData1 = canvas1.toDataURL("image/jpeg", 0.8);
        const imgWidth1 = 190; // A4 width minus padding
        const imgHeight1 = (canvas1.height * imgWidth1) / canvas1.width;

        pdf.addImage(imgData1, "JPEG", 10, 10, imgWidth1, imgHeight1);

        // Render Section 2 (on a new page)
        pdf.addPage();
        const canvas2 = await html2canvas(section2, options);
        const imgData2 = canvas2.toDataURL("image/jpeg", 0.8);
        const imgWidth2 = 190;
        const imgHeight2 = (canvas2.height * imgWidth2) / canvas2.width;

        pdf.addImage(imgData2, "JPEG", 10, 10, imgWidth2, imgHeight2);

        // Generate PDF Blob and open in a new tab
        const pdfBlob = pdf.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");

        // Clean up the temporary container
        ReactDOM.unmountComponentAtNode(tempDiv);
        document.body.removeChild(tempDiv);
      } else {
        console.error("Failed to fetch permit details:", response.statusText);
      }
    } catch (error) {
      console.error("Error generating PDF:", error.message);
    }
  };

  function getStatusColor(status) {
    switch (status) {
      case "Pending":
        return "orange";
      case "Approved":
        return "green";
      case "In Review":
        return "blue";
      case "Pending Approval":
        return "purple";
      default:
        return "black";
    }
  }
 
  // Call the popup when clicking the button
  const openApprovePopup = (permitId) => {
    setSelectedPermitId(permitId);
    setApprovePopupOpen(true);
  };

  const closeApprovePopup = () => {
    setApprovePopupOpen(false);
  };

  const confirmApprove = () => {
    if (selectedPermitId) {
      handleApprove(selectedPermitId); // Call the approval function
    }
    setApprovePopupOpen(false);
  };
  const handleApprove = async (permitId) => {
    setIsLoading(true);
    console.log("Approving permit:", permitId);
    
    // Simulating API call
    setTimeout(() => {
      console.log("Permit approved:", permitId);
      setIsLoading(false);
      setApprovePopupOpen(false);
    }, 2000);
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-list-content" ref={containerRef}>
          {isChecked ? (
            <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                    <button className="delete" onClick={handleDeletePopup}>
                      Move to archive
                    </button>
                    {isDeletePopup && (
                      <div className="confirm-delete-popup">
                        <div className="confirm-delete-popup-content">
                          <div className="confirm-model">
                            <div className="confirm-model-content">
                              <div className="confirm-model-content-body">
                                <p>Are you sure want to move archive?</p>
                              </div>
                              <div className="confirm-model-content-footer">
                                <button
                                  className="confirm-delete-button"
                                  onClick={handleArchivedDesignation}
                                >
                                  Yes
                                </button>
                                <button
                                  className="confirm-cancel-button"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="data-list-header">
              <div className="data-list-header-content">
                <div className="data-list-heading-content-left-side">
                  <Tooltip title="Back" arrow>
                    <IoMdArrowBack
                      className="back-company"
                      onClick={handleBack}
                    />
                  </Tooltip>
                  All Extension Permit
                </div>
                <div className="data-list-heading-content-right-side">
                  {/* <div className="search-bar-container">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      className="search-bar"
                    />
                    <IoSearchOutline className="search-bar-icon" />
                  </div>

                  <Link to="/designation-form" className="data-list-new">
                    <Tooltip title="New designation" arrow>
                      <button className="new-button">
                        <HiOutlinePlus className="new-plus-icon" /> New
                      </button>
                    </Tooltip>
                  </Link> */}

                  <Tooltip title="Refresh List" arrow>
                    <button className="refresh-button" onClick={handleRefresh}>
                      <TbRefresh className="refresh-icon" />
                    </button>
                  </Tooltip>
                  {/* <button
                    className="sort-button"
                    onClick={handleSortpopup}
                    ref={buttonRef}
                  >
                    <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                  </button>
                  {isSortPopup && (
                    <div className="sort-popup-container" ref={containerRef}>
                      <div className="sort-popup-content">
                        <div className="sort-by">
                          SORT BY{" "}
                          {sortOrder[sortColumn] === "asc"
                            ? "(Ascending)"
                            : "(Descending)"}
                        </div>
                        <div className="sort-by-button">
                          <button
                            onClick={() => handleSort("designation_name")}
                          >
                            Designations{" "}
                            {sortOrder.designation_name === "asc" ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                          <br />
                        </div>
                        <button
                          className="delete-item"
                          onClick={handleArchiveDesignation}
                        >
                          Archived Designations
                        </button>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          )}
          <div className="data-list-body" onClick={closePopups}>
            <div className="data-list-scroll-body">
              <div className="data-list-table">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : designations.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        {/* <th className="center-alignment">
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th> */}
                        <th className="center-alignment">SR. NO.</th>
                        <th>FROM DATE & TIME</th>
                        <th>TO DATE & TIME</th>
                        <th>RECEIVER NAME</th>
                        <th>EXTENSION REASON</th>
                        <th>STATUS</th>
                        <th>CREATED AT</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {designations.map((designation, index) => (
                        <tr key={designation.id} className="hoverable-row">
                          {/* <td className="center-alignment">
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(designation.id)}
                              onChange={() => handleSelectRow(designation.id)}
                            />
                          </td> */}

                          <td className="center-alignment">{index + 1}</td>
                          <td>
                            {formatDateTime(designation.date_from || "NA")}
                          </td>
                          <td>{formatDateTime(designation.date_to || "NA")}</td>
                          <td>{designation.receiver_name || "NA"}</td>
                          <td>{designation.extension_reason || "NA"}</td>
                          {/* <td>{designation.status || "NA"}</td> */}
                          <td
                            style={{
                              color: getStatusColor(designation.status),
                              fontWeight: "bold",
                            }}
                          >
                            {designation.status || "NA"}
                          </td>
                          <td>
                            {designation.renewal_date
                              ? new Date(designation.renewal_date)
                                  .toUTCString()
                                  .split("GMT")[0]
                                  .trim() + " IST"
                              : "NA"}
                          </td>
                          {/* <td>
                            <div className="new-permit">
                              {designation.is_renewed === 0 && (
                                <button
                                  className="new-button-download"
                                  onClick={() =>
                                    handleEditExtension(designation.id)
                                  }
                                >
                                  Add Precautions
                                </button>
                              )}

                              {designation.is_renewed === 1 && (
                                <button
                                  className="new-button-download"
                                  onClick={() =>
                                    handleEditExtension(designation.id)
                                  }
                                >
                                  Edit Precautions
                                </button>
                              )}

                              <button
                                className="new-button-download"
                                onClick={() =>
                                  handleExtensionSaveClick(designation.id)
                                }
                              >
                                Approve Extenstion request
                              </button>

                              <button
                                className="new-button-download"
                                onClick={() =>
                                  handleExtensionIssuClick(designation.id)
                                }
                              >
                                Issue Extenstion request
                              </button>

                              <button
                                className="new-button-download"
                                onClick={() =>
                                  handleDownloadPdf(designation.work_permit_id)
                                }
                              >
                                Download
                              </button>

                              <button
                                className="data-form-reject-button"
                                onClick={() =>
                                  handleRejectClick(designation.work_permit_id)
                                }
                              >
                                Reject
                              </button>

                              {isPopupOpen && (
                                <div className="reminder-popup-model">
                                  <div className="reminder-popup-content">
                                    <div className="reminder-popup-model-header">
                                      <div className="reminder-popup-model-content-header">
                                        <div className="reminder-popup-model-content-header-left-part">
                                          Reject Request Permit
                                        </div>
                                        <div className="reminder-popup-model-content-header-right-part"></div>
                                      </div>
                                      <div className="popup-new-form">
                                        <form className="popup-form-detail">
                                          <div className="new-data-form">
                                            <div className="form-group email-filed">
                                              <label htmlFor="reason">
                                                Reason{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <div className="input-group">
                                                <textarea
                                                  id="reason"
                                                  name="reason"
                                                  placeholder="Enter reason for rejected"
                                                  value={reason}
                                                  onChange={handleReasonChange}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                      <div className="footers">
                                        <div className="button-group-email">
                                          <div>
                                            <button
                                              className="person-user-save-button"
                                              type="button"
                                              onClick={() =>
                                                handleSaveRejectClick(
                                                  designation.work_permit_id
                                                )
                                              }
                                            >
                                              Ok
                                            </button>

                                            <button
                                              className="person-user-cancel-button"
                                              onClick={closePopup}
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                          <div></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                             
                            </div>
                          </td> */}

                          <td>
                            <div className="new-permit">
                              {/* <button
                                className="new-button-download"
                                onClick={() =>
                                  handleActionButton(designation.id)
                                }
                              >
                                Take action
                              </button> */}

                              {/* <button
                                className="new-button-download"
                                onClick={() =>
                                  handleActionButton(designation.id)
                                }
                                disabled={designation.status === "Approved" } // Disable button if status is Approved
                              >
                                Take action
                              </button> */}
                              {(designation.status === "Pending Approval" ||
                                designation.status === "Pending" ||
                                designation.status === "Approved") && (
                                <button
                                  className="new-button-download"
                                  onClick={() =>
                                    handleActionButton(designation.id)
                                  }
                                  disabled={designation.status === "Approved"}
                                >
                                  Take Action
                                </button>
                              )}

                              {designation.status === "In Review" && (
                                <button
                                  className="new-button-download"
                                  onClick={() =>
                                    handleActionButton(designation.id)
                                  }
                                  disabled={
                                    designation.safety_officer_approval_status ===
                                    1
                                  } // Disable button if status is Approved or Extension Rejected
                                >
                                  Take Action
                                </button>
                              )}
                            </div>

                            {isActionPopupOpen && (
                              <div className="reminder-popup-model">
                                <div className="reminder-popup-content">
                                  <div className="reminder-popup-model-header">
                                    <div className="reminder-popup-model-content-header">
                                      <div className="reminder-popup-model-content-header-left-part">
                                        Extension Permit Detail
                                      </div>
                                      <div className="reminder-popup-model-content-header-right-part">
                                        <button
                                          className="new-button-download"
                                          onClick={() =>
                                            handleViewGenratePermit(
                                              designation.work_permit_id
                                            )
                                          }
                                        >
                                          View Permit Request
                                        </button>
                                      </div>
                                    </div>
                                    <div className="popup-new-form">
                                      <div className="popup-form-detail">
                                        <div className="new-data-form">
                                          <table className="table multilevel-data">
                                            <thead>
                                              <tr>
                                                <th className="center-alignment">
                                                  SR. NO.
                                                </th>
                                                <th>RECEIVER NAME</th>
                                                {/* <th>ISSUER NAME</th> */}
                                                <th>FROM DATE & TIME</th>
                                                <th>TO DATE & TIME</th>
                                                <th>EXTENSION REASON</th>
                                                <th>ADDITIONAL PRECAUTIONS</th>
                                              </tr>
                                            </thead>
                                            <tbody className="data-list-scroll-bodys">
                                              {actionDesignations && (
                                                <tr key={actionDesignations.id}>
                                                  <td className="center-alignment">
                                                    {index + 1}
                                                  </td>
                                                  <td>
                                                    {actionDesignations.receiver_name ||
                                                      "NA"}
                                                  </td>
                                                  {/* <td>
                                                    {clearanceRenewal.issuer_name ||
                                                      "NA"}
                                                  </td> */}

                                                  <td>
                                                    {formatDateTime(
                                                      actionDesignations.date_from ||
                                                        "NA"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {formatDateTime(
                                                      actionDesignations.date_to ||
                                                        "NA"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {actionDesignations.extension_reason ||
                                                      "NA"}
                                                  </td>
                                                  <td>
                                                    {actionDesignations.additional_instruction ||
                                                      "NA"}
                                                  </td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        {actionDesignations?.status ===
                                          "Pending" && (
                                          <form className="form-detail">
                                            <div className="new-data-form">
                                              <div className="form-group">
                                                <label htmlFor="additional_instruction">
                                                  Additional precautions if any,
                                                  Otherwise mention "NIL"
                                                </label>
                                                <div className="input-group">
                                                  <textarea
                                                    name="additional_instruction"
                                                    type="message"
                                                    rows={5}
                                                    cols={7}
                                                    value={
                                                      formData.additional_instruction
                                                    }
                                                    onChange={handleInputChange}
                                                    className="textarea"
                                                    placeholder="Enter additional instruction"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                        )}
                                      </div>
                                    </div>
                                    <div className="footers">
                                      <div className="button-group-email">
                                        <div>
                                          {actionDesignations?.status ===
                                            "Pending" && (
                                            <button
                                              className="new-button-download"
                                              onClick={() =>
                                                handleExtensionReason(
                                                  actionDesignations.id
                                                )
                                              }
                                            >
                                              {isLoading
                                                ? "Approving..."
                                                : "Approve"}
                                              {/* Approve */}
                                            </button>
                                          )}

                                          {actionDesignations?.status ===
                                            "In Review" && (
                                            <button
                                              className="new-button-download"
                                              onClick={() =>
                                                handleExtensionSaveClick(
                                                  actionDesignations.id
                                                )
                                              }
                                            >
                                              {isLoading
                                                ? "Approving..."
                                                : "Approve"}
                                            </button>
                                          )}

                                          {actionDesignations?.status ===
                                            "Pending Approval" && (
                                            <button
                                              className="new-button-download"
                                              onClick={() =>
                                                handleExtensionIssuClick(
                                                  actionDesignations.id
                                                )
                                              }
                                            >
                                              {isLoading
                                                ? "Issuing..."
                                                : "Issue"}
                                            </button>
                                          )}

                                          <button
                                            className="data-form-cancel-button"
                                            onClick={handleClosepopup}
                                          >
                                            Cancel
                                          </button>

                                          {(actionDesignations?.status ===
                                            "In Review" ||
                                            actionDesignations?.status ===
                                              "Pending") && (
                                            <button
                                              className="data-form-reject-button"
                                              onClick={() =>
                                                handleRejectClick(
                                                  designation.work_permit_id,
                                                  designation.id
                                                )
                                              }
                                            >
                                              Reject
                                            </button>
                                          )}
                                          {isApprovePopupOpen && (
        <div className="permit-approval-popup">
          <div className="permit-approval-popup-content">
            <div className="approval-popup-header">Confirm Approval</div>
            <div className="approval-popup-message">
              Are you sure you want to approve this?
            </div>
            <div className="approval-popup-buttons">
              <button className="approve-button" onClick={confirmApprove}>
                Yes, Approve
              </button>
              <button className="cancel-button" onClick={closeApprovePopup}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {isPopupOpen && (
                              <div className="permit-popup-model">
                                <div className="permit-popup-content">
                                  <div className="reminder-popup-model-header">
                                    <div className="reminder-popup-model-content-header">
                                      <div className="reminder-popup-model-content-header-left-part">
                                        Reject Request Permit
                                      </div>
                                      <div className="reminder-popup-model-content-header-right-part"></div>
                                    </div>
                                    <div className="permit-popup-new-form">
                                      <form className="permit-popup-form-detail">
                                        <div className="new-data-form">
                                          <div className="form-group email-filed">
                                            <label htmlFor="reason">
                                              Reason{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <div className="input-group">
                                              <textarea
                                                id="reason"
                                                name="reason"
                                                placeholder="Enter reason for rejected"
                                                value={reason}
                                                onChange={handleReasonChange}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                    <div className="footers">
                                      <div className="button-group-email">
                                        <div>
                                          <button
                                            className="person-user-save-button"
                                            type="button"
                                            onClick={() =>
                                              handleSaveRejectClick(
                                                designation.work_permit_id
                                              )
                                            }
                                          >
                                            Ok
                                          </button>

                                          <button
                                            className="person-user-cancel-button"
                                            onClick={closePopup}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                        <div></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">No Data Found</div>
                  </div>
                )}
              </div>
              {/* <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                /> */}
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PermitExtensionRequestView;
