import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Form1 from "../ColdWorkPermit/Form1";
import Form2 from "../ColdWorkPermit/Form2";
import Form3 from "../ColdWorkPermit/Form3";
import API_URL from "../../src/Config";
import axios from "axios";
import { Tabs, Tab } from "@mui/material";

function PermitRequestEditTab({ isSidebarExpanded }) {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get("tab");

  const [tabValue, setTabValue] = useState(tabFromUrl || "Work Permit Details");
  const [tabStatus, setTabStatus] = useState({
    isCheckListCompleted: false,
    isPrecautionCompleted: false,
    isWorkDetailsCompleted: true,
  });

  useEffect(() => {
    if (tabFromUrl && tabFromUrl !== tabValue) {
      setTabValue(tabFromUrl);
    }
  }, [location.search]);

  const handleTabChange = (event, newValue) => {
    if (isTabAccessible(newValue)) {
      setTabValue(newValue);
      navigate(`?tab=${newValue}`);
    }
  };

  const isTabAccessible = (tabName) => {
    switch (tabName) {
      case "Work Permit Details":
        return tabStatus.isWorkDetailsCompleted;
      case "Checklist Items":
        return tabStatus.isCheckListCompleted;
      case "Hazards Precautions":
        return tabStatus.isPrecautionCompleted;
      default:
        return true;
    }
  };

  const fetchComplitionSatusData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_permit_completion_status/${id}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      setTabStatus({
        isCheckListCompleted: response.data.isCheckListCompleted,
        isPrecautionCompleted: response.data.isPrecautionCompleted,
        isWorkDetailsCompleted: response.data.isWorkDetailsCompleted,
      });
    } catch (error) {
      console.error("Error fetching tab status:", error.message);
    }
  };

  useEffect(() => {
    fetchComplitionSatusData();
  }, []);

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-list-content" ref={containerRef}>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => handleTabChange(event, newValue)}
            aria-label="report tabs"
            className="tabs-container"
          >
            <Tab
              label={
                <div className="tab-label">
                  Work Permit Details
                  {tabStatus.isWorkDetailsCompleted && (
                    <span className="status-icon">✔</span>
                  )}
                </div>
              }
              value="Work Permit Details"
              disabled={!tabStatus.isWorkDetailsCompleted}
            />
            <Tab
              label={
                <div className="tab-label">
                  Checklist Items
                  {tabStatus.isCheckListCompleted && (
                    <span className="status-icon">✔</span>
                  )}
                </div>
              }
              value="Checklist Items"
              disabled={!tabStatus.isCheckListCompleted}
            />
            <Tab
              label={
                <div className="tab-label">
                  Hazards & Precautions
                  {tabStatus.isPrecautionCompleted && (
                    <span className="status-icon">✔</span>
                  )}
                </div>
              }
              value="Hazards Precautions"
              disabled={!tabStatus.isPrecautionCompleted}
            />
          </Tabs>
          <div className="header-separater"></div>
          <div className="tabs-content">
            {tabValue === "Work Permit Details" && <Form1  fetchComplitionSatusData={fetchComplitionSatusData} />}
            {tabValue === "Checklist Items" && <Form2 fetchComplitionSatusData={fetchComplitionSatusData} />}
            {tabValue === "Hazards Precautions" && <Form3 fetchComplitionSatusData={fetchComplitionSatusData} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default PermitRequestEditTab;
