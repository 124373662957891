import React, { useState, useEffect, KeyboardEvent } from "react";
import "./BOQAddDetails.css";
import axios from "axios";
import API_URL from "../../../src/Config";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";

function BOQAddQtyForWeek({ isSidebarExpanded }) {
  const [boqDetail, setBoqDetail] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [saveStatus, setSaveStatus] = useState({ message: "", type: "" });
  const { id } = useParams();
  const navigate = useNavigate();
  const [editedValues, setEditedValues] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [boqData, setBoqData] = useState([]); // Store API data
  const fetchData = async () => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Fetch BOQ details
      const boqResponse = await axios.get(`${API_URL}/api/get_amc_boqs`, {
        headers: { Authorization: authKey },
        params: { project_id: id },
      });

      setBoqDetail(boqResponse.data.BOQs);

      // Fetch calculated planned quantity data
      const calcResponse = await axios.post(
        `${API_URL}/api/calculate-project-planned-qty`,
        { project_id: id },
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      setResponseData(calcResponse.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchBOQQuantity = async () => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Fetch BOQ quantities using the boq_id in the URL
      const boqQtyResponse = await axios.get(
        `${API_URL}/api/get_amc_boqs_with_quantities/${id}`,
        {
          headers: { Authorization: authKey },
        }
      );

      // Handle the response (e.g., update state with fetched data)
      console.log(boqQtyResponse.data.BOQs); // Use response data here
      setBoqData(boqQtyResponse.data.BOQs || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBOQQuantity();
  }, []);

  const handleBack = () => {
    navigate("/amc-projects");
  };

//   const getMonthsFromPlannedDistribution = () => {
//     const monthsWithWeeks = [];
//     responseData?.boqs.forEach((boq) => {
//       Object.keys(boq.planned_distribution).forEach((month) => {
//         const weeks = Object.keys(boq.planned_distribution[month]);
//         if (!monthsWithWeeks.find((entry) => entry.month === month)) {
//           monthsWithWeeks.push({ month, weeks });
//         }
//       });
//     });
//     return monthsWithWeeks;
//   };

const getMonthsFromPlannedDistribution = () => {
    if (!responseData?.boqs || !Array.isArray(responseData.boqs)) {
      return []; // Return an empty array if boqs are missing or not an array
    }
  
    const months = new Set(); // Using Set to avoid duplicates
  
    responseData.boqs.forEach((boq) => {
      if (boq.planned_distribution && typeof boq.planned_distribution === "object") {
        Object.keys(boq.planned_distribution).forEach((month) => {
          months.add(month); // Add unique months
        });
      }
    });
  
    return Array.from(months); // Convert Set to array
  };
  
  

 
  const handleInputChange = (month, value, boq_id) => {
    console.log(
      `Input Change: boq_id=${boq_id}, month=${month}, value=${value}`
    );
  
    setEditedValues((prevState) => ({
      ...prevState,
      [boq_id]: {
        ...prevState[boq_id],
        [month]: value, // Directly store value under month (no weeks)
      },
    }));
  };
  

  const getPlannedDotColor = (actual, planned) => {
    if (!actual) return "yellow-dot"; // No value entered
    if (parseFloat(actual) > parseFloat(planned)) return "green-dot"; // Actual > Planned
    if (parseFloat(actual) < parseFloat(planned)) return "red-dot"; // Actual < Planned
    return "blue-dot"; // Actual = Planned
  };



const handleKeyPress = async (event, month, boq_id) => {
    if (event.key === "Enter") {
      try {
        const newQty = editedValues?.[boq_id]?.[month];
  
        if (newQty) {
          const authKey = localStorage.getItem("authKey");
  
          // Find the matching BOQ item
          const boqItem = boqData.find((boq) => boq.id === boq_id);
  
          // Get the planned quantity for the month (without dividing by weeks)
          const plannedQty = responseData?.boqs.find(
            (boq) => boq.boq_id === boq_id
          )?.planned_distribution?.[month] || 0;
  
          // Construct payload
          const payload = {
            boq_id: boq_id,
            boq_qty_id: boqItem?.id || null, // Send BOQ ID or null
            month_year: month,
            qty: newQty,
            planned_qty: plannedQty, // Send full planned monthly quantity
          };
  
          // Send the payload to the backend API
          const response = await axios.post(
            `${API_URL}/api/add_boq_quantity`,
            payload,
            {
              headers: {
                Authorization: authKey,
                "Content-Type": "application/json",
              },
            }
          );
  
          console.log(response.data);
          setSaveStatus({
            message: "Quantity added successfully!",
            type: "success",
          });
  
          await fetchBOQQuantity(); // Refresh data after successful update
        }
      } catch (error) {
        console.error("Error adding BOQ quantity:", error.message);
        setSaveStatus({ message: "Failed to add quantity.", type: "error" });
      }
    }
  };
  
  

  const handleSaveClick = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      if (!authKey) {
        console.error("Authorization key is missing.");
        return;
      }

      // Construct payload based on edited values
      const boq_quantities = Object.entries(editedValues).flatMap(
        ([boq_id, months]) =>
          Object.entries(months).flatMap(([month, weeks]) =>
            Object.entries(weeks).map(([weekKey, qty]) => {
              const week_num = parseInt(weekKey.replace(/\D/g, ""), 10); // Convert "week_3" to 3

              // Find the matching BOQ item
              const boqItem = boqData.find((boq) => boq.id === boq_id);

              // Find the corresponding boq_qty_id from the quantities array
              const boqQtyItem = boqItem?.quantities?.[month]?.find(
                (q) => q.week === week_num
              );
              const boq_qty_id = boqQtyItem?.id || null; // Get the ID or send null if not found

              // Get planned quantity from responseData
              const plannedQty = responseData?.boqs.find(
                (boq) => boq.boq_id === boq_id
              )?.planned_distribution?.[month]?.[weekKey];

              return {
                boq_id,
                boq_qty_id, // Include boq_qty_id
                planned_qty: plannedQty ?? 0, // Fallback to 0 if not found
                schedule_date: null, // Set this if necessary
                week_num,
                month_year: month,
                qty: Number(qty), // Ensure it's a number
              };
            })
          )
      );

      if (boq_quantities.length === 0) {
        console.error("No quantities to save.");
        return;
      }

      const payload = { boq_quantities };

      const response = await axios.post(
        `${API_URL}/api/add_boq_quantities_bulk`,
        payload,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data.message);
      if (response.status === 200) {
        setSaveStatus({
          message: "Quantity added successfully!",
          type: "success",
        });
        await fetchBOQQuantity();
      }
    } catch (error) {
      console.error("Error saving BOQ quantities:", error.message);
      setSaveStatus({ message: "Failed to add quantity.", type: "error" });
    }
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBack}
                  />
                </Tooltip>
                Add BOQ qty
              </div>
              {saveStatus.message && (
                <div className={`save-status ${saveStatus.type}`}>
                  {saveStatus.message}
                </div>
              )}
            </div>
          </div>
          <div className="header-separator"></div>
        </div>

        <div className="data-list-body">
          <div className="legend-container">
            <div className="legend-item">
              <span className="legend-color actual-more"></span>
              <span>Actual &gt; Planned</span>
            </div>
            <div className="legend-item">
              <span className="legend-color actual-less"></span>
              <span>Actual &lt; Planned</span>
            </div>
            <div className="legend-item">
              <span className="legend-color actual-equal"></span>
              <span>Actual = Planned</span>
            </div>
            <div className="legend-item">
              <span className="legend-color no-value"></span>
              <span>No value entered</span>
            </div>
          </div>
          <div className="data-list-scroll-body">
            <div className="data-list-table">
              {loading ? (
                <div className="loader-container">
                  <div className="loader"></div>
                  <div>Loading...</div>
                </div>
              ) : boqDetail.length > 0 ? (
                <table className="excel-table">
                  <thead>
                    <tr>
                      <th
                        rowSpan="2"
                        className="sticky-col sticky-back boq-th1"
                      >
                        Service Code
                      </th>
                      <th
                        rowSpan="2"
                        className="sticky-col sticky-back boq-th2"
                      >
                        Description
                      </th>
                      <th
                        rowSpan="2"
                        className="sticky-col sticky-back boq-th3"
                      >
                        UOM
                      </th>
                      <th
                        rowSpan="2"
                        className="sticky-col sticky-back boq-th4"
                      >
                        Quantity
                      </th>
                      <th
                        rowSpan="2"
                        className="sticky-col sticky-back boq-th5"
                      >
                        Unit Rate
                      </th>
                      <th
                        rowSpan="2"
                        className="sticky-col sticky-back boq-th6"
                      >
                        Total Amount Including GST
                      </th>

                      <th rowSpan="2">Total Completed Qty</th>
                      <th rowSpan="2">Balance Qty</th>
                      <th rowSpan="2">Cummulative Amount</th>
                      <th rowSpan="2">Balance Amount</th>
                    
                    
                      {getMonthsFromPlannedDistribution().map((month, index) => (
  <th key={index}>{month}</th> // Directly show month name
))}

                    </tr>
                   
                  </thead>
                  <tbody>
                    {boqDetail.map((row, index) => {
                      // Find matching BOQ item from boqData
                      const matchingBoq =
                        boqData.find((boq) => boq.id === row.id) || {};

                      return (
                        <tr key={index}>
                          {/* First API: BOQ Details */}
                          <td className="sticky-col boq-th1">
                            {row.service_code}
                          </td>
                          <td className="sticky-col boq-th2">
                            {row.description}
                          </td>
                          <td className="sticky-col boq-th3">{row.uom}</td>
                          <td className="sticky-col boq-th4">{row.qty}</td>
                          <td className="sticky-col boq-th5">
                            {row.unit_rate}
                          </td>
                          <td className="sticky-col boq-th6">
                            {row.total_amount_with_gst}
                          </td>

                          {/* Second API: BOQ Quantities */}
                          <td>{matchingBoq.total_complete_qty ?? "-"}</td>
                          <td>{matchingBoq.balance_qty ?? "-"}</td>
                          <td>{matchingBoq.cumulative_amount ?? "-"}</td>
                          <td>{matchingBoq.balance_amount ?? "-"}</td>

                       
{/*            
                          {getMonthsFromPlannedDistribution().map(
                            (monthWithWeeks) => {
                              return monthWithWeeks.weeks.map((week) => {
                                // Find the matching BOQ item
                                const boqItem = boqData.find(
                                  (boq) => boq.id === row.id
                                );

                                // Extract planned quantity from responseData
                                const plannedQty = responseData?.boqs.find(
                                  (boq) => boq.boq_id === row.id
                                )?.planned_distribution[monthWithWeeks.month]?.[
                                  week
                                ];

                                // Convert "week_20" to 20
                                const weekNum = parseInt(
                                  week.replace(/\D/g, ""),
                                  10
                                );

                                // Find the matching quantity for the given week
                                const quantityRecord = boqItem?.quantities?.[
                                  monthWithWeeks.month
                                ]?.find((q) => q.week === weekNum);

                                const quantityValue = quantityRecord
                                  ? quantityRecord.quantity
                                  : "";

                                // Use editedValues or fallback to quantityValue/plannedQty
                                const inputValue =
                                  editedValues[row.id]?.[
                                    monthWithWeeks.month
                                  ]?.[week] ??
                                  quantityValue ??
                                  plannedQty ??
                                  "";

                                return (
                                  <td key={week}>
                                    <input
                                      type="number"
                                      className="qty-input"
                                      value={inputValue}
                                      placeholder="Enter Acutual Qty"
                                      onChange={(e) =>
                                        handleInputChange(
                                          monthWithWeeks.month,
                                          week,
                                          e.target.value,
                                          row.id
                                        )
                                      }
                                      onKeyDown={(e) =>
                                        handleKeyPress(
                                          e,
                                          monthWithWeeks.month,
                                          week,
                                          row.id
                                        )
                                      }
                                      min={1}
                                    />
                                    <div className="planned-qty">
                                      <span
                                        className={`planned-dot ${getPlannedDotColor(
                                          inputValue,
                                          plannedQty
                                        )}`}
                                      ></span>{" "}
                                      Planned: {plannedQty}
                                    </div>
                                  </td>
                                );
                              });
                            }
                          )}
                           */}

                        
{/* {getMonthsFromPlannedDistribution().map((month) => {
  const plannedQty = responseData?.boqs.find(
    (boq) => boq.boq_id === row.id
  )?.planned_distribution?.[month] ?? ""; // Safe access

  const inputValue = editedValues?.[row.id]?.[month] ?? plannedQty ?? "";

  return (
    <td key={month}>
     <input
  type="number"
  className="qty-input"
  value={inputValue}
  placeholder="Enter Actual Qty"
  onChange={(e) =>
    handleInputChange(month, e.target.value, row.id)
  }
  onKeyDown={(e) =>
    handleKeyPress(e, month, row.id) // Removed 'week'
  }
  min={1}
/>
      <div className="planned-qty">
        <span
          className={`planned-dot ${getPlannedDotColor(
            inputValue,
            plannedQty
          )}`}
        ></span>{" "}
        Planned: {plannedQty}
      </div>
    </td>
  );
})} */}


{/* {getMonthsFromPlannedDistribution().map((month) => {
  const plannedQty = responseData?.boqs.find(
    (boq) => boq.boq_id === row.id
  )?.planned_distribution?.[month] ?? "";

  // Ensure input is empty by default but shows existing data if available
  const inputValue =
    editedValues?.[row.id]?.[month] ??
    (plannedQty !== "" ? plannedQty : ""); 

  return (
    <td key={month}>
      <input
        type="number"
        className="qty-input"
        value={inputValue} // Empty by default
        placeholder="Enter Actual Qty"
        onChange={(e) =>
          handleInputChange(month, e.target.value, row.id)
        }
        onKeyDown={(e) =>
          handleKeyPress(e, month, row.id)
        }
        min={1}
      />
      <div className="planned-qty">
        <span
          className={`planned-dot ${getPlannedDotColor(
            inputValue,
            plannedQty
          )}`}
        ></span>{" "}
        Planned: {plannedQty}
      </div>
    </td>
  );
})} */}

{/* {getMonthsFromPlannedDistribution().map((month) => {
  const plannedQty = responseData?.boqs.find(
    (boq) => boq.boq_id === row.id
  )?.planned_distribution?.[month] ?? "";

  // Ensure input is empty by default but shows existing data if available
  const inputValue =
    editedValues?.[row.id]?.[month] ??
    (plannedQty !== "" ? plannedQty : ""); 

  return (
    <td key={month}>
      <input
        type="number"
        className="qty-input"
        value={inputValue} // Empty by default
        placeholder="Enter Actual Qty"
        onChange={(e) =>
          handleInputChange(month, e.target.value, row.id)
        }
        onKeyDown={(e) =>
          handleKeyPress(e, month, row.id) // Removed 'week'
        }
        min={1}
      />
      <div className="planned-qty">
        <span
          className={`planned-dot ${getPlannedDotColor(
            inputValue,
            plannedQty
          )}`}
        ></span>{" "}
        Planned: {plannedQty}
      </div>
    </td>
  );
})} */}
{/* {getMonthsFromPlannedDistribution().map((month) => {
  const plannedQty = responseData?.boqs.find(
    (boq) => boq.boq_id === row.id
  )?.planned_distribution?.[month] ?? "";

  // Ensure input is empty by default but shows edited data if available
  const inputValue =
    editedValues?.[row.id]?.[month] ?? ""; // Now it's always empty initially

  return (
    <td key={month}>
      <input
        type="number"
        className="qty-input"
        value={inputValue} // Always empty by default
        placeholder="Enter Actual Qty" // Shows placeholder instead
        onChange={(e) =>
          handleInputChange(month, e.target.value, row.id)
        }
        onKeyDown={(e) =>
          handleKeyPress(e, month, row.id)
        }
        min={1}
      />
      <div className="planned-qty">
        <span
          className={`planned-dot ${getPlannedDotColor(
            inputValue,
            plannedQty
          )}`}
        ></span>{" "}
        Planned: {plannedQty}
      </div>
    </td>
  );
})} */}

{getMonthsFromPlannedDistribution().map((month) => {
  // Ensure responseData and BOQs exist before accessing properties
  const boqItem = responseData?.BOQs?.find((boq) => boq.id === row?.id);

  // Ensure quantities exist before accessing month data
  const monthData = boqItem?.quantities?.[month] ?? [];

  // Check if a saved quantity exists for the month
  const savedQuantity = monthData.length > 0 ? monthData[0]?.quantity ?? "" : "";

  // Ensure input shows saved quantity, otherwise remains empty
  const inputValue = editedValues?.[row?.id]?.[month] ?? savedQuantity ?? "";

  return (
    <td key={month}>
      <input
        type="number"
        className="qty-input"
        value={inputValue} // Show saved quantity if available
        placeholder="Enter Actual Qty" // Otherwise, keep it empty
        onChange={(e) =>
          handleInputChange(month, e.target.value, row?.id)
        }
        onKeyDown={(e) =>
          handleKeyPress(e, month, row?.id)
        }
        min={1}
      />
      <div className="planned-qty">
        <span
          className={`planned-dot ${getPlannedDotColor(
            inputValue,
            boqItem?.planned_distribution?.[month] ?? ""
          )}`}
        ></span>{" "}
        Planned: {boqItem?.planned_distribution?.[month] ?? ""}
      </div>
    </td>
  );
})}



                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div>No BOQ details found.</div>
              )}
            </div>
          </div>

          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                // disabled={isLoading}
              >
                {/* {isLoading ? "Saving..." : "Save"} */}
                Save
              </button>
              <button
                className="data-form-cancel-button"
                // onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BOQAddQtyForWeek;
