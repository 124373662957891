import React, { useState, useEffect, useRef } from "react";
import "./ColdWorkPermit.css";
import "./ColdWorkPermitForm.css";
import { Link } from "react-router-dom";
import { HiOutlinePlus } from "react-icons/hi";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Pagination from "../Component/Pagination";
import useClickOutside from "../useClickOutside";
import jsPDF from "jspdf";
import ReactDOM from "react-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import html2canvas from "html2canvas";
import ColdWorkPermitTemplate from "./ColdWorkPermitTemplate";
import HotWorkPermitTemplate from "../HotWorkPermit/HotWorkPermitTemplate";
import { RxDownload } from "react-icons/rx";

function PermitRequestForRequester({
  isSidebarExpanded,
  status,
  updateNewCounts,
  updateTotalItems,
  updateNotification,
  updateNotificationIds,
}) {
  const [designations, setDesignations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedWorkPermitId, setSelectedWorkPermitId] = useState(null);
  const [workStatus, setWorkStatus] = useState({
    completionStatus: 0, // For "Completed"
    stoppageStatus: 0, // For "Stopped"
    areaCleanedStatus: 0, // For "Area Clean"
    work_permit_id: "", // For Work Permit ID
  });

  const [selectedStatus, setSelectedStatus] = useState(""); // Track selected status
  const [reason, setReason] = useState(""); // Track reason input

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setSelectedStatus(value);

    setWorkStatus((prevState) => ({
      ...prevState,
      completionStatus: value === "Completed",
      stoppageStatus: value === "Stopped",
    }));

    if (value === "Stopped") {
      setReason(""); // Clear reason field if switched to Stopped
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setWorkStatus((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const closePopup = () => {
    console.log("Popup is being closed"); // Log message
    setIsPopupOpen(false);
  };

  // const handleSaveCloseClick = async (work_permit_id) => {
  //   try {
  //     setIsLoading(true);

  //     const authKey = localStorage.getItem("authKey");
  //     if (!authKey) {
  //       throw new Error("Authorization key not found");
  //     }

  //     const payload = {
  //       work_permit_id,
  //       completion_status: workStatus.completionStatus ? 1 : 0,
  //       stoppage_status: workStatus.stoppageStatus ? 1 : 0,
  //       area_cleaned_status: workStatus.areaCleanedStatus ? 1 : 0,
  //       reason: selectedStatus === "Stopped" ? reason : "",
  //     };

  //     const response = await axios.post(
  //       `${API_URL}/api/request_permit_closure`,
  //       payload,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );

  //     if (response.status === 201) {
  //       setSeverity("success");
  //       showSnackbar("Form added successfully");
  //       closePopup();
  //       await fetchData(page, itemsPerPage, status);
  //     }
  //   } catch (error) {
  //     console.error("Error saving form:", error.message);
  //     setSeverity("error");
  //     showSnackbar(
  //       error.response?.data?.message ||
  //       "An error occurred while saving the form"
  //     );
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveCloseClick = async (work_permit_id) => {
    try {
      setIsLoading(true);

      const authKey = localStorage.getItem("authKey");
      if (!authKey) {
        throw new Error("Authorization key not found");
      }

      const payload = {
        work_permit_id,
        completion_status: workStatus.completionStatus ? 1 : 0,
        stoppage_status: workStatus.stoppageStatus ? 1 : 0,
        area_cleaned_status: workStatus.areaCleanedStatus ? 1 : 0,
        reason: selectedStatus === "Stopped" ? reason : "",
      };

      const response = await axios.post(
        `${API_URL}/api/request_permit_closure`,
        payload,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Close Request Send successfully");

        // **Close the popup after showing the success message**
        // closePopup();
        setIsPopupOpen(false);
        await fetchData(page, itemsPerPage, status);
      }
    } catch (error) {
      console.error("Error saving form:", error.message);
      setSeverity("error");
      showSnackbar(
        error.response?.data?.message ||
          "An error occurred while saving the form"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const openPopup = (work_permit_id) => {
    console.log("Selected Work Permit ID:", work_permit_id);
    setSelectedWorkPermitId(work_permit_id); // Store the selected ID
    setIsPopupOpen(true); // Open the popup
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const fetchData = async (currentPage, itemsPerPage, status) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/requester/get_work_permit_requests`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
            status: status, // Pass status here
          },
        }
      );
      console.log("Fetched totalItems:", response.data.totalItems);
      setDesignations(response.data.permit_details);
      setTotalItems(response.data.totalItems);
      updateTotalItems(response.data.tabCounts);
      updateNotification(response.data.notificationCounts);
      updateNotificationIds(response.data.notificationIds);
      updateNewCounts(response.data.newCounts);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
      setLoading(false);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage, status);
      return;
    }
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_designations`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setDesignations(response.data.Designations);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        if (status) {
          fetchData(page, itemsPerPage, status);
        }
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage, status]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleDownloadColdworkPdf = async (formId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_permit_details`, {
        headers: { Authorization: authKey },
        params: { work_permit_id: formId },
      });

      if (response.status === 200) {
        const permitDetails = response.data;

        // Create a temporary div to render the template
        const tempDiv = document.createElement("div");
        document.body.appendChild(tempDiv);

        ReactDOM.render(
          <ColdWorkPermitTemplate
            permitDetails={permitDetails}
            isSidebarExpanded={isSidebarExpanded}
          />,
          tempDiv
        );

        // Generate PDF from the rendered content
        const section1 = tempDiv.querySelector("#section1");
        const section2 = tempDiv.querySelector("#section2");

        if (!section1 || !section2) {
          console.error("PDF sections not found.");
          return;
        }

        const pdf = new jsPDF("p", "mm", "a4");
        const options = {
          scale: 2,
          useCORS: true,
          backgroundColor: "yellow",
          windowWidth: 1550,
        };

        // Render Section 1
        const canvas1 = await html2canvas(section1, options);
        const imgData1 = canvas1.toDataURL("image/jpeg", 0.8);
        const imgWidth1 = 190; // A4 width minus padding
        const imgHeight1 = (canvas1.height * imgWidth1) / canvas1.width;

        pdf.addImage(imgData1, "JPEG", 10, 10, imgWidth1, imgHeight1);

        // Add Section 2
        pdf.addPage();
        const canvas2 = await html2canvas(section2, options);
        const imgData2 = canvas2.toDataURL("image/jpeg", 0.8);
        const imgWidth2 = 190;
        const imgHeight2 = (canvas2.height * imgWidth2) / canvas2.width;

        pdf.addImage(imgData2, "JPEG", 10, 10, imgWidth2, imgHeight2);
        pdf.save(`Cold_Work_Permit_${formId}.pdf`);

        // Clean up the temporary div
        ReactDOM.unmountComponentAtNode(tempDiv);
        document.body.removeChild(tempDiv);
      } else {
        console.error("Failed to fetch permit details:", response.statusText);
      }
    } catch (error) {
      console.error("Error generating PDF:", error.message);
    }
  };
  const handleDownloadHotWorkPdf = async (formId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_permit_details`, {
        headers: { Authorization: authKey },
        params: { work_permit_id: formId },
      });

      if (response.status === 200) {
        const permitDetails = response.data;

        // Create a temporary div to render the template
        const tempDiv = document.createElement("div");
        document.body.appendChild(tempDiv);

        ReactDOM.render(
          <HotWorkPermitTemplate
            permitDetails={permitDetails}
            isSidebarExpanded={isSidebarExpanded}
          />,
          tempDiv
        );

        // Generate PDF from the rendered content
        const section1 = tempDiv.querySelector("#section1");
        const section2 = tempDiv.querySelector("#section2");

        if (!section1 || !section2) {
          console.error("PDF sections not found.");
          return;
        }

        const pdf = new jsPDF("p", "mm", "a4");
        const options = {
          scale: 2,
          useCORS: true,
          backgroundColor: "yellow",
          windowWidth: 1550,
        };

        // Render Section 1
        const canvas1 = await html2canvas(section1, options);
        const imgData1 = canvas1.toDataURL("image/jpeg", 0.8);
        const imgWidth1 = 190; // A4 width minus padding
        const imgHeight1 = (canvas1.height * imgWidth1) / canvas1.width;

        pdf.addImage(imgData1, "JPEG", 10, 10, imgWidth1, imgHeight1);

        // Add Section 2
        pdf.addPage();
        const canvas2 = await html2canvas(section2, options);
        const imgData2 = canvas2.toDataURL("image/jpeg", 0.8);
        const imgWidth2 = 190;
        const imgHeight2 = (canvas2.height * imgWidth2) / canvas2.width;

        pdf.addImage(imgData2, "JPEG", 10, 10, imgWidth2, imgHeight2);
        pdf.save(`Hot_Work_Permit_${formId}.pdf`);

        // Clean up the temporary div
        ReactDOM.unmountComponentAtNode(tempDiv);
        document.body.removeChild(tempDiv);
      } else {
        console.error("Failed to fetch permit details:", response.statusText);
      }
    } catch (error) {
      console.error("Error generating PDF:", error.message);
    }
  };


  const handleExtension = (work_permit_id, index) => {
    navigate(`/form4/${work_permit_id}`);
  };
  const handleRejectRequest = (work_permit_id, index) => {
    navigate(`/reject-req/${work_permit_id}`);
  };

  const handleCloseRequest = (work_permit_id, index) => {
    navigate(`/close-request-tab/${work_permit_id}`);
  };

  const handleEditClick = (work_permit_id, index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
    navigate(`/edit-permit-request-form/${work_permit_id}`);
  };

  const handleRefresh = () => {
    if (searchInput) {
      console.log("Search input is set:", searchInput);
    } else {
      fetchData(page, itemsPerPage, status);
    }
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleApproveExtension = (workPermitId) => {
    navigate(`/view-extension/${workPermitId}`);
  };

  const handleSaveClick = async (work_permit_id) => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Create payload with only work_permit_id and status
      const payload = {
        permit_id: work_permit_id,
        status: "Submitted", // Hardcoded to "Submitted" for this case
      };

      // API call
      const response = await axios.put(
        `${API_URL}/api/update_status`,
        payload,
        {
          headers: { Authorization: authKey },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar(`Permit Request sent successfully.`);

        // Refetch data after successful update
        await fetchData(page, itemsPerPage, status);

        // Optionally navigate if needed
        // navigate(`/permit-requests`);
      } else {
        throw new Error(
          response.data.message || "Failed to update permit status."
        );
      }
    } catch (error) {
      console.error("Error updating permit status:", error);
      setSeverity("error");
      showSnackbar("An error occurred while updating permit status.");
    } finally {
      setIsLoading(false); // Ensure loading state is reset
    }
  };

  const handleViewPermitsPdf = (workPermitId) => {
    // navigate(`/cold-work-permit-inspection-view/${workPermitId}`);
    navigate(`/view-pdf-formate/${workPermitId}`);
  };

  function getRequestStatusColor(request_status) {
    switch (request_status) {
      case "Draft":
        return "gray";
      case "In Review":
        return "blue";
      case "Submitted":
        return "orange";
      case "Approved":
        return "green";
      case "Pending Approval":
        return "purple";
      case "Renewed":
        return "teal";
        case "Safety Verified":
          return "#fb7c1f";
      case "Closed":
        return "#d42d2d";
      case "Rejected":
        return "red";
      case "Issued":
        return "darkgreen";
      default:
        return "black";
    }
  }

  function getStatusColor(status) {
    switch (status) {
      case "Active":
        return "green";
      case "Inactive":
        return "gray";
      case "Closed":
        return "#d42d2d";
      case "Expired":
        return "red";
      default:
        return "black";
    }
  }

  const handleClosingRequest = (work_permit_id, request_status) => {
    if (request_status === "Issued") {
      navigate(`/close-request-tab/${work_permit_id}`);
    }
  };

  return (
    <>
      <div className="data-list-content" ref={containerRef}>
        <div className="data-list-header">
          <div className="data-list-header-content">
            <div className="data-list-heading-content-left-side">
              All Work Permit Requests
            </div>

            <div className="data-list-heading-content-right-side">
              {/* <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div> */}

              <Link to="/permit-request-form" className="data-list-new">
                <Tooltip title="New" arrow>
                  <button className="new-button">
                    <HiOutlinePlus className="new-plus-icon" /> New Permit
                    Request
                  </button>
                </Tooltip>
              </Link>

              <Tooltip title="Refresh List" arrow>
                <button className="refresh-button" onClick={handleRefresh}>
                  <TbRefresh className="refresh-icon" />
                </button>
              </Tooltip>
              <button
                className="sort-button"
                onClick={handleSortpopup}
                ref={buttonRef}
              >
                <PiDotsThreeOutlineVerticalFill className="sort-icon" />
              </button>
              {/* {isSortPopup && (
                    <div className="sort-popup-container" ref={containerRef}>
                      <div className="sort-popup-content">
                        <div className="sort-by">
                          SORT BY{" "}
                          {sortOrder[sortColumn] === "asc"
                            ? "(Ascending)"
                            : "(Descending)"}
                        </div>
                        <div className="sort-by-button">
                          <button
                            onClick={() => handleSort("designation_name")}
                          >
                            Designations{" "}
                            {sortOrders.designation_name === "asc" ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                          <br />
                        </div>
                        <button
                          className="delete-item"
                          onClick={handleArchiveDesignation}
                        >
                          Archived Designations
                        </button>
                      </div>
                    </div>
                  )} */}
            </div>
          </div>
        </div>

        <div className="data-list-body" onClick={closePopups}>
          <div className="data-list-scroll-bodys">
            <div className="data-list-table-report">
              {loading ? (
                <div className="loader-container">
                  <div className="loader"></div>
                  <div>Loading...</div>
                </div>
              ) : designations.length > 0 ? (
                <table className="table multilevel-data">
                  <thead>
                    <tr>
                      {/* <th className="center-alignment"></th> */}
                      <th className="center-alignment">SR. NO.</th>
                      <th className="center-alignment">REQUEST ID</th>
                      <th>PERMIT TYPE</th>
                      <th>RECEIVER NAME</th>
                      <th>WORK DESCRIPTION</th>
                      <th>REQUEST STATUS</th>
                      <th>STATUS</th>
                      <th>REQUESTED AT</th>
                      {/* {designations.every(
                        (designation) =>
                          designation.request_status !== "Issued" &&
                          designation.request_status !== "Renewed"
                      ) && <th>REQUESTED AT</th>} */}

                      {designations.some(
                        (designation) =>
                          designation.request_status === "Issued" ||
                          designation.request_status === "Renewed"
                      ) && <th>ISSUED AT</th>}

                      {designations.some(
                        (designation) => designation.request_status === "Closed"
                      ) && <th>CLOSED AT</th>}

                      <th className="center-alignment">ACTION</th>
                    </tr>
                  </thead>
                  <tbody className="data-list-scroll-bodys">
                    {designations.map((designation, index) => (
                      <tr key={designation.id} className="hoverable-row">
                        {/* <td className="center-alignment">
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(designation.id)}
                                onChange={() => handleSelectRow(designation.id)}
                              />
                            </td> */}
                        {/* <td className="center-alignment">
                          <div className="icon-container">
                            <span
                              className={
                                designation.is_new_row === "1"
                                  ? "new-badge blinking-new-badge"
                                  : "new-badge"
                              }
                            >   
                            </span>
                          </div>
                        </td> */}

                        <td className="center-alignment">{index + 1}</td>
                        <td
                          className="center-alignment"
                          style={{ color: "red" }}
                        >
                          {designation.request_id || "NA"}
                        </td>
                        <td>{designation.work_permit_name || "NA"}</td>
                        <td>{designation.receiver_name || "NA"}</td>
                        <td>{designation.work_description || "NA"}</td>
                        {/* <td
                          style={{
                            color:
                              designation.request_status === "Draft"
                                ? "gray"
                                : designation.request_status === "Submitted"
                                ? "orange"
                                : designation.request_status === "In Review"
                                ? "#8f7a13"
                                : designation.request_status ===
                                  "Pending Approval"
                                ? "#8f7a13"
                                : designation.request_status === "Approved"
                                ? "blue"
                                : designation.request_status === "Issued"
                                ? "green"
                                : designation.request_status === "Rejected"
                                ? "red"
                                : "inherit", // Default color if status doesn't match
                            fontWeight: "bold", // Make the text bold
                          }}
                        >
                          {designation.request_status || "NA"}
                        </td> */}

                        <td
                          style={{
                            color: getRequestStatusColor(
                              designation.request_status
                            ),
                            fontWeight: "bold",
                          }}
                        >
                          {designation.request_status || "NA"}
                        </td>
                        {/* <td
                          style={{
                            color:
                              designation.status === "Inactive"
                                ? "gray"
                                : designation.status === "Closed"
                                ? "orange"
                                : designation.status === "Active"
                                ? "blue"
                                : designation.status === "Actives"
                                ? "green"
                                : designation.status === "Expired"
                                ? "red"
                                : "inherit", // Default color if status doesn't match
                            fontWeight: "bold", // Make the text bold
                          }}
                        >
                          {designation.status || "NA"}
                        </td> */}

                        <td
                          style={{
                            color: getStatusColor(designation.status),
                            fontWeight: "bold",
                          }}
                        >
                          {designation.status || "NA"}
                        </td>

                        <td>
                          {designation.created_at
                            ? new Date(designation.created_at)
                                .toUTCString()
                                .split("GMT")[0]
                                .trim() + " IST"
                            : "NA"}
                        </td>

                        {(designation.request_status === "Issued" ||
                          designation.request_status === "Renewed") && (
                          <td>
                            {designation.issued_date
                              ? new Date(designation.issued_date)
                                  .toUTCString()
                                  .split("GMT")[0]
                                  .trim() + " IST"
                              : "NA"}
                          </td>
                        )}

                        {designation.request_status === "Closed" && (
                          <td>
                            {designation.closed_at
                              ? new Date(designation.closed_at)
                                  .toUTCString()
                                  .split("GMT")[0]
                                  .trim() + " IST"
                              : "NA"}
                          </td>
                        )}

                        {/* {!(
                          designation.request_status === "Issued" ||
                          designation.request_status === "Renewed"
                        ) && (
                          <td>
                            {designation.created_at
                              ? new Date(designation.created_at)
                                  .toUTCString()
                                  .split("GMT")[0]
                                  .trim() + " IST"
                              : "NA"}
                          </td>
                        )} */}

                        <td className="center-alignment edit-req-but">
                          {(designation?.request_status === "Draft" ||
                            designation?.request_status ===
                              "Pending Approval" ||
                            designation?.request_status === "Safety Verified" ||
                            designation?.request_status === "In Review" ||
                            designation?.request_status === "Submitted") && (
                            <button
                              className={`request-buttons ${
                                designation.request_status !== "Draft"
                                  ? "disabled"
                                  : ""
                              }`}
                              type="button"
                              onClick={() =>
                                handleSaveClick(designation.work_permit_id)
                              }
                              disabled={
                                isLoading ||
                                designation.request_status !== "Draft"
                              } // Disable if loading or status is not "Draft"
                            >
                              {isLoading
                                ? "Sending Request..."
                                : "Send Request"}
                            </button>
                          )}

                          {/* {(designation?.request_status === "Issued" || 
                          designation?.request_status === "Closed" ||
                            designation?.request_status === "Renewed") && (
                            <button
                              className="new-button-download"
                              onClick={() =>
                                handleDownloadColdworkPdf(designation.work_permit_id)
                              }
                            >
                              <RxDownload className="dnld-btn" />
                            </button>
                          )} */}

{designation?.work_permit_name === "Cold Work" && 
  (designation?.request_status === "Issued" || 
   designation?.request_status === "Closed" ||
   designation?.request_status === "Renewed") && (
    <button
      className="new-button-download"
      onClick={() =>
        handleDownloadColdworkPdf(designation.work_permit_id)
      }
    >
      <RxDownload className="dnld-btn" />
    </button>
)}

                            {/* {(designation?.request_status === "Issued" || 
  designation?.request_status === "Closed" ||
  designation?.request_status === "Renewed" ||
  designation?.work_permit_name === "Hot Work") && (
    <button
      className="new-button-download"
      onClick={() =>
        handleDownloadHotWorkPdf(designation.work_permit_id)
      }
    >
      <RxDownload className="dnld-btn" />
    </button>
)}  */}

{designation?.work_permit_name === "Hot Work" && 
  (designation?.request_status === "Issued" || 
   designation?.request_status === "Closed" ||
   designation?.request_status === "Renewed") && (
    <button
      className="new-button-download"
      onClick={() =>
        handleDownloadHotWorkPdf(designation.work_permit_id)
      }
    >
      <RxDownload className="dnld-btn" />
    </button>
)}



                          {designation?.request_status === "Rejected" && (
                            <button
                              className="new-button-download"
                              onClick={() =>
                                handleRejectRequest(designation.work_permit_id)
                              }
                            >
                              View
                            </button>
                          )}

                          {designation?.request_status === "Closed" && (
                            <button
                              className="new-button-download"
                              onClick={() =>
                                handleCloseRequest(designation.work_permit_id)
                              }
                            >
                              View
                            </button>
                          )}
                          {(designation.request_status === "Renewed" ||
                            designation.request_status === "Issued") && (
                            <button
                              className="new-button-download"
                              onClick={() =>
                                handleViewPermitsPdf(designation.work_permit_id)
                              }
                            >
                              View
                            </button>
                          )}

                          {(designation?.request_status === "Submitted" ||
                            designation?.request_status === "Draft" ||
                            designation?.request_status === "In Review") && (
                            <button
                              className="edit-button-inquiry12"
                              onClick={() =>
                                handleEditClick(
                                  designation.work_permit_id,
                                  index
                                )
                              }
                            >
                              <MdOutlineModeEditOutline className="edit-show-icon" />{" "}
                              Edit
                            </button>
                          )}

                          {designation?.can_request_extension === true && (
                            <button
                              className="new-button-download"
                              onClick={() =>
                                handleExtension(designation.work_permit_id)
                              }
                            >
                              Extension Request
                            </button>
                          )} 


{!designation.can_request_extension &&
  (designation.request_status === "Renewed" ||
    designation.request_status === "Issued") && (
    <div className="new-permit dot-relative">
      <button
        className="new-button-download view-pulse-button"
        onClick={() =>
          handleApproveExtension(
            designation.work_permit_id,
            designation.request_status
          )
        }
      >
        View Extension Request
      </button>
      <div className="red-button"></div>
    </div>
  )}

                          {/* {designation.can_request_extension === true && (
                            <div className="new-permit">
                              <button
                                className="new-button-download"
                                onClick={() =>
                                  handleClosingRequest(
                                    designation.work_permit_id,
                                    designation.request_status
                                  )
                                }
                              >
                                View Close Request
                              </button>
                            </div>
                          )}

                          {designation.can_request_extension === true && (
                            <div className="new-permit">
                              <button
                                className="new-button-download"
                                onClick={() =>
                                  handleApproveExtension(
                                    designation.work_permit_id,
                                    designation.request_status
                                  )
                                }
                              >
                                View Extension Request
                              </button>
                            </div>
                          )} */}

                          {designation.can_request_closure === true && (
                            <button
                              className="new-button-download"
                              onClick={() =>
                                openPopup(
                                  designation.work_permit_id,
                                  designation.request_status
                                )
                              }
                            >
                              Close Request
                            </button>
                         )} 
                        </td>

                        {isPopupOpen && (
                          <div className="permit-popup-model">
                            <div className="permit-popup-content">
                              <div className="reminder-popup-model-header">
                                <div className="reminder-popup-model-content-header">
                                  <div className="reminder-popup-model-content-header-left-part">
                                    Permit Closure Request Form
                                  </div>
                                  <div className="reminder-popup-model-content-header-right-part"></div>
                                </div>
                                <div className="popup-new-form">
                                  <form className="permit-popup-form-detail">
                                    <div className="new-data-form">
                                      <div className="form-group-select">
                                        Receiver: Please certify that the
                                        subject work has been:
                                        <span className="text-danger">*</span>
                                      </div>
                                      <div className="form-group-select">
                                        <label htmlFor="work_status">
                                          Work Status{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="checkbox-input">
                                          {/* <div className="form-check"> */}
                                          <input
                                            // className="form-check-input"
                                            type="radio"
                                            name="workStatus"
                                            id="workCompleted"
                                            value="Completed"
                                            checked={
                                              selectedStatus === "Completed"
                                            }
                                            onChange={handleRadioChange}
                                          />
                                          <label htmlFor="workCompleted">
                                            Completed
                                          </label>
                                        </div>
                                        {/* </div> */}
                                        {/* <div className="form-check"> */}
                                        <div className="checkbox-input">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="workStatus"
                                            id="workStopped"
                                            value="Stopped"
                                            checked={
                                              selectedStatus === "Stopped"
                                            }
                                            onChange={handleRadioChange}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="workStopped"
                                          >
                                            Stopped
                                          </label>
                                        </div>
                                      </div>

                                      {selectedStatus === "Stopped" && (
                                        <div className="form-group email-filed">
                                          <label htmlFor="reason">
                                            Reason{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group">
                                            <textarea
                                              id="reason"
                                              name="reason"
                                              placeholder="Enter reason for stoppage"
                                              value={reason}
                                              onChange={handleReasonChange}
                                            />
                                          </div>
                                        </div>
                                      )}
                                      <div className="form-group-select">
                                        <label htmlFor="work_status">
                                          Area Status{" "}
                                          <span className="text-danger">*</span>
                                        </label>

                                        <div className="checkbox-input">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="areaCleanedStatus"
                                            id="areaClean"
                                            checked={
                                              workStatus.areaCleanedStatus
                                            }
                                            onChange={handleCheckboxChange}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="areaClean"
                                          >
                                            AreaClean
                                          </label>
                                          {/* </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                                <div className="footers">
                                  <div className="button-group-email">
                                    <div>
                                      <button
                                        className="person-user-save-button"
                                        type="button"
                                        onClick={() =>
                                          handleSaveCloseClick(
                                            selectedWorkPermitId
                                          )
                                        }
                                        disabled={isLoading}
                                      >
                                        {isLoading ? "Saving..." : "Save"}
                                      </button>

                                      <button
                                        className="person-user-cancel-button"
                                        onClick={closePopup}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-users-container">
                  <div className="no-users-message">No Data Found</div>
                </div>
              )}
            </div>
            <Pagination
              showPerPage={itemsPerPage}
              total={totalItems}
              onPaginationChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PermitRequestForRequester;
