import { useState, useEffect } from 'react';
import { onMessageListener, requestFCMToken } from '../../src/firebase';
import { registerFCMToken } from '../Services/api';

export const useNotifications = () => {
  const [notification, setNotification] = useState(null);
  const [fcmToken, setFcmToken] = useState(null);
  const [notificationPermission, setNotificationPermission] = useState(false);
  const notificationSound = "/assets/notification.mp3";
  
  useEffect(() => {
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications');
      return;
    }

    const initializeFCM = async () => {
      try {
        const permission = await Notification.requestPermission();
        setNotificationPermission(permission === 'granted');

        if (permission === 'granted') {
          const token = await requestFCMToken();
          setFcmToken(token);
          await registerFCMToken(token);
        } else {
          console.log('Notification permission denied.');
        }
      } catch (error) {
        console.error('Error initializing FCM:', error);
      }
    };

    initializeFCM();

    onMessageListener().then((payload) => {
      setNotification({
        title: payload.notification?.title || 'New Notification',
        body: payload.notification?.body || 'You have a new update.',
        data: payload.data || {},
      });

      const audio = new Audio(notificationSound);
      audio.play().catch((err) => console.error('Error playing sound:', err));

    }).catch((err) => console.error('Error in onMessageListener:', err));

  }, []);

  return { notification, fcmToken, notificationPermission };
};
