import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PermitRequestForRequester from "../ColdWorkPermit/PermitRequestForRequester";
import { Tabs, Tab } from "@mui/material";
import axios from "axios";
import API_URL from "../../src/Config";
import "./ColdWorkPermit.css"; // Import the CSS file with the blinking effect

function PermitRequestForRequesterTab({ isSidebarExpanded }) {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [tabCounts, setTabCounts] = useState({
    Issued: 0,
    Rejected: 0,
    Closed: 0,
    Draft: 0,
    Submitted: 0,
  });
  const [notificationCounts, setNotificationCounts] = useState({
    Issued: 0,
    Rejected: 0,
    Closed: 0,
    Draft: 0,
    Submitted: 0,
  });

  const [notificationCountsIds, setNotificationCountsIds] = useState({
    Issued: 0,
    Rejected: 0,
    Closed: 0,
    Draft: 0,
    Submitted: 0,
  });

  // Extracting tab from URL query
  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get("tab");

  const [tabValue, setTabValue] = useState(tabFromUrl || "NewRequests");

  const updateTotalItems = (counts) => {
    console.log("Updated counts:", counts);
    setTabCounts({
      Issued: counts.Issued || 0,
      Rejected: counts.Rejected || 0,
      Closed: counts.Closed || 0,
      Submitted: counts.Submitted || 0,
      Draft: counts.Draft || 0,
    });
  };
  const updateNotification = (counts) => {
    console.log("Updated counts1:", counts);
    setNotificationCounts({
      Issued: counts.Issued || 0,
      Rejected: counts.Rejected || 0,
      Closed: counts.Closed || 0,
      Submitted: counts.Submitted || 0,
      Draft: counts.Draft || 0,
    });
  };

  const updateNotificationIds = (counts) => {
    console.log("Updated ids:", counts);
    setNotificationCountsIds({
      Issued: counts.Issued || 0,
      Rejected: counts.Rejected || 0,
      Closed: counts.Closed || 0,
      Submitted: counts.Submitted || 0,
      Draft: counts.Draft || 0,
    });
  };

  useEffect(() => {
    // Update tab value if URL param changes
    if (tabFromUrl && tabFromUrl !== tabValue) {
      setTabValue(tabFromUrl);
    }
  }, [location.search]);


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // Update URL query parameter to reflect the current tab
    navigate(`?tab=${newValue}`);
  };

  const getStatusForTab = (tabValue) => {
    switch (tabValue) {
      case "NewRequests":
        return "Draft"; // Draft
      case "Issued":
        return "Issued"; // Submitted
      case "Rejected":
        return "Rejected"; // Submitted
      case "Closed":
        return "Closed"; // Submitted
      default:
        return null;
    }
  };

 
  const handleTabClick = async (tabName) => {
    try {
      // Fetch notification_ids from state (notificationCountsIds) based on the selected tab
      const notificationIds = notificationCountsIds[tabName];
  
      if (!notificationIds || notificationIds.length === 0) return;
  
      const authKey = localStorage.getItem("authKey");
  
      const response = await axios.put(
        `${API_URL}/api/notifications/update_read_status`,
        { notification_ids: notificationIds }, // Send correct payload
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );
  
      console.log(`Notifications for ${tabName} marked as read.`, response.data);
    } catch (error) {
      console.error("Error updating read status:", error);
    }
  };
  
  
  // Mock function to return notification IDs for a tab

  

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {/* <div className="data-list-header">
          <div className="data-list-header-content">
            <div className="data-list-heading-content-left-side">
              All Work Permit Request
            </div>

            <div className="data-list-heading-content-right-side">
              <Link to="/permit-request-form" className="data-list-new">
                <Tooltip title="New" arrow>
                  <button className="new-button">
                    <HiOutlinePlus className="new-plus-icon" /> New Permit
                    Request
                  </button>
                </Tooltip>
              </Link>
            </div>
          </div>
        </div> */}
       
          <div className="data-list-content permit-tab" ref={containerRef}>
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => handleTabChange(event, newValue)}
              aria-label="report tabs"
              className="tabs-container"
            >
              <Tab
                label={
                  <div className="tab-label-container">
                    <span className="tab-status-label">New Requests</span>
                    <span className="tab-count">({tabCounts.Draft || 0})</span>
                  </div>
                }
                value="NewRequests"
              />

              <Tab
                label={
                  <div className="tab-label-container">
                    <span className="tab-status-label">Issued</span>
                    <span className="tab-count">({tabCounts.Issued || 0}) 
                    {notificationCounts.Issued > 0 && (
                    <span className="new-tab-count">
                      {notificationCounts.Issued || 0}
                      </span>
                    )}
                      </span>
                  </div>
                }
                value="Issued"
                onClick={() => handleTabClick("Issued")}
              />

              <Tab
                label={
                  <div className="tab-label-container">
                    <span className="tab-status-label">Rejected</span>
                    <span className="tab-count">
                      <span className="tab-count">
                        ({tabCounts.Rejected || 0})
                        {notificationCounts.Rejected > 0 && (
                        <span className="new-tab-count">
                        {notificationCounts.Rejected || 0}
                        </span>
                        )}
                      </span>
                    </span>
                  </div>
                }
                value="Rejected"
                onClick={() => handleTabClick("Rejected")}
              />
              <Tab
                label={
                  <div className="tab-label-container">
                    <span className="tab-status-label">Closed</span>
                    <span className="tab-count">
                      <span className="tab-count">
                        ({tabCounts.Closed || 0})
                        {notificationCounts.Closed > 0 && (
                        <span className="new-tab-count">
                        {notificationCounts.Closed || 0}
                        </span>
                        )}
                      </span>
                    </span>
                  </div>
                }
                value="Closed"
                onClick={() => handleTabClick("Closed")}
              />
            </Tabs>
            <div className="header-separater"></div>
            <div className="tabs-content">
              <PermitRequestForRequester
                status={getStatusForTab(tabValue)}
                // updateNewCounts={updateNewCounts}
                updateTotalItems={updateTotalItems}
                updateNotification={updateNotification}
                updateNotificationIds={updateNotificationIds}
              />
            </div>
          </div>

      </div>
    </>
  );
}

export default PermitRequestForRequesterTab;
