import React, { useState, useEffect } from "react";
import "./Form.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";

function Form2({ isSidebarExpanded, fetchComplitionSatusData }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { permit_type_id } = useParams();


  const [checklistItems, setChecklistItems] = useState([]);
  const [status, setStatus] = useState({});

  const [dropdownValue, setDropdownValue] = useState({});
  const [inputValue, setInputValue] = useState({});

  const handleDropdownChange = (id, value) => {
    setDropdownValue((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleInputChange = (id, value) => {
    setInputValue((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const handleCancelClick = () => {
    // navigate(`/cold-work-permit-form/${id}?tab=Work Permit Details`);
    navigate("/cold-work-permit");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleRadioChange = (itemId, value) => {
    setStatus((prevState) => ({
      ...prevState,
      [itemId]: value,
    }));
  };

  
  useEffect(() => {
    // const fetchPermitDetailsItem = async () => {
    //   try {
    //     const authKey = localStorage.getItem("authKey");
  
    //     const response = await axios.get(
    //       `${API_URL}/api/get_permit_checklist_items?work_permit_id=${id}`,
    //       {
    //         headers: {
    //           Authorization: authKey,
    //         },
    //       }
    //     );
  
    //     const checklistItemsFromAPI = response.data.checklist_items;
  
    //     // If response is null, call fetchPermitDetails instead
    //     if (checklistItemsFromAPI === null) {
    //       fetchPermitDetails();
    //       return;
    //     }
  
    //     // Process checklist data and store initial status, dropdown values, and input values
    //     const initialStatus = {};
    //     const initialDropdownValues = {};
    //     const initialInputValues = {};
  
    //     checklistItemsFromAPI.forEach((item) => {
    //       initialStatus[item.id] = item.status === 1 ? "done" : "not-required";
          
    //       // Store equipment_status if dropdown exists
    //       if (item.has_dropdown === 1) {
    //         initialDropdownValues[item.id] = item.equipment_status
    //           ? { value: item.equipment_status, label: item.equipment_status }
    //           : null;
    //       }
  
    //       // Store permit_no if input field exists
    //       if (item.has_input === 1) {
    //         initialInputValues[item.id] = item.permit_no || "";
    //       }
    //     });
  
    //     setChecklistItems(checklistItemsFromAPI);
    //     setStatus(initialStatus);
    //     setDropdownValue(initialDropdownValues);
    //     setInputValue(initialInputValues);
    //   } catch (error) {
    //     console.error("Error fetching permit checklist items:", error);
    //   }
    // };

    const fetchPermitDetailsItem = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
    
        const response = await axios.get(
          `${API_URL}/api/get_permit_checklist_items?work_permit_id=${id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
    
        const checklistItemsFromAPI = response.data.checklistItems;
    
        // If response is null, call fetchPermitDetails instead
        if (!checklistItemsFromAPI) {
          fetchPermitDetails();
          return;
        }
    
        // Process checklist data and store initial status, dropdown values, and input values
        const initialStatus = {};
        const initialDropdownValues = {};
        const initialInputValues = {};
    
        // Iterate over categories and then checklist items
        Object.keys(checklistItemsFromAPI).forEach((category) => {
          checklistItemsFromAPI[category].forEach((item) => {
            initialStatus[item.id] = item.status === 1 ? "done" : "not-required";
    
            // Store equipment_status if dropdown exists
            if (item.has_dropdown === 1) {
              initialDropdownValues[item.id] = item.equipment_status
                ? { value: item.equipment_status, label: item.equipment_status }
                : null;
            }
    
            // Store permit_no if input field exists
            if (item.has_input === 1) {
              initialInputValues[item.id] = item.permit_no || "";
            }
          });
        });
    
        setChecklistItems(checklistItemsFromAPI); // Store the correctly structured response
        setStatus(initialStatus);
        setDropdownValue(initialDropdownValues);
        setInputValue(initialInputValues);
      } catch (error) {
        console.error("Error fetching permit checklist items:", error);
      }
    };
    
    
  
    // const fetchPermitDetails = async () => {
    //   try {
    //     const authKey = localStorage.getItem("authKey");
  
    //     if (!permit_type_id) {
    //       console.error("Permit Type ID is missing");
    //       return;
    //     }
  
    //     const response = await axios.get(
    //       `${API_URL}/api/get_checklist_item_descriptions`,
    //       {
    //         params: { permit_type_id },
    //         headers: {
    //           Authorization: authKey,
    //         },
    //       }
    //     );
  
    //     const checklistItemsFromAPI = response.data.checklistItems || [];
  
    //     // Initialize status, dropdown state, and input state
    //     const initialStatus = {};
    //     const initialDropdownValues = {};
    //     const initialInputValues = {};
  
    //     checklistItemsFromAPI.forEach((item) => {
    //       initialStatus[item.id] = "not-required";
          
    //       if (item.has_dropdown === 1) {
    //         initialDropdownValues[item.id] = null;
    //       }
  
    //       if (item.has_input === 1) {
    //         initialInputValues[item.id] = "";
    //       }
    //     });
  
    //     setChecklistItems(checklistItemsFromAPI);
    //     setStatus(initialStatus);
    //     setDropdownValue(initialDropdownValues);
    //     setInputValue(initialInputValues);
    //   } catch (error) {
    //     console.error("Error fetching permit details:", error);
    //   }
    // };

    const fetchPermitDetails = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
    
        if (!permit_type_id) {
          console.error("Permit Type ID is missing");
          return;
        }
    
        const response = await axios.get(
          `${API_URL}/api/get_checklist_item_descriptions`,
          {
            params: { permit_type_id },
            headers: {
              Authorization: authKey,
            },
          }
        );
    
        const checklistItemsFromAPI = response.data.checklistItems || {};
    
        // Initialize states for status, dropdown, and input
        const initialStatus = {};
        const initialDropdownValues = {};
        const initialInputValues = {};
    
        Object.values(checklistItemsFromAPI).flat().forEach((item) => {
          initialStatus[item.id] = "not-required";
    
          if (item.has_dropdown === 1) {
            initialDropdownValues[item.id] = null;
          }
    
          if (item.has_input === 1) {
            initialInputValues[item.id] = "";
          }
        });
    
        setChecklistItems(checklistItemsFromAPI);
        setStatus(initialStatus);
        setDropdownValue(initialDropdownValues);
        setInputValue(initialInputValues);
      } catch (error) {
        console.error("Error fetching permit details:", error);
      }
    };
  
    fetchPermitDetailsItem();
  }, [id, permit_type_id, API_URL]);
  


//  const handleSaveClick = async () => {
//   setIsLoading(true);

//   const items = checklistItems.map((item) => ({
//     checklist_item_id: item.id,
//     item_number: item.item_number,
//     item_desc: item.description,
//     status: status[item.id] === "done" ? 1 : 0,
//     permit_no: item.item_number === 5 ? inputValue[item.id] || "" : "",
//     h2s_gas: "",
//     o2s_gas: "",
//     vehicle_regd_no: "",
//     hc_gas: "",
//     lel_gas: "",
//     toxic_gases: "",
//     equipment_status:
//       item.item_number === 3
//         ? dropdownValue[item.id]?.value || ""
//         : "",
//   }));

//   const work_permit_id = id;
//   const payload = { work_permit_id, items };

//   try {
//     const authKey = localStorage.getItem("authKey");

//     let response;

//     // Check if existing checklist data was fetched (update case)
//     const isUpdate = checklistItems.some((item) => item.checklist_item_id);

//     if (isUpdate) {
//       response = await axios.post(
//         `${API_URL}/api/update_permit_checklist_items`,
//         payload,
//         { headers: { Authorization: authKey } }
//       );
//     } else {
//       response = await axios.post(
//         `${API_URL}/api/add_permit_checklist_items`,
//         payload,
//         { headers: { Authorization: authKey } }
//       );
//     }

//     if (response.status === 200) {
//       setSeverity("success");
//       showSnackbar(isUpdate ? "Permit checklist updated successfully" : "Permit checklist added successfully");

//       setTimeout(() => {
//         navigate(`/cold-work-permit-form/${id}/${permit_type_id}?tab=Hazards Precautions`);
//       }, 1000);
//     }
//   } catch (error) {
//     console.error("Error saving checklist:", error.message);
//     setSeverity("error");
//     showSnackbar("Failed to save checklist items");
//   } finally {
//     setIsLoading(false);
//   }
// };


// const handleSaveClick = async () => {
//   setIsLoading(true);

//   const items = checklistItems.map((item) => ({
//     checklist_item_id: item.id,  // Use checklist_item_id
//     item_id: item.id,  // Ensure item_id is included
//     item_number: item.item_number,
//     item_desc: item.description,
//     status: status[item.id] === "done" ? 1 : 0,
//     permit_no: item.item_number === 5 ? inputValue[item.id] || "" : "",
//     h2s_gas: "",
//     o2s_gas: "",
//     vehicle_regd_no: "",
//     hc_gas: "",
//     lel_gas: "",
//     toxic_gases: "",
//     equipment_status:
//       item.item_number === 3
//         ? dropdownValue[item.id]?.value || ""
//         : "",
//   }));

//   const work_permit_id = id;
//   const payload = { work_permit_id, items };

//   try {
//     const authKey = localStorage.getItem("authKey");

//     let response;

//     // Check if existing checklist data was fetched (update case)
//     const isUpdate = checklistItems.some((item) => item.checklist_item_id);

//     if (isUpdate) {
//       response = await axios.post(
//         `${API_URL}/api/update_permit_checklist_items`,
//         payload,
//         { headers: { Authorization: authKey } }
//       );
//     } else {
//       response = await axios.post(
//         `${API_URL}/api/add_permit_checklist_items`,
//         payload,
//         { headers: { Authorization: authKey } }
//       );
//     }

//     if (response.status === 200) {
//       setSeverity("success");
//       showSnackbar(isUpdate ? "Permit checklist updated successfully" : "Permit checklist added successfully");

//       setTimeout(() => {
//         navigate(`/cold-work-permit-form/${id}/${permit_type_id}?tab=Hazards Precautions`);
//       }, 1000);
//     }
//   } catch (error) {
//     console.error("Error saving checklist:", error.message);
//     setSeverity("error");
//     showSnackbar("Failed to save checklist items");
//   } finally {
//     setIsLoading(false);
//   }
// };

// const handleSaveClick = async () => {
//   setIsLoading(true);

//   // Map through checklistItems and prepare the payload
//   const items = checklistItems.map((item) => ({
//     checklist_item_id: item.id,  // Ensure checklist_item_id is included
//     item_id: item.id,  // Ensure item_id is included
//     status: status[item.id] === "done" ? 1 : 0,
//     permit_no: item.item_number === 5 ? inputValue[item.id] || "" : "",
//     h2s_gas: "",
//     o2s_gas: "",
//     vehicle_regd_no: "",
//     hc_gas: "",
//     lel_gas: "",
//     toxic_gases: "",
//     equipment_status:
//       item.item_number === 3
//         ? dropdownValue[item.id]?.value || ""
//         : "",
//   }));
  

//   const work_permit_id = id;
//   const payload = { work_permit_id, items };

//   try {
//     const authKey = localStorage.getItem("authKey");

//     let response;

//     // Check if existing checklist data was fetched (update case)
//     const isUpdate = checklistItems.some((item) => item.checklist_item_id);

//     if (isUpdate) {
//       response = await axios.put(
//         `${API_URL}/api/update_permit_checklist_items`,
//         payload,
//         { headers: { Authorization: authKey } }
//       );
//     } else {
//       response = await axios.post(
//         `${API_URL}/api/add_permit_checklist_items`,
//         payload,
//         { headers: { Authorization: authKey } }
//       );
//     }

//     if (response.status === 200) {
//       setSeverity("success");
//       showSnackbar(isUpdate ? "Permit checklist updated successfully" : "Permit checklist added successfully");

//       setTimeout(() => {
//         navigate(`/cold-work-permit-form/${id}/${permit_type_id}?tab=Hazards Precautions`);
//       }, 1000);
//     }
//   } catch (error) {
//     console.error("Error saving checklist:", error.message);
//     setSeverity("error");
//     showSnackbar("Failed to save checklist items");
//   } finally {
//     setIsLoading(false);
//   }
// };


// const handleSaveClick = async () => {
//   setIsLoading(true);

//   // Prepare payload for "add" API
//   const addItems = checklistItems.map((item) => ({
//     checklist_item_id: item.id,  // For add, use item.id (new items)
//     status: status[item.id] === "done" ? 1 : 0,
//     permit_no: item.item_number === 5 ? inputValue[item.id] || "" : "",
//     h2s_gas: "",
//     o2s_gas: "",
//     vehicle_regd_no: "",
//     hc_gas: "",
//     lel_gas: "",
//     toxic_gases: "",
//     equipment_status:
//       item.item_number === 3
//         ? dropdownValue[item.id]?.value || ""
//         : "",
//   }));

//   // Prepare payload for "update" API
//   const updateItems = checklistItems.map((item) => ({
//     checklist_item_id: item.checklist_item_id,  // For update, use item.checklist_item_id (existing checklist item)
//     item_id: item.id,  // For update, use item.id (item_id)
//     status: status[item.id] === "done" ? 1 : 0,
//     permit_no: item.item_number === 5 ? inputValue[item.id] || "" : "",
//     h2s_gas: "",
//     o2s_gas: "",
//     vehicle_regd_no: "",
//     hc_gas: "",
//     lel_gas: "",
//     toxic_gases: "",
//     equipment_status:
//       item.item_number === 3
//         ? dropdownValue[item.id]?.value || ""
//         : "",
//   }));

//   const work_permit_id = id;
//   const payload = { work_permit_id };

//   try {
//     const authKey = localStorage.getItem("authKey");

//     let response;

//     // Check if existing checklist data was fetched (update case)
//     const isUpdate = checklistItems.some((item) => item.checklist_item_id);

//     if (isUpdate) {
//       // For update API
//       response = await axios.put(
//         `${API_URL}/api/update_permit_checklist_items`,
//         { ...payload, items: updateItems },
//         { headers: { Authorization: authKey } }
//       );
//     } else {
//       // For add API
//       response = await axios.post(
//         `${API_URL}/api/add_permit_checklist_items`,
//         { ...payload, items: addItems },
//         { headers: { Authorization: authKey } }
//       );
//     }

//     if (response.status === 200) {
//       setSeverity("success");
//       showSnackbar(isUpdate ? "Permit checklist updated successfully" : "Permit checklist added successfully");

//       setTimeout(() => {
//         navigate(`/cold-work-permit-form/${id}/${permit_type_id}?tab=Hazards Precautions`);
//       }, 1000);
//     }
//   } catch (error) {
//     console.error("Error saving checklist:", error.message);
//     setSeverity("error");
//     showSnackbar("Failed to save checklist items");
//   } finally {
//     setIsLoading(false);
//   }
// };

const handleSaveClick = async () => {
  setIsLoading(true);

  // Ensure checklistItems is an array
  const itemsArray = checklistItems && typeof checklistItems === "object"
    ? Object.values(checklistItems).flat() // Convert object to array
    : [];

  if (!Array.isArray(itemsArray)) {
    console.error("Error: checklistItems is not an array", checklistItems);
    setIsLoading(false);
    return;
  }

  // Prepare payload for "add" API
  const addItems = itemsArray.map((item) => ({
    checklist_item_id: item.id,  // For add, use item.id (new items)
    status: status[item.id] === "done" ? 1 : 0,
    permit_no: item.item_number === 5 ? inputValue[item.id] || "" : "",
    h2s_gas: "",
    o2s_gas: "",
    vehicle_regd_no: "",
    hc_gas: "",
    lel_gas: "",
    toxic_gases: "",
    equipment_status: item.item_number === 3 ? dropdownValue[item.id]?.value || "" : "",
  }));

  // Prepare payload for "update" API
  const updateItems = itemsArray.map((item) => ({
    checklist_item_id: item.checklist_item_id || item.id,  // Use checklist_item_id if available
    item_id: item.id,  // Original item ID
    status: status[item.id] === "done" ? 1 : 0,
    permit_no: item.item_number === 5 ? inputValue[item.id] || "" : "",
    h2s_gas: "",
    o2s_gas: "",
    vehicle_regd_no: "",
    hc_gas: "",
    lel_gas: "",
    toxic_gases: "",
    equipment_status: item.item_number === 3 ? dropdownValue[item.id]?.value || "" : "",
  }));

  const work_permit_id = id;
  const payload = { work_permit_id };

  try {
    const authKey = localStorage.getItem("authKey");

    let response;
    const isUpdate = itemsArray.some((item) => item.checklist_item_id);

    if (isUpdate) {
      // Update API Call
      response = await axios.put(
        `${API_URL}/api/update_permit_checklist_items`,
        { ...payload, items: updateItems },
        { headers: { Authorization: authKey } }
      );
    } else {
      // Add API Call
      response = await axios.post(
        `${API_URL}/api/add_permit_checklist_items`,
        { ...payload, items: addItems },
        { headers: { Authorization: authKey } }
      );
    }

    if (response.status === 200) {
      setSeverity("success");
      showSnackbar(isUpdate ? "Permit checklist updated successfully" : "Permit checklist added successfully");

      setTimeout(() => {
        navigate(`/cold-work-permit-form/${id}/${permit_type_id}?tab=Hazards Precautions`);
      }, 1000);
    }
  } catch (error) {
    console.error("Error saving checklist:", error);
    setSeverity("error");
    showSnackbar("Failed to save checklist items");
  } finally {
    setIsLoading(false);
  }
};



  return (
    <>
      <div className="data-form-content">
        <div className="data-form-header">
          <div className="data-form-header-content">
            <div className="data-form-heading-content-left-side">
              New Cold Work Permit
            </div>
            <div className="data-form-heading-content-right-side"></div>
          </div>
        </div>
        <div className="header-separator"></div>
        <div className="data-form-body-permit">
          <form className="form-detail">
            <div className="new-data-form">
              <h3 className="line2">
                THE FOLLOWING ITEMS SHALL BE CHECKED BEFORE USING THE PERMIT
              </h3>
              <div className="checklist-container">
                {/* {checklistItems.map((item) => (
                  <div key={item.id} className="checklist-item">
                    <p>
                      ({item.item_number}) {item.description}
                    </p>
                    <div className="radio-buttons">
                      <label>
                        <input
                          type="radio"
                          name={`item-${item.id}`}
                          value="done"
                          checked={status[item.id] === "done"}
                          onChange={() => handleRadioChange(item.id, "done")}
                        />
                        Done
                      </label>
                      <label>
                        <input
                          type="radio"
                          name={`item-${item.id}`}
                          value="not-required"
                          checked={status[item.id] === "not-required"}
                          onChange={() =>
                            handleRadioChange(item.id, "not-required")
                          }
                        />
                        Not Required
                      </label>

                      {item.has_dropdown === 1 &&
                        status[item.id] === "done" && (
                          <div className="dropdowns">
                            <label htmlFor={`dropdown-${item.id}`}>
                              Select a equipment :
                            </label>
                            <Select
                              id={`dropdown-${item.id}`}
                              value={dropdownValue[item.id] || null}
                              onChange={(selectedOption) =>
                                handleDropdownChange(item.id, selectedOption)
                              }
                              options={[
                                { value: "blinded", label: "blinded" },
                                { value: "connected", label: "connected" },
                                { value: "closed", label: "closed" },
                                { value: "isolated", label: "isolated" },
                                { value: "wedgeopened", label: "wedge opened" },
                              ]}
                              isClearable
                              placeholder="Select a equipment"
                            />
                          </div>
                        )}

                      {item.has_input === 1 && status[item.id] === "done" && (
                        <div className="dropdowns">
                          <label htmlFor={`input-${item.id}`}>Permit No:</label>
                          <div className="input-group">
                            <input
                              type="number"
                              id={`input-${item.id}`}
                              value={inputValue[item.id] || ""}
                              placeholder="Enter permit no"
                              onChange={(e) =>
                                handleInputChange(item.id, e.target.value)
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))} */}
                 <div>
                 <div>
      {Object.keys(checklistItems).map((category) => (
        <div key={category} className="category-section">
          <h3>{category}</h3>
          {checklistItems[category].map((item) => (
            <div key={item.id} className="checklist-item">
              <p>({item.item_number}) {item.description}</p>
              <div className="radio-buttons">
                <label>
                  <input
                    type="radio"
                    name={`item-${item.id}`}
                    value="done"
                    checked={status[item.id] === "done"}
                    onChange={() => handleRadioChange(item.id, "done")}
                  />
                  Done
                </label>
                <label>
                  <input
                    type="radio"
                    name={`item-${item.id}`}
                    value="not-required"
                    checked={status[item.id] === "not-required"}
                    onChange={() => handleRadioChange(item.id, "not-required")}
                  />
                  Not Required
                </label>
              </div>
              {item.has_dropdown === 1 && status[item.id] === "done" && (
                <div className="dropdowns">
                  <label htmlFor={`dropdown-${item.id}`}>Select an equipment:</label>
                  <Select
                    id={`dropdown-${item.id}`}
                    value={dropdownValue[item.id] || null}
                    onChange={(selectedOption) => handleDropdownChange(item.id, selectedOption)}
                    options={[
                      { value: "blinded", label: "Blinded" },
                      { value: "connected", label: "Connected" },
                      { value: "closed", label: "Closed" },
                      { value: "isolated", label: "Isolated" },
                      { value: "wedgeopened", label: "Wedge Opened" },
                    ]}
                    isClearable
                    placeholder="Select an equipment"
                  />
                </div>
              )}
              {item.has_input === 1 && status[item.id] === "done" && (
                <div className="input-field">
                  <label htmlFor={`input-${item.id}`}>Permit No:</label>
                  <input
                    type="text"
                    id={`input-${item.id}`}
                    value={inputValue[item.id] || ""}
                    placeholder="Enter permit no"
                    onChange={(e) => handleInputChange(item.id, e.target.value)}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
              </div>
            </div>
          </form>
        </div>
        <div className="footer">
          <div className="button-group">
            <button
              className="data-form-cancel-button"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
            <button className="data-form-next-button" onClick={handleSaveClick}>
              Next
            </button>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Form2;


// import React, { useState, useEffect } from "react";
// import "./Form.css";
// import { useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// import API_URL from "../../src/Config";
// import Select from "react-select";

// function Form2({ isSidebarExpanded }) {
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [severity, setSeverity] = useState("success");
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const { permit_type_id } = useParams();


//   const [checklistItems, setChecklistItems] = useState([]);
//   const [status, setStatus] = useState({});

//   const [dropdownValue, setDropdownValue] = useState({});
//   const [inputValue, setInputValue] = useState({});

//   const handleDropdownChange = (id, value) => {
//     setDropdownValue((prevState) => ({
//       ...prevState,
//       [id]: value,
//     }));
//   };

//   const handleInputChange = (id, value) => {
//     setInputValue((prevState) => ({
//       ...prevState,
//       [id]: value,
//     }));
//   };
//   const handleCancelClick = () => {
//     // navigate(`/cold-work-permit-form/${id}?tab=Work Permit Details`);
//     navigate("/cold-work-permit");
//   };

//   const handleSnackbarClose = (event, reason) => {
//     if (reason === "clickaway") {
//       return;
//     }
//     setSnackbarOpen(false);
//   };

//   const showSnackbar = (message) => {
//     setSnackbarMessage(message);
//     setSnackbarOpen(true);
//   };

//   const handleRadioChange = (itemId, value) => {
//     setStatus((prevState) => ({
//       ...prevState,
//       [itemId]: value,
//     }));
//   };

  
//   useEffect(() => {
//     const fetchPermitDetailsItem = async () => {
//       try {
//         const authKey = localStorage.getItem("authKey");
  
//         const response = await axios.get(
//           `${API_URL}/api/get_permit_checklist_items?work_permit_id=${id}`,
//           {
//             headers: {
//               Authorization: authKey,
//             },
//           }
//         );
  
//         const checklistItemsFromAPI = response.data.checklist_items;
  
//         // If response is null, call fetchPermitDetails instead
//         if (checklistItemsFromAPI === null) {
//           fetchPermitDetails();
//           return;
//         }
  
//         // Process checklist data and store initial status, dropdown values, and input values
//         const initialStatus = {};
//         const initialDropdownValues = {};
//         const initialInputValues = {};
  
//         checklistItemsFromAPI.forEach((item) => {
//           initialStatus[item.id] = item.status === 1 ? "done" : "not-required";
          
//           // Store equipment_status if dropdown exists
//           if (item.has_dropdown === 1) {
//             initialDropdownValues[item.id] = item.equipment_status
//               ? { value: item.equipment_status, label: item.equipment_status }
//               : null;
//           }
  
//           // Store permit_no if input field exists
//           if (item.has_input === 1) {
//             initialInputValues[item.id] = item.permit_no || "";
//           }
//         });
  
//         setChecklistItems(checklistItemsFromAPI);
//         setStatus(initialStatus);
//         setDropdownValue(initialDropdownValues);
//         setInputValue(initialInputValues);
//       } catch (error) {
//         console.error("Error fetching permit checklist items:", error);
//       }
//     };
  
//     const fetchPermitDetails = async () => {
//       try {
//         const authKey = localStorage.getItem("authKey");
  
//         if (!permit_type_id) {
//           console.error("Permit Type ID is missing");
//           return;
//         }
  
//         const response = await axios.get(
//           `${API_URL}/api/get_checklist_item_descriptions`,
//           {
//             params: { permit_type_id },
//             headers: {
//               Authorization: authKey,
//             },
//           }
//         );
  
//         const checklistItemsFromAPI = response.data.checklistItems || [];
  
//         // Initialize status, dropdown state, and input state
//         const initialStatus = {};
//         const initialDropdownValues = {};
//         const initialInputValues = {};
  
//         checklistItemsFromAPI.forEach((item) => {
//           initialStatus[item.id] = "not-required";
          
//           if (item.has_dropdown === 1) {
//             initialDropdownValues[item.id] = null;
//           }
  
//           if (item.has_input === 1) {
//             initialInputValues[item.id] = "";
//           }
//         });
  
//         setChecklistItems(checklistItemsFromAPI);
//         setStatus(initialStatus);
//         setDropdownValue(initialDropdownValues);
//         setInputValue(initialInputValues);
//       } catch (error) {
//         console.error("Error fetching permit details:", error);
//       }
//     };
  
//     fetchPermitDetailsItem();
//   }, [id, permit_type_id, API_URL]);
  


// //  const handleSaveClick = async () => {
// //   setIsLoading(true);

// //   const items = checklistItems.map((item) => ({
// //     checklist_item_id: item.id,
// //     item_number: item.item_number,
// //     item_desc: item.description,
// //     status: status[item.id] === "done" ? 1 : 0,
// //     permit_no: item.item_number === 5 ? inputValue[item.id] || "" : "",
// //     h2s_gas: "",
// //     o2s_gas: "",
// //     vehicle_regd_no: "",
// //     hc_gas: "",
// //     lel_gas: "",
// //     toxic_gases: "",
// //     equipment_status:
// //       item.item_number === 3
// //         ? dropdownValue[item.id]?.value || ""
// //         : "",
// //   }));

// //   const work_permit_id = id;
// //   const payload = { work_permit_id, items };

// //   try {
// //     const authKey = localStorage.getItem("authKey");

// //     let response;

// //     // Check if existing checklist data was fetched (update case)
// //     const isUpdate = checklistItems.some((item) => item.checklist_item_id);

// //     if (isUpdate) {
// //       response = await axios.post(
// //         `${API_URL}/api/update_permit_checklist_items`,
// //         payload,
// //         { headers: { Authorization: authKey } }
// //       );
// //     } else {
// //       response = await axios.post(
// //         `${API_URL}/api/add_permit_checklist_items`,
// //         payload,
// //         { headers: { Authorization: authKey } }
// //       );
// //     }

// //     if (response.status === 200) {
// //       setSeverity("success");
// //       showSnackbar(isUpdate ? "Permit checklist updated successfully" : "Permit checklist added successfully");

// //       setTimeout(() => {
// //         navigate(`/cold-work-permit-form/${id}/${permit_type_id}?tab=Hazards Precautions`);
// //       }, 1000);
// //     }
// //   } catch (error) {
// //     console.error("Error saving checklist:", error.message);
// //     setSeverity("error");
// //     showSnackbar("Failed to save checklist items");
// //   } finally {
// //     setIsLoading(false);
// //   }
// // };


// // const handleSaveClick = async () => {
// //   setIsLoading(true);

// //   const items = checklistItems.map((item) => ({
// //     checklist_item_id: item.id,  // Use checklist_item_id
// //     item_id: item.id,  // Ensure item_id is included
// //     item_number: item.item_number,
// //     item_desc: item.description,
// //     status: status[item.id] === "done" ? 1 : 0,
// //     permit_no: item.item_number === 5 ? inputValue[item.id] || "" : "",
// //     h2s_gas: "",
// //     o2s_gas: "",
// //     vehicle_regd_no: "",
// //     hc_gas: "",
// //     lel_gas: "",
// //     toxic_gases: "",
// //     equipment_status:
// //       item.item_number === 3
// //         ? dropdownValue[item.id]?.value || ""
// //         : "",
// //   }));

// //   const work_permit_id = id;
// //   const payload = { work_permit_id, items };

// //   try {
// //     const authKey = localStorage.getItem("authKey");

// //     let response;

// //     // Check if existing checklist data was fetched (update case)
// //     const isUpdate = checklistItems.some((item) => item.checklist_item_id);

// //     if (isUpdate) {
// //       response = await axios.post(
// //         `${API_URL}/api/update_permit_checklist_items`,
// //         payload,
// //         { headers: { Authorization: authKey } }
// //       );
// //     } else {
// //       response = await axios.post(
// //         `${API_URL}/api/add_permit_checklist_items`,
// //         payload,
// //         { headers: { Authorization: authKey } }
// //       );
// //     }

// //     if (response.status === 200) {
// //       setSeverity("success");
// //       showSnackbar(isUpdate ? "Permit checklist updated successfully" : "Permit checklist added successfully");

// //       setTimeout(() => {
// //         navigate(`/cold-work-permit-form/${id}/${permit_type_id}?tab=Hazards Precautions`);
// //       }, 1000);
// //     }
// //   } catch (error) {
// //     console.error("Error saving checklist:", error.message);
// //     setSeverity("error");
// //     showSnackbar("Failed to save checklist items");
// //   } finally {
// //     setIsLoading(false);
// //   }
// // };

// // const handleSaveClick = async () => {
// //   setIsLoading(true);

// //   // Map through checklistItems and prepare the payload
// //   const items = checklistItems.map((item) => ({
// //     checklist_item_id: item.id,  // Ensure checklist_item_id is included
// //     item_id: item.id,  // Ensure item_id is included
// //     status: status[item.id] === "done" ? 1 : 0,
// //     permit_no: item.item_number === 5 ? inputValue[item.id] || "" : "",
// //     h2s_gas: "",
// //     o2s_gas: "",
// //     vehicle_regd_no: "",
// //     hc_gas: "",
// //     lel_gas: "",
// //     toxic_gases: "",
// //     equipment_status:
// //       item.item_number === 3
// //         ? dropdownValue[item.id]?.value || ""
// //         : "",
// //   }));
  

// //   const work_permit_id = id;
// //   const payload = { work_permit_id, items };

// //   try {
// //     const authKey = localStorage.getItem("authKey");

// //     let response;

// //     // Check if existing checklist data was fetched (update case)
// //     const isUpdate = checklistItems.some((item) => item.checklist_item_id);

// //     if (isUpdate) {
// //       response = await axios.put(
// //         `${API_URL}/api/update_permit_checklist_items`,
// //         payload,
// //         { headers: { Authorization: authKey } }
// //       );
// //     } else {
// //       response = await axios.post(
// //         `${API_URL}/api/add_permit_checklist_items`,
// //         payload,
// //         { headers: { Authorization: authKey } }
// //       );
// //     }

// //     if (response.status === 200) {
// //       setSeverity("success");
// //       showSnackbar(isUpdate ? "Permit checklist updated successfully" : "Permit checklist added successfully");

// //       setTimeout(() => {
// //         navigate(`/cold-work-permit-form/${id}/${permit_type_id}?tab=Hazards Precautions`);
// //       }, 1000);
// //     }
// //   } catch (error) {
// //     console.error("Error saving checklist:", error.message);
// //     setSeverity("error");
// //     showSnackbar("Failed to save checklist items");
// //   } finally {
// //     setIsLoading(false);
// //   }
// // };


// const handleSaveClick = async () => {
//   setIsLoading(true);

//   // Prepare payload for "add" API
//   const addItems = checklistItems.map((item) => ({
//     checklist_item_id: item.id,  // For add, use item.id (new items)
//     status: status[item.id] === "done" ? 1 : 0,
//     permit_no: item.item_number === 5 ? inputValue[item.id] || "" : "",
//     h2s_gas: "",
//     o2s_gas: "",
//     vehicle_regd_no: "",
//     hc_gas: "",
//     lel_gas: "",
//     toxic_gases: "",
//     equipment_status:
//       item.item_number === 3
//         ? dropdownValue[item.id]?.value || ""
//         : "",
//   }));

//   // Prepare payload for "update" API
//   const updateItems = checklistItems.map((item) => ({
//     checklist_item_id: item.checklist_item_id,  // For update, use item.checklist_item_id (existing checklist item)
//     item_id: item.id,  // For update, use item.id (item_id)
//     status: status[item.id] === "done" ? 1 : 0,
//     permit_no: item.item_number === 5 ? inputValue[item.id] || "" : "",
//     h2s_gas: "",
//     o2s_gas: "",
//     vehicle_regd_no: "",
//     hc_gas: "",
//     lel_gas: "",
//     toxic_gases: "",
//     equipment_status:
//       item.item_number === 3
//         ? dropdownValue[item.id]?.value || ""
//         : "",
//   }));

//   const work_permit_id = id;
//   const payload = { work_permit_id };

//   try {
//     const authKey = localStorage.getItem("authKey");

//     let response;

//     // Check if existing checklist data was fetched (update case)
//     const isUpdate = checklistItems.some((item) => item.checklist_item_id);

//     if (isUpdate) {
//       // For update API
//       response = await axios.put(
//         `${API_URL}/api/update_permit_checklist_items`,
//         { ...payload, items: updateItems },
//         { headers: { Authorization: authKey } }
//       );
//     } else {
//       // For add API
//       response = await axios.post(
//         `${API_URL}/api/add_permit_checklist_items`,
//         { ...payload, items: addItems },
//         { headers: { Authorization: authKey } }
//       );
//     }

//     if (response.status === 200) {
//       setSeverity("success");
//       showSnackbar(isUpdate ? "Permit checklist updated successfully" : "Permit checklist added successfully");

//       setTimeout(() => {
//         navigate(`/cold-work-permit-form/${id}/${permit_type_id}?tab=Hazards Precautions`);
//       }, 1000);
//     }
//   } catch (error) {
//     console.error("Error saving checklist:", error.message);
//     setSeverity("error");
//     showSnackbar("Failed to save checklist items");
//   } finally {
//     setIsLoading(false);
//   }
// };


//   return (
//     <>
//       <div className="data-form-content">
//         <div className="data-form-header">
//           <div className="data-form-header-content">
//             <div className="data-form-heading-content-left-side">
//               New Cold Work Permit
//             </div>
//             <div className="data-form-heading-content-right-side"></div>
//           </div>
//         </div>
//         <div className="header-separator"></div>
//         <div className="data-form-body-permit">
//           <form className="form-detail">
//             <div className="new-data-form">
//               <h3 className="line2">
//                 THE FOLLOWING ITEMS SHALL BE CHECKED BEFORE USING THE PERMIT
//               </h3>
//               <div className="checklist-container">
//                 {checklistItems.map((item) => (
//                   <div key={item.id} className="checklist-item">
//                     <p>
//                       ({item.item_number}) {item.description}
//                     </p>
//                     <div className="radio-buttons">
//                       <label>
//                         <input
//                           type="radio"
//                           name={`item-${item.id}`}
//                           value="done"
//                           checked={status[item.id] === "done"}
//                           onChange={() => handleRadioChange(item.id, "done")}
//                         />
//                         Done
//                       </label>
//                       <label>
//                         <input
//                           type="radio"
//                           name={`item-${item.id}`}
//                           value="not-required"
//                           checked={status[item.id] === "not-required"}
//                           onChange={() =>
//                             handleRadioChange(item.id, "not-required")
//                           }
//                         />
//                         Not Required
//                       </label>

//                       {item.has_dropdown === 1 &&
//                         status[item.id] === "done" && (
//                           <div className="dropdowns">
//                             <label htmlFor={`dropdown-${item.id}`}>
//                               Select a equipment :
//                             </label>
//                             <Select
//                               id={`dropdown-${item.id}`}
//                               value={dropdownValue[item.id] || null}
//                               onChange={(selectedOption) =>
//                                 handleDropdownChange(item.id, selectedOption)
//                               }
//                               options={[
//                                 { value: "blinded", label: "blinded" },
//                                 { value: "connected", label: "connected" },
//                                 { value: "closed", label: "closed" },
//                                 { value: "isolated", label: "isolated" },
//                                 { value: "wedgeopened", label: "wedge opened" },
//                               ]}
//                               isClearable
//                               placeholder="Select a equipment"
//                             />
//                           </div>
//                         )}

//                       {item.has_input === 1 && status[item.id] === "done" && (
//                         <div className="dropdowns">
//                           <label htmlFor={`input-${item.id}`}>Permit No:</label>
//                           <div className="input-group">
//                             <input
//                               type="number"
//                               id={`input-${item.id}`}
//                               value={inputValue[item.id] || ""}
//                               placeholder="Enter permit no"
//                               onChange={(e) =>
//                                 handleInputChange(item.id, e.target.value)
//                               }
//                             />
//                           </div>
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </form>
//         </div>
//         <div className="footer">
//           <div className="button-group">
//             <button
//               className="data-form-cancel-button"
//               onClick={handleCancelClick}
//             >
//               Cancel
//             </button>
//             <button className="data-form-next-button" onClick={handleSaveClick}>
//               Next
//             </button>
//           </div>
//         </div>
//       </div>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000} // Converted to milliseconds
//         onClose={handleSnackbarClose}
//         anchorOrigin={{ vertical: "top", horizontal: "right" }}
//         style={{ marginTop: "35px", marginLeft: "20px" }}
//       >
//         <MuiAlert onClose={handleSnackbarClose} severity={severity}>
//           {snackbarMessage}
//         </MuiAlert>
//       </Snackbar>
//     </>
//   );
// }

// export default Form2;

