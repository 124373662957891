import React, { useState, useEffect } from "react";
import "./UserForm.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";

function DesignationForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  const [designationData, setDesignationData] = useState({
    email: "",
    password: "",
    is_default: "0", // ID to be sent in the payload
  });

  // useEffect(() => {
  //   if (id) {
  //     setDesignationData((prevState) => ({
  //       ...prevState,
  //       daily_work_id: id, // Set 'daily_work_id' dynamically
  //     }));
  //   }
  // }, [id]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setDesignationData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value, // Handle checkbox state
    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleCancelClick = () => {
    navigate(`/email-configuration`);
  };

  // const handleSaveClick = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.post(
  //       `${API_URL}/api/add_daily_work_email_config`,
  //       designationData, // Send departmentData directly
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     console.log(response.data.message);
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Designation added successfully");
  //       setTimeout(() => {
  //         navigate(`/email-configuration`);
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving department:", error.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const validateForm = () => {
    const validationErrors = {};
    if (!designationData.email) {
      validationErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(designationData.email)) {
      validationErrors.email = "Enter a valid email.";
    }
    if (!designationData.password) {
      validationErrors.password = "Password is required.";
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0; // Return true if no errors
  };

  const handleSaveClick = async () => {
    if (!validateForm()) {
      return; // Stop execution if validation fails
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.post(
        `${API_URL}/api/add_daily_work_email_config`,
        designationData, // Send departmentData directly
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar(" Email added successfully");
        setTimeout(() => {
          navigate(`/email-configuration`);
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving designation:", error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Sender Email Configuration
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="email">
                    Email<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="email"
                      value={designationData.email}
                      onChange={handleInputChange}
                      placeholder="Enter email"
                    />
                  </div>
                  {errors.email && <div className="error-message">{errors.email}</div>}
                </div>
                <div className="form-group msmme">
                  <label htmlFor="is_default">Default Email</label>
                  <div className="checkbox-input">
                  
                      <input
                        type="radio"
                        name="is_default"
                        value="1" // Yes
                        checked={designationData.is_default === "1"}
                        onChange={(event) =>
                          setDesignationData((prevState) => ({
                            ...prevState,
                            is_default: event.target.value,
                          }))
                        }
                      />
                        <label>
                      Yes
                    </label>
                    </div>
                    
                    <div className="checkbox-input">
                      <input
                        type="radio"
                        name="is_default"
                        value="0" // No
                        checked={designationData.is_default === "0"}
                        onChange={(event) =>
                          setDesignationData((prevState) => ({
                            ...prevState,
                            is_default: event.target.value,
                          }))
                        }
                      />
                      <label>
                      No
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="password">
                    App Password<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="password"
                      name="password"
                      value={designationData.password}
                      onChange={handleInputChange}
                      placeholder="Enter password"
                    />
                  
                  </div>
                  {errors.password && (
                      <div className="error-message">{errors.password}</div>
                    )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default DesignationForm;
