import React, { useState, useEffect, useRef } from "react";
import "./ReportPage.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../Config";
import useClickOutside from '../useClickOutside';
import '../TableColor.css';
import { MdExpandMore, MdExpandLess } from "react-icons/md";

function ScheduleGenerator({ isSidebarExpanded }) {
  const [generators, setGenerator] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  
  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedschedules = [...generators].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setGenerator(sortedschedules);
  };
  const [loadingRows, setLoadingRows] = useState({});
  const containerRef = useRef(null);
const buttonRef  = useRef(null)
  const fetchData = async () => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/schedule_departments`, {
        headers: {
          Authorization: authKey,
        },
      });

      setGenerator(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching generated data:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));
  
  const handleGenerateSchedule = async (department_id) => {
    try {
      setIsLoading(true);
      setLoadingRows((prev) => ({ ...prev, [department_id]: true }));
      const authKey = localStorage.getItem("authKey");

      const response = await axios.post(
        `${API_URL}/api/generate_schedule/${department_id}`,
        {},
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        showSnackbar("Schedule generated successfully", "success");
      } else {
        showSnackbar("Unexpected response status", "warning");
      }
    } catch (error) {
      if (error.response) {
        const backendMessage = error.response.data.message;
        showSnackbar(backendMessage || "An error occurred", "error");
      } else if (error.request) {
        showSnackbar("No response received from server", "error");
      } else {
        showSnackbar("Error in request setup: " + error.message, "error");
      }
    } finally {
      setLoadingRows((prev) => ({ ...prev, [department_id]: false }));
      setIsLoading(false  );
    }
  };


  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const closePopups = () => {
    setIsSortPopup(false);
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleRefresh = () => {
    fetchData();
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
          <div className="data-list-content" ref={containerRef}>
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    Bulk Schedule Generator
                  </div>

                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search ..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup} ref={buttonRef}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button onClick={() => handleSort("department_name")}>
                            Department{" "}
                              {sortOrder.department_name === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
         
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : generators.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th className="center-alignment">SR. NO.</th>
                          <th>DEPARTMENTS</th>
                          <th className="total center-alignment">TOTAL EQUIPMENTS</th>
                          <th className="quantity center-alignment">SCHEDULE GENERATED EQUIPMENTS</th>
                          <th className="rejected center-alignment">NOT SCHEDULES</th>
                          <th className="center-alignment">ACTION</th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {generators.map((generator, index) => (
                          <tr key={index} className="hoverable-row">
                            <td className="center-alignment"> {index + 1}</td>
                            <td>{generator.department_name || "0"}</td>
                            <td className="total center-alignment">{generator.total_equipments || "0"}</td>
                            <td className="quantity center-alignment">{generator.schedules_generated || "0"}</td>
                            <td className="rejected center-alignment">{generator.pending_schedules || "0"}</td>
                            <td className="center-alignment">
                              <button
                                onClick={() =>
                                  handleGenerateSchedule(
                                    generator.department_id
                                  )
                                }
                                className="generate-scheduler-button"
                                disabled={
                                  loadingRows[generator.department_id] ||
                                  isLoading
                                }
                              >
                                {loadingRows[generator.department_id] ? (
                                  <>
                                 <div class="loaders"></div>
                                  <div>Generating...</div>
                                  </>
                                ) : (
                                  "Generate Schedule"
                                )}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">No Schedules Found</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ScheduleGenerator;
