import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { IoMdArrowBack } from "react-icons/io";
import { FaHistory } from "react-icons/fa";
import Pagination from "../Component/Pagination";
import useClickOutside from "../useClickOutside";
import Select from "react-select";

function ActualManpower({ isSidebarExpanded }) {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [isSearching, setIsSearching] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [filteredData, setFilteredData] = useState([]); // Filtered data

  const navigate = useNavigate();
  const { id } = useParams();
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [selectedProject, setSelectedProject] = useState(null); // Stores selected project
  const [projects, setProjects] = useState([]);
  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedBreakdown = [...departments].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setDepartments(sortedBreakdown);
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);

    // Store values in local storage
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };
  const handleViewHistory = (departmentID) => {
    navigate(`/joining-history/${departmentID}`);
  };

  const fetchData = async (currentPage, itemsPerPage, projectId = null) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const params = {
        page: currentPage,
        per_page: itemsPerPage,
      };

      // Include project_id if a project is selected
      if (projectId) {
        params.project_id = projectId;
      }

      const response = await axios.get(`${API_URL}/api/get_actual_manpower`, {
        headers: { Authorization: authKey },
        params: params, // Send parameters in request
      });

      setDepartments(response.data.Items);
      setTotalItems(response.data.TotalItems);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle project selection and fetch data with project_id
  const handleProjectChange = (selectedOption) => {
    if (selectedProject?.value === selectedOption?.value) return; // Prevent duplicate API calls

    setSelectedProject(selectedOption);

    if (selectedOption) {
      fetchData(1, 10, selectedOption.value);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage, projectId) => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/search_actual_man_power`,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
          params: {
            query: searchInput,
            page: currentPage,
            per_page: itemsPerPage,
            project_id: projectId,
          },
        }
      );

      if (response.status === 200) {
        setDepartments(response.data.Items);
        setTotalItems(response.data.TotalItems);
        setPage(response.data.CurrentPage);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    }
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      if (searchInput.trim() !== "") {
        setIsSearching(true);
        handleSearch(page, itemsPerPage, id); // Pass projectId here
      } else {
        setIsSearching(false);
        fetchData(page, itemsPerPage);
      }
    }, 600); // Adding delay for debouncing

    return () => clearTimeout(delay);
  }, [searchInput, page, itemsPerPage, id]);

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? departments.map((department) => department.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (departmentID) => {
    const updatedSelectedRows = selectedRows.includes(departmentID)
      ? selectedRows.filter((id) => id !== departmentID)
      : [...selectedRows, departmentID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === departments.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchiveDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_actual_man_power`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDepartments = departments.filter(
          (department) => !selectedRows.includes(department.id)
        );
        setDepartments(updatedDepartments);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting departments:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveDdeparment = (project_id) => {
    console.log("Navigating to ID:", project_id); // Log to ensure it's correct
    navigate(`/archive-Actual-man-Power/${project_id}`);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRefresh = () => {
    setSelectedProject(null); // Clear project filter
    fetchData(page, itemsPerPage);
  };

  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  const handleNew = () => {
    navigate("/actualmanpower-form"); // No project ID
  };

  const handleBackView = () => {
    navigate(`/project-view/${id}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  const fetchProjects = async () => {
    try {
      const authKey = localStorage.getItem("authKey"); // Retrieve auth key from local storage

      const response = await fetch(`${API_URL}/api/get_all_projects`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey, // Attach auth key in headers
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      const projectOptions = data.Projects.map((project) => ({
        value: project.id, // Unique identifier
        label: project.project_name, // Display name
      }));

      setProjects(projectOptions); // Update state with project options
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-list-content" ref={containerRef}>
          {isChecked ? (
            <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                    <button className="delete" onClick={handleDeletePopup}>
                      Move to archive
                    </button>
                    {isDeletePopup && (
                      <div className="confirm-delete-popup">
                        <div className="confirm-delete-popup-content">
                          <div className="confirm-model">
                            <div className="confirm-model-content">
                              <div className="confirm-model-content-body">
                                <p>Are you sure want to move archive?</p>
                              </div>
                              <div className="confirm-model-content-footer">
                                <button
                                  className="confirm-delete-button"
                                  onClick={handleArchiveDepartment}
                                >
                                  Yes
                                </button>
                                <button
                                  className="confirm-cancel-button"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="data-list-header">
              <div className="data-list-header-content">
                <div className="data-list-heading-content-left-side">
                  {/* <Tooltip title="Back" arrow>
                    <IoMdArrowBack
                      className="back-company"
                      onClick={handleBackView}
                    />
                  </Tooltip>{" "} */}
                  All Actual Manpower
                </div>
                <div className="data-list-heading-content-right-side">
                  <div className="dropdown-container">
                    <Select
                      id="project_name"
                      options={projects}
                      value={selectedProject}
                      onChange={handleProjectChange}
                      placeholder="Select Project"
                      isSearchable
                      className="project-dropdown"
                    />
                  </div>

                  <div className="search-bar-container">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      className="search-bar"
                    />
                    <IoSearchOutline className="search-bar-icon" />
                  </div>

                  {/* <Link to="/actualmanpower-form" className="data-list-new"> */}
                  <Tooltip title="New Department" arrow>
                    <button className="new-button" onClick={handleNew}>
                      <HiOutlinePlus className="new-plus-icon" /> New
                    </button>
                  </Tooltip>
                  {/* </Link> */}

                  <Tooltip title="Refresh List" arrow>
                    <button className="refresh-button" onClick={handleRefresh}>
                      <TbRefresh className="refresh-icon" />
                    </button>
                  </Tooltip>
                  <button
                    className="sort-button"
                    onClick={handleSortpopup}
                    ref={buttonRef}
                  >
                    <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                  </button>

                  {isSortPopup && (
                    <div className="sort-popup-container" ref={containerRef}>
                      <div className="sort-popup-content">
                        <div className="sort-by">
                          SORT BY{" "}
                          {sortOrder[sortColumn] === "asc"
                            ? "(Ascending)"
                            : "(Descending)"}
                        </div>
                        <div className="sort-by-button">
                          <button onClick={() => handleSort("DepartmentName")}>
                            DepartmentName{" "}
                            {sortOrder.DepartmentName === "asc" ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                        </div>
                        <button
                          className="delete-item"
                          onClick={() => handleArchiveDdeparment(id)}
                        >
                          Archived Manpower
                        </button>
                      </div>
                    </div>
                  )}
                  {/* {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button"></div>
                          <button
                            className="delete-item"
                            onClick={() => handleArchiveDdeparment(id)}>
                          
                            Archived Manpower
                          </button>
                        </div>
                      </div>
                    )} */}
                </div>
              </div>
            </div>
          )}
          <div className="data-list-body" onClick={closePopups}>
            <div className="data-list-scroll-body">
              <div className="data-list-table">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : departments.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th>SR. NO.</th>
                        <th>PROJECT NAME</th>
                        <th>PERSON NAME</th>
                        <th>EC NO</th>
                        <th>CONTACT NO</th>
                        <th>POSITION</th>
                        <th>CURRENT STATUS</th>
                        <th>EXPECTED DATE OF JOINING</th>
                        <th>CREATED AT </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {departments.map((department, index) => (
                        <tr key={department.ID} className="hoverable-row">
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(department.id)}
                              onChange={() => handleSelectRow(department.id)}
                            />
                          </td>

                          <td>{index + 1}</td>
                          <td>{department.project_name || "NA"}</td>
                          <td>{department.person_name || "NA"}</td>
                          <td>{department.ec_no || "NA"}</td>
                          <td>{department.contact_no || "NA"}</td>
                          <td>{department.position || "NA"}</td>
                          <td>
                            <div className="status-schedules">
                              <div
                                className="ss-status"
                                style={{
                                  backgroundColor:
                                    department.status === "Did not join"
                                      ? "rgb(219, 145, 33)" // Orange background
                                      : department.status === "Exited"
                                      ? "red" // Orange background
                                      : department.status === "Absconding"
                                      ? "rgb(192, 97, 43)" // Orange background
                                      : department.status === "Present at site"
                                      ? "blue" // Blue background
                                      : department.status === "Resigned"
                                      ? "red" // Red background
                                      : department.status ===
                                        "Appointment issued (yet to join)"
                                      ? "green" // Green background
                                      : "transparent", // Default background
                                  color: department.status ? "#fff" : "#888", // Adjust text color if needed
                                }}
                              >
                                {department.status || "NA"}
                              </div>
                              <div className="h-btn">
                                <div
                                  className="history-button"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    handleViewHistory(department.id)
                                  }
                                >
                                  <FaHistory />
                                </div>
                              </div>
                            </div>
                          </td>

                          {/* <td>
                            {formatDate(
                              department.joining_expected_date || "NA"
                            )}
                          </td> */}
                          <td>
  {department.joining_expected_date 
    ? formatDate(department.joining_expected_date) 
    : "NA"}
</td>

                          <td>
                            {department.created_at
                              ? new Date(department.created_at)
                                  .toUTCString()
                                  .split("GMT")[0]
                                  .trim() + " IST"
                              : "NA"}
                          </td>

                          <td className="edit-pop">
                            <button
                              className="edit-data-button"
                              onClick={() => handleButtonClick(index)}
                            >
                              <div className="edit-button">
                                <MdExpandMore />
                              </div>
                            </button>
                          </td>
                          {selectedRowIndex === index && (
                            <div className="edit-popup">
                              <div className="edit-popup-contant">
                                <div className="edit-popup-button">
                                  <Link
                                    to={`/edit-actual-man-powers/${department.id}`}
                                    className="inquiry-new"
                                  >
                                    <button className="edit-button-inquiry">
                                      <MdOutlineModeEditOutline /> Edit
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">
                      No Actual Manpower Found
                    </div>
                  </div>
                )}
              </div>
              <Pagination
                showPerPage={itemsPerPage}
                total={totalItems}
                onPaginationChange={handlePaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ActualManpower;
