import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBkHBbXprm85KPOone94Fdx1e4vA6GdzQE",
  authDomain: "keshavencon-mms.firebaseapp.com",
  projectId: "keshavencon-mms",
  storageBucket: "keshavencon-mms.firebasestorage.app",
  messagingSenderId: "395349980038",
  appId: "1:395349980038:web:e9d4088616673cfcec7207",
  measurementId: "G-532GP54CLB"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// ✅ FIX: Properly resolve notifications
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('📩 Message received in promise:', payload);
      resolve(payload); // ✅ Ensure this triggers re-render
    });
  });

// Request permission and get FCM token
export const requestFCMToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      throw new Error('Notification permission denied');
    }

    // Get token
    const token = await getToken(messaging, {
      vapidKey: 'BK_ZBGKu64mC5qCDxnbUc3i5bqBr1l6L7hf3YZC7-E_DW32nbuENZT8_kF0qyfsJslOzglSBcwqvWU8BlB5idos',
    });

    return token;
  } catch (error) {
    console.error('Error getting FCM token:', error);
    throw error;
  }
};
