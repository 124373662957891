import React, { useState, useEffect, useRef } from "react";
import "./PreventiveReportView.css";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../Config";
import { IoMdArrowBack } from "react-icons/io";
import Pagination from "../Component/Pagination";

function PreventiveReportView({ isSidebarExpanded }) {
  const { department_id, status, filter_option } = useParams();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_report_schedule_details`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            department_id,
            filter_option: filter_option,
            status,
            page: currentPage, // Current page from state, no need to store in localStorage
            per_page: itemsPerPage,
          },
        }
      );

      setReports(response.data.schedules);
      setTotalItems(response.data.total);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching schedule report:", error.message);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const page = 1; // Start from the first page by default
  //   const itemsPerPage = 50; // Default items per page

  //   setPage(page);
  //   setItemsPerPage(itemsPerPage);

  //   fetchData(page, itemsPerPage);
  // }, [department_id, filter_option, status, page, itemsPerPage]);

  useEffect(() => {
    fetchData(page, itemsPerPage);
  }, [department_id, filter_option, status]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);

    // Store values in local storage
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  // useEffect(() => {
  //   fetchData();
  // }, [department_id, filter_option, status]);

  const containerRef = useRef(null);
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };


  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const closePopups = () => {
    setIsSortPopup(false);
  };

  const handleRefresh = () => {
    fetchData();
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };
  const handleBackScheduler = () => {
    navigate("/reports?tab=preventive-schedule-reports");
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "#2980B9";
      case "cancelled":
        return "#F81700";
      case "hold":
        return "#D4AC0D";
      case "not in tenure":
        return "#313639";
      case "overdue":
        return "#C0612B";
      case "In Progress":
        return "#df7f26";
      case "completed":
        return "#2E8B57";
      default:
        return "black"; // Fallback color if needed
    }
  };

  const handleViewClick = async (scheduler_id) => {
    navigate(`/scheduler-view/${scheduler_id}`);
  };

  const filterOptionMap = {
    all: "All",
    today: "Today",
    yesterday: "Yesterday",
    tomorrow: "Tomorrow",
    next7days: "Next 7 Days",
    next_month: "Next Month",
    current_week: "Current Week",
    previous_month: "Previous Month",
    past_week: "Previous Week",
    current_month: "Current Month",
    custom_date: "Custom Date",

    // Add other mappings as needed
  };
  // Get the display text based on filter_option
  const displayText = filterOptionMap[filter_option] || filter_option;

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-list-content" ref={containerRef}>
          <div className="data-list-header">
            <div className="data-list-header-content">
              <div className="data-list-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackScheduler}
                  />
                </Tooltip>
                {reports.length > 0 && (
                  <>
                    <span className="report-name">Preventive Report</span>
                    <span
                      className="status-show"
                      style={{
                        color: getStatusColor(reports[0].status),
                        fontWeight: "500",
                      }}
                    >
                      {reports[0].status
                        ? reports[0].status.charAt(0).toUpperCase() +
                          reports[0].status.slice(1).toLowerCase()
                        : "0"}
                    </span>
                    <span
                      className="department-show"
                      style={{
                        color: "green",
                        marginLeft: "15px",
                        fontWeight: "500",
                      }}
                    >
                      {reports[0].department
                        ? reports[0].department.charAt(0).toUpperCase() +
                          reports[0].department.slice(1).toLowerCase()
                        : "0"}
                    </span>
                    <span
                      className="option-show"
                      style={{
                        color: "#ce9139ed",
                        marginLeft: "15px",
                        fontWeight: "500",
                      }}
                    >
                      {displayText}
                    </span>
                  </>
                )}
              </div>

              <div className="data-list-heading-content-right-side">
                <div className="search-bar-container">
                  <input
                    type="text"
                    placeholder="Search ..."
                    value={searchInput}
                    onChange={handleSearchInputChange}
                    className="search-bar"
                  />
                  <IoSearchOutline className="search-bar-icon" />
                </div>

                <Tooltip title="Refresh List" arrow>
                  <button className="refresh-button" onClick={handleRefresh}>
                    <TbRefresh className="refresh-icon" />
                  </button>
                </Tooltip>
                <button className="sort-button" onClick={handleSortpopup}>
                  <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                </button>
                {isSortPopup && (
                  <div className="sort-popup-container">
                    <div className="sort-popup-content">
                      <div className="sort-by">
                        SORT BY {isAscending ? "(Ascending)" : "(Descending)"}
                      </div>
                      <div className="sort-by-button"></div>
                      {/* <button className='delete-item' onClick={handleArchiveDeparment}>Archived Sub Department</button> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="data-list-body" onClick={closePopups}>
            <div className="data-list-scroll-bodys">
              <div className="data-list-table">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : reports.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th className="center-alignment">SR. NO.</th>
                        <th>DEPARTMENT</th>
                        <th>CATEGORY</th>
                        <th>TAG NO</th>
                        <th className="center-alignment">STATUS</th>
                        <th>SERVICE DUE DATE</th>
                        <th>GENERATED AT</th>
                        <th className="center-alignment">ACTION</th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {reports.map((report, index) => (
                        <tr key={index} className="hoverable-row">
                          <td className="center-alignment">{index + 1}</td>
                          <td>{report.department || "NA"}</td>
                          <td>{report.category || "NA"}</td>
                          <td>{report.equipment_tag_no || "NA"}</td>
                          <td className="status-schedules">
                            <div
                              style={{
                                backgroundColor:
                                  report.status === "Hold"
                                    ? "#D4AC0D"
                                    : report.status === "Cancelled"
                                    ? "#F81700"
                                    : report.status === "not in tenure"
                                    ? "#313639"
                                    : report.status === "overdue"
                                    ? "#C0612B"
                                    : report.status === "active"
                                    ? "#2980B9"
                                    : report.status === "In Progress"
                                    ? "#df7f26"
                                    : report.status === "Completed"
                                    ? "#2E8B57"
                                    : "transparent",
                                width: "6vw",
                              }}
                            >
                              {report.status
                                ? report.status.charAt(0).toUpperCase() +
                                  report.status.slice(1).toLowerCase()
                                : "NA"}
                            </div>
                          </td>
                          <td>{formatDate(report.service_due_date || "NA")}</td>
                          <td>{formatDate(report.generated_at || "NA")}</td>

                          <td className="center-alignment">
                            <button
                              onClick={() => handleViewClick(report.id)}
                              className="view-button"
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">
                      No Schedules Report Found
                    </div>
                  </div>
                )}
              </div>
              <Pagination
                showPerPage={itemsPerPage}
                total={totalItems}
                onPaginationChange={handlePaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreventiveReportView;
