import axios from 'axios';
import API_URL from "../../src/Config";


export const registerFCMToken = async (fcmToken) => {
  try {
    const authKey = localStorage.getItem("authKey");
    const response = await axios.post(
      `${API_URL}/api/fcm/register`,
      { fcm_token: fcmToken },
      {
        headers: {
          Authorization: authKey,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error registering FCM token:', error);
    throw error;
  }
};

