import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Level1Form";
import axios from "axios";
import Select from "react-select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
function MatCodeLevelOneForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [levelOneData, setLevelOneData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDescriptionDuplicate, setIsDescriptionDuplicate] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [levelOptions, setLevelOptions] = useState(
    Array.from({ length: 100 }, (_, i) => ({
      value: i.toString().padStart(2, "0"),
      label: i.toString().padStart(2, "0"),
    }))
  );

  const [levelData, setLevelData] = useState({
    level: "",
    description: "",
  });
  const { theme } = useContext(ThemeContext);

  const fetchData = async () => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_mat_code_level_ones`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      const apiData = response.data;
      setLevelOneData(apiData);

      // Extract used levels from the API response
      const usedLevels = new Set(apiData.map((item) => item.level));

      // Filter out used levels from levelOptions
      const filteredLevelOptions = levelOptions.filter(
        (option) => !usedLevels.has(option.value)
      );
      setLevelOptions(filteredLevelOptions);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching level1 data:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    setLevelData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    // Call API to check if the description already exists
    // if (name === "description" && value.trim()) {
    //   checkDescription(value.trim());
    // }
  };

  // const checkDescription = async (description) => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");

  //     // Make the API call
  //     await axios.post(
  //       `${API_URL}/api/l1/check_mat_code_description`,
  //       { description },
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );

  //     setIsDescriptionDuplicate(false);
  //   } catch (error) {
  //     if (
  //       error.response &&
  //       error.response.status === 400 &&
  //       error.response.data.message === "Description already exists"
  //     ) {
  //       setIsDescriptionDuplicate(true);
  //       setErrors((prevState) => ({
  //         ...prevState,
  //         description: "Description already exists",
  //       }));
  //     } else {
  //       // Handle other errors
  //       console.error("Error checking description:", error.message);
  //       setSeverity("error");
  //       showSnackbar("An error occurred while checking the description");
  //     }
  //   }
  // };

  const handleLevelChange = (selectedOption) => {
    setLevelData((prevState) => ({
      ...prevState,
      level: selectedOption ? selectedOption.value : "",
    }));

    setErrors((prevState) => ({
      ...prevState,
      level: "",
    }));
  };

  // Cancel button handler
  const handleCancelClick = () => {
    navigate("/matcode-levels?tab=level-one");
  };

  // Save button handler
  const handleSaveClick = async () => {
    const errors = {};
    let error = false;
    Object.keys(levelData).forEach((key) => {
      if (!levelData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning");
        error = true;
      }
    });
    if (isDescriptionDuplicate) {
      if (isDescriptionDuplicate) {
        errors.description = "Description already exists";
      }
      setErrors(errors);
      return;
    }

    if (error) {
      showSnackbar("Required fields are empty");
      setErrors(errors);
      return;
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.post(
        `${API_URL}/api/add_mat_code_level_one`,
        levelData,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Level 1 added successfully");
        setTimeout(() => {
          navigate("/matcode-levels?tab=level-one");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving Level 1:", error.message);
      setSeverity("error");
      showSnackbar("An error occurred while saving");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Level 1
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="level">
                    Level 1<span className="text-danger">*</span>
                  </label>

                  <Select
                    name="level"
                    value={levelOptions.find(
                      (option) => option.value === levelData.level
                    )}
                    onChange={handleLevelChange}
                    options={levelOptions}
                    placeholder="Select Level 1"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                        border: `1px solid ${
                          theme === "dark" ? "#444" : "#ccc"
                        }`, // Border for the menu
                        borderRadius: "4px", // Optional: add border radius for rounded edges
                        zIndex: 1000, // Ensures the menu appears above other elements
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.level && (
                    <div className="error-message">{errors.level}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="description">
                    Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="description"
                      value={levelData.description}
                      onChange={handleInputChange}
                      placeholder="Enter description"
                      className={errors.description ? "input-error" : ""}
                    />
                   
                  </div>
                  {errors.description && (
                      <div className="error-message">{errors.description}</div>
                    )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(51px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default MatCodeLevelOneForm;
