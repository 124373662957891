
import "./energisation.css"

export default function IsolationTemplate4() {
  return (
    <div className="certificate-container">
      <div className="certificate-header">
        <h1>ऊर्जावान होने का प्रमाण पत्र / Certificate of Energisation:</h1>
        <div className="date-time">
          <p>दिनांक / Date:........................समय / Time:.................</p>
        </div>
      </div>

      <div className="certificate-body">
        <p className="equipment-info">
          उपकरण को प्रमाणित किया गया है/सर्किट क्र. / Certified that Equipment / Circuit no.
          ................................. का / of .....................................
        </p>

        <p className="energisation-info">
          संयंत्र को विद्युत रूप से सक्रिय किया है और अपूर्ति पैनल से खतरे का टैग हटा दिया गया है। यह इलेक्ट्रिकल शिफ्ट लॉगबुक में भी दर्ज है /
          plant has been electrically energized and the danger tag removed from the supply panel. This is also recorded
          in the electrical shift logbook.
        </p>

        <div className="signature-section">
          <div className="signature-field">
            <div className="signature-line"></div>
            <p>अधिकृत व्यक्ति का नाम / Name of Authorized Person</p>
          </div>

          <div className="signature-field">
            <div className="signature-line"></div>
            <p>पद / Designation</p>
          </div>

          <div className="signature-field">
            <div className="signature-line"></div>
            <p>हस्ताक्षर / Signature</p>
          </div>
        </div>
      </div>
    </div>
  )
}

