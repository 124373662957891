import React, { useState, useEffect } from "react";
// import "./Template1.css";
import "./HotWorkTemplate4.css";

  const HotWorkTemplate9 = ({ permitClosure }) => {
    const formatDateTime = (dateString) => {
      if (!dateString) return "";
  
      // Create a Date object from the date string
      const date = new Date(dateString);
  
      // Convert to UTC and format time
      const time = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "UTC",
      });
  
      // Format date to DD/MM/YYYY using UTC date methods
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based in JS
      const year = date.getUTCFullYear();
  
      // Combine to form the date string in DD/MM/YYYY format
      const dateStr = `${day}/${month}/${year}`;
  
      return `${dateStr}, ${time}`;
    };
  return (
    <>

        <div className="hot-template-container">
          <div className="hot-template-container-content">
            <div className="template-container-header">
            <div className="cold-remark">
            <h4>कार्य अनुमति पत्र बंद करना / Closing of the work permit
            </h4>
            </div>
              <div className="hot-work-table">
                <table className="cold-work-table">
                  <thead>
                    <tr>
                      <th colspan="3" className="permit-closing table-closing1">
                        प्राप्तकर्ता प्रमाणिक किया गया है कि विषयगत कार्य पूरा हो गया <br/> है। रोक दिया गया है तथा क्षेत्र साफ कर दिया है। <br/>
                        Receiver: Certified that the subject work has been <br/>
                        completed<input type="checkbox" className="permit-check1" />
                        stopped<input type="checkbox" className="permit-check1" />and area cleaned
                      </th>
                      <th colspan="3" className="permit-closing table-closing1">
                        सत्यापित है कि काम पूरा होगया है और क्षेत्र साफ होगया है <br/>
                        और किसोभी खतरे से सुरक्षित है। <br/> Issuer: Verified that the
                        job has been completed and <br/> area cleaned and is safe any
                        hazard
                      </th>
                      <th colspan="1" className="permit-center-align table-closing2" >
                        सुरक्षा आधिकारी <br /> Safety Officer
                      </th>
                    </tr>
                    <tr>
                      {/* first row */}
                      <th className="permit-center-align">
                      दिनांक एवं समय <br /> Date & Time
                      </th>
                      <th className="permit-center-align">
                        नाम और पद <br /> Name & Designation
                      </th>
                      <th className="permit-center-align">
                        हस्ताक्षर <br /> Signature
                      </th>
                      {/* ends */}
                      <th className="permit-center-align">
                      दिनांक एवं समय <br /> Date & Time
                      </th>
                      <th className="permit-center-align">
                        नाम और पद <br /> Name & Designation
                      </th>
                      <th className="permit-center-align">
                        हस्ताक्षर <br /> Signature
                      </th >
                      {/* second row ends */}
                      <th className="permit-center-align">नाम और हस्ताक्षर <br/>  Name & Signature
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr className="permit-row-end">
                      <td>{formatDateTime(permitClosure.receiver_date_time)}</td>
                      <td>{permitClosure.receiver_name}</td>
                      <td></td>
                      <td>{formatDateTime(permitClosure.issuer_date_time)}</td>
                      <td>{permitClosure.issuer_name}</td>
                      <td></td>
                      <td>{permitClosure.safety_officer_name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
  
    </>
  );
};

export default HotWorkTemplate9;
