import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import API_URL from "../../src/Config";
import "./DailyProgressEmail.css";
import Select from "react-select";

const EmailConfiguration = ({ closePopupEmail, dailyId, onClose }) => {
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedEmailConfiguration, setSelectedEmailConfiguration] = useState(null);
  const [isSending, setIsSending] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const popupRef = useRef(null);
  const [emailData, setEmailData] = useState({
    to_email: "",
    subject: "",
    body: "",
    dailyId: "",
  });

  const [emails, setEmails] = useState([]);
  const [emailConfiguration, setEmailConfiguration] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);

  useEffect(() => {
    console.log("Received dailyId in popup:", dailyId);
    // You can use dailyId here for fetching or other logic
  }, [dailyId]);

  const handleEmailConfigChange = (selectedOption) => {
    setSelectedEmailConfiguration(selectedOption); // Update the selected email in state
  };


  const handleEmailChange = (selectedOption) => {
    setSelectedEmail(selectedOption); // Update the selected email in state

    // Find the selected template data from the response
    const selectedTemplate = emailTemplates.find(
      (template) => template.id === selectedOption.value
    );

    if (selectedTemplate) {
      setEmailData({
        ...emailData,
        to_email: selectedTemplate.to_emails || "", // Populate To email
        subject: selectedTemplate.subject || "", // Populate Subject
        body: selectedTemplate.body || "", // Populate Body
      });
    }
  };

  const fetchEmailsTemplate = async (moduleName) => {
    if (!moduleName) {
      console.error("Module name is required!");
      return;
    }

    try {
      const authKey = localStorage.getItem("authKey");
      const encodedModuleName = encodeURIComponent(moduleName); // Encode the module name
      const apiUrl = `${API_URL}/api/get_hr_compliance_reminder_config`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });

      if (response.ok) {
        const data = await response.json();
        // Map the API response to the required format
        const optionsName = data.records.map((email) => ({
          label: email.template_name || "No Template Name", // Template name or fallback text
          value: email.email_temp_id, // Template ID as value
        }));
        setSelectedEmail(optionsName); // Update the emails state
        const optionsEmailName = data.records.map((email) => ({
          label: email.email_config_email || "No Template Name", // Template name or fallback text
          value: email.template_id, // Template ID as value
        }));
        setSelectedEmailConfiguration(optionsEmailName)
      } else {
        console.error("Failed to fetch email templates");
      }
    } catch (error) {
      console.error("An error occurred while fetching email templates:", error);
    }
  };
  // Use the encoded module name
  useEffect(() => {
    fetchEmailsTemplate("HR Compliances");
  }, []);


 

  const fetchEmails = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_email_templates`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });

      if (response.ok) {
        const data = await response.json();

        setEmailTemplates(data.templates); // Store the raw templates

        const options = data.templates.map((email) => {
          const label = email.temp_name || "No Template Name"; // Use temp_name or fallback
          return {
            label: label,
            value: email.id,
          };
        });

        setEmails(options); // Update state with formatted options
      } else {
        console.error("Failed to fetch email templates");
      }
    } catch (error) {
      console.error("An error occurred while fetching email templates:", error);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);



  const fetchEmailsConfig = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_email_configs`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const options = data.configs.map((email) => {
          const label = email.email || "No Template Name"; // Use temp_name or fallback
          return {
            label: label,
            value: email.id,
          };
        });

        setEmailConfiguration(options); // Update state with formatted options
      } else {
        console.error("Failed to fetch email templates");
      }
    } catch (error) {
      console.error("An error occurred while fetching email templates:", error);
    }
  };

  useEffect(() => {
    fetchEmailsConfig();
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!selectedEmail) {
      errors.from_email = "Email is required";
    }
    if (!emailData.to_email) {
      errors.to_email = "Email is required";
    }
    if (!emailData.subject) {
      errors.subject = "Subject is required";
    }
    if (!emailData.body) {
      errors.body = "Body is required";
    }

    // Return true if no errors, false otherwise
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const payload = {
        email_temp_id: selectedEmail.value, // Sending only template_id
        email_config_id: selectedEmailConfiguration.value,
      };

      const response = await axios.post(
        `${API_URL}/api/hr_compliance_reminder_config`,
        payload,
        {
          headers: { Authorization: authKey },
        }
      );

      if (response.status === 200) {
        setSuccessMessage(response.data.message); // Show dynamic success message
        setShowSuccessMessage(true);
      } else {
        setErrorMessage(`Error: ${response.data.message}`);
      }
      onClose();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message); // Display error message from API
      } else {
        setErrorMessage("Failed to send email. Please try again."); // General fallback error
      }
      console.error("Error sending email:", error);
    } finally {
      setIsSending(false); // Hide "Sending email..." message
    }
  };

  return (
    <div className="reminder-popup-model" ref={popupRef}>
      <div className="reminder-popup-content">
        <div className="reminder-popup-model-header">
          <div className="reminder-popup-model-content-header">
            <div className="reminder-popup-model-content-header-left-part">
          Reminder Configuration
            </div>
            <div className="reminder-popup-model-content-header-right-part"></div>
          </div>
          <div className="popup-new-form">
            <form className="popup-form-detail">
              <div className="new-data-form">
              <div className="form-group-select email-filed">
                  <label htmlFor="department_name">
                    Email Configuration<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department_name"
                    options={emailConfiguration} // emails should be in the format { label, value }
                    value={selectedEmailConfiguration}
                    onChange={handleEmailConfigChange}
                    placeholder="Select email"
                    isSearchable
                  />
                </div>
                <div className="form-group-select email-filed">
                  <label htmlFor="department_name">
                    Email Template<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department_name"
                    options={emails} // emails should be in the format { label, value }
                    value={selectedEmail}
                    onChange={handleEmailChange}
                    placeholder="Select email template"
                    isSearchable
                  />
                </div>
                <div className="form-group email-filed">
                  <label htmlFor="to_email">To</label>
                  <div className="input-group emailconfig1">
                    <input
                      type="email"
                      id="to_email"
                      name="to_email"
                      value={emailData.to_email}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group email-filed">
                  <label htmlFor="subject">Subject</label>
                  <div className="input-group subject1">
                    <textarea
                      id="subject"
                      name="subject"
                      value={emailData.subject}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group email-filed">
                  <label htmlFor="body">Body</label>
                  <div className="input-group body1">
                    <textarea
                      id="body"
                      name="body"
                      value={emailData.body}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footers">
            <div className="button-group-email">
              <div>
                <button
                  className="person-user-save-button"
                  type="button"
                  onClick={handleSubmit}
                  disabled={isSending}
                >
                  {isSending ? "Saving..." : "Save"}
                </button>

                <button className="person-user-cancel-button" onClick={onClose}>
                  Cancel
                </button>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailConfiguration;
