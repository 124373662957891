import React, { useState, useEffect, useRef } from "react";
import "./UserForm.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiUpload } from "react-icons/fi";
import { MdAttachFile, MdDeleteOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
function HrCompilanceForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [errors, setErrors] = useState({});
  const [reminderDays, setReminderDays] = useState("");
  const [selectedGSTFiles, setSelectedGSTFiles] = useState([]);
  const [isGSTPopupOpen, setIsGSTPopupOpen] = useState(false);
  const gstFileInputRef = useRef(null);
  const buttonRef = useRef(null);
  const popupRef = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [manPowerData, setManPowerData] = useState({
    project_id: id || "",
    position: "",
    profile: "", // This will be renamed to expected_profile
    no_of_position: "", // This will be renamed to no_of_positions
    status: "open", // Add status with a default value
  });
  const [startDate, setStartDate] = useState(null);
  const [LicenceName, setLicenceName] = useState("");
  const [selectedProject, setSelectedProject] = useState(null); // Stores selected project
  const [endDate, setEndDate] = useState(null);
  const [selectedCompanyType, setSelectedCompanyType] = useState(null);
  const companyTypes = [
    { value: "CEA", label: "CEA" },
    { value: "Factory Licence", label: "Factory Licence" },
    { value: "IBR", label: "IBR" },
    { value: "ISMW Licence", label: "ISMW Licence" },
    { value: "KPCB", label: "KPCB" },
    { value: "Labour Licence ", label: "Labour Licence " },
    { value: "PESO", label: "PESO" },
    { value: "RTO Renewal", label: "RTO Renewal" },
    { value: "WC Policy", label: "WC Policy" },
    { value: "W&M", label: "W&M" },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setManPowerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const validateForm = () => {
    const validationErrors = {};

    // Validate License Name
    // if (!LicenceName) {
    //   validationErrors.license_name = "License name is required.";
    // }

    // Validate Department (selectedProject)
    if (!selectedProject || !selectedProject.value) {
      validationErrors.department_id = "Department is required.";
    }

    // Validate Compliance License (selectedCompanyType)
    if (!selectedCompanyType || !selectedCompanyType.value) {
      validationErrors.compliance_license = "License type is required.";
    }

    // Validate Start Date
    if (!startDate) {
      validationErrors.start_date = "Valid from date is required.";
    }

    // Validate End Date
    if (!endDate) {
      validationErrors.end_date = "Valid to date is required.";
    }

    // Validate Reminder Days
    if (!reminderDays) {
      validationErrors.reminder_days = "Reminder days are required.";
    }
    if (selectedGSTFiles.length === 0) {
      validationErrors.files = "At least one file must be uploaded.";
    }

    // Set errors if any
    setErrors(validationErrors);

    // Return true if no errors, otherwise false
    return Object.keys(validationErrors).length === 0;
  };

  const handleSaveClick = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Create a FormData instance to handle multipart form data
      const formData = new FormData();

      // Append all the data to FormData
      // formData.append("license_name", LicenceName);
      formData.append("department_id", selectedProject?.value); // Department dropdown value
      formData.append("license_type", selectedCompanyType?.value || ""); // Compliance License type
      formData.append(
        "valid_from",
        startDate ? startDate.toISOString().split("T")[0] : ""
      ); // Format start date to yyyy-MM-dd
      formData.append(
        "valid_to",
        endDate ? endDate.toISOString().split("T")[0] : ""
      ); // Format end date to yyyy-MM-dd
      formData.append("reminder_before_days", reminderDays); // Reminder days before

      // Append the selected files to FormData
      selectedGSTFiles.forEach((file) => {
        formData.append("attachments", file); // Assuming file is the actual file object
      });

      // API call to save the data
      const response = await axios.post(
        `${API_URL}/api/add_hr_compliance`,
        formData,
        {
          headers: {
            Authorization: authKey, // Authorization header with key
            "Content-Type": "multipart/form-data", // Set the content type
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Statutory Approvals added successfully");
        setTimeout(() => {
          navigate(`/hr-compliances`); // Redirect after saving
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving statutory approvals:", error.message);
      setSeverity("error");
      showSnackbar("Failed to save statutory approvals");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const fetchProjects = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      if (!authKey) {
        console.error("Auth key is missing!");
        return;
      }

      const response = await fetch(`${API_URL}/api/get_departments`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("API Response:", data); // Debugging

      if (!Array.isArray(data)) {
        throw new Error("Invalid API response format - expected an array");
      }

      const projectOptions = data.map((project) => ({
        value: project.ID, // Ensure API returns `ID`
        label: project.DepartmentName, // Ensure API returns `DepartmentName`
      }));

      setProjects(projectOptions); // Update state
    } catch (error) {
      //   console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleProjectChange = (selectedOption) => {
    setSelectedProject(selectedOption);
  };
  const handleCompanyTypeChange = (selectedOption) => {
    setSelectedCompanyType(selectedOption); // Store full object
    // handleInputChanges("selectedCompanyType", selectedOption?.value || ""); // Keep string for validation
  };

  const handleUploadButtonClick = (inputRef) => {
    inputRef.current.click(); // Trigger the file input click
  };

  const handleFileInputChange = (files) => {
    setSelectedGSTFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleGSTIconClick = () => {
    setIsGSTPopupOpen(!isGSTPopupOpen); // Toggle the file attachment popup visibility
  };

  const handleDeleteFile = (index) => {
    const updatedFiles = selectedGSTFiles.filter((_, i) => i !== index);
    setSelectedGSTFiles(updatedFiles); // Remove the file from the selected files
  };

  const handleGSTCloseAttachment = () => {
    setIsGSTPopupOpen(false); // Close the attachment popup
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Statutory Approvals
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onSubmit={handleSaveClick}>
              <div className="new-data-form">
                {/* <div className="form-group">
                  <label htmlFor="position">
                    Licence Name <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="position"
                      value={LicenceName}
                      onChange={(e) => {
                        setLicenceName(e.target.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          license_name: "",
                        }));
                      }}
                      placeholder="Enter Licence Name"
                      className={`form-control ${
                        errors.license_name ? "is-invalid" : ""
                      }`} // Add 'is-invalid' class if there's an error
                    />
                  </div>
                  {errors.license_name && (
                    <div className="error-message">{errors.license_name}</div> // Display error message
                  )}
                </div>  */}

                <div className="form-group-select">
                  <label htmlFor="department_name">
                    Departments <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="project_name"
                    options={projects}
                    value={selectedProject} // Ensure this is an object { value, label }
                    onChange={(selectedOption) => {
                      handleProjectChange(selectedOption); // Handle project change
                      setErrors((prevState) => ({
                        ...prevState,
                        department_id: "", // Clear error when the user selects a department
                      }));
                    }}
                    placeholder="Select Departments"
                    isSearchable
                    className={`select-input ${
                      errors.department_id ? "is-invalid" : ""
                    }`}
                  />

                  {errors.department_id && (
                    <div className="error-message">{errors.department_id}</div>
                  )}
                </div>

                <div className="form-group-select">
                  <label htmlFor="no_of_position">
                    Compliance Licence<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="project_name"
                    className={`select-field-companyType ${
                      errors.compliance_license ? "is-invalid" : ""
                    }`} // Add 'is-invalid' class if there's an error
                    value={selectedCompanyType} // Store full object
                    onChange={(selectedOption) => {
                      handleCompanyTypeChange(selectedOption); // Handle company type change
                      setErrors((prevState) => ({
                        ...prevState,
                        compliance_license: "", // Clear error when user selects an option
                      }));
                    }}
                    options={companyTypes}
                    placeholder="Select License Type"
                    isClearable
                    styles={{
                      menu: (base) => ({
                        ...base,
                        marginTop: 0,
                        borderTop: "none",
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                      }),
                      control: (base) => ({
                        ...base,
                        marginBottom: 0,
                      }),
                    }}
                  />

                  {errors.compliance_license && (
                    <div className="error-message">
                      {errors.compliance_license}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="start_date">
                    Valid From Date <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <DatePicker
                      id="start-date"
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setErrors((prevState) => ({
                          ...prevState,
                          start_date: "", // Clear error when user selects a date
                        }));
                      }}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      showMonthDropdown
                      placeholderText="Valid From Date"
                      className={`datepicker ${
                        errors.start_date ? "is-invalid" : ""
                      }`} // Add 'is-invalid' class if there's an error
                    />
                  </div>
                  {errors.start_date && (
                    <div className="error-message">{errors.start_date}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="end_date">
                    Valid To Date <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <DatePicker
                      id="end-date"
                      selected={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                        setErrors((prevState) => ({
                          ...prevState,
                          end_date: "", // Clear error when user selects a date
                        }));
                      }}
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      showMonthDropdown
                      placeholderText="Valid To Date"
                      minDate={startDate}
                      className={`datepicker ${
                        errors.end_date ? "is-invalid" : ""
                      }`}
                    />
                  </div>
                  {errors.end_date && (
                    <div className="error-message">{errors.end_date}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="reminder_days">
                    Reminder Before Days <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      min="1"
                      name="reminder_days"
                      value={reminderDays}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const newValue =
                          inputValue === ""
                            ? ""
                            : Math.max(1, Number(inputValue));
                        setReminderDays(e.target.value, newValue);
                        setErrors((prevState) => ({
                          ...prevState,
                          reminder_days: "", // Clear the error when the user types
                        }));
                      }}
                      placeholder="Enter Reminder Days"
                      className={`form-control ${
                        errors.reminder_days ? "is-invalid" : ""
                      }`} // Add 'is-invalid' class if there's an error
                    />
                  </div>
                  {errors.reminder_days && (
                    <div className="error-message">{errors.reminder_days}</div>
                  )}
                </div>

                <div className="form-group-attachments">
                  <div className="form-group">
                    <label htmlFor="gst_attachments">Attachments</label>
                    <button
                      type="button"
                      className="challan-btn"
                      onClick={() => {
                        handleUploadButtonClick(gstFileInputRef);
                      }}
                      ref={buttonRef}
                    >
                      <FiUpload /> Upload File
                    </button>
                    <input
                      type="file"
                      id="gst_attachments"
                      ref={gstFileInputRef}
                      onChange={(e) => {
                        handleFileInputChange(
                          e.target.files,
                          setSelectedGSTFiles
                        );
                        setErrors((prevState) => ({
                          ...prevState,
                          gst_attachments:
                            e.target.files.length === 0
                              ? "At least one file is required."
                              : "", // Check if files are selected
                        }));
                      }}
                      style={{ display: "none" }}
                    />
                    {selectedGSTFiles.length > 0 && (
                      <span
                        className="inquiry-file-btn"
                        onClick={handleGSTIconClick}
                      >
                        <MdAttachFile className="inquiry-file-icon" />{" "}
                        {`${selectedGSTFiles.length}`}
                      </span>
                    )}
                    {isGSTPopupOpen && (
                      <div className="attach-popup-amc1" ref={popupRef}>
                        <div className="attach-popup-contant">
                          <div className="attachment-header">
                            <div className="attachment-header-content">
                              Attachments
                            </div>
                            <button
                              className="fiile-close-btn"
                              onClick={handleGSTCloseAttachment}
                            >
                              <IoCloseOutline className="file-close-icon" />
                            </button>
                          </div>
                          {selectedGSTFiles.length > 0 && (
                            <div className="attach-scrroll-body">
                              {selectedGSTFiles.map((file, index) => (
                                <div
                                  className="attach-popup-contant-body"
                                  key={index}
                                >
                                  <div className="attach-file-container">
                                    <div className="attach-file-show">
                                      <div className="text-attachment">
                                        {file.name}
                                      </div>
                                      <div className="text-attachment-file">
                                        File Size:(
                                        {(file.size / 1024).toFixed(2)} KB)
                                      </div>
                                    </div>
                                    <div
                                      className="file-delete-btn"
                                      onClick={() => handleDeleteFile(index)}
                                    >
                                      <span>
                                        {" "}
                                        <MdDeleteOutline className="file-delete-icon" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {/* Error Message for Files */}
                    {errors.files && (
                      <div className="error-message">{errors.files}</div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default HrCompilanceForm;
