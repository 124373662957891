import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";

function Form3 (fetchComplitionSatusData) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
 

  const [formData, setFormData] = useState({
    form_id: "",
    hazard_description: "",
    precaution_description: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCancelClick = () => {
    navigate("/cold-work-permit");
  };

  useEffect(() => {
    const fetchPermitDetails = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_hazard_precautions?work_permit_id=${id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );


        const hazardPrecaution = response.data.hazard_precautions[0]; 
        if (hazardPrecaution) {
          setFormData({
            precaution_description: hazardPrecaution.precaution_description || "",
            permit_id: hazardPrecaution.id,
          });
        } else {
          console.warn("No hazard precautions found.");
        }
      } catch (error) {
        console.error("Error fetching hazard precautions:", error);
      }
    };

    fetchPermitDetails();
  }, [id]);

  
  
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

 
  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      // Prepare payload
      const dataToSave = {
        ...formData,
        work_permit_id: id, // Attach form_id for both add and update
      };
  
      let response;
  
      if (formData.permit_id) {
        // Update API call (when permit_id exists)
        dataToSave.id = formData.permit_id; // Ensure id is attached for update
        response = await axios.put(
          `${API_URL}/api/update_hazard_precaution`,
          dataToSave,
          {
            headers: {
              Authorization: authKey,
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        // Add API call
        response = await axios.post(
          `${API_URL}/api/add_hazard_precaution`,
          dataToSave,
          {
            headers: {
              Authorization: authKey,
              "Content-Type": "application/json",
            },
          }
        );
      }
  
      // Handle success response
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar(
          formData.permit_id
            ? "Precaution details updated successfully."
            : "Precaution details added successfully."
        );
  
        // Navigate to the next tab or page after success
        setTimeout(() => {
          navigate(`/cold-work-permit`);
        }, 1000);
      } else {
        throw new Error(response.data.message || "Failed to save permit details.");
      }
    } catch (error) {
      // Handle errors
      console.error("Error saving permit details:", error);
      setSeverity("error");
      showSnackbar(
        error.response?.data?.message ||
          "An error occurred while saving permit details."
      );
    } finally {
      setIsLoading(false);
    }
  };
  // const handleSaveClick = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");
  
  //     // Prepare payload
  //     const dataToSave = {
  //       id: id,
  //       work_permit_id: id,
  //       permit_id: formData.permit_id,
  //       precaution_description: formData.precaution_description,
  //     };
  
  //     console.log("Payload being sent:", dataToSave);
  
  //     let response;
  
  //     if (formData.permit_id) {
  //       // Update API call
  //       response = await axios.put(
  //         `${API_URL}/api/update_hazard_precaution`,
  //         dataToSave,
  //         {
  //           headers: {
  //             Authorization: authKey,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //     } else {
  //       // Add API call
  //       response = await axios.post(
  //         `${API_URL}/api/add_hazard_precaution`,
  //         dataToSave,
  //         {
  //           headers: {
  //             Authorization: authKey,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //     }
  
  //     console.log("API response:", response);
  
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar(
  //         formData.permit_id
  //           ? "Precaution details updated successfully."
  //           : "Precaution details added successfully."
  //       );
  
  //       // Navigate to another page on success
  //       setTimeout(() => {
  //         navigate(`/cold-work-permit`);
  //       }, 1000);
  //     } else {
  //       throw new Error(response.data.message || "Failed to save details.");
  //     }
  //   } catch (error) {
  //     console.error("Error occurred:", error.response || error.message);
  
  //     setSeverity("error");
  //     showSnackbar(
  //       error.response?.data?.message ||
  //         "An error occurred while saving the details."
  //     );
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  
  
  
  
  return (
    <>
      <div className="data-form-content">
        <div className="data-form-header">
          <div className="data-form-header-content">
            <div className="data-form-heading-content-left-side">
              New Cold Work Permit
            </div>
            <div className="data-form-heading-content-right-side"></div>
          </div>
        </div>
        <div className="header-separator"></div>
        <div className="data-form-body">
          <form className="form-detail">
            <div className="new-data-form">
            
              <div className="cold-remark">
              <div>Remarks:</div>
                <div>1.The activity has the following expected residual hazards (Tick the relevant items): lack of Oxygen/H₂S, Toxic Gases, Combustible Cases/Pyrophoric Iron/Corrosive Chemicals/Steam-Condensable/others.
                </div>
                <div>2.Following PPE to be used in addition to standards PPEs (Helmet, Safety Shoes, Hand gloves, Boiler suit) Face shield/Apron/ Goggles/Dust Respirator/ Fresh Air mask/Life line/Safety belt (Full body harness)/Airline/Earmuff etc.
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="precaution_description">
                  Additional Precaution, if any
                </label>
                <div className="input-group">
                  <textarea
                    type="message"
                    rows={4}
                    cols={5}
                    name="precaution_description"
                    value={formData.precaution_description}
                    onChange={handleInputChange}
                    className="textarea"
                    placeholder="Enter Additional Precaution"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="footer">
          <div className="button-group">
            <button
              className="data-form-save-button"
              type="save"
              onClick={handleSaveClick}
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
            <button
              className="data-form-cancel-button"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
            
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Form3;
