import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./NotificationPopup.css";
import API_URL from "../Config";
import "./DailyProgressEmail.css";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import "../Responsive/PopupResponsive.css";
import PaginationForPopup from "../Component/PaginationForPopup";
import { formatDistanceToNow } from "date-fns";

const NotificationPopup = ({ updateTotalUnread, onClose }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const popupRef = useRef(null);
  const [page, setPage] = useState(1);
  const userName = localStorage.getItem("userName");

  const [notifications, setNotifications] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/notifications`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      setNotifications(response.data.notifications);
      updateTotalUnread(response.data.unread);
      setTotalItems(response.data.total);
    } catch (error) {
      console.error("Error fetching frequency data:", error.message);
    }
  };
  useEffect(() => {
    fetchData(page, itemsPerPage);
  }, [page, itemsPerPage]);

  const handleClose = () => {
    onClose();
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      const authKey = localStorage.getItem("authKey"); // Retrieve authKey from localStorage
      const response = await axios.put(
        `${API_URL}/api/notifications/mark-read`, // API URL
        {
          notification_ids: [notificationId], // Payload: Sending the clicked notification ID
        },
        {
          headers: {
            Authorization: authKey, // Setting the Authorization header
          },
        }
      );

      console.log(response.data.message); // Log success message
      // Optionally refresh notifications or update state here
      fetchData();
    } catch (error) {
      console.error("Error marking notification as read:", error); // Log error for debugging
    }
  };

  const firstLetter = userName ? getInitials(userName) : "";

  function getInitials(userName) {
    const nameParts = userName.trim().split(" ");

    const titles = ["Mr.", "Miss", "Ms.", "Mrs."];

    const initials = nameParts
      .filter((part) => !titles.includes(part))
      .map((part) => part.charAt(0).toUpperCase())
      .slice(0, 2) // Take only the first 2 initials
      .join("");

    return initials;
  }
  const handleView = () => {
    navigate("/all-notification");
    handleClose();
  };

  // function formatTimeAgo(dateString) {
  //   const createdAt = new Date(dateString);
  //   const now = new Date();
  //   const diffInMilliseconds = now - createdAt;
  //   const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  //   const diffInMinutes = Math.floor(diffInSeconds / 60);
  //   const diffInHours = Math.floor(diffInMinutes / 60);
  //   const diffInDays = Math.floor(diffInHours / 24);
  
  // if (diffInMinutes < 60) {
  //     return `${diffInMinutes} minutes ago`;
  //   } else if (diffInHours < 24) {
  //     return `${diffInHours} hours ago`;
  //   } else if (diffInDays === 1) {
  //     return "Yesterday";
  //   } else {
  //     return `${diffInDays} days ago`;
  //   }
  // }

  function formatTimeAgo(dateString) {
    // Parse the created time as UTC
    const createdAt = new Date(dateString); 

    // Force `now` to use UTC instead of local time
    const nowUTC = new Date(dateString);; 

    console.log("Created At (GMT):", createdAt.toISOString());
    console.log("Now (Corrected UTC Time):", nowUTC.toISOString());

    // Convert both timestamps to milliseconds
    const diffInMilliseconds = nowUTC.getTime() - createdAt.getTime();

    console.log("Time Difference (ms):", diffInMilliseconds);

    // Prevent negative values (for future dates)
    if (diffInMilliseconds < 0) {
        console.log("Future date detected, returning 'Just now'");
        return "Just now";
    }

    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    console.log("Difference in Seconds:", diffInSeconds);
    console.log("Difference in Minutes:", diffInMinutes);
    console.log("Difference in Hours:", diffInHours);
    console.log("Difference in Days:", diffInDays);

    if (diffInMinutes < 1) {  
        return "Less than a minute ago";  
    } else if (diffInMinutes < 60) {  
        return `${diffInMinutes} minutes ago`;  
    } else if (diffInHours < 24) {  
        return `${diffInHours} hours ago`;  
    } else if (diffInDays === 1) {  
        return "Yesterday";  
    } else {  
        return `${diffInDays} days ago`;  
    }
}

// const getTimeAgo = (timestamp) => {
//   // Ensure correct parsing of GMT time
//   const date = new Date(timestamp);
//   return formatDistanceToNow(date, { addSuffix: true });
// };



const getTimeAgo = (timestamp) => {
  // Parse the notification time (it is in UTC)
  const date = new Date(timestamp);

  // Get the current time in IST
  const nowUTC = new Date();
  const nowIST = new Date(nowUTC.getTime() + 5.5 * 60 * 60 * 1000); // Convert to IST by adding 5.5 hours

  // Convert notification time to IST
  const notificationIST = new Date(date.getTime() + 5.5 * 60 * 60 * 1000);

  // Ensure correct relative time calculation
  const timeAgo = formatDistanceToNow(date, { addSuffix: true });

  console.log(
    `Notification Time (IST): ${notificationIST.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })}, ` +
    `Current Time (IST): ${nowIST.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })}, ` +
    `Time Ago: ${timeAgo}`
  );

  return timeAgo;
};

  return (
    <div className="notification-popup-model" ref={popupRef}>
      <div className="notification-popup-content">
        <div className="notification-popup-model-header">
          <div className="notification-popup-model-content-header">
            <div className="notification-popup-model-content-header-left-part">
              Notifications
            </div>
            <div className="notification-popup-model-content-header-right-part">
              <RxCross2 onClick={handleClose} />
            </div>
          </div>
        </div>
        <div className="header-separator"></div>
        <div className="notification-body">
          {notifications.length > 0 ? (
            <div className="notification-body-container">
              {notifications.map((notification, index) => (
                <>
                  <div
                    key={notification.id}
                    className={`notification-body-content ${
                      notification.read_status === 0 ? "unread" : "read"
                    }`}
                    onClick={() => markNotificationAsRead(notification.id)} // Call the function with the notification ID
                    style={{ cursor: "pointer" }} // Add pointer cursor for better UX
                  >
                    <div className="username-letter-n">
                      <div className="letter-background-notification">
                      <div className="first-letter-n">
  {notification.requested_by
    ? notification.requested_by
        .split(" ") // Split by space
        .slice(0, 2) // Take only the first two words
        .map((word) => word[0]) // Get the first letter of each
        .join("")
        .toUpperCase() // Convert to uppercase
    : ""}
</div>

                      </div>
                    </div>
                    <div className="notification-title-message">
                      <div className="notification-title">
                        {notification.title}
                      </div>
                      <div className="notification-message">
                        {notification.message}
                      </div>
                      <div className="notification-message">
                      {getTimeAgo(notification.created_at)}
                      </div>
                    </div>
                    <div className="">
                      {/* <div className="notification-dot"></div> */}
                      <div
                        className={`notification-dot ${
                          notification.read_status === 0 ? "bg-blue" : "bg-gray"
                        }`}
                      ></div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          ) : (
            <p>No notifications available</p>
          )}
        </div>
        <div className="header-separator"></div>
        <div className="footers-notification">
          <button className="view-notification" onClick={handleView}>
            View All Notifications{" "}
          </button>
          {/* <PaginationForPopup
          showPerPage={itemsPerPage}
          total={totalItems}
          onPaginationChange={handlePaginationChange}
        /> */}
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default NotificationPopup;
