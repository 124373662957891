import React from "react";
import IsolationTemplate1 from "./IsolationTemplate1"; // Ensure the correct path
import IsolationTemplate2 from "./IsolationTemplate2"; // Ensure the correct path
import IsolationTemplate3 from "./IsolationTemplate3"; // Ensure the correct path
import IsolationTemplate4 from "./IsolationTemplate4"; // Ensure the correct path

const IsolationTemplate = () => {
  return (
    <>
    <div className="isolation-permit">
    <div className="isolation-permit-pdf">
        <IsolationTemplate1/>
        <IsolationTemplate2/>
        <IsolationTemplate3/>
        <IsolationTemplate4/>
        </div>
        </div>
    </>
  )

};

export default IsolationTemplate;
