import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ColdWorkPermit.css"; // Import the CSS file with the blinking effect
import PermitRequestForIssuer from "../ColdWorkPermit/PermitRequestForIssuer";
import { Tabs, Tab } from "@mui/material";
import axios from "axios";
import API_URL from "../../src/Config";

function PermitRequestForIssuerTab({ isSidebarExpanded }) {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

   const [tabCounts, setTabCounts] = useState({
      Issued: 0,
      Rejected: 0,
      Closed: 0,
      Draft: 0,
      Submitted: 0,
    });

    const [notificationCounts, setNotificationCounts] = useState({
      Issued: 0,
      Rejected: 0,
      Closed: 0,
      Draft: 0,
      Submitted: 0,
      Approved: 0,
    });
    const [notificationCountsIds, setNotificationCountsIds] = useState({
      Issued: 0,
      Rejected: 0,
      Closed: 0,
      Draft: 0,
      Submitted: 0,
      Approved: 0,
    });

  const [newCounts, setNewCounts] = useState({
    Approved: 0,
    Issued: 0,
    Rejected: 0,
    Closed: 0,
    pending: 0,
  });

  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get("tab");

  const [tabValue, setTabValue] = useState(tabFromUrl || "NewRequests");

  useEffect(() => {
    if (tabValue === "NewRequests") {
      console.log("notificationCountsIds.Submitted:", notificationCountsIds.Submitted);
      console.log("Length Check:", notificationCountsIds.Submitted?.length);
    }
  
    if (tabValue === "NewRequests" && notificationCountsIds.Submitted?.length > 0) {
      handleTabClick("Submitted");
    }
  }, [tabValue, notificationCountsIds]);
  
  

  const updateNewCounts = (counts) => {
    setNewCounts({
      Approved: counts.Approved || 0,
      Issued: counts.Issued || 0,
      Rejected: counts.Rejected || 0,
      Closed: counts.Closed || 0,
      pending: counts.pending || 0,
    });
  };
  const updateTotalItems = (counts) => {
    setTabCounts({
      Approved: counts.Approved || 0,
      Issued: counts.Issued || 0,
      Rejected: counts.Rejected || 0,
      Closed: counts.Closed || 0,
      Submitted: counts.Submitted || 0,
      Draft: counts.Draft || 0,
    });
  };


  const updateNotification = (counts) => {
    setNotificationCounts({
      Approved: counts.Approved || 0,
      Issued: counts.Issued || 0,
      Rejected: counts.Rejected || 0,
      Closed: counts.Closed || 0,
      Submitted: counts.Submitted || 0,
      Draft: counts.Draft || 0,
    });
  };

  const updateNotificationIds = (counts) => {
    setNotificationCountsIds({
      Approved: counts.Approved || 0,
      Issued: counts.Issued || 0,
      Rejected: counts.Rejected || 0,
      Closed: counts.Closed || 0,
      Submitted: counts.Submitted || 0,
      Draft: counts.Draft || 0,
    });
  };

  useEffect(() => {
    if (tabFromUrl && tabFromUrl !== tabValue) {
      setTabValue(tabFromUrl);
    }

  
    
  }, [location.search]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    navigate(`?tab=${newValue}`);
  };
  const getStatusForTab = (tabValue) => {
    switch (tabValue) {
      case "NewRequests":
        return "Submitted";
      case "Approved":
        return "Approved"; 
      case "Issued":
        return "Issued";
      case "Rejected":
        return "Rejected"; 
      case "Closed":
        return "Closed";

      default:
        return null;
    }
  };
 
  const handleTabClick = async (tabName) => {
    try {
      // Fetch notification_ids from state (notificationCountsIds) based on the selected tab
      const notificationIds = notificationCountsIds[tabName];
  
      if (!notificationIds || notificationIds.length === 0) return;
  
      const authKey = localStorage.getItem("authKey");
  
      const response = await axios.put(
        `${API_URL}/api/notifications/update_read_status`,
        { notification_ids: notificationIds }, // Send correct payload
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );
  
      console.log(`Notifications for ${tabName} marked as read.`, response.data);
    } catch (error) {
      console.error("Error updating read status:", error);
    }
  };
  

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {/* <div className="data-list-header">
          <div className="data-list-header-content">
            <div className="data-list-heading-content-left-side">
              All Cold Work Permit Request
            </div>

            <div className="data-list-heading-content-right-side">
              <Link to="/permit-request-form" className="data-list-new">
                <Tooltip title="New" arrow>
                  <button className="new-button">
                    <HiOutlinePlus className="new-plus-icon" /> New Permit
                    Request
                  </button>
                </Tooltip>
              </Link>
            </div>
          </div>
        </div> */}
      
          <div className="data-list-content permit-tab" ref={containerRef}>
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => handleTabChange(event, newValue)}
              aria-label="report tabs"
              className="tabs-container"
            >
              {/* <Tab label="New Requests" value="NewRequests" />
              <Tab label="To Be Approved" value="Approved" />
              <Tab label="Issued" value="Issued" />
              <Tab label="Rejected" value="Rejected" />
              <Tab label="Closed " value="Closed" /> */}
              <Tab
                label={
                  <div className="tab-label-container">
                    <span className="tab-status-label">New Requests</span>
                   
                      <span
                     className="tab-count"
                      >
                        ({tabCounts.Submitted || 0})
                        {notificationCounts.Submitted > 0 && (
                        <span className="new-tab-count">
                        {notificationCounts.Submitted || 0}
                        </span>
                         )}
                      </span>
                   
                  </div>
                }
                value="NewRequests"
                onClick={() => handleTabClick("Submitted")}
              />

              {/* <Tab
                label={
                  <div className="tab-label-container">
                    <span className="tab-status-label">To Be Approved</span>
                    <span
                      className={`tab-count ${getBlinkingClass(
                        newCounts.Approved
                      )}`}
                    >
                      {newCounts.Approved}
                    </span>
                  </div>
                }
                value="Approved"
              /> */}
              <Tab
                label={
                  <div className="tab-label-container">
                    <span className="tab-status-label">To Be Approved</span>
                  
                      <span
                     className="tab-count"
                      >
                        ({tabCounts.Approved || 0})
                        {notificationCounts.Approved > 0 && (
                        <span className="new-tab-count">
                        {notificationCounts.Approved || 0}
                        </span>
                        )}
                      </span>
                  </div>
                }
                value="Approved"
                onClick={() => handleTabClick("Approved")}
              />
              <Tab
                label={
                  <div className="tab-label-container">
                    <span className="tab-status-label">Issued</span>
                   
                      <span
                     className="tab-count"
                      >
                        ({tabCounts.Issued || 0})
                        {notificationCounts.Issued > 0 && (
                        <span className="new-tab-count">
                        {notificationCounts.Issued || 0}
                        </span>
                        )}
                      </span>
                   
                  </div>
                }
                value="Issued"
                onClick={() => handleTabClick("Issued")}
              />

              <Tab
                label={
                  <div className="tab-label-container">
                    <span className="tab-status-label">Rejected</span>
                   
                      <span
                     className="tab-count"
                      >
                        ({tabCounts.Rejected || 0})
                        {notificationCounts.Rejected > 0 && (
                        <span className="new-tab-count">
                        {notificationCounts.Rejected || 0}
                        </span>
                        )}
                      </span>
                 
                  </div>
                }
                value="Rejected"
                onClick={() => handleTabClick("Rejected")}
              />

              <Tab
                label={
                  <div className="tab-label-container">
                    <span className="tab-status-label">Closed</span>
                   
                      <span
                     className="tab-count"
                      >
                        ({tabCounts.Closed || 0})
                        {notificationCounts.Closed > 0 && (
                        <span className="new-tab-count">
                        {notificationCounts.Closed || 0}
                        </span>
                        )}
                      </span>
                  </div>
                }
                value="Closed"
                onClick={() => handleTabClick("Closed")}
              />
            </Tabs>
            <div className="header-separater"></div>
            <div className="tabs-content">
              <PermitRequestForIssuer
                status={getStatusForTab(tabValue)}
                updateNewCounts={updateNewCounts}
                updateTotalItems={updateTotalItems}
                updateNotification={updateNotification}
                updateNotificationIds={updateNotificationIds}
              />
            </div>
          </div>
  
      </div>

    </>
  );
}

export default PermitRequestForIssuerTab;
