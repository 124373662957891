import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns"; // Import format from date-fns
import { RxCrossCircled } from "react-icons/rx";
import { setHours, setMinutes } from "date-fns";

function CetificateIsolation({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { id } = useParams();
  console.log("Fetched ID:", id);
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [issuedTo, setIssuedTo] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDepartment, setShowDepartment] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [permitType, setPermitType] = useState([]);
  const [selectedPermitType, setSelectedPermitType] = useState(null);
  const [addedTags, setAddedTags] = useState([]);
  const [showContractor, setShowContractor] = useState(false);
  const [showareaissuing, setshowareaissuing] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedTagNo, setSelectedTagNo] = useState(null);
  const [formData, setFormData] = useState({
    section: "",
    department_id: "",
    form_id: "",
    area: "",
    equipment_no: "",
    issued_to: "",
    work_description: "",
    area_issuing_permit: "",
    unit: "",
    location: "",
    valid_from: "",
    valid_to: "",
    receiver_name: "",
    permit_type_id: "",
    equipment_number_isloated:"",
    name_of_equipment:"",
    circuit_to_isolated:"",
  });
  const [issuedToOptions] = useState([
    { value: "department", label: "Department" },
    { value: "section", label: "Section" },
    { value: "Area issuing the Permit", label: "Area issuing the Permit" },
  ]);

  //   const handleIssuedToChange = (selectedOption) => {
  //     setIssuedTo(selectedOption);
  //     // Update formData with the selected issued_to value
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       issued_to: selectedOption ? selectedOption.value : "",
  //     }));

  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       issued_to: selectedOption ? "" : prevErrors.issued_to, // Clear error if value exists
  //     }));
  //     // Show relevant fields based on the selection
  //     setShowDepartment(selectedOption?.value === "department");
  //     setShowSection(selectedOption?.value === "section");
  //     setshowareaissuing(selectedOption?.value === "Area issuing the permit");
  //   };

  const handleIssuedToChange = (selectedOption) => {
    setIssuedTo(selectedOption);

    // Update formData with selected value
    setFormData((prevState) => ({
      ...prevState,
      issued_to: selectedOption ? selectedOption.value : "",
    }));

    // Clear error if value exists
    setErrors((prevErrors) => ({
      ...prevErrors,
      issued_to: selectedOption ? "" : prevErrors.issued_to,
    }));

    // Debugging: Check selected value
    console.log("Selected Option:", selectedOption?.value);

    // Show relevant fields based on the selection
    setShowDepartment(selectedOption?.value === "department");
    setShowSection(selectedOption?.value === "section");
    setshowareaissuing(selectedOption?.value === "Area issuing the Permit"); // Ensure case matches
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Remove the validation error for this specific field when the user types
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() ? "" : prevErrors[name], // Clear error if value exists
    }));
  };

  // const handleSaveClick = async (request_status) => {
  //   // console.log("Clicked button status:", status); // Log the status

  //   const errors = {};

  //   // // Validate fields
  //   // if (Object.keys(errors).length > 0) {
  //   //   setErrors(errors);
  //   //   showSnackbar("Please fill all required fields.");
  //   //   return; // Prevent further execution if validation fails
  //   // }

  //   const formattedValidFrom = startDate
  //     ? format(startDate, "yyyy-MM-dd HH:mm:ss")
  //     : "";
  //   const formattedValidTo = endDate
  //     ? format(endDate, "yyyy-MM-dd HH:mm:ss")
  //     : "";

  //   // Prepare payload
  //   const payload = {
  //     ...formData,
  //     valid_from: formattedValidFrom,
  //     valid_to: formattedValidTo,
  //     equipment_ids: addedTags.map((tag) => tag.id), // Collect all added tags' IDs
  //     id: formData.work_permit_id,
  //     request_status, // Add the status to the payload
  //   };

  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     // Make the API call
  //     const response = await axios.post(
  //       `${API_URL}/api/add_permit_work_details`,
  //       payload,
  //       {
  //         headers: { Authorization: authKey },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Permit details updated successfully.");
  //       setTimeout(() => {
  //         navigate(`/permit-requests`);
  //       }, 1000);
  //     } else {
  //       throw new Error(
  //         response.data.message || "Failed to update permit details."
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error saving permit details:", error);
  //     setSeverity("error");
  //     showSnackbar("An error occurred while saving permit details.");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveClick = async (request_status) => {
    // Clear any existing errors
    const errors = {};

    // Validate mandatory fields

    if (!request_status) {
      errors.request_status = "Request status is required";
    }

    if (!selectedPermitType) {
      errors.selectedPermitType = "Permit Type is required";
    }

    if (!formData.receiver_name) {
      errors.receiver_name = "Reciver name is required";
    }

    if (!formData.issued_to) {
      errors.issued_to = "Issued to is required";
    }

    if (!formData.work_description?.trim()) {
      errors.work_description = "Work description is required";
    }

    if (!startDate) {
      errors.valid_from = "Start date and time is required";
    }

    if (!endDate) {
      errors.valid_to = "End date and time is required";
    }

    // If there are any validation errors
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setSeverity("error");
      showSnackbar("Please fill all required fields");
      return;
    }

    const formattedValidFrom = startDate
      ? format(startDate, "yyyy-MM-dd HH:mm:ss")
      : "";
    const formattedValidTo = endDate
      ? format(endDate, "yyyy-MM-dd HH:mm:ss")
      : "";

    // Prepare payload
    const payload = {
      ...formData,
      valid_from: formattedValidFrom,
      valid_to: formattedValidTo,
      equipment_ids: addedTags.map((tag) => tag.id),
      id: formData.work_permit_id,
      request_status,
    };

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.post(
        `${API_URL}/api/add_permit_work_details`,
        payload,
        {
          headers: { Authorization: authKey },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Permit details updated successfully.");
        setTimeout(() => {
          navigate(`/permit-requests`);
        }, 1000);
      } else {
        throw new Error(
          response.data.message || "Failed to update permit details."
        );
      }
    } catch (error) {
      console.error("Error saving permit details:", error);
      setSeverity("error");
      showSnackbar("An error occurred while saving permit details.");
    } finally {
      setIsLoading(false);
    }
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCancelClick = () => {
    navigate("/permit-requests");
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setFormData((prevUserData) => ({
      ...prevUserData,
      department_id: selectedOption ? selectedOption.value : "",
      department_name: selectedOption ? selectedOption.label : "",
    }));

    // Clear department error when it changes
    setErrors((prevErrors) => ({
      ...prevErrors,
      department: null,
    }));
  };

  const fetchOptions = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_equipment_tag_no`, {
        headers: {
          Authorization: authKey,
        },
      });

      const optionsData = response.data.Items.map((item) => ({
        value: item.ID,
        label: `${item.TagNo}`,
      }));

      // Set options to state
      setTagOptions(optionsData);

      // Handle the case where no options are returned
      if (optionsData.length === 0) {
        console.warn("No options available from API");
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  // const handleTagNoChange = (selectedOption) => {
  //   setSelectedTagNo(selectedOption);
  // };

  const handleTagNoChange = (selectedOption) => {
    setSelectedTagNo(selectedOption);

    // Automatically add the selected tag
    if (selectedOption) {
      console.log("Selected Tag No:", selectedOption); // Log the selected tag details
      setAddedTags((prevTags) => {
        const updatedTags = [
          ...prevTags,
          { tagNo: selectedOption.label, id: selectedOption.value },
        ];
        console.log("Updated Added Tags:", updatedTags); // Log the updated added tags
        return updatedTags;
      });
      setSelectedTagNo(null);
    } else {
      console.log("No tag selected."); // Log if no tag was selected
    }
  };

  // const handleAddTag = () => {
  //   if (selectedTagNo) {
  //     console.log("Selected Tag No:", selectedTagNo); // Log the selected tag details
  //     setAddedTags((prevTags) => {
  //       const updatedTags = [
  //         ...prevTags,
  //         { tagNo: selectedTagNo.label, id: selectedTagNo.value },
  //       ];
  //       console.log("Updated Added Tags:", updatedTags); // Log the updated added tags
  //       return updatedTags;
  //     });
  //     setSelectedTagNo(null);
  //   } else {
  //     console.log("No tag selected."); // Log if no tag was selected
  //   }
  // };

  const handleRemoveTag = (tagId) => {
    // Remove the tag from the added tags list based on its ID
    setAddedTags((prevTags) => prevTags.filter((tag) => tag.id !== tagId));
  };

  const fetchPermitType = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_work_permit_types`, {
        headers: {
          Authorization: authKey,
        },
      });

      const permitTypeOptions = response.data.work_permit_types.map(
        (permitType) => ({
          value: permitType.id,
          label: permitType.work_permit_name,
        })
      );

      setPermitType(permitTypeOptions);
    } catch (error) {
      console.error("Error fetching customers data:", error.message);
    }
  };

  useEffect(() => {
    fetchPermitType();
  }, []);

  const handlePermitTypeChange = (selectedOption) => {
    setSelectedPermitType(selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      permit_type_id: selectedOption ? selectedOption.value : "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      selectedPermitType: selectedOption ? "" : prevErrors.selectedPermitType,
    }));
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                Certificate Isolation
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">

                <div className="form-group">
                  <label htmlFor="DepartmentName">
                    Date & Time<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <DatePicker
                      id="start-date"
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);

                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          valid_from: date ? "" : prevErrors.valid_from,
                        }));
                      }}
                      showTimeSelect
                      dateFormat="dd/MM/yyyy hh:mm a" // Date format: dd/MM/yyyy and Time format: hh:mm
                      placeholderText="Select start date and time"
                      className="datepicker"
                      minDate={new Date()}
                      minTime={
                        startDate &&
                        startDate.toDateString() === new Date().toDateString()
                          ? new Date() // Disable past times for today
                          : setHours(setMinutes(new Date(), 0), 0) // Allow full range on future dates
                      }
                      maxTime={setHours(setMinutes(new Date(), 59), 23)}
                    />
                  </div>
                  {errors.valid_from && (
                    <div className="error-message">{errors.valid_from}</div>
                  )}
                </div>

                <div className="form-group-select">
                  <label htmlFor="issued_to">
                    Issued to: <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="issued_to"
                    options={issuedToOptions}
                    value={issuedToOptions.find(
                      (option) => option.value === formData.issued_to
                    )}
                    onChange={handleIssuedToChange}
                    placeholder="Select Issued To"
                    isClearable
                  />
                  {errors.issued_to && (
                    <div className="error-message">{errors.issued_to}</div>
                  )}
                </div>
                {showDepartment && (
                  <div className="form-group-select">
                    <label htmlFor="department-select">Department</label>
                    <Select
                      id="department-select"
                      options={departments}
                      value={selectedDepartment}
                      onChange={handleDepartmentChange}
                      placeholder="Select Department"
                      isClearable
                    />
                  </div>
                )}

                {showSection && (
                  <div className="form-group">
                    <label htmlFor="">Section</label>
                    <div className="input-group">
                      <input
                        type="text"
                        name="section"
                        value={formData.section}
                        onChange={handleInputChange}
                        placeholder="Enter section"
                      />
                    </div>
                  </div>
                )}

                {showareaissuing && (
                  <div className="form-group">
                    <label htmlFor="contractor">Area Issuing Permit</label>

                    <div className="input-group">
                      <input
                        type="text"
                        name="area_issuing_permit"
                        value={formData.area_issuing_permit}
                        onChange={handleInputChange}
                        placeholder="Enter area issuing permit"
                      />
                    </div>
                  </div>
                )}
            
                <div className="form-group">
                  <label htmlFor="area">Circuit no.</label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="equipment_number_isloated"
                      value={formData.equipment_number_isloated}
                      onChange={handleInputChange}
                      placeholder="Enter Equipment Number"
                    />
                  </div>
                </div>

               

                {/* <div className="form-group-tag-no">
                  <div className="form-group-select form-group-tag-no">
                    <label htmlFor="ContactNo">Equipment No</label>
                    <Select
                      id="TagNo"
                      options={tagOptions}
                      value={selectedTagNo}
                      onChange={handleTagNoChange}
                      placeholder="Select Equipment No"
                      isSearchable
                    />
                  </div>
                </div> */}
                
                <div className="form-group">
                  <label htmlFor="area">Name of the Equipment</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="name_of_equipment"
                      value={formData.name_of_equipment}
                      onChange={handleInputChange}
                      placeholder="Enter name of equipment"
                    />
                  </div>
                </div>

            

                <div className="form-group">
                  <label htmlFor="area">Circuit To Be energized</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="circuit_to_isolated"
                      value={formData.circuit_to_isolated}
                      onChange={handleInputChange}
                      placeholder="Enter the circuit to be energized"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="work_description">
                    Description of work<span className="text-danger">*</span>
                  </label>
                  <div className="input-group permit-input">
                    <textarea
                      name="work_description"
                      rows={4}
                      cols={10}
                      value={formData.work_description}
                      onChange={handleInputChange}
                      className="textarea"
                      placeholder="Enter description"
                    />
                  </div>
                  {errors.work_description && (
                    <div className="error-message">
                      {errors.work_description}
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                // onClick={() => handleSaveClick("Draft")}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save as Draft"}
              </button>
              <button
                className="request-button"
                type="save"
                // onClick={() => handleSaveClick("Submitted")}
                disabled={isLoading}
              >
                {isLoading ? "Sending Request..." : "Send Request"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default CetificateIsolation;
